import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useSelector } from "react-redux";
import { getAssessmentColors } from "../../Config/utils/configSelectors";
import { showRatingColor } from "../../Snapshots/utils/snapshotHelpers";

const TraitPieChart = ({ className, showGridPosition, ratingCounts = [] }) => {
    const [data, setData] = useState([]);
    const assessmentColors = useSelector(getAssessmentColors)

    useEffect(() => {
        let newData = [];
        if (showGridPosition) {
            newData = ratingCounts.map((entry) => {
                return { value: entry.count, color: entry.color };
            });
        } else {
            newData = Object.entries(ratingCounts).map(([rating, count]) => {
                return { value: count, color: showRatingColor(assessmentColors, rating) };
            });
        }
        if (newData.length === 0) {
            newData = [{ value: 1, color: "#e3e3e3" }];
        }
        setData(newData);
    }, [assessmentColors, showGridPosition, ratingCounts]);

    return (
        <div className={className} style={{ height: "100%", margin: "auto" }}>
            <PieChart data={data} />
        </div>
    );
};

export default TraitPieChart;
