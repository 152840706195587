import React from "react";
import { Select } from "../../../../common/components";
import MyRange from "../../../../components/MyComponents/Range";
import { CONFIDENCE_LABELS, OBJECTIVE_BLOCKER_OPTIONS } from "../../../../constants";

const CheckInBlock = ({ hidden, objective, updateField, readOnly }) => {
    if (hidden) return null;
    const { confidence, blockers } = objective;
    const confidenceLabel = CONFIDENCE_LABELS[confidence] || "Confidence not set";

    const handleSetConfidence = (value) => {
        updateField("confidence", value);
    };

    const handleSetBlockers = (value) => {
        updateField("blockers", value);
    };

    return (
        <div className="space-x-12 flex border rounded-lg p-4 bg-blue-100">
            <div className="flex-1 space-y-2">
                <h6 className="text-sm text-base-800 tracking-tight font-medium">Confidence</h6>
                <MyRange
                    disabled={readOnly}
                    hideVal
                    min={0}
                    max={100}
                    step={25}
                    value={confidence || 0}
                    size="xs"
                    color="primary"
                    showSteps
                    onChange={handleSetConfidence}
                />
                <div className="tracking-tight text-center font-medium text-sm">{confidenceLabel}</div>
            </div>
            <div className="flex-1 space-y-2">
                <h6 className="text-sm text-base-800 tracking-tight font-medium">
                    What might prevent this being achieved?
                </h6>
                <Select
                    disabled={readOnly}
                    selectedIds={blockers}
                    menuPlacement="top"
                    options={OBJECTIVE_BLOCKER_OPTIONS}
                    onChange={handleSetBlockers}
                    maxMenuHeight={180}
                    isMulti
                    clearable
                />
            </div>
        </div>
    );
};

export default CheckInBlock;
