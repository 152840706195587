import { createSelector } from "@reduxjs/toolkit";
import { getRole } from "../../Roles/utils/roleSelectors";

const getEmployees = createSelector(
    (state) => state.businessUnit.employees,
    (employees) => employees || {}
);

const getEmployee = createSelector(
    (state, employeeId) => state.businessUnit.employees?.[employeeId],
    (employee) => employee || {}
);

const getSelectedEmployeeId = createSelector(
    (state) => state.app.selectedEmployeeId,
    (selectedEmployeeId) => selectedEmployeeId
);

const getSelectedEmployee = createSelector(
    [getEmployees, getSelectedEmployeeId],
    (employees, selectedEmployeeId) => employees[selectedEmployeeId] || {}
);

const getEmployeeJobTitle = createSelector(
    [getEmployee, (state, employeeId) => getRole(state, getEmployee(state, employeeId)?.roleId)],
    (employee, role) => role?.jobTitle
);

const getEmployeeRole = createSelector(
    (state, empId) => state.roles[empId],
    (role) => role
);

const getEmployeeTalentBoardIds = createSelector(
    [(state, empId) => getRole(state, empId), (state) => state.businessUnit.boardMap],
    (role, boardMap) => {
        return Object.entries(boardMap)
            .filter(([_, linkedRoleIds]) => linkedRoleIds.includes(role.id))
            .map(([boardId, _]) => boardId);
    }
);

export {
    getEmployee,
    getEmployeeJobTitle,
    getEmployeeRole, getEmployeeTalentBoardIds, getEmployees, getSelectedEmployee, getSelectedEmployeeId
};

