import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEmployees } from "../../../../../../features/Employees/hooks/useEmployees";
import { useSelectOrgNodes } from "../../../../../../features/Organisation/hooks/useSelectOrgNodes";
import ReviewQueueTemplate from "../../../../../../features/Reviews/components/templates/ReviewQueueTemplate";
import { useReviewQueue } from "../../../../../../features/Reviews/hooks/useReviewQueue";

const ReviewQueuePage = () => {
    const navigate = useNavigate();
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const { getEmployee } = useEmployees();
    const { getEmployeePath } = useSelectOrgNodes();
    const reviewQueue = useReviewQueue();

    const handleGetEmployee = (employeeId) => {
        return getEmployee(employeeId);
    };

    const handleReviewClick = (reviewItem) => {
        const { cycleId, iterationId, revieweeId, responderId, approvalRequired } = reviewItem;
        const employeePath = getEmployeePath(revieweeId);
        if (responderId === userEmployeeId) {
            let reviewPath = `/${employeePath}/reviews/${cycleId}/${iterationId}/${responderId}`;
            if (approvalRequired) {
                reviewPath = `${reviewPath}?approval`;
            }
            navigate(reviewPath);
        } else {
            const nodePath = `/${employeePath}/talent-assessment`;
            navigate(nodePath);
        }
    };

    return (
        <div className="page page-padding">
            <ReviewQueueTemplate
                userEmployeeId={userEmployeeId}
                reviewQueue={reviewQueue}
                getEmployee={handleGetEmployee}
                onReviewClick={handleReviewClick}
            />
        </div>
    );
};

export default ReviewQueuePage;
