import { useEffect, useRef } from "react";
import { Each } from "../../../../../common/components";
import MeasureCard from "../MeasureCard/MeasureCard";
import classNames from "classnames";

const MeasureGroup = ({ locked, measures, updateObjective, title = "Measures", allowAdd = false }) => {
    const creatorRef = useRef(null);
    const initialisedRef = useRef(false);
    const measureCount = measures.length;

    useEffect(() => {
        if (creatorRef.current && initialisedRef.current) {
            creatorRef.current.scrollIntoView({ behavior: "smooth" });
            creatorRef.current.focus();
        }
        initialisedRef.current = true;
    }, [measures]);

    const handleEditOrAddMeasure = (measure) => {
        updateObjective("editOrAddMeasure", measure);
    };

    const handleRemoveMeasure = (measure) => {
        updateObjective("removeMeasure", measure);
    };

    const hidden = !allowAdd || locked;

    return (
        <div className="space-y-2">
            <h6 className="text-sm text-base-800 tracking-tight font-medium">{title}</h6>
            <div className="space-y-2">
                <Each
                    of={measures}
                    emptyStateProps={{ hidden: true }}
                    render={(measure) => {
                        return (
                            <MeasureCard
                                measure={measure}
                                locked={locked}
                                onChange={handleEditOrAddMeasure}
                                onRemove={handleRemoveMeasure}
                            />
                        );
                    }}
                />
                <MeasureCard
                    ref={creatorRef}
                    hidden={hidden}
                    placeholder={
                        measureCount === 0 ? "Add your first measure" : "Next Measure? (optional)"
                    }
                    creatorMode
                    locked={locked}
                    onChange={handleEditOrAddMeasure}
                    onRemove={handleRemoveMeasure}
                />
                <div
                    className={classNames(
                        { hidden: hidden || measureCount > 0 },
                        "bg-base-150 text-base-500 font-bold border rounded-box px-6 py-3 text-sm tracking-tight"
                    )}
                >
                    Next Measure
                </div>
            </div>
        </div>
    );
};

export default MeasureGroup;
