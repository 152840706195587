import { PropTypes } from "prop-types";
import React from "react";
import { USER_ROLES } from "../../../../constants";
import { IdPicker } from "../../../../components";
import { useClaims } from "../../hooks/useClaims";

const UserRolePicker = (props) => {
    const { selectedIds, canCreateAdmins, excludeIds, isMulti, hideNoRoleOption, isClearable } = props;
    const { isDeveloper } = useClaims();
    let userRoleOptions = { ...USER_ROLES };
    if (!canCreateAdmins && !isDeveloper) {
        delete userRoleOptions.admin;
    }
    if (hideNoRoleOption) {
        delete userRoleOptions.none;
    }

    const getLabel = (userRole) => {
        return userRole.label;
    };
    return (
        <IdPicker
            {...props}
            name="userRoles"
            getLabel={getLabel}
            options={userRoleOptions}
            selectedIds={Array.isArray(selectedIds) ? selectedIds : [selectedIds]}
            excludeIds={excludeIds}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

UserRolePicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    hideNoRoleOption: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

UserRolePicker.defaultProps = {
    selectedIds: [],
    excludeIds: [],
    isMulti: false,
    isClearable: false,
    onBlur: () => {},
    onChange: () => {},
};

export default UserRolePicker;
