import { isEqual, merge } from "lodash";
import { useEffect, useReducer, useRef, useState } from "react";

const INITIALIZE_OBJECTIVES = "INITIALIZE_OBJECTIVES";
const UPDATE_OBJECTIVE = "UPDATE_OBJECTIVE";
const DELETE_OBJECTIVE = "DELETE_OBJECTIVE";
const UPDATE_NOTE = "UPDATE_NOTE";
const UPDATE_FLAG = "UPDATE_FLAG";
const UPDATE_FLAGS = "UPDATE_FLAGS";
const UPDATE_RATINGS = "UPDATE_RATINGS";
const TOGGLE_ACTION_ID = "TOGGLE_ACTION_ID";

function reviewReducer(state = {}, action) {
    switch (action.type) {
        case UPDATE_FLAG: {
            const [fieldId, value] = action.payload;
            const currentFlags = state.flags || {};
            const newFlags = { ...currentFlags, [fieldId]: value };
            return { ...state, flags: newFlags };
        }
        case UPDATE_NOTE:
            const note = action.payload;
            const currentNotes = state.notes || [];
            const newNotes = currentNotes.filter((n) => {
                const authorIsSame = n.authorId === note.authorId;
                const iterationIsSame = n.iterationId === note.iterationId;
                const objectiveIsSame = n.objectiveId === note.objectiveId;
                const reviewNoteIdisSame = n.reviewNoteId === note.reviewNoteId;
                const sameNote = authorIsSame && iterationIsSame && objectiveIsSame && reviewNoteIdisSame;
                return !sameNote;
            });
            newNotes.push(note);
            return { ...state, notes: newNotes };
        case UPDATE_OBJECTIVE: {
            const objective = action.payload;
            const currentObjectives = state.objectives || {};
            const newObjectives = { ...currentObjectives, [objective.id]: objective };
            return { ...state, objectives: newObjectives };
        }
        case DELETE_OBJECTIVE: {
            const objectiveId = action.payload;
            const deleteObjectiveQueue = state.deleteObjectiveQueue || [];
            const currentObjectives = state.objectives || {};
            const newObjectives = { ...currentObjectives };
            delete newObjectives[objectiveId];
            deleteObjectiveQueue.push(objectiveId);
            return { ...state, objectives: newObjectives, deleteObjectiveQueue };
        }
        case INITIALIZE_OBJECTIVES: {
            const draftObjectives = state.objectives || {};
            const liveObjectives = action.payload;
            const mergedObjectives = merge({}, liveObjectives, draftObjectives);
            // if the objectiveId is in the delete queue, remove it
            const deleteObjectiveQueue = state.deleteObjectiveQueue || [];
            deleteObjectiveQueue.forEach((id) => {
                delete mergedObjectives[id];
            });
            return { ...state, objectives: mergedObjectives };
        }
        case UPDATE_RATINGS:
            const currentRatings = state.ratings || {};
            const newRatings = { ...currentRatings, ...action.payload };
            return { ...state, ratings: newRatings };
        case UPDATE_FLAGS:
            const currentFlags = state.flags || {};
            const newFlags = { ...currentFlags, ...action.payload };
            return { ...state, flags: newFlags };
        case TOGGLE_ACTION_ID:
            const actionId = action.payload;
            let scheduleActionIds = state.scheduleActionIds || [];
            if (scheduleActionIds.includes(actionId)) {
                scheduleActionIds = scheduleActionIds.filter((id) => id !== actionId);
            } else {
                scheduleActionIds.push(actionId);
            }
            return { ...state, scheduleActionIds };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

// TODO: cleanDepricatedFlags somewhere
export function useDraftReview(savedDraft = {}, initialFlagsAndRatings, liveObjectives) {
    const [draftIsReady, setDraftIsReady] = useState(false);
    const [draftHasChanged, setDraftHasChanged] = useState(false);
    const [draft, draftDispatch] = useReducer(reviewReducer, savedDraft);
    const initializedTalentRef = useRef(false);
    const initializedObjectivesRef = useRef(false);
    const draftSnapshot = useRef(draft);

    useEffect(() => {
        if (draftIsReady) {
            setDraftHasChanged(!isEqual(draftSnapshot.current, draft));
        }
    }, [draft, draftIsReady]);

    const handleSnapshotDraft = () => {
        draftSnapshot.current = draft;
        setDraftHasChanged(false);
    };

    // Add saved talent assessment to the draft, with local taking priority
    useEffect(() => {
        if (!initializedTalentRef.current) {
            const ratings = initialFlagsAndRatings?.ratings || {};
            const flags = initialFlagsAndRatings?.flags || {};
            draftDispatch({ type: "UPDATE_RATINGS", payload: ratings });
            draftDispatch({ type: "UPDATE_FLAGS", payload: flags });
            initializedTalentRef.current = true;
            const updatedDraftSnapshot = {
                ...draftSnapshot.current,
                flags: flags,
                ratings: ratings,
            };
            draftSnapshot.current = updatedDraftSnapshot;
            setDraftIsReady(initializedObjectivesRef);
        }
    }, [draftDispatch, initialFlagsAndRatings]);

    // Add live objectives to the draft, with local taking priority
    useEffect(() => {
        if (!initializedObjectivesRef.current) {
            draftDispatch({ type: "INITIALIZE_OBJECTIVES", payload: liveObjectives });
            initializedObjectivesRef.current = true;
            const updatedDraftSnapshot = {
                ...draftSnapshot.current,
                objectives: liveObjectives,
            };
            draftSnapshot.current = updatedDraftSnapshot;
            setDraftIsReady(initializedTalentRef);
        }
    }, [draftDispatch, liveObjectives]);

    return {
        draft,
        draftHasChanged,
        snapshotDraft: handleSnapshotDraft,
        updateObjective: (objective) => draftDispatch({ type: UPDATE_OBJECTIVE, payload: objective }),
        removeObjective: (objectiveId) => draftDispatch({ type: DELETE_OBJECTIVE, payload: objectiveId }),
        updateNote: (note) => draftDispatch({ type: UPDATE_NOTE, payload: note }),
        updateFlag: (fieldId, value) => draftDispatch({ type: UPDATE_FLAG, payload: [fieldId, value] }),
        updateRatings: (ratings) => draftDispatch({ type: UPDATE_RATINGS, payload: ratings }),
        toggleActionId: (actionId) => draftDispatch({ type: TOGGLE_ACTION_ID, payload: actionId }),
    };
}
