import React, { useEffect, useMemo, useState } from "react";
import WaitingImg from "../../../../../assets/img/emptystates/NoConnection.png";
import { Each, HelperDropdown } from "../../../../../common/components";
import ObjectiveSetting from "../../../../Objectives/components/organisms/ObjectiveSetting";
import NoteEditorBlock from "../../../components/organisms/NoteEditorBlock/NoteEditorBlock";

const EmptyState = () => (
    <div className="flex-1 flex flex-col items-center justify-center space-y-4">
        <h2>No Objectives Set</h2>
        <img src={WaitingImg} alt="A simple illustration of a sleeping cloud" />
        <div className="max-w-xl text-center space-y-4">
            <h5>No Objectives have been added to this review yet</h5>
            <p className="tracking-tight">
                Enter a short Objective title in the prompt below to start creating Objectives. (You'll be able to add
                more detail afterwards).
            </p>
        </div>
    </div>
);

function getLabels(isSelf, activeEmployee) {
    const labels = {};
    if (isSelf) {
        labels.shared = "You are invited to contribute the these objectives.";
        labels.own = "Your Next Objectives";
    } else {
        labels.shared = `These objectives have been made available to ${activeEmployee.firstname} to contribute to.`;
        labels.own = `${activeEmployee.firstname}'s Next Objectives`;
    }

    return labels;
}

const SetObjectivesStepTemplate = ({ values, actions }) => {
    const [selectedObjective, setSelectedObjective] = useState(null);
    const {
        locked,
        employeeId,
        hasChildren,
        isSelf,
        cycle,
        activeEmployee = {},
        iterationId,
        objectives = {},
        noObjectivesToReview,
        cascadedObjectives = [],
    } = values;
    const { updateObjective, removeObjective, setNavigateBlocked, setCloseBlocked } = actions;
    const { checkInWhenSetting } = cycle;
    const labels = getLabels(isSelf, activeEmployee);
    const showCheckIn = checkInWhenSetting && isSelf;

    useEffect(() => {
        setNavigateBlocked(!!selectedObjective);
    }, [selectedObjective, setNavigateBlocked]);

    // Filter objectives to only include those being created in this cycle
    const createdObjectives = useMemo(() => {
        const filtered = Object.values(objectives).filter(
            (objective) => objective.createdInIterationId === iterationId
        );
        return filtered;
    }, [objectives, iterationId]);

    const handleCascade = (cascaded) => {
        setSelectedObjective(cascaded);
    };

    const handleSaveObjective = (objective) => {
        const enhancedObjective = { ...objective, createdInIterationId: iterationId };
        updateObjective(enhancedObjective);
    };

    const handleRemoveObjective = (objective) => {
        function afterRemove() {
            setSelectedObjective(null);
            setNavigateBlocked(false);
        }
        const createdAlready = createdObjectives.find((o) => o.id === objective.id);
        if (createdAlready) {
            removeObjective(objective.id, afterRemove);
        }
    };

    const handleViewObjective = (objective) => {
        setSelectedObjective(objective || null);
        setNavigateBlocked(!!objective);
        setCloseBlocked(!!objective);
    };

    if (selectedObjective) {
        return (
            <div className="h-full flex flex-1 py-4 px-6">
                <div className="flex-1 border rounded-box bg-white overflow-auto">
                    <ObjectiveSetting.Content
                        objective={selectedObjective}
                        hasChildren={hasChildren}
                        allowAdd
                        showCheckIn={showCheckIn}
                        locked={locked}
                        onClose={() => handleViewObjective(null)}
                        onRemove={handleRemoveObjective}
                        onSave={handleSaveObjective}
                    />
                </div>
            </div>
        );
    }

    const renderCascadeOptions = () => {
        if (!cascadedObjectives.length) return null;
        return (
            <>
                <div className="pb-4">
                    <div className="flex justify-between">
                        <h5>Cascaded Objectives</h5>
                        <HelperDropdown title="Cascaded Objectives" color="info" size="xs">
                            <p>{labels.shared}</p>
                        </HelperDropdown>
                    </div>
                    <Each
                        of={cascadedObjectives}
                        emptyStateProps={{ hidden: true }}
                        render={(objective) => {
                            return (
                                <ObjectiveSetting.Cascade
                                    objective={objective}
                                    ownerEmployeeId={employeeId}
                                    onCascade={handleCascade}
                                />
                            );
                        }}
                    />
                </div>
                <hr className="border-secondary" />
            </>
        );
    };

    return (
        <div className="flex flex-col relative fade h-full w-full items-stretch">
            <div className="flex items-stretch flex-1 divide-x overflow-auto">
                <div className="flex flex-col flex-2 px-8 py-6 space-y-6 overflow-scroll">
                    {renderCascadeOptions()}
                    <div className="flex-1 flex flex-col">
                        {createdObjectives.length > 0 && <h5 className="mb-1">Next Objectives</h5>}
                        <div className="flex flex-col mb-4 flex-1">
                            <Each
                                of={createdObjectives}
                                renderEmpty={() => <EmptyState />}
                                render={(objective) => {
                                    return (
                                        <ObjectiveSetting.Header
                                            objective={objective}
                                            onClick={handleViewObjective}
                                            onRemove={handleRemoveObjective}
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div>
                            <ObjectiveSetting.Creator onSubmit={handleViewObjective} ownerEmployeeId={employeeId} />
                        </div>
                    </div>
                </div>
                <div className="flex-1 min-w-md max-w-md px-8 py-4 bg-white overflow-scroll">
                    <NoteEditorBlock
                        values={values}
                        actions={actions}
                        segmentId="objectiveSetting"
                        includeAllNotes={noObjectivesToReview}
                    />
                </div>
            </div>
        </div>
    );
};

export default SetObjectivesStepTemplate;
