import { isEqual, reduce } from "lodash";

export function removeUndefined(dataToClean) {
    if (!dataToClean) return null;
    if (Array.isArray(dataToClean)) {
        let cleaned = [];
        dataToClean.forEach((entry) => {
            if (entry !== undefined && entry !== "undefined") cleaned.push(entry);
        });
        return cleaned;
    } else {
        Object.keys(dataToClean).forEach((key) => dataToClean[key] === undefined && delete dataToClean[key]);
        return dataToClean;
    }
}

export function convertObjectToArray(object) {
    return Array.isArray(object) ? object : Object.values(object);
}

export const getDifferences = (obj1, obj2) => {
    return reduce(
        obj1,
        (result, value, key) => {
            if (!isEqual(value, obj2[key])) {
                result[key] = { oldValue: value, newValue: obj2[key] };
            }
            return result;
        },
        {}
    );
};

export function ensureSerializable(data) {
    if (!data) return data;

    // Convert dates to ISO strings
    if (data instanceof Date) {
        return data.toISOString();
    }

    // If it's a timestamp, convert it to a Date and then to an ISO string
    if (data?.seconds && data?.nanoseconds) {
        return new Date(data.seconds * 1000 + data.nanoseconds / 1000000).toISOString();
    }

    // If it's an array, recursively check its items
    if (Array.isArray(data)) {
        return data.map((item) => ensureSerializable(item));
    }

    // If it's an object, recursively check its values
    if (typeof data === "object") {
        return Object.fromEntries(Object.entries(data).map(([key, value]) => [key, ensureSerializable(value)]));
    }

    // Return data as is if it's not a Date, a potential Date, an Array or an Object
    return data;
}