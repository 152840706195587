import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function chooseProgressBarClass(baseClass, barClass) {
    switch (barClass) {
        case "content":
            return `text-base-100`;
        case "hide":
            return `text-${baseClass}`;
        default:
            return `text-${barClass}`;
    }
}

const MyProgress = ({ value, colors, variant, radialSize, baseClass, barClass, className, neutral, children }) => {
    let colorClass = "progress-primary";
    colors.forEach((color) => {
        const { display, below } = color;
        if (value <= below) {
            colorClass = `progress-${display}`;
        }
    });
    const preppedBarClass = chooseProgressBarClass(baseClass, barClass);

    // If the value is 0, render it as 2% so that the bar is visible
    if (value === 0) value = 2;

    switch (variant) {
        case "radial":
            return (
                <div
                    className={classNames(
                        "radial-progress border-4",
                        `bg-${baseClass}`,
                        `border-${baseClass}`,
                        "text-base-100",
                        preppedBarClass,
                        className,
                    )}
                    style={{ "--value": value, "--size": `${radialSize}rem` }}
                    role="progressbar"
                >
                    {children}
                </div>
            );
        case "standard":
        default:
            return (
                <progress
                    className={classNames("progress", className, colorClass, { "white-progress": neutral })}
                    value={value}
                    max="100"
                ></progress>
            );
    }
};

MyProgress.propTypes = {
    value: PropTypes.number,
    colors: PropTypes.arrayOf(PropTypes.object),
    variant: PropTypes.oneOf(["radial", "standard"]),
    barClass: PropTypes.string,
    radialSize: PropTypes.number,
    baseClass: PropTypes.string,
};

MyProgress.defaultProps = {
    value: 50,
    colors: [{ display: "warning", below: 60 }, { display: "error", below: 40 }],
    variant: "standard",
    barClass: "content",
    radialSize: 5,
    baseClass: "info",
};

export default MyProgress;
