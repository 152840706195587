import React from "react";
import ViewTalentAssessment from "../../../../../../features/EmployeeAssessments/Views/ViewTalentAssessment";
import { useSnapshots } from "../../../../../../features/Snapshots/hooks/useSnapshots";
import { useOrgNodeContext } from "../../OrgNodePage";


const SelfAssessmentPage = () => {
    const { activeEmployee, loading } = useOrgNodeContext();
    const { getSnapshot } = useSnapshots();
    const { selfAssessment } = activeEmployee;
    const snapshot = getSnapshot(activeEmployee.id, selfAssessment || {});

    if (loading) return null;

    return (
        <div className="page page-padding">
            <ViewTalentAssessment activeEmployee={activeEmployee} thisAssessment={selfAssessment} snapshot={snapshot} />
        </div>
    );
};

export default SelfAssessmentPage;
