import classNames from "classnames";
import React, { useState } from "react";
import "../../talent-map.scss";
import SegmentView from "./organisms/SegmentView";
import CustomGrid from "./organisms/CustomGrid";
import TalentScatterChart from "../TalentScatterChart/TalentScatterChart";

const TalentMap = ({
    mapConfig = {},
    grid,
    scatterData,
    talentMapRef,
    getFieldLabel = () => {},
    onItemClick = () => {},
}) => {
    const { x, y, chartType, singleAxis } = mapConfig;
    const hideYAxis = singleAxis && chartType !== "scatter";
    const [selectedSegment, selectSegment] = useState(null);
    const handleSegmentClick = (segment) => {
        selectSegment(segment);
    };

    const handleItemClick = (employeeId) => {
        onItemClick(employeeId);
    };

    if (selectedSegment) {
        return (
            <SegmentView
                isFullPage
                onItemClick={handleItemClick}
                segment={selectedSegment}
                onClose={() => selectSegment(null)}
            />
        );
    }

    return (
        <div ref={talentMapRef} className="overflow-hidden h-full w-full flex flex-col talent-map-container pt-2">
            <div className={classNames("talent-map")}>
                {!hideYAxis && (
                    <div className="axis y-axis">
                        <div className="text">{getFieldLabel(y)}</div>
                    </div>
                )}
                {chartType === "scatter" && (
                    <TalentScatterChart snapshots={scatterData} onItemClick={handleItemClick} />
                )}
                {chartType === "grid" && (
                    <CustomGrid grid={grid} onSegmentClick={handleSegmentClick} onItemClick={handleItemClick} />
                )}
            </div>
            <div className="axis text-center mb-3">{getFieldLabel(x)}</div>
        </div>
    );
};

export default TalentMap;
