import classNames from "classnames";
import { darken } from "polished";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import SegmentView from "./SegmentView";
import SummaryTile from "./SummaryTile";
import { useSelector } from "react-redux";
import { LoadingIndicator } from "../../../../../../components";
import { showRatingColor } from "../../../../../Snapshots/utils/snapshotHelpers";
import { getAssessmentColors } from "../../../../../Config/utils/configSelectors";
import { useDebounce } from "../../../../../../hooks/useDebounce";

const GridTile = ({ segment, bigTile, onItemClick, onSegmentClick }) => {
    const { height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    const { tile, cohort } = segment;
    const [maxEmployees, setMaxEmployees] = useState();
    const assessmentColors = useSelector(getAssessmentColors);
    const backgroundColor = showRatingColor(assessmentColors, tile?.color);
    const color = darken(0.3, backgroundColor);
    const debouncedHeight = useDebounce(height, 250);

    useEffect(() => {
        const itemHeight = 30;
        const itemsPerRow = 3;
        const numberOfRows = Math.floor((debouncedHeight - 15) / itemHeight);
        setMaxEmployees(numberOfRows * itemsPerRow);
    }, [debouncedHeight]);

    const showSummary = cohort.length > maxEmployees;

    const headerStyle = {
        backgroundColor: backgroundColor,
        color: color,
        fontWeight: "bold",
    };

    const renderContent = () => {
        if (!maxEmployees) return <LoadingIndicator icon="spinner" color="base-500" />;
        if (showSummary) return <SummaryTile bigTile={bigTile} segment={segment} onClick={onSegmentClick} />;
        return <SegmentView segment={segment} onItemClick={onItemClick} />;
    };

    return (
        <div ref={ref} className="summary-tile rounded-lg overflow-hidden flex-1 flex-col">
            <div className="p-2 text-sm flex justify-between" style={headerStyle}>
                <span>{`${tile.title} (${cohort.length})`}</span>
                <span>{`${segment.percent}%`} </span>
            </div>
            {renderContent()}
        </div>
    );
};

const GridColumn = ({ column, onItemClick, onSegmentClick }) => {
    const bigTile = column.length === 1;
    return (
        <div className={classNames("talent-map-column")}>
            {column.map((segment, i) => {
                const { x, y } = segment;
                return (
                    <GridTile
                        bigTile={bigTile}
                        key={`${x}-${y}`}
                        segment={segment}
                        onItemClick={onItemClick}
                        onSegmentClick={onSegmentClick}
                    />
                );
            })}
        </div>
    );
};

const CustomGrid = ({ grid, onItemClick, onSegmentClick }) => {
    return (
        <div className="talent-map-grid">
            {grid.map((col, i) => (
                <GridColumn key={`fbg-c-${i}`} column={col} onItemClick={onItemClick} onSegmentClick={onSegmentClick} />
            ))}
        </div>
    );
};

CustomGrid.propTypes = {
    grid: PropTypes.array,
    filter: PropTypes.string,
    config: PropTypes.object,
    snapshots: PropTypes.object,
};

CustomGrid.defaultProps = {
    grid: [],
    config: {},
    snapshots: {},
};

export default CustomGrid;
