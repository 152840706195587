import { useCallback, useState } from "react";

export function useToggle(initialVal = false) {
    const [value, setValue] = useState(initialVal);

    const toggle = useCallback(() => {
        setValue((prev) => !prev);
    }, []);

    return [value, toggle];
}
