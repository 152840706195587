import _ from "lodash";
import { useCallback, useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { writeEveryoneConfig } from "../../Config/utils/configDb";

const customFieldOptions = [
    { id: "select", label: "Multiple Choice", help: "Users can select from any of the options provided." },
    { id: "likertScale", label: "Likert Scale", help: "Users choose a value based on the provided scale." },
    { id: "numeric", label: "Numeric", help: "Users can respond with any number" },
    { id: "binary", label: "Binary", help: "Users can provide a Yes / No response" },
    { id: "date", label: "Date", help: "Users can provide a date" },
    {
        id: "comment",
        label: "Comment",
        help: "Users can provide an unlimited rich text response",
        exclude: ["surveyFields", "customEmployeeFields"],
    },
];

const categoryOptions = [
    /*    {
        id: "surveyFields",
        label: "Survey",
        help: "Survey Fields can be included in reviews & surveys. The data gathered will only be saved in the specific survey or review being run.",
    },*/
    {
        id: "flagFields",
        label: "Indicator",
        help: "Indicator Fields can be included in reviews & surveys. The data gathered will be saved to the employee record and available in reporting outside of the review or survey.",
    },
    {
        id: "customEmployeeFields",
        label: "Employee",
        help: "Employee fields allow you to provide more information on specific employees, such as gender or date or birth. They are not available in reviews & surveys.",
    },
];

export function useCustomFields() {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const { customFields } = useSelector((state) => state.config.everyoneConfig);

    const groupedFields = useMemo(() => {
        const grouped = _.groupBy(customFields, "fieldCategory");
        const { flagFields = [], customEmployeeFields = [], surveyFields = [] } = grouped;
        const assessmentFields = [...flagFields, ...surveyFields];
        return { flagFields, customEmployeeFields, surveyFields, assessmentFields };
    }, [customFields]);

    const getCustomField = useCallback(
        (fieldId) => {
            return customFields.find((field) => field.id === fieldId);
        },
        [customFields]
    );

    const getCustomFieldValueLabel = useCallback(
        (fieldId, value) => {
            const field = getCustomField(fieldId);
            if (field?.options) {
                return field.options[value];
            } else {
                return value;
            }
        },
        [getCustomField]
    );

    const getLikertScaleValue = useCallback(
        (fieldId, value) => {
            const field = getCustomField(fieldId);
            const { options } = field || {};
            if (options) {
                const optionCount = options.length;
                let rank = value;
                if (value != null) {
                    rank = value + 1;
                    rank = Math.round((rank / optionCount) * 100);
                }
                return isNaN(rank) ? null : rank;
            } else {
                return value;
            }
        },
        [getCustomField]
    );

    async function handleDeleteFields(fieldIds) {
        const newFields = customFields.filter((f) => !fieldIds.includes(f.id));
        const configUpdate = { customFields: newFields };
        try {
            const batch = writeEveryoneConfig(workspaceId, configUpdate);
            await batch.commit();
            toast.success("Delete successful");
        } catch (error) {
            console.error("Error deleting field", error.message);
        }
    }

    async function saveCustomField(field) {
        let newFields = customFields.filter((f) => f.id !== field.id);
        newFields.push(field);
        const configUpdate = { customFields: newFields };
        try {
            const batch = writeEveryoneConfig(workspaceId, configUpdate);
            await batch.commit();
            toast.success("Field saved successfully");
        } catch (error) {
            console.error("Error saving field", error.message);
        }
    }

    const deleteFields = (fieldIds) => {
        confirmAlert({
            title: "Delete Fields",
            message:
                "Are you sure you want to delete these fields? All collected data associated with these fields will be lost. This cannot be undone.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => handleDeleteFields(fieldIds),
                },
                {
                    label: "No",
                    onClick: () => {},
                },
            ],
        });
    };

    return {
        customFieldOptions,
        categoryOptions,
        customFields,
        flagFields: groupedFields.flagFields,
        customEmployeeFields: groupedFields.customEmployeeFields,
        surveyFields: groupedFields.surveyFields,
        assessmentFields: groupedFields.assessmentFields,
        getCustomField,
        getCustomFieldValueLabel,
        getLikertScaleValue,
        saveCustomField,
        deleteFields,
    };
}
