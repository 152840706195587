import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useClaims } from "../../features/User/hooks/useClaims";

export function prepSideMenuItem(page, linkPrefix, labelOverrides = {}) {
    let pageLink = `/${linkPrefix}/${page.id}`;
    const parameters = page.parameters || {};
    Object.entries(parameters).forEach(([key, val]) => {
        pageLink = `${pageLink}?${key}=${val}`;
    });
    if (page.pages) {
        return {
            ...page,
            title: labelOverrides[page.id] || page.title,
            pages: page.pages.map((subPage) => prepSideMenuItem(subPage, linkPrefix, labelOverrides)),
        };
    } else {
        return {
            ...page,
            title: labelOverrides[page.id] || page.title,
            link: pageLink,
        };
    }
}

const clearTextSelection = () => {
    // Handles strange bug where the text is being selected on toggle sometimes
    if (window.getSelection) {
        if (window.getSelection().empty) {
            // Chrome
            window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
            // Firefox and others
            window.getSelection().removeAllRanges();
        }
    } else if (document.selection) {
        // IE?
        document.selection.empty();
    }
};

const PageLinkRenderer = ({ page, notifications, activePageId, isParentOpen = false, checkShow }) => {
    const Icon = page.icon;
    const notificationCount = notifications[page.id];
    const isActive = page.id === activePageId;
    const itemClassName =
        "rounded-box border border-transparent collapse-title text-md font-bold p-3 min-h-0 no-marker";
    const activeItemClassName = "bg-blue-100 border shadow";
    const isParentOfActivePage = page.pages?.some((subPage) => subPage.id === activePageId) || isParentOpen;

    if (page.pages) {
        const filteredPages = page.pages.filter((page) => checkShow(page));
        return (
            <details className="collapse collapse-arrow" open={true} onClick={clearTextSelection}>
                <summary className={classNames(itemClassName, isActive && activeItemClassName)}>
                    <div className="menu-item flex space-x-3 items-center">
                        {page.icon && <Icon className="w-6 h-6" />}
                        <span className="font-medium capitalize">{page.title}</span>
                    </div>
                </summary>
                <div className="collapse-content space-y-1">
                    {filteredPages.map((subPage) => (
                        <PageLinkRenderer
                            key={`${page.id}-${subPage.id}`}
                            page={subPage}
                            notifications={notifications}
                            activePageId={activePageId}
                            isParentOpen={isParentOfActivePage}
                        />
                    ))}
                </div>
            </details>
        );
    } else {
        return (
            <summary
                className={classNames(itemClassName, isActive && activeItemClassName)}
                onClick={clearTextSelection}
            >
                <Link to={page.link}>
                    <div className="menu-item flex space-x-3 items-center justify-between">
                        {page.icon && <Icon className="w-6 h-6" />}
                        <span className="flex-1 font-medium capitalize">{page.title}</span>
                        {notificationCount > 0 && <span className="h-full badge badge-info">{notificationCount}</span>}
                    </div>
                </Link>
            </summary>
        );
    }
};

const MySideMenu = ({ isSelf, hasChildren, hidden, pages, notifications = {}, activePageId, children }) => {
    const enabledModules = useSelector((state) => state.config.everyoneConfig.enabledModules);
    const featureFlips = useSelector((state) => state.config.everyoneConfig.featureFlips);
    const { isDeveloper } = useClaims();

    const checkShow = useCallback(
        (page) => {
            if (page.requiresSelf) return isSelf;
            if (page.requiresNotSelf) return !isSelf;
            if (page.requiresDeveloper) return isDeveloper;
            if (page.requiresChildren && !hasChildren) return false;
            if (featureFlips[page?.requires]) return true;
            if (enabledModules.includes(page?.requires)) return true;
            return !page.requires;
        },
        [enabledModules, featureFlips, hasChildren, isSelf, isDeveloper]
    );

    const filteredPages = useMemo(() => {
        return pages.filter((page) => checkShow(page));
    }, [checkShow, pages]);

    if (hidden) return null;

    return (
        <div className="flex-1 menu space-y-3 flex flex-col overflow-hidden">
            {children}
            <div className="flex-1 overflow-y-scroll hide-scroll-bars">
                {filteredPages.map((page) => (
                    <PageLinkRenderer
                        key={page.id}
                        page={page}
                        notifications={notifications}
                        activePageId={activePageId}
                        checkShow={checkShow}
                    />
                ))}
            </div>
        </div>
    );
};

export default MySideMenu;
