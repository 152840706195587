import classNames from "classnames";
import React from "react";
import "./rich-text-editor.scss";
import { checkIfEmptyContent } from "./utils/checkIfEmptyContent";
import { createEditorState } from "./utils/createEditorState";
import { convertStateToHtml } from "./utils/convertStateToHtml";

const RichTextDisplay = ({
    content,
    wrapperStyle,
    borderReadOnly,
    extraClasses,
    removePadding,
    className,
    bgClass,
    textSize = "sm",
    placeholder = "Empty",
}) => {
    const isEmpty = checkIfEmptyContent(content);

    if (isEmpty) {
        return (
            <div
                className={classNames(`text-${textSize}`, "rich-text-display rounded-lg", className, extraClasses, {
                    "p-3": !removePadding,
                    border: borderReadOnly,
                })}
                style={wrapperStyle}
            >
                {placeholder}
            </div>
        );
    }
    const editorState = createEditorState(content);
    const html = convertStateToHtml(editorState);
    let style = wrapperStyle || {};

    return (
        <div
            style={style}
            className={classNames("rich-text-display prose overflow-y-scroll", className, `text-${textSize}`, extraClasses, {
                border: borderReadOnly,
                "p-3": !removePadding,
                bgClass,
            })}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};

export default RichTextDisplay;
