import { useSelector } from "react-redux";
import { MyModal, MyModalBody, MyModalHeader } from "../../../../components/MyComponents/Modal";
import { useEmployees } from "../../../Employees/hooks/useEmployees";
import CascadeTemplate from "../../components/templates/CascadeTemplate";
import { useRoles } from "../../../Roles/hooks/useRoles";
import { useRoleRelationships } from "../../../Roles/hooks/useRoleRelationships";
import { useEmployeeRelationships } from "../../../Employees/hooks/useEmployeeRelationships";

const CascadeModal = ({ isOpen, objective, onToggle }) => {
    const employeeSnapshots = useSelector((state) => state.snapshots.employeeSnapshots);
    const { getEmployee } = useEmployees();
    const { getRole } = useRoles();
    const { getRoleOrgLevel } = useRoleRelationships();
    const { getDirectReportIds } = useEmployeeRelationships();
    if (!isOpen || !objective) return null;
    return (
        <MyModal size="xl" isOpen={isOpen} onToggle={onToggle}>
            <MyModalHeader onToggle={onToggle}>{objective?.objectiveName}</MyModalHeader>
            <MyModalBody className="flex-1 flex flex-col">
                <CascadeTemplate
                    objective={objective}
                    employeeSnapshots={employeeSnapshots}
                    getEmployee={getEmployee}
                    getRole={getRole}
                    getRoleOrgLevel={getRoleOrgLevel}
                    getDirectReportIds={getDirectReportIds}
                />
            </MyModalBody>
        </MyModal>
    );
};

export default CascadeModal;
