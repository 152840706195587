import { useCallback, useMemo } from "react";
import { prepQuerySnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { useListener } from "../../../firebase/hooks/useListener";

export function useCascadedListener(ref, onDataReceived, enabled) {
    const shouldListen = useMemo(() => {
        return enabled && !!ref;
    }, [enabled, ref]);

    const handleDataReceived = useCallback(
        (querySnapshot) => {
            onDataReceived(prepQuerySnapshot(querySnapshot));
        },
        [onDataReceived]
    );

    return useListener(ref, handleDataReceived, shouldListen);
}
