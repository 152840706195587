import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useCustomFields } from "../../hooks/useCustomFields";
import { Each } from "../../../../common/components";
import { ShowMore } from "../../../../components";

function getColorClass(fieldType, value, optionCount) {
    switch (fieldType) {
        case "likertScale":
            if (value === 0) return "error";
            if (value === optionCount - 1) return "success";
            return "neutral";
        default:
            return "neutral";
    }
}

const Flag = ({ flag }) => {
    const { getCustomFieldValueLabel } = useCustomFields();
    const { id, title, value, fieldType, optionCount } = flag;
    const valueIsArray = Array.isArray(value);

    function renderContent() {
        if (valueIsArray) {
            return (
                <ShowMore stacked>
                    {value.map((val) => {
                        const label = getCustomFieldValueLabel(id, val);
                        return (
                            <h2 className="text-base" key={`${flag.id}-opt-${val}`}>
                                {label}
                            </h2>
                        );
                    })}
                </ShowMore>
            );
        } else {
            let colorClass = getColorClass(fieldType, value, optionCount);
            let valueLabel = getCustomFieldValueLabel(id, value);
            valueLabel = valueLabel === undefined ? "N/A" : valueLabel;
            return <h2 className={classNames("text-base", `text-${colorClass}`)}>{valueLabel}</h2>;
        }
    }

    return (
        <div className="join-item flex-1 min-w-40 max-w-60 bg-base-100 rounded-lg p-4 shadow border space-y-2">
            <div className="flex justify-between">{renderContent()}</div>
            <span className="text-xs text-base-500 font-medium">{title}</span>
        </div>
    );
};

const FlagsDisplay = ({ snapshot }) => {
    const { flagFields } = useCustomFields();

    const flags = useMemo(() => {
        return flagFields.map((field) => {
            const { id, fieldType, options = [] } = field;
            const value = snapshot?.flags?.[id];
            const optionCount = options.length;
            return {
                id: id,
                fieldType,
                optionCount,
                title: field.label,
                value,
            };
        });
    }, [snapshot, flagFields]);

    if (!flags.length) return null;
    return (
        <div className="flex flex-wrap join">
            <Each of={flags} render={(flag) => <Flag flag={flag} />} />
        </div>
    );
};

FlagsDisplay.propTypes = {
    values: PropTypes.object,
    fields: PropTypes.array,
};

FlagsDisplay.defaultProps = {
    fields: [],
};

export default FlagsDisplay;
