import { useMemo } from "react";

export function useFilteredEmployees(filters = [], allEmployees = []) {
    const filteredEmployees = useMemo(() => {
        return allEmployees.filter((employee) => {
            return filters.every((filter) => {
                const { fieldId, fieldType, matchAny, minValue, maxValue } = filter;
                switch (fieldType) {
                    case "binary":
                        const value = matchAny && matchAny[0];
                        const isTrue = value === "true" || value === true || value === 1;
                        return isTrue === employee[fieldId];
                    case "range":
                        return employee[fieldId] >= minValue && employee[fieldId] <= maxValue;
                    default:
                        return !matchAny || matchAny.includes(employee[fieldId]);
                }
            });
        });
    }, [allEmployees, filters]);

    return { filteredEmployees, allEmployees };
}
