import { stateToHTML } from "draft-js-export-html";

const options = {
    entityStyleFn: (entity) => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "link") {
            const data = entity.getData();
            return {
                element: "a",
                attributes: {
                    href: data.url,
                    target: "_blank", // Force links to open in a new tab
                    rel: "noopener noreferrer", // For security reasons
                },
                style: {
                    color: "blue",
                },
            };
        }
    },
};

export function convertStateToHtml(editorState) {
    const contentState = editorState.getCurrentContent();
    return stateToHTML(contentState, options);
}
