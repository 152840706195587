import { createSelector } from "@reduxjs/toolkit";

const getEveryoneConfig = createSelector(
    (state) => state.config.everyoneConfig,
    (everyoneConfig) => everyoneConfig
);

const getOwnConfig = createSelector(
    (state) => state.config.ownConfig,
    (ownConfig) => ownConfig
);

const getAssessmentConfig = createSelector(
    (state) => state.config.everyoneConfig.assessmentConfig,
    (assessmentConfig) => assessmentConfig
);

const getAssessmentColors = createSelector(
    [(state) => state.config.everyoneConfig.assessmentColors],
    (colors) => colors || []
);

const getActiveOrgTable = createSelector(
    (state) => state.config.orgTables,
    (state) => state.config.ownConfig.activeOrgTableId,
    (orgTables, orgTableId) => {
        return orgTables[orgTableId];
    }
);

export { getActiveOrgTable, getAssessmentColors, getAssessmentConfig, getEveryoneConfig, getOwnConfig };
