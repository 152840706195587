import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useConfig } from "../../Config/hooks/useConfig";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";

function getSubKeys(node) {
    let subKeys = [];
    if (node.roleId) {
        subKeys.push(node.roleId);
    }
    if (node.nodes && Array.isArray(node.nodes)) {
        node.nodes.forEach((childNode) => {
            subKeys = subKeys.concat(getSubKeys(childNode));
        });
    }
    return subKeys;
}

export function useExpandedNodes() {
    const selectedRoleId = useSelector((state) => state.app.selectedRoleId);
    const { ownConfig, setConfigValue, updateConfigObject } = useConfig();
    const { allRoleIdsAbove, getAllRoleIdsAbove } = useRoleRelationships(selectedRoleId);
    const expandedNodes = ownConfig.expandedNodes;
    const autoCloseSiblings = ownConfig.autoCloseSiblings;
    const roles = useSelector((state) => state.businessUnit.roles);
    const rolesReady = useSelector((state) => state.businessUnit.rolesReady);
    const selectedRoleExpandChecked = useRef(false);
    const nodesChecked = useRef(false);

    // Reset the expand check whenever the role changes
    useEffect(() => {
        selectedRoleExpandChecked.current = false;
    }, [selectedRoleId]);

    // Ensure the parents of the selected role are always expanded
    // Close siblings if autoCloseSiblings is enabled
    useEffect(() => {
        if (selectedRoleId && !selectedRoleExpandChecked.current) {
            let expandedNodesUpdate = { [selectedRoleId]: true };
            for (let roleId of allRoleIdsAbove) {
                expandedNodesUpdate[roleId] = true;
            }
            if (autoCloseSiblings) {
                setConfigValue("expandedNodes", expandedNodesUpdate);
            } else {
                updateConfigObject("expandedNodes", expandedNodesUpdate);
            }
            selectedRoleExpandChecked.current = true;
        }
    }, [allRoleIdsAbove, selectedRoleId, autoCloseSiblings, updateConfigObject, setConfigValue]);

    useEffect(() => {
        // Make sure expanded nodes are valid, debounce by 750ms
        // Only needs to run once on mount. Handles prev workspace or org changes
        if (!nodesChecked.current && rolesReady) {
            const timeout = setTimeout(() => {
                let containsInvalidNode = false;
                const validatedNodes = { ...expandedNodes };
                for (let roleId in expandedNodes) {
                    if (!roles[roleId]) {
                        delete validatedNodes[roleId];
                        containsInvalidNode = true;
                    }
                }
                if (containsInvalidNode) {
                    setConfigValue("expandedNodes", validatedNodes);
                }
                nodesChecked.current = true;
            }, 750);
            return () => clearTimeout(timeout);
        }
    }, [roles, rolesReady, expandedNodes, setConfigValue]);

    const updateExpandedNodes = useCallback(
        (node) => {
            const roleId = node?.roleId;

            // Use the current expanded nodes as a base if autoCloseSiblings is disabled
            let newExpandedNodes = {};
            if (!autoCloseSiblings && expandedNodes) {
                newExpandedNodes = { ...expandedNodes };
            }

            // Ensure the parents of the toggled node are expanded
            const parentRoleIds = getAllRoleIdsAbove(roleId);
            parentRoleIds.forEach((parentRoleId) => {
                newExpandedNodes[parentRoleId] = true;
            });

            // Collapse all junior nodes when a node is being collapsed
            if (expandedNodes[roleId]) {
                const subKeys = getSubKeys(node);
                subKeys.forEach((subKey) => {
                    newExpandedNodes[subKey] = false;
                });
            }
            newExpandedNodes[roleId] = !expandedNodes[roleId];

            // remove any nodes that have a false value
            Object.keys(newExpandedNodes).forEach((roleId) => {
                if (newExpandedNodes[roleId] === false) {
                    delete newExpandedNodes[roleId];
                }
            });
            setConfigValue("expandedNodes", newExpandedNodes);
        },
        [expandedNodes, autoCloseSiblings, getAllRoleIdsAbove, setConfigValue]
    );

    return { expandedNodes, updateExpandedNodes };
}
