import {
    browserSessionPersistence,
    isSignInWithEmailLink,
    sendSignInLinkToEmail,
    setPersistence,
    signInWithEmailLink,
    signOut,
    updateProfile,
} from "firebase/auth";
import { update } from "lodash";
import applogger from "../common/utils/applogger";
import { auth } from "./firebase";

export function isConfirmLinkValid(emailLinkSignIn) {
    return isSignInWithEmailLink(auth, emailLinkSignIn);
}

export function updateAuthProfile(updates) {
    const user = auth.currentUser;
    if (update.displayName !== user.displayName) {
        updateProfile(user, {
            displayName: updates.displayName,
        });
    }
}

export function requestSignInLink(email, requestFrom) {
    const domain = requestFrom.substring(0, requestFrom.lastIndexOf("/"));
    const continueUrl = `${domain}/confirm`;

    var actionCodeSettings = {
        // URL you want to redirect back to. The domain for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: continueUrl,
        // This must be true.
        handleCodeInApp: true,
    };

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
            applogger.info("Login link sent");
        })
        .catch((error) => {
            applogger.error("login link did not send:", error.message);
        });
}

export async function completeUserSignIn(email, emailLinkSignIn, staySignedIn) {
    if (staySignedIn) {
        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(auth, emailLinkSignIn)) {
            return signInWithEmailLink(auth, email, emailLinkSignIn)
                .then((_) => {
                    applogger.info("Persistent Sign-in");
                    return false;
                })
                .catch((error) => {
                    applogger.error("completeUserSignIn", error);
                    switch (error.code) {
                        case "auth/invalid-email":
                            return "Invalid Email. Please request a sign-in link.";
                        case "auth/invalid-action-code":
                            return "The link you have provided is not valid. It may have expired. Please request another link.";
                        default:
                            return error.code;
                    }
                });
        } else {
            return "Please request a sign-in link.";
        }
    } else {
        ///Auth only lasts for single session - doesn't survive refresh on safari
        await setPersistence(auth, browserSessionPersistence);
        return signInWithEmailLink(auth, email, emailLinkSignIn)
            .then((_) => {
                applogger.info("Single session sign-in");
                return false;
            })
            .catch((error) => {
                switch (error.code) {
                    case "auth/invalid-email":
                        return "Invalid Email. Please request a sign-in link.";
                    case "auth/invalid-action-code":
                        return "The link you have provided is not valid. It may have expired. Please request another link.";
                    default:
                        return error.code;
                }
            });
    }
}

export function userSignOut() {
    applogger.info("Signing user out");
    signOut(auth);
}
