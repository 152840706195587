import { LoadingIndicator } from "../../../components";
import WorkspaceFrame from "./WorkspaceFrame";

const WorkspaceFallback = () => (
    <WorkspaceFrame>
        <LoadingIndicator fullscreen message="Page Loading" />
    </WorkspaceFrame>
);

export default WorkspaceFallback;
