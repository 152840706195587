import { CollectionReference, DocumentReference, Query } from "firebase/firestore";

function cleanUpPath(str) {
    const prefix = "workspaces/";
    if (str.startsWith(prefix)) {
        // Find the position of the next '/'
        const nextSlashIndex = str.indexOf("/", prefix.length);
        if (nextSlashIndex !== -1) {
            // Remove the prefix and everything up to the next '/'
            return str.substring(nextSlashIndex + 1);
        }
    }
    return str;
}

function getQueryString(item) {
    const segment = item.field.segments[0]; // Take the first item in the segments array
    const op = item.op;
    // eslint-disable-next-line no-unused-vars
    const [_, value] = Object.entries(item.value)[0]; // Extract the value from the value object
    return `${segment} ${op} ${value}`;
}

export function checkIfFirestoreRef(ref) {
    return ref instanceof DocumentReference || ref instanceof CollectionReference || ref instanceof Query;
}

export function checkIfCollectionOrQueryRef(ref) {
    return ref instanceof CollectionReference || ref instanceof Query;
}


export function generateQueryId(ref) {
    try {
        const filters = ref?._query?.filters || [];
        const pathString = ref._query?.path?.segments?.join("/");
        const cleanedPath = cleanUpPath(pathString);
        const segmentString = filters.map(getQueryString).join(", ");
        return `${cleanedPath} | ${segmentString}`;
    } catch (e) {
        return "Couldn't ID ref";
    }
}

export function identifyFirestoreRef(ref) {
    const isValidRef = checkIfFirestoreRef(ref);
    if (!isValidRef) return false;
    if (ref instanceof DocumentReference || ref instanceof CollectionReference) {
        return cleanUpPath(ref.path);
    } else if (ref instanceof Query) {
        return generateQueryId(ref);
    }
}
