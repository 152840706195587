import { useMemo } from "react";
import { useRoleRelationships } from "./useRoleRelationships";
import { useRoles } from "./useRoles";

export function useDepartmentTree(topLevelRoleId, validEmployees, getValues) {
    const { getSubDepartments } = useRoleRelationships();
    const { getRole } = useRoles();

    return useMemo(() => {
        function helperCreateNodes(roleId, isTopLevel = false) {
            const role = getRole(roleId);
            const subDepartments = getSubDepartments(roleId); // Returns array of sub-roles
            const cohortEmpIds = subDepartments
                .filter((role) => role.incumbentId)
                .map((role) => {
                    return role.incumbentId;
                });
            if (role.incumbentId) cohortEmpIds.push(role.incumbentId);
            const cohort = validEmployees.filter((emp) => cohortEmpIds.includes(emp.id));
            const values = getValues(cohort);
            return {
                id: role.id,
                isTopLevel,
                label: role.department || role.title,
                ...values,    
                children: subDepartments.map((subRole) => helperCreateNodes(subRole.id)),
            };
        }

        // Generate the full tree starting from the top-level role
        return helperCreateNodes(topLevelRoleId, true);
    }, [topLevelRoleId, validEmployees, getSubDepartments, getRole, getValues]);
}
