import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_FEATURE_FLIPS } from "../../../constants";
import { toggleItemInArray } from "../../../helpers/helpers";
import { getAllColors, prepAssessmentSettings } from "./configHelpers";
import {
    ACTIVE_EMPLOYEES_TABLE,
    ALL_EMPLOYEES_TABLE,
    ALL_USERS_TABLE,
    DEFAULT_TABLE_ID,
    INACTIVE_EMPLOYEES_TABLE,
} from "./defaultConfig";
import { ROOT_ROLE_ID } from "shared";

export const CLEAR_UNSAVED = "config/clearUnsaved";

function setOrgTables(ownTables = {}, everyoneTables = {}) {
    const defaultTables = {
        [DEFAULT_TABLE_ID]: ALL_EMPLOYEES_TABLE,
        allUsr: ALL_USERS_TABLE,
        actEmp: ACTIVE_EMPLOYEES_TABLE,
        iacEmp: INACTIVE_EMPLOYEES_TABLE,
    };
    let preppedShared = {};
    Object.entries(everyoneTables).forEach(([id, table]) => {
        const prepped = {
            ...table,
            shared: true,
        };
        preppedShared[id] = prepped;
    });
    const allTables = {
        ...defaultTables,
        ...preppedShared,
        ...ownTables,
    };
    let categorisedTables = {};
    Object.entries(allTables).forEach(([id, table]) => {
        categorisedTables[id] = {
            id: id,
            ...table,
            category: table.shared ? "Shared" : "Private",
        };
    });
    return categorisedTables;
}

function setTalentMaps(ownMaps = {}, everyoneMaps = {}) {
    let preppedShared = {};
    Object.entries(everyoneMaps).forEach(([id, map]) => {
        const prepped = {
            ...map,
            shared: true,
        };
        preppedShared[id] = prepped;
    });
    const allMaps = {
        ...preppedShared,
        ...ownMaps,
    };
    let categorisedMaps = {};
    Object.entries(allMaps).forEach(([id, map]) => {
        categorisedMaps[id] = {
            id: id,
            ...map,
            category: map.shared ? "Shared" : "Private",
        };
    });
    return categorisedMaps;
}

function prepCustomFields(customFields) {
    return customFields.map((field) => {
        const { help } = field;
        if (Array.isArray(help)) {
            const richTextObject = {
                blocks: help.map((text, index) => ({
                    key: `block-${index}`, // Unique key for each block
                    text: text,
                    type: "unstyled", // Assuming each string should be unstyled
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                })),
                entityMap: {}, // Assuming no entities are needed
            };
            return { ...field, help: richTextObject };
        } else {
            return field;
        }
    });
}

/// Default values
const initialState = {
    configStatus: "not-checked",
    savedConfigChanged: false,
    ownConfigLoaded: false,
    configIsReady: false,
    userEmail: null,
    userDisplayName: null,
    claims: {},
    dateLastReadNotifications: null,
    orgTables: {},
    talentMaps: {},
    pendingCycleChanges: [],
    expandedNodes: {},
    everyoneConfig: {
        assessmentColors: getAllColors(100),
        overallRatingLabel: "Overall",
        orgTables: {},
        talentMaps: {},
        cycles: {},
        assessmentConfig: {},
        customFields: [],
        enabledModules: [],
        featureFlips: DEFAULT_FEATURE_FLIPS,
        subscribedModules: [],
        checkinFrequency: 30,
        checkInsEnabled: true,
        roleLimit: 5000,
    },
    ownConfig: {},
};

const slice = createSlice({
    name: "config",
    initialState,
    reducers: {
        resetConfig: () => initialState,
        setClaims: (state, action) => {
            state.claims = action.payload;
        },
        setUserDetails: (state, action) => {
            const { userEmail, userDisplayName } = action.payload;
            state.userEmail = userEmail;
            state.userDisplayName = userDisplayName;
        },
        setDateLastReadNotifications: (state, action) => {
            state.dateLastReadNotifications = action.payload;
        },
        setOwnConfig: (state, action) => {
            const { orgTables = {}, ...cleanedConfig } = action.payload || {};
            let { expandedNodes, ...currentOwnConfig } = state.ownConfig;
            if (Object.keys(expandedNodes || {}).length === 0) {
                expandedNodes = { [ROOT_ROLE_ID]: true };
            }
            state.ownConfig = { expandedNodes, ...currentOwnConfig, ...cleanedConfig };
            state.configStatus = state.configIsReady ? "loaded" : "loading";
            state.orgTables = setOrgTables(orgTables, state.everyoneConfig.orgTables);
            state.talentMaps = setTalentMaps(cleanedConfig.talentMaps, state.everyoneConfig.talentMaps);
            state.ownConfigLoaded = true;
        },
        initialiseOwnConfig: (state, action) => {
            state.ownConfig = action.payload;
        },
        updateOwnConfig: (state, action) => {
            state.savedConfigChanged = true;
            state.ownConfig = action.payload;
        },
        updateOwnConfigValue: (state, action) => {
            const { key, value } = action.payload;
            state.savedConfigChanged = true;
            state.ownConfig[key] = value;
        },
        setExpandedNodes: (state, action) => {
            state.expandedNodes = action.payload;
        },
        setEveryoneConfig: (state, action) => {
            const assessmentColors = state.everyoneConfig.assessmentColors;
            const payloadConfig = action.payload;
            let newConfig = { ...state.everyoneConfig, ...payloadConfig };
            const assessmentConfig = prepAssessmentSettings(newConfig.assessmentConfig, assessmentColors);
            newConfig.assessmentConfig = assessmentConfig;
            newConfig.customFields = prepCustomFields(newConfig.customFields);
            state.everyoneConfig = newConfig;
            state.configIsReady = true;
            state.orgTables = setOrgTables(state.ownConfig.orgTables || {}, newConfig.orgTables);
            state.talentMaps = setTalentMaps(state.ownConfig.talentMaps || {}, newConfig.talentMaps);
            state.configStatus = state.ownConfigLoaded ? "loaded" : "loading";
            state.pendingCycleChanges = [];
        },
        toggleCheckedId: (state, action) => {
            const { tableId, itemId } = action.payload;
            const newCheckedIds = toggleItemInArray(state.ownConfig.allCheckedIds[tableId], itemId);
            state.ownConfig.allCheckedIds[tableId] = newCheckedIds;
        },
    },
    extraReducers: (builder) =>
        builder.addCase(CLEAR_UNSAVED, (state) => {
            state.unsavedChanges = {};
        }),
});

const { actions, reducer } = slice;

export const {
    resetConfig,
    setClaims,
    setUserDetails,
    setOwnConfig,
    updateOwnConfig,
    setExpandedNodes,
    updateOwnConfigValue,
    initialiseOwnConfig,
    setEveryoneConfig,
    toggleCheckedId,
    setDateLastReadNotifications,
} = actions;

export default reducer;
