import {
    AiOutlineCloudDownload,
    AiOutlineFileDone,
    AiOutlineUser,
    AiOutlineUserAdd,
    AiOutlineUsergroupAdd,
} from "react-icons/ai";
import { BiCog, BiWindowOpen } from "react-icons/bi";
import { BsArchive } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import {
    FaMailBulk as BulkIcon,
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaBalanceScaleLeft,
    FaBorderAll,
    FaCheck,
    FaCheckCircle,
    FaChevronDown,
    FaChevronLeft,
    FaChevronRight,
    FaChevronUp,
    FaDna,
    FaEllipsisH,
    FaEllipsisV,
    FaExpand,
    FaFilter,
    FaFlag,
    FaInfoCircle,
    FaLock,
    FaLockOpen,
    FaMapMarkerAlt,
    FaPlus,
    FaRegClock,
    FaRegCopy,
    FaRegEnvelope,
    FaRegTrashAlt,
    FaSave,
    FaSitemap,
    FaTable,
    FaTasks,
    FaThumbsUp,
    FaUndoAlt,
    FaUserAltSlash,
    FaUserCheck,
    FaUserClock,
    FaUserSlash,
    FaUsers,
    FaWalking,
} from "react-icons/fa";
import { FaMinus, FaRegComments, FaRepeat } from "react-icons/fa6";
import { FiCamera, FiCameraOff, FiMove, FiUserMinus, FiUserPlus } from "react-icons/fi";
import { GiRadarSweep } from "react-icons/gi";
import { GoGoal, GoWorkflow } from "react-icons/go";
import { GrDocumentMissing } from "react-icons/gr";
import { ImBlocked, ImOffice } from "react-icons/im";
import { IoMdHelp } from "react-icons/io";
import { IoOpenOutline } from "react-icons/io5";
import {
    MdAccountTree,
    MdKeyboardBackspace,
    MdOutlineAssignment,
    MdOutlineAssignmentInd,
    MdOutlineAssignmentLate,
    MdOutlineAssignmentReturned,
    MdOutlineAssignmentTurnedIn,
    MdOutlineCalendarToday,
    MdOutlineCheckBox,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineClose,
    MdOutlineCompareArrows,
    MdOutlineControlPointDuplicate,
    MdOutlineEditNote,
    MdOutlineExpandLess,
    MdOutlineExpandMore,
    MdOutlineHelp,
    MdOutlineHistory,
    MdOutlinePreview,
    MdOutlineSpaceDashboard,
    MdOutlineStart,
    MdOutlineWatchLater,
    MdPeople,
    MdRocketLaunch,
    MdWorkspaces,
} from "react-icons/md";
import { RiSortDesc, RiSurveyLine } from "react-icons/ri";
import { TbDashboard } from "react-icons/tb";
import { TiWarningOutline } from "react-icons/ti";
import { VscSyncIgnored } from "react-icons/vsc";
import { BallTriangle, Oval, Puff, Rings, ThreeDots } from "react-loading-icons";

const LOADING_ICON_PROPS = {
    stroke: "#4878ff",
    strokeOpacity: 0.325,
    speed: 0.75,
};

const DEFAULT_SIZE = 24;

// Org Actions
export const RevokeIcon = (props) => <ImBlocked size={DEFAULT_SIZE} {...props} />;
export const InviteIcon = (props) => <AiOutlineUsergroupAdd size={DEFAULT_SIZE} {...props} />;
export const VacateRoleIcon = (props) => <FiUserMinus size={DEFAULT_SIZE} {...props} />;
export const FillRoleIcon = (props) => <FiUserPlus size={DEFAULT_SIZE} {...props} />;
export const ViewRoleIcon = (props) => <BiWindowOpen size={DEFAULT_SIZE} {...props} />;
export const ViewTalentMapIcon = (props) => <FaMapMarkerAlt size={DEFAULT_SIZE} {...props} />;
export const ViewStrengthsIcon = (props) => <FaDna size={DEFAULT_SIZE} {...props} />;
export const CreateRoleIcon = (props) => <AiOutlineUserAdd size={DEFAULT_SIZE} {...props} />;
export const ViewActionsIcon = (props) => <FaTasks size={DEFAULT_SIZE} {...props} />;
export const MoveRoleIcon = (props) => <FiMove size={DEFAULT_SIZE} {...props} />;
export const OrgIcon = (props) => <FaSitemap size={DEFAULT_SIZE} {...props} />;
export const TableIcon = (props) => <FaTable size={DEFAULT_SIZE} {...props} />;
export const RatingIcon = (props) => <FaDna size={DEFAULT_SIZE} {...props} />;
export const BranchIcon = (props) => <MdAccountTree size={DEFAULT_SIZE} {...props} />;
export const OpenIcon = FaExpand;
export const ExpandIcon = FaExpand;
export const StartRoleIcon = FaWalking;
export const DashboardIcon = (props) => <TbDashboard size={DEFAULT_SIZE} {...props} />;

// Assessments
export const AssessmentPendingIcon = (props) => <MdOutlineAssignmentLate size={DEFAULT_SIZE} {...props} />;
export const AssessmentDoneIcon = (props) => <MdOutlineAssignmentTurnedIn size={DEFAULT_SIZE} {...props} />;
export const AssessmentLateIcon = (props) => <MdOutlineWatchLater size={DEFAULT_SIZE} {...props} />;
export const RequestAssessmentIcon = (props) => <MdOutlineAssignmentReturned size={DEFAULT_SIZE} {...props} />;
export const CompleteAssessmentIcon = (props) => <MdOutlineAssignmentInd size={DEFAULT_SIZE} {...props} />;
export const SkipAssessmentIcon = (props) => <VscSyncIgnored size={DEFAULT_SIZE} {...props} />;
export const ViewAssessmentIcon = (props) => <MdOutlineAssignment size={DEFAULT_SIZE} {...props} />;
export const OpenAssessmentIcon = (props) => <FaLockOpen size={DEFAULT_SIZE} {...props} />;
export const ClosedAssessmentIcon = (props) => <FaLock size={DEFAULT_SIZE} {...props} />;
export const SnapshotEnabledIcon = (props) => <FiCamera size={DEFAULT_SIZE} {...props} />;
export const SnapshotDisabledIcon = (props) => <FiCameraOff size={DEFAULT_SIZE} {...props} />;
export const TalentBoardIcon = (props) => <FaBorderAll size={DEFAULT_SIZE} {...props} />;
export const TraitRatedIcon = (props) => <FaCheckCircle size={DEFAULT_SIZE} {...props} />;
export const TalentAreaIcon = (props) => <GiRadarSweep size={DEFAULT_SIZE} {...props} />;
export const FlagIcon = (props) => <FaFlag size={DEFAULT_SIZE} {...props} />;
export const TeamIcon = (props) => <FaUsers size={DEFAULT_SIZE} {...props} />;
export const OwnerIcon = (props) => <AiOutlineUser size={DEFAULT_SIZE} {...props} />;
export const CopyIcon = (props) => <FaRegCopy size={DEFAULT_SIZE} {...props} />;
export const LaunchIcon = (props) => <MdRocketLaunch size={DEFAULT_SIZE} {...props} />;
export const StartIcon = (props) => <MdOutlineStart size={DEFAULT_SIZE} {...props} />;
export const HelpIcon = (props) => <IoMdHelp size={DEFAULT_SIZE} {...props} />;
export const ReRerunIcon = (props) => <FaRepeat size={DEFAULT_SIZE} {...props} />;

// Loading
export const LoadingIconOval = (props) => (
    <Oval strokeWidth={6} {...LOADING_ICON_PROPS} height={props.size} width={props.size} {...props} />
);
export const LoadingIconRings = (props) => (
    <Rings {...LOADING_ICON_PROPS} height={props.size} width={props.size} {...props} />
);
export const LoadingIconPuff = (props) => (
    <Puff {...LOADING_ICON_PROPS} height={props.size} width={props.size} {...props} />
);
export const LoadingIconTriangle = (props) => <BallTriangle {...LOADING_ICON_PROPS} {...props} />;
export const LoadingIconDots = (props) => <ThreeDots {...LOADING_ICON_PROPS} {...props} height={props.size} />;

// Generic
export const CheckIcon = (props) => <FaCheck size={DEFAULT_SIZE} {...props} />;
export const WarningIcon = (props) => <TiWarningOutline size={DEFAULT_SIZE} {...props} />;
export const CalendarIcon = (props) => <MdOutlineCalendarToday size={DEFAULT_SIZE} {...props} />;
export const EllipsisIcon = (props) => <FaEllipsisV size={DEFAULT_SIZE} {...props} />;
export const SettingsIcon = (props) => <BiCog size={DEFAULT_SIZE} {...props} />;
export const ViewIcon = (props) => <IoOpenOutline size={DEFAULT_SIZE} {...props} />;
export const FilterIcon = (props) => <FaFilter size={DEFAULT_SIZE} {...props} />;
export const InfoIcon = (props) => <FaInfoCircle size={DEFAULT_SIZE} {...props} />;
export const DownloadIcon = (props) => <AiOutlineCloudDownload size={DEFAULT_SIZE} {...props} />;
export const UserIcon = (props) => <FaUserCheck size={DEFAULT_SIZE} {...props} />;
export const PendingUserIcon = (props) => <FaUserClock size={DEFAULT_SIZE} {...props} />;
export const NotUserIcon = (props) => <FaUserAltSlash size={DEFAULT_SIZE} {...props} />;
export const PeopleIcon = (props) => <MdPeople size={DEFAULT_SIZE} {...props} />;
export const DuplicateIcon = (props) => <MdOutlineControlPointDuplicate size={DEFAULT_SIZE} {...props} />;
export const CompareIcon = (props) => <MdOutlineCompareArrows size={DEFAULT_SIZE} {...props} />;
export const HistoryIcon = (props) => <MdOutlineHistory size={DEFAULT_SIZE} {...props} />;
export const DeleteEmployeeIcon = (props) => <FaUserSlash size={DEFAULT_SIZE} {...props} />;
export const BusinessUnitIcon = (props) => <ImOffice size={DEFAULT_SIZE} {...props} />;
export const EmailIcon = (props) => <FaRegEnvelope size={DEFAULT_SIZE} {...props} />;
export const PreviewIcon = (props) => <MdOutlinePreview size={DEFAULT_SIZE} {...props} />;
export const ProfileIcon = (props) => <CgProfile size={DEFAULT_SIZE} {...props} />;
export const WorkspaceIcon = (props) => <MdWorkspaces size={DEFAULT_SIZE} {...props} />;
export const SurveyIcon = (props) => <RiSurveyLine size={DEFAULT_SIZE} {...props} />;

// Controls
export const CheckBoxCheckedIcon = (props) => <MdOutlineCheckBox size={DEFAULT_SIZE} {...props} />;
export const CheckBoxUncheckedIcon = (props) => <MdOutlineCheckBoxOutlineBlank size={DEFAULT_SIZE} {...props} />;
export const DownChevronIcon = (props) => <FaChevronDown size={DEFAULT_SIZE} {...props} />;
export const UpChevronIcon = (props) => <FaChevronUp size={DEFAULT_SIZE} {...props} />;
export const LeftChevronIcon = (props) => <FaChevronLeft size={DEFAULT_SIZE} {...props} />;
export const RightChevronIcon = (props) => <FaChevronRight size={DEFAULT_SIZE} {...props} />;
export const BackIcon = (props) => <MdKeyboardBackspace size={DEFAULT_SIZE} {...props} />;
export const EditIcon = (props) => <MdOutlineEditNote size={DEFAULT_SIZE} {...props} />;
export const DeleteIcon = (props) => <FaRegTrashAlt size={DEFAULT_SIZE} {...props} />;
export const UndoIcon = (props) => <FaUndoAlt size={DEFAULT_SIZE} {...props} />;
export const CrossIcon = (props) => <MdOutlineClose size={DEFAULT_SIZE} {...props} />;
export const BackToStartIcon = (props) => <FaAngleDoubleLeft size={DEFAULT_SIZE} {...props} />;
export const SkipToEndIcon = (props) => <FaAngleDoubleRight size={DEFAULT_SIZE} {...props} />;
export const ReopenIcon = (props) => <FaUndoAlt size={DEFAULT_SIZE} {...props} />;
export const SortIcon = (props) => <RiSortDesc size={DEFAULT_SIZE} {...props} />;
export const SaveIcon = (props) => <FaSave size={DEFAULT_SIZE} {...props} />;
export const AcceptIcon = (props) => <FaThumbsUp size={DEFAULT_SIZE} {...props} />;
export const CheckInIcon = (props) => <FaBalanceScaleLeft size={DEFAULT_SIZE} {...props} />;
export const CompleteIcon = (props) => <AiOutlineFileDone size={DEFAULT_SIZE} {...props} />;
export const InProgressIcon = (props) => <FaEllipsisH size={DEFAULT_SIZE} {...props} />;
export const NotesIcon = (props) => <FaRegComments size={DEFAULT_SIZE} {...props} />;
export const ObjectiveIcon = (props) => <GoGoal size={DEFAULT_SIZE} {...props} />;
export const PrivateIcon = (props) => <FaLock size={DEFAULT_SIZE} {...props} />;
export const ArchiveIcon = (props) => <BsArchive size={DEFAULT_SIZE} {...props} />;
export const CloseIcon = (props) => <MdOutlineClose size={DEFAULT_SIZE} {...props} />;
export const LessIcon = (props) => <MdOutlineExpandLess size={DEFAULT_SIZE} {...props} />;
export const MoreIcon = (props) => <MdOutlineExpandMore size={DEFAULT_SIZE} {...props} />;
export const PlusIcon = (props) => <FaPlus size={DEFAULT_SIZE} {...props} />;
export const MinusIcon = (props) => <FaMinus size={DEFAULT_SIZE} {...props} />;
export const MeasureIcon = (props) => <FaBalanceScaleLeft size={DEFAULT_SIZE} {...props} />;
export const CascadeIcon = (props) => <GoWorkflow size={DEFAULT_SIZE} {...props} />;

//Other
export const TourIcon = (props) => <MdOutlineHelp size={DEFAULT_SIZE} {...props} />;
export const ToDoIcon = (props) => <FaRegClock size={DEFAULT_SIZE} {...props} />;
export const OverviewIcon = (props) => <MdOutlineSpaceDashboard size={DEFAULT_SIZE} {...props} />;
export const EmptyIcon = (props) => <GrDocumentMissing size={DEFAULT_SIZE} {...props} />;

// Togglable Icons
export const LessMoreIcon = (props) => (props.checked ? <LessIcon {...props} /> : <MoreIcon {...props} />);
export const PlusMinusIcon = (props) => (props.checked ? <MinusIcon {...props} /> : <PlusIcon {...props} />);

export { BulkIcon };
