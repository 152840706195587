import React from "react";
import { useEmployeeRefs } from "../../../../../../features/Employees/refs/useEmployeeRefs";
import ObjectivesTemplate from "../../../../../../features/Objectives/components/templates/ObjectivesTemplate";
import { usePaginatedFetched } from "../../../../../../hooks/usePaginatedFetched";
import { useOrgNodeContext } from "../../OrgNodePage";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSideArea } from "../../../../../../redux/appSlice";
import { useWorkspaceContext } from "../../../../../../features/Workspace/WorkspaceContent";
import { toast } from "react-toastify";

const whereConditions = [["isComplete", "==", true]];

const ITEMS_PER_PAGE = 8;

const ObjectivesPage = () => {
    const dispatch = useDispatch();
    const { setSideAreaProps } = useWorkspaceContext();
    const activeSideArea = useSelector((state) => state.app.activeSideArea);
    const { isSelf, isAdmin, hasChildren, liveObjectives, employeeId, employeePath, onSaveObjective } =
        useOrgNodeContext();
    const { objectivesRef } = useEmployeeRefs(employeeId);

    const [completed, fetchMore, hasMore, fetchStatus] = usePaginatedFetched(
        objectivesRef,
        whereConditions,
        ITEMS_PER_PAGE
    );

    const handleSaveObjective = async (objective) => {
        const batch = onSaveObjective(objective);
        await batch.commit();
        toast.success("Objective Created");
    };

    const handleViewComments = (objective) => {
        setSideAreaProps({ objective });
        dispatch(setActiveSideArea("comments"));
    };

    return (
        <div className="page page-padding">
            <ObjectivesTemplate
                employeeId={employeeId}
                isSelf={isSelf}
                hasChildren={hasChildren}
                liveObjectives={liveObjectives}
                completeObjectives={completed}
                page={employeePath}
                fetchMore={fetchMore}
                fetchStatus={fetchStatus}
                hasMore={hasMore}
                allowCreate={isAdmin}
                viewingComments={activeSideArea === "comments"}
                onViewComments={handleViewComments}
                onSaveObjective={handleSaveObjective}
            />
        </div>
    );
};

export default ObjectivesPage;
