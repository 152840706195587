import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useRef, useState } from "react";

function useEditValues(initialValues) {
    const [updatedValues, setUpdatedValues] = useState(initialValues);
    const initialValuesRef = useRef(initialValues);

    useEffect(() => {
        // Only update if initialValues have changed
        if (initialValues !== initialValuesRef.current) {
            setUpdatedValues((prev) => {
                const mergedValues = { ...prev, ...initialValues };
                return mergedValues;
            });
            initialValuesRef.current = initialValues;
        }
    }, [initialValues]);

    const setValue = (key, value) => {
        setUpdatedValues((prev) => {
            const newValues = { ...prev, [key]: value };
            return newValues;
        });
    };

    const setAllValues = (newValues) => {
        setUpdatedValues(newValues);
    };

    const toggleValue = (key) => {
        setUpdatedValues((prev) => {
            const newValue = !prev[key];
            const newValues = { ...prev, [key]: newValue };
            return newValues;
        });
    };

    const hasChanges = useMemo(() => !isEqual(updatedValues, initialValues), [updatedValues, initialValues]);

    return [updatedValues, setValue, hasChanges, setAllValues, toggleValue];
}

export default useEditValues;
