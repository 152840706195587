import { Each, HelperDropdown } from "../../../../common/components";
import MyRadioButtons from "../../../../components/MyComponents/RadioButtons";

export const cascadeOptions = [
    {
        label: "No Cascading",
        value: "individual",
        help: "Nobody will be able to link other Objectives to this Objective.",
    },
    { label: "To Team", value: "team", help: "Direct Reports will be able to link Objectives to this Objective." },
    {
        label: "To Branch",
        value: "branch",
        help: "All subordinates will be able to link Objectives to this Objective.",
    },
];

const CascadeSelector = ({ value, disabled, onChange }) => {
    const handleChange = (newVal) => {
        if (disabled) return;
        if (newVal === value) return;
        onChange(newVal);
    };

    return (
        <div className="flex space-x-2 items-center">
            <HelperDropdown size="xs" width={400}>
                <div className="space-y-2">
                    <div className="space-y-1">
                        <h6>How Does Cascading Work?</h6>
                        <p className="font-normal text-xs">
                            Cascading controls who can link their own Objectives to this one.
                        </p>
                    </div>
                    <hr className="border border-info" />
                    <Each
                        of={cascadeOptions}
                        render={(option) => (
                            <div>
                                <h6 className="text-info">{option.label}</h6>
                                <p className="font-normal text-xs">{option.help}</p>
                            </div>
                        )}
                    />
                </div>
            </HelperDropdown>
            <div className="flex-1">
                <MyRadioButtons
                    size="xs"
                    disabled={disabled}
                    options={cascadeOptions}
                    value={value}
                    checkedColor="secondary"
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default CascadeSelector;
