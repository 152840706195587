import PropTypes from "prop-types";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useSelector } from "react-redux";
import { RichText } from "../../components";
import { CheckIcon, DeleteIcon } from "../../components/Icons/MyIcons";
import MyButton from "../../components/MyComponents/Button";
import { useActions } from "../Actions/hooks/useActions";
import MyBadge from "../../components/MyComponents/Badge";

const CardActions = ({ actionId, status, isFlipped, onFlip, onComplete, onDelete, onReopen }) => {
    const handleDeleteAction = () => {
        onDelete(actionId);
    };

    const handleCompleteAction = () => {
        onComplete(actionId);
    };

    switch (status) {
        case "scheduled":
        default:
            return (
                <div className="card-actions justify-center p-2">
                    <MyButton size="xs" tooltip={isFlipped ? "Less" : "More"} onClick={onFlip}>
                        {isFlipped ? "Less" : "More"}
                    </MyButton>
                    <MyButton tooltip="Cancel" size="xs" shape="circle" onClick={handleDeleteAction}>
                        <DeleteIcon size={18} />
                    </MyButton>
                    <MyButton tooltip="Complete" size="xs" shape="circle" onClick={handleCompleteAction}>
                        <CheckIcon size={18} />
                    </MyButton>
                </div>
            );
    }
};

const DetailsCard = (props) => {
    const { fullAction } = props;
    if (!fullAction) return null;
    return (
        <div className="card h-60 flex flex-col justify-between bg-white rounded-lg glass p-4">
            <div className="flex-1">
                <h2 className="card-title text-tiny">Action</h2>
                <div>
                    <h4>{fullAction.actionName}</h4>
                    <div className="text-sm">
                        <RichText readOnly removePadding content={fullAction.description} />
                    </div>
                </div>
            </div>
            <CardActions actionId={fullAction.id} {...props} />
        </div>
    );
};

const SummaryCard = (props) => {
    const { action } = props;
    const traits = useSelector((state) => state.workspace.traits);
    const linkedTraitIds = action?.linkedTraitIds || [];

    return (
        <div className="card h-40 flex flex-col bg-white rounded-lg glass p-4">
            <div className="flex-1">
                <h2 className="card-title text-tiny">Action</h2>
                <div>
                    <h4>{action.actionName}</h4>
                    <div className="text-sm space-x-1">
                        {linkedTraitIds.map((traitId) => (
                            <MyBadge color="secondary" key={`${action?.id}-${traitId}`}>
                                {traits?.[traitId]?.traitName}
                            </MyBadge>
                        ))}
                    </div>
                </div>
            </div>
            <CardActions actionId={action.id} {...props} />
        </div>
    );
};

export const ScheduledActionCard = ({ status, actionId, fetchAction, onComplete, onDelete, onReopen }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [actionDoc, setActionDoc] = useState();
    const { getAction } = useActions();
    const action = getAction(actionId);

    const handleFlip = async (e) => {
        e.preventDefault();
        const newFlipped = !isFlipped;
        if (newFlipped) {
            const action = await fetchAction(actionId);
            if (action) {
                setActionDoc(action);
                setIsFlipped(true);
            } else {
                setActionDoc({});
            }
        } else {
            setIsFlipped(false);
        }
    };

    const props = {
        status,
        onComplete,
        onDelete,
        onReopen,
        isFlipped,
        onFlip: handleFlip,
    };

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical" containerClassName="h-full">
            <SummaryCard action={action} {...props} />
            <DetailsCard fullAction={actionDoc} {...props} />
        </ReactCardFlip>
    );
};

ScheduledActionCard.propTypes = {
    status: PropTypes.oneOf(["complete", "scheduled", "browse"]),
    actionId: PropTypes.string.isRequired,
    actionsAreComplete: PropTypes.bool,
    disabled: PropTypes.bool,
    fetchAction: PropTypes.func.isRequired,
    actionName: PropTypes.string.isRequired,
    checked: PropTypes.bool,
};

ScheduledActionCard.defaultProps = {
    status: "browse",
    disabled: false,
    checked: false,
    onToggleChecked: () => {},
    onComplete: () => {},
    onDelete: () => {},
    onReopen: () => {},
};

export default ScheduledActionCard;
