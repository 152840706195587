import { createSlice } from "@reduxjs/toolkit";
import { getEmployees } from "../features/Employees/utils/employeeSelectors";
import { listRoleParentRoleIds } from "../features/Roles/utils/roleHelpers";
import { getRoles } from "../features/Roles/utils/roleSelectors";
import { ROOT_ROLE_ID } from "shared";

const SET_CHECKED_IDS = "appSlice/setCheckedIds";
export const SELECT_EMPLOYEE = "appSlice/selectEmployee";
export const CLEAR_SELECTED = "appSlice/clearSelected";
export const ADD_PENDING_CHANGES = "appSlice/addPendingChanges";
export const CLEAR_PENDING_CHANGES = "appSlice/clearPendingChanges";

const selectEmployeeThunk = (employeeId) => (dispatch, getState) => {
    const employees = getEmployees(getState());
    const parentMap = getState().businessUnit.parentMap;
    const employee = employees[employeeId];
    const roleId = employee?.roleId;
    const parentRoleIds = listRoleParentRoleIds(parentMap, roleId);
    const data = {
        employeeId: employeeId,
        roleId: roleId,
        parentRoleIds: parentRoleIds,
    };
    dispatch({ type: SELECT_EMPLOYEE, payload: data });
};

const selectRoleThunk = (roleId) => (dispatch, getState) => {
    const roles = getRoles(getState());
    const parentMap = getState().businessUnit.parentMap;
    // If the roleId being selected is the ROOT_ROLE_ID, clear the selected role
    if (roleId === ROOT_ROLE_ID || !roleId) {
        dispatch({ type: CLEAR_SELECTED, payload: ["selectedRoleId", "selectedEmployeeId"] });
    } else {
        const role = roles?.[roleId];
        const employeeId = role?.incumbentId;
        const parentRoleIds = listRoleParentRoleIds(parentMap, roleId);
        const data = {
            employeeId: employeeId,
            roleId: roleId,
            parentRoleIds: parentRoleIds,
        };
        dispatch({ type: SELECT_EMPLOYEE, payload: data });
    }
};

const setCheckedIdsThunk = (saveId, update) => (dispatch, getState) => {
    const { allCheckedIds } = getState().app;
    let payload = {};
    if (!update) {
        payload = { saveId: saveId, itemIds: [] };
    }
    if (Array.isArray(update)) {
        // When receiving an arrya, set checked to the provided array
        payload = { saveId: saveId, itemIds: update };
    } else {
        // Otherwise, toggle the provided itemId
        const checkedIds = allCheckedIds[saveId] || [];
        let newCheckedIds = [...checkedIds];
        if (checkedIds.includes(update)) {
            newCheckedIds = checkedIds.filter((id) => id !== update);
        } else {
            newCheckedIds = [...checkedIds, update];
        }
        payload = { saveId: saveId, itemIds: newCheckedIds };
    }
    dispatch({ type: SET_CHECKED_IDS, payload: payload });
};

const initialState = {
    uid: null,
    signedIn: false,
    activeWorkspaceId: null,
    activeModal: null,
    activeSideArea: null,
    activeRoleId: null,
    activeTraitId: null,
    activeTalentAreaId: null,
    activeTalentBoardId: null,
    activeActionId: null,
    selectedEmployeeId: null,
    selectedRoleId: null,
    selectedTraitId: null,
    selectedTalentAreaId: null,
    selectedTalentBoardId: null,
    selectedActionId: null,
    selectedCycleId: null,
    objectiveSideArea: {},
    selectedObjective: null,
    activeEmployee: null,
    activeRole: null,
    activeTrait: null,
    activeTalentArea: null,
    activeTalentBoard: null,
    activeAction: null,
    allCheckedIds: {},
    scrollPositions: {},
    boardViews: {},
    needsLiveCycles: false,
    needsArchivedCycles: false,
    employeeLoading: false,
    pendingRoleChanges: [],
};

const slice = createSlice({
    name: "app",
    initialState,
    reducers: {
        resetApp: () => initialState,
        deselectOrgNodes: (state) => {
            state.selectedEmployeeId = null;
            state.selectedRoleId = null;
            state.activeEmployee = null;
        },
        setUid: (state, action) => {
            state.uid = action.payload;
            if (action.payload) {
                state.signedIn = true;
            } else {
                state.signedIn = false;
            }
        },
        setActiveSideArea(state, action) {
            state.activeSideArea = action.payload || null;
        },
        setActiveModal(state, action) {
            state.activeModal = action.payload;
        },
        selectTrait(state, action) {
            state.selectedTraitId = action.payload;
        },
        selectTalentArea(state, action) {
            state.selectedTalentAreaId = action.payload;
        },
        selectTalentBoard(state, action) {
            state.selectedTalentBoardId = action.payload;
        },
        selectAction(state, action) {
            state.selectedActionId = action.payload;
        },
        selectObjective(state, action) {
            state.selectedObjective = action.payload;
        },
        selectCycle(state, action) {
            state.selectedCycleId = action.payload;
        },
        overrideSideHeader(state, action) {
            state.sideHeaderOverride = action.payload;
        },
        setObjectiveSideArea(state, action) {
            state.activeSideArea = action?.payload?.sideArea || null;
            state.objectiveSideArea = action.payload;
        },
        setScrollPosition(state, action) {
            const [positionFor, position] = action.payload;
            state.scrollPositions[positionFor] = position;
        },
        setBoardView(state, action) {
            const [viewFor, view] = action.payload;
            state.boardViews[viewFor] = view;
        },
        setCycleNeeds(state, action) {
            const [key, val] = action.payload;
            state[key] = val;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(SELECT_EMPLOYEE, (state, action) => {
            const { employeeId, roleId } = action.payload;
            state.selectedEmployeeId = employeeId;
            state.selectedRoleId = roleId;
        });
        builder.addCase(SET_CHECKED_IDS, (state, action) => {
            const { saveId, itemIds } = action.payload;
            state.allCheckedIds[saveId] = itemIds;
        });
        builder.addCase(CLEAR_SELECTED, (state, action) => {
            const idsToClear = action.payload || [];
            idsToClear.forEach((id) => {
                state[id] = null;
            });
        });
        builder.addCase(ADD_PENDING_CHANGES, (state, action) => {
            const [type, changes] = action.payload;
            state[type] = [...state[type], ...changes];
        });
        builder.addCase(CLEAR_PENDING_CHANGES, (state, action) => {
            const [type, changes] = action.payload;
            state[type] = state[type].filter((item) => !changes.includes(item));
        });
    },
});

const { actions, reducer } = slice;

export const {
    resetApp,
    setUid,
    deselectOrgNodes,
    setActiveSideArea,
    setActiveModal,
    selectTrait,
    selectTalentArea,
    selectTalentBoard,
    selectAction,
    selectObjective,
    selectCycle,
    overrideSideHeader,
    setObjectiveSideArea,
    setScrollPosition,
    setBoardView,
    setCycleNeeds,
} = actions;

export { selectEmployeeThunk, selectRoleThunk, setCheckedIdsThunk };

export default reducer;
