import classNames from "classnames";
import React from "react";
import PromptInput from "../../../../../common/components/molecules/PromptInput/PromptInput";
import { useEditedObjective } from "../../../hooks/useEditedObjective";

const ObjectiveCreatorHeader = ({ ownerEmployeeId, onSubmit = () => {} }) => {
    const { createObjective } = useEditedObjective();

    const handleSubmit = (objectiveName) => {
        const createdObjective = createObjective(objectiveName, ownerEmployeeId);
        onSubmit(createdObjective);
    };

    return (
        <div className={classNames("flex rounded-box items-center flex-2 text-md h-14 bg-editing border p-4")}>
            <PromptInput
                placeholder="Enter An Objective Name To Begin Creating a New Objective..."
                onSubmit={handleSubmit}
                size="sm"
            />
        </div>
    );
};

export default ObjectiveCreatorHeader;
