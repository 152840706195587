import { useMemo } from "react";
import useEditValues from "../../../common/hooks/useEditValues";
import { getUniqueId } from "../../../common/utils/getUniqueId";

export function useEditedMeasure(measure) {
    const [editedMeasure, setValue, hasChanges, setAllValues, toggleValue] = useEditValues(measure);

    const updateMeasure = (action, val) => {
        switch (action) {
            case "create":
                const measureId = getUniqueId();
                setAllValues({
                    id: measureId,
                    measureName: val,
                    hasTarget: false,
                });
                break;
            case "toggleTarget":
                toggleValue("hasTarget");
                break;
            case "currentVal":
                const currentIsGreatedThanTarget = val > editedMeasure.targetVal;
                const targetIsEmpty = !editedMeasure.targetVal;
                if (currentIsGreatedThanTarget || targetIsEmpty) {
                    setValue("targetVal", val + 1);
                }
                if (!editedMeasure.currentVal) {
                    setValue("startingVal", val);
                }
                setValue(action, val);
                break;
            case "targetVal":
                const newTargetValue = Math.max(val, editedMeasure.currentVal + 1);
                setValue("hasTarget", !!newTargetValue);
                setValue(action, newTargetValue);
                break;
            case "startingVal":
            case "measureType":
                break;
            default:
                // action is just a key in default case
                setValue(action, val);
                break;
        }
    };

    const isValid = useMemo(() => {
        const measureName = editedMeasure?.measureName || "";
        return measureName.length > 0;
    }, [editedMeasure]);

    return { editedMeasure, updateMeasure, hasChanges, setAllValues, isValid };
}
