import { useState, useEffect, useMemo } from "react";
import * as Yup from "yup";

export function useValidation(values, validationSchema) {
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const validate = async () => {
            try {
                const validationShape = Yup.object().shape(validationSchema);
                await validationShape.validate(values, { abortEarly: false });
                setErrors({});
            } catch (err) {
                let errorMessages = {};
                if (err.inner) {
                    // Handle multiple validation errors
                    errorMessages = err.inner.reduce((acc, error) => {
                        acc[error.path] = error.message;
                        return acc;
                    }, {});
                } else if (err.path) {
                    // Handle single validation error
                    errorMessages[err.path] = err.message;
                } else {
                    // Generic error handling (optional)
                    errorMessages.generic = err.message;
                }
                setErrors(errorMessages);
            }
        };
        validate();
    }, [values, validationSchema]);

    const allValid = useMemo(() => Object.keys(errors).length === 0, [errors]);

    return [allValid, errors];
}
