import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatName } from "../../../common/utils/stringUtils";
import MyBadge from "../../../components/MyComponents/Badge";
import RichText from "../../../components/RichText/RichTextContainer";
import { useFetched } from "../../../hooks/useFetched";

const Action = ({ action, complete }) => {
    const actionId = action.actionId;
    const [loadedAction, setLoadedAction] = useState();
    const traits = useSelector((state) => state.workspace.traits);
    const fullAction = { ...loadedAction, ...action };
    const { linkedTraitIds } = action;
    const [fetchAction] = useFetched("actions");

    useEffect(() => {
        async function fetchData() {
            const fetched = await fetchAction(actionId);
            setLoadedAction(fetched);
        }
        if (actionId) {
            fetchData();
        }
    }, [actionId, fetchAction]);

    return (
        <div className="p-3 border rounded-box">
            <div className="flex justify-between">
                <h5 className="font-bold mb-2 text-xl capitalize">{fullAction.actionName}</h5>
                <MyBadge color={complete ? "success" : "primary"}>{complete ? "Complete" : "Scheduled"}</MyBadge>
            </div>
            <div className="mb-4 space-x-3">
                {linkedTraitIds.map((traitId) => {
                    const trait = traits[traitId];
                    return <MyBadge key={`${fullAction.id}-${trait.id}`}>{trait.traitName}</MyBadge>;
                })}
            </div>
            <h6>Action Description</h6>
            <RichText readOnly content={fullAction.description} />
        </div>
    );
};

const EmployeeActionReport = ({ employee, complete }) => {
    const [completeActions, setCompleteActions] = useState([]);
    const [incompleteActions, setIncompleteActions] = useState([]);
    const actions = useSelector((state) => state.workspace.actions);
    const employeeId = employee?.id;
    const { scheduledActionIds, completedActionIds } = employee || {};
    const displayName = formatName(employee);

    useEffect(() => {
        if (scheduledActionIds) {
            const incompleteActions = scheduledActionIds.map((id) => {
                const action = actions[id];
                return { ...action, actionId: id, employeeId: employeeId, complete: false };
            });
            setIncompleteActions(incompleteActions);
        }

        if (completedActionIds) {
            const completeActions = completedActionIds.map((id) => {
                const action = actions[id];
                return { ...action, actionId: id, employeeId: employeeId, complete: false };
            });
            setCompleteActions(completeActions);
        }
    }, [actions, employeeId, scheduledActionIds, completedActionIds]);

    const employeeActions = complete ? completeActions : incompleteActions;

    return (
        <div className="p-5">
            <div>
                <h3 className="mb-1">{`${displayName} - Development Plan`}</h3>
            </div>
            <div className="pt-3 flex flex-col flex-1 space-y-4">
                {employeeActions.map((action, i) => (
                    <Action complete={complete} action={action} key={`${action.id}-${i}`} />
                ))}
            </div>
        </div>
    );
};

export default EmployeeActionReport;
