import { useState } from "react";

export function useHighlander() {
    const [highlander, setHighlander] = useState(null);

    const handleSetHighlander = (val) => {
        setHighlander((prev) => {
            return prev === val ? null : val;
        });
    };

    return [highlander, handleSetHighlander];
}
