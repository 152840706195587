import classNames from "classnames";
import * as d3 from "d3";
import { darken, lighten } from "polished";
import PropTypes from "prop-types";
import React, { memo, useEffect, useMemo, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { getUniqueId } from "../../../common/utils/getUniqueId";

const PolarAreaChart = ({
    chartId = "polar-area-chart",
    data,
    max = 100,
    clickableItems,
    clickableBackground,
    onItemClick,
    onBackgroundClick,
    height,
    width,
    onItemEnter,
    onItemLeave,
    selected,
}) => {
    const svgRef = useRef();

    const tooltipId = useMemo(() => chartId || getUniqueId(), [chartId]);

    useEffect(() => {
        const getFillColor = (data, i, ignoreSelected) => {
            if (!ignoreSelected && selected === data[i].id) {
                const color = data[i].color || "#000000";
                return darken(0.2, color);
            }
            if (data[i].color) {
                return data[i].color;
            } else {
                return i % 2 === 0 ? "grey" : "darkgrey";
            }
        };

        const getBackgroundFillColor = (data, i) => {
            const baseColor = getFillColor(data, i, true);
            return lighten(0.2, baseColor);
        };

        if (!isNaN(width) && !isNaN(height)) {
            const svg = d3.select(svgRef.current);
            const chartWidth = width;
            const chartHeight = height;
            const radius = Math.min(chartWidth, chartHeight) / 2;
            const centerX = chartWidth / 2;
            const centerY = chartHeight / 2;

            const angleScale = d3
                .scaleLinear()
                .range([0, 2 * Math.PI])
                .domain([0, data.length]);

            const radiusScale = d3.scaleLinear().range([0, radius]).domain([0, max]);

            const pie = d3
                .pie()
                .value((d) => d.valueOf)
                .sort(null);

            const arc = d3
                .arc()
                .innerRadius(0)
                .outerRadius((d) => radiusScale(d.data.value))
                .startAngle((d) => angleScale(d.index))
                .endAngle((d) => angleScale(d.index + 1));

            const backgroundArc = d3
                .arc()
                .innerRadius(0)
                .outerRadius(radius) // Background bar is full height
                .startAngle((d, i) => angleScale(i))
                .endAngle((d, i) => angleScale(i + 1));

            const gridLines = d3.range(0, radius, radius / 10);

            svg.selectAll("*").remove();

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("path.background")
                .data(data) // Use data instead of gridLines to match with the data bars
                .join("path")
                .attr("data-tip", (d) => `${d.label} (${d.value || 0})`)
                .attr("data-for", tooltipId)
                .attr("class", classNames("focus:outline-none background", { clickable: clickableBackground }))
                .attr("d", backgroundArc)
                .attr("fill", (d, i) => getBackgroundFillColor(data, i))
                .style("opacity", 0.3)
                .on("click", function (d, i) {
                    onBackgroundClick(i);
                });

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("path")
                .data(pie(data))
                .join("path")
                .attr("d", arc)
                .attr("fill", (d, i) => getFillColor(data, i))
                .attr("stroke", "white")
                .style("stroke-width", "2px")
                .style("opacity", 0.7)
                .attr("data-tip", (d) => `${d.data.label} (${d.data.value})`)
                .attr("data-for", tooltipId)
                .attr("class", classNames("focus:outline-none", { clickable: clickableItems }))
                .on("click", function (d, i) {
                    onItemClick(i);
                })
                .on("mouseenter", function (d, i) {
                    onItemEnter(i);
                })
                .on("mouseleave", function (d, i) {
                    onItemLeave();
                });

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("circle")
                .data(gridLines)
                .join("circle")
                .attr("cx", 0)
                .attr("cy", 0)
                .attr("r", (d) => d)
                .attr("fill", "none")
                .attr("stroke", "#a3abb3")
                .style("stroke-dasharray", "1,1")
                .style("opacity", 0.3);

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("line")
                .data(data)
                .join("line")
                .attr("x1", 0)
                .attr("y1", 0)
                .attr("x2", (d, i) => radius * Math.sin(angleScale(i)))
                .attr("y2", (d, i) => -radius * Math.cos(angleScale(i)))
                .attr("stroke", "#a3abb3")
                .style("stroke-dasharray", "2,2")
                .style("opacity", 0.3);

            ReactTooltip.rebuild();
        }
    }, [
        tooltipId,
        data,
        selected,
        max,
        width,
        height,
        onItemClick,
        onItemEnter,
        onItemLeave,
        onBackgroundClick,
        clickableBackground,
        clickableItems,
    ]);

    if (isNaN(width) || isNaN(height)) return null;

    if (data.length === 0) {
        return (
            <div className="flex flex-col flex-1 justify-center items-center rounded-full border border-base-500">
                <svg ref={svgRef} width={width} height={height}></svg>
            </div>
        );
    }

    return (
        <div className="flex flex-col flex-1 justify-center items-center">
            <ReactTooltip id={tooltipId} place="top" effect="solid" delayShow={300} />
            <svg ref={svgRef} width={width} height={height}></svg>
        </div>
    );
};

PolarAreaChart.propTypes = {
    data: PropTypes.array,
    onItemClick: PropTypes.func,
    onItemEnter: PropTypes.func,
    onItemLeave: PropTypes.func,
    onBackgroundClick: PropTypes.func,
};

PolarAreaChart.defaultProps = {
    data: [],
    onItemClick: () => {},
    onBackgroundClick: () => {},
    onItemEnter: () => {},
    onItemLeave: () => {},
};

export default memo(PolarAreaChart);
