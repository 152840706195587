import { useMemo, useState } from "react";
import applogger from "../../../../../common/utils/applogger";
import { sortArrayOfObjects } from "../../../../../common/utils/arrayUtils";
import { confirmFirst } from "../../../../../common/utils/confirmFirst";
import { useCascadedListener } from "../../../../Objectives/hooks/useCascadedListener";
import { useCascadeRefs } from "../../../../Objectives/refs/useCascadeRefs";
import { useDraftReview } from "../../../hooks/useDraftReview";
import ReviewEditor from "../ReviewEditor";
import CurrentObjectivesStep from "./steps/CurrentObjectivesStep";
import NotesAndFlagsStep from "./steps/NotesAndFlagsStep";
import SetObjectivesStep from "./steps/SetObjectivesStep";

const moduleSteps = [
    { segmentId: "objectiveReview", label: "Review Current Objectives", component: CurrentObjectivesStep },
    { segmentId: "objectiveSetting", label: "Set New Objectives", component: SetObjectivesStep },
];
const notesAndFlagsStep = { segmentId: "flags", label: "Set Indicators", component: NotesAndFlagsStep };

function getSteps(cycle = {}, relationship, includeFlagsPage, objectivesToReviewCount) {
    function getGuidance(segmentId) {
        const fieldName = `${relationship}Guidance`;
        return cycle[fieldName]?.[segmentId];
    }
    const { segmentIds } = cycle;
    const segmentSteps = moduleSteps.filter((step) => segmentIds.includes(step.segmentId));
    const includesSettingStep = segmentSteps.some((step) => step.segmentId === "objectiveSetting");
    if (objectivesToReviewCount === 0 && includesSettingStep) {
        segmentSteps.shift();
    }
    if (includeFlagsPage) {
        segmentSteps.push(notesAndFlagsStep);
    }
    segmentSteps.forEach((step) => {
        const guidance = getGuidance(step?.segmentId);
        step.help = guidance;
    });
    return segmentSteps;
}

const ObjectiveReviewEditorContainer = ({ values, actions }) => {
    const [draftSaving, setDraftSaving] = useState(false);
    const {
        relationship,
        savedReview,
        unsubmittedSaves,
        approvalRequired,
        savedNotes,
        includeFlagsPage,
        employeeId,
        iterationId,
        cycle,
        preview,
        liveObjectives,
        initialFlagsAndRatings,
    } = values;
    const { onSaveDraft, onSubmit } = actions;
    const [cascadedTeamObjectives, setTeamObjectives] = useState({});
    const [cascadedBranchObjectives, setBranchObjectives] = useState({});
    const { teamCascadeRef, branchCascadeRef } = useCascadeRefs(employeeId);
    useCascadedListener(teamCascadeRef, setTeamObjectives);
    useCascadedListener(branchCascadeRef, setBranchObjectives);
    const draftReview = useDraftReview(savedReview, initialFlagsAndRatings, liveObjectives);
    const hasUnsubmittedSaves = unsubmittedSaves > 0;
    const shouldSubmit = draftReview.draftHasChanged || hasUnsubmittedSaves;
    const confirmLabel = useMemo(() => {
        if (approvalRequired && !shouldSubmit) return "Approve & Complete";
        if (!approvalRequired && !shouldSubmit) return "Submit Unchanged";
        return "Submit & Share Changes";
    }, [shouldSubmit, approvalRequired]);

    const cascadedObjectives = useMemo(() => {
        const allCascaded = { ...cascadedTeamObjectives, ...cascadedBranchObjectives };
        return Object.values(allCascaded);
    }, [cascadedTeamObjectives, cascadedBranchObjectives]);

    // filter out objectives that were created during this iteration
    const objectives = draftReview?.draft?.objectives;
    const objectivesToReview = useMemo(() => {
        const filtered = Object.values(objectives || []).filter(
            (objective) => objective.createdInIterationId !== iterationId
        );
        return sortArrayOfObjects(filtered, "objectiveName");
    }, [objectives, iterationId]);
    const objectivesToReviewCount = objectivesToReview.length;

    // Determine the steps to display
    const steps = useMemo(
        () => getSteps(cycle, relationship, includeFlagsPage, objectivesToReviewCount),
        [cycle, relationship, includeFlagsPage, objectivesToReviewCount]
    );

    const handleRemoveObjective = (objectiveId, afterRemove) => {
        confirmFirst({
            title: "Delete Objective?",
            message:
                "This will remove the objective from the review. If other reviewers submit unsaved changes after this, the Objective will be re-created.",
            onConfirm: () => draftReview.removeObjective(objectiveId),
            onAfter: afterRemove,
        });
    };

    const handleSaveDraft = async () => {
        let notifySaving = false;
        if (draftReview.draftHasChanged) {
            try {
                applogger.info("Draft has changed, saving...");
                draftReview.snapshotDraft();
                onSaveDraft(draftReview.draft);
                notifySaving = true;
            } catch (error) {
                applogger.error("Error saving draft", error);
            }
        }
        if (notifySaving) {
            setDraftSaving(true);
            setTimeout(() => setDraftSaving(false), 750);
        }
    };

    const handleSubmit = async () => {
        if (preview) return;
        onSubmit(draftReview.draft, shouldSubmit);
    };

    const enhancedValues = {
        ...values,
        hideStepNotes: true,
        draftObjectives: objectives || [],
        objectivesToReview,
        noObjectivesToReview: objectivesToReviewCount === 0,
        savedNotes,
        draftNotes: draftReview?.draft?.notes || [],
        draftSaving,
        draftReview,
        cascadedObjectives,
    };

    const enhancedActions = {
        ...actions,
        updateObjective: draftReview.updateObjective,
        removeObjective: handleRemoveObjective,
        onSaveDraft: handleSaveDraft,
        onSubmit: handleSubmit,
    };

    return (
        <ReviewEditor
            isOpen={true}
            values={enhancedValues}
            actions={enhancedActions}
            submitLabel={confirmLabel}
            removePadding
        >
            {steps.map((step, index) => {
                const StepComponent = step.component;
                return <StepComponent step={step} key={`cycle-editor-${index}`} />;
            })}
        </ReviewEditor>
    );
};

export default ObjectiveReviewEditorContainer;
