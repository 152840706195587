import classNames from "classnames";
import { darken, lighten } from "polished";
import PropTypes from "prop-types";
import React from "react";
import { getColorChanges } from "../../helpers/helpers";

const MyBadge = ({ children, color, noFade, className, hidden, onClick }) => {
    if (hidden) {
        return null;
    }
    let style = {};
    let colorClassName;
    if (!noFade) {
        const [lightenBy, darkenBy, fullColor] = getColorChanges(color);
        const bgColor = lighten(lightenBy, fullColor);
        const textColor = darken(darkenBy, bgColor);
        style = {
            backgroundColor: bgColor,
            color: textColor,
        };
    } else {
        colorClassName = `badge-${color}`;
    }

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            style={style}
            className={classNames("badge tracking-tighter font-medium p-3", colorClassName, className)}
            onClick={handleClick}
        >
            {children}
        </div>
    );
};

MyBadge.propTypes = {
    color: PropTypes.string,
};

MyBadge.defaultProps = {
    color: "secondary",
};

export default MyBadge;
