import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useConfig } from "../../Config/hooks/useConfig";
import { useEmployeeFields } from "../../Employees/hooks/useEmployeeFields";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";
import { useOrgTableConfig } from "../OrgTable/hooks/useOrgTableConfig";

function ensureArray(value) {
    if (Array.isArray(value)) {
        return value;
    } else {
        return Object.values(value);
    }
}

export function useOrgData(topLevelRoleId, highestAllowedRoleId) {
    const employees = useSelector((state) => state.businessUnit.employees);
    const { allFields } = useEmployeeFields();
    const { ownConfig } = useConfig();
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const { getAllRoleIdsBelow } = useRoleRelationships();
    const { orgView } = ownConfig;
    const [allPreppedEmployees, setAllPreppedEmployees] = useState([]);
    const [pageReady, setPageReady] = useState(false);
    const snapshotsReady = useSelector((state) => state.snapshots.snapshotsReady);
    const { activeOrgTableId } = useOrgTableConfig();

    // Org Table Data
    const includeTopRole = useMemo(() => {
        return orgView === "table" || orgView === "chart";
    }, [orgView]);

    const includeInactive = useMemo(() => {
        return orgView === "table" && activeOrgTableId === "iacEmp" && isAdmin;
    }, [orgView, isAdmin, activeOrgTableId]);

    useEffect(() => {
        function prepEmployees() {
            const initialEmployees = ensureArray(employees);
            if (!initialEmployees || !allFields) {
                // Set to empty array if no employees or fields
                setAllPreppedEmployees([]);
            } else {
                // Prep the employees
                const prepped = initialEmployees.map((employee) => {
                    let employeeRow = { ...employee };
                    allFields.forEach((column) => {
                        const columnVal = column.getValue ? column.getValue(employee) : employee[column.id];
                        if (columnVal !== undefined) {
                            employeeRow[column.id] = columnVal;
                        }
                    });
                    return employeeRow;
                });
                setAllPreppedEmployees(prepped);
            }

            // Set page ready
            setPageReady(true);
        }

        // Wait until stable for half a second
        const timer = setTimeout(() => {
            if (snapshotsReady) prepEmployees();
        }, 500);

        return () => clearTimeout(timer);
    }, [employees, allFields, snapshotsReady]);

    const filteredEmployees = useMemo(() => {
        const roleId = topLevelRoleId || highestAllowedRoleId;

        let included = [];
        if (!roleId || roleId === rootRoleId) {
            // When no role filtering is needed, include all employees with a role
            included = allPreppedEmployees.filter((employee) => !!employee.roleId);
        } else {
            // When there is filtering, include all employees below the selected role
            const subordinateRoleIds = getAllRoleIdsBelow(roleId);
            included = allPreppedEmployees.filter((employee) => {
                return subordinateRoleIds.includes(employee.roleId);
            });

            // If the selected role should be included, add it
            if (includeTopRole) {
                const topEmployee = allPreppedEmployees.find((employee) => employee.roleId === roleId);
                if (topEmployee) {
                    included.push(topEmployee);
                }
            }
        }

        if (includeInactive && roleId === rootRoleId) {
            const inactiveEmployees = allPreppedEmployees.filter((employee) => !employee.roleId);
            included.push(...inactiveEmployees);
        }

        return included;
    }, [
        rootRoleId,
        allPreppedEmployees,
        highestAllowedRoleId,
        topLevelRoleId,
        includeInactive,
        includeTopRole,
        getAllRoleIdsBelow,
    ]);
    return [filteredEmployees, pageReady];
}
