import { getDoc } from "firebase/firestore";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../common/components";
import { readTracker } from "../../../common/utils/activityTracker";
import { IdPicker } from "../../../components";
import { changeActiveWorkspaceThunk } from "../../../features/User/utils/userThunks";
import { getWorkspaceInfoRef } from "../../../firebase/firebaseActions";
import { useToggle } from "../../../hooks/useToggle";
import { FaPlus } from "react-icons/fa";
import CreateWorkspaceModal from "../../../features/Workspace/modals/CreateWorkspaceModal";
import MyEmptyState from "../../../components/MyComponents/EmptyState";

const SelectWorkspace = () => {
    const dispatch = useDispatch();
    const [showCreate, toggleCreate] = useToggle();
    const [selectedWorkspaceId, setSelectedWorkspaceId] = useState();
    const { isDeveloper } = useSelector((state) => state.user);
    const [allWorkspaces, setAllWorkspaces] = useState([]);

    async function handleFetchList() {
        if (!isDeveloper) return;
        const workspaceInfoRef = getWorkspaceInfoRef();
        const fetchedDoc = await getDoc(workspaceInfoRef);
        readTracker(workspaceInfoRef);
        const docData = fetchedDoc.data();
        const workspaceNameData = docData?.workspaces || {};
        const allWorkspaceNames = Object.entries(workspaceNameData).map(([id, name]) => {
            return { id, label: name };
        });
        setAllWorkspaces(allWorkspaceNames);
    }

    const handleSetWorkspace = (id) => {
        setSelectedWorkspaceId(id);
    };

    const handleSelectWorkspace = () => {
        dispatch(changeActiveWorkspaceThunk(selectedWorkspaceId));
    };

    if (!isDeveloper) {
        return (
            <div className="fixed inset-0 flex flex-col w-full">
                <MyEmptyState type="error" className="flex-1" message="You are not authorised to view this page" />
            </div>
        );
    }

    return (
        <div className="fixed inset-0 flex flex-col w-full">
            <div className="border rounded-lg shadow m-auto max-w-md flex flex-col justify-center space-y-6 p-12">
                <h2 className="text-center tracking-tighter">Select Workspace</h2>
                <Button onClick={handleFetchList} label="Fetch Workspace Info" color="secondary">
                    Fetch List
                </Button>
                <div>
                    <IdPicker
                        options={allWorkspaces || []}
                        onChange={handleSetWorkspace}
                        selectedIds={selectedWorkspaceId}
                    />
                </div>
                <Button label="select" color="primary" onClick={handleSelectWorkspace}>
                    Select
                </Button>
            </div>
            <div className="p-4">
                <Button size="sm" color="ghost" onClick={toggleCreate}>
                    <FaPlus />
                    Create Workspace
                </Button>
            </div>
            <CreateWorkspaceModal isOpen={showCreate} onToggle={toggleCreate} />
        </div>
    );
};

export default SelectWorkspace;
