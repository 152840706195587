function camelCaseToTitleCase(text) {
    return text.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
    });
}

function formatName(userData = {}) {
    const { firstname, surname } = userData;
    if (firstname && surname) {
        return `${firstname} ${surname}`;
    } else {
        return "Name Missing";
    }
}

function convertStringToInt(string) {
    const parsed = parseInt(string);
    return isNaN(parsed) ? 0 : parsed;
}

export { camelCaseToTitleCase, formatName, convertStringToInt };
