import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { IoIosHelpCircleOutline as HelpIcon } from "react-icons/io";
import { usePopper } from "react-popper";
import { useClickOutside } from "../../../hooks/useClickOutside";

const HelperDropdown = ({
    title,
    children,
    size = "sm",
    color = "info",
    width = 420,
    preferredPosition = "bottom",
    hidden = false,
    disabled = false,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const triggerRef = useRef(null);
    const dropdownRef = useRef(null);
    useClickOutside(dropdownRef, () => setIsVisible(false));

    const [dropdownElement, setDropdownElement] = useState(null);
    const { styles, attributes } = usePopper(triggerRef.current, dropdownElement, {
        placement: preferredPosition,
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });

    let iconSize = 24;
    switch (size) {
        case "xs":
            iconSize = 24;
            break;
        case "sm":
        default:
            iconSize = 32;
            break;
    }

    const handleIconClick = () => {
        if (disabled) return;
        setIsVisible(!isVisible);
    };

    if (hidden) return null;
    return (
        <div className="dropdown-container" ref={triggerRef}>
            <div
                tabIndex={-1}
                role="button"
                style={{ padding: 0 }}
                disabled={disabled}
                className={classNames("flex items-center justify-center rounded-full", {
                    "text-info hover:bg-info hover:text-info-content": !disabled,
                    "cursor-not-allowed text-base-500": disabled,
                })}
                onClick={handleIconClick}
            >
                <HelpIcon size={iconSize} />
            </div>
            {isVisible && (
                <div
                    ref={(node) => {
                        setDropdownElement(node);
                        dropdownRef.current = node;
                    }}
                    className="compact dropdown-content z-[1] pe-14"
                    style={{ ...styles.popper, width }}
                    {...attributes.popper}
                >
                    <div className="bg-base-100 rounded-lg shadow-lg">
                        <div tabIndex={0} className={classNames("bg-opacity-10 rounded-lg p-4 text-sm", `bg-${color}`)}>
                            <h4 className="text-sm font-medium mb-1 capitalize">{title}</h4>
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

HelperDropdown.propTypes = {
    title: PropTypes.string,
    color: PropTypes.oneOf(["success", "primary", "error", "warning", "info"]),
    width: PropTypes.number,
    preferredPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
};

export default HelperDropdown;
