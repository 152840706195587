import React from "react";
import logo from "../../assets/img/logo/logo.png";
import { Button } from "../../common/components";
import { ALT_TEXT } from "../../constants";

const ErrorDefault = ({ error, onSignOut, onTryAgain, onGoHome }) => {
    const getErrorDetails = () => {
        switch (error.message) {
            case "timedOut":
                return {
                    title: "This Page is taking too long to load.",
                    message: "Try going to the home page. If the problem persists, contact support.",
                };
            case "blocked":
                return {
                    title: "Your Account Has Been Locked",
                    message: "We've noticed some strange behaviour on your account. Please contact your support team.",
                };
            default:
                return {
                    title: "Uh Oh. Something went wrong.",
                    message: "An error occurred. Please try again.",
                    showFullOption: true,
                };
        }
    };

    const errorDetails = getErrorDetails();

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-base-150">
            <div className="flex-1 max-h-11/12 w-full max-w-11/12 bg-base-100 p-8 rounded-lg shadow-md text-center space-y-12">
                <div className="flex-1 mb-5 text-primary">
                    <img src={logo} alt={ALT_TEXT.LOGO} width={60} />
                </div>
                <div>
                    <h1 className="text-2xl font-semibold text-red-500 mb-4">{errorDetails.title}</h1>
                    <p>{errorDetails.message}</p>
                </div>
                <div className="flex justify-center">
                    <div className="flex flex-col justify-center space-y-4 max-w-xs">
                        <Button onClick={onGoHome} color="primary">
                            Home
                        </Button>
                        <Button onClick={onTryAgain} color="primary">
                            Refresh Page
                        </Button>
                        <Button size="sm" onClick={onSignOut} color="ghost" variant="outline">
                            Sign Out
                        </Button>
                    </div>
                </div>
                {errorDetails.showFullOption && (
                    <div className="flex justify-center p-3 text-center">
                        <div className="collapse text-center">
                            <input type="checkbox" />
                            <div className="collapse-title p-0 font-medium">Show Full Error</div>
                            <div className="collapse-content">
                                <p className="text-gray-700 mb-6">{error.message}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ErrorDefault;
