import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const ChartLegend = ({ items, vertical, selectedId, highlightSelected }) => {
    const legendStyle = vertical
        ? { flexDirection: "column", alignItems: "flex-start" }
        : { flexDirection: "row", alignItems: "center", flexWrap: "wrap" };

    return (
        <div style={{ display: "flex", ...legendStyle }}>
            {items.map((item) => (
                <div
                    key={item.id}
                    className={classNames("p-1 flex space-x-1 items-center min-w-fit", {
                        "opacity-30": highlightSelected && item.id !== selectedId,
                    })}
                >
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: item.color,
                        }}
                    ></div>
                    <span className={classNames("text-xs uppercase tracking-tight font-semibold")}>{item.label}</span>
                </div>
            ))}
        </div>
    );
};

ChartLegend.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
        })
    ).isRequired,
    vertical: PropTypes.bool,
    minWidth: PropTypes.number,
};

ChartLegend.defaultProps = {
    vertical: false,
    minWidth: 100, // Set a default min-width in case it is not provided
};

export default ChartLegend;
