import "os-browserify/browser";
import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import Session from "./Session.jsx";
import store from "./redux/store.js";
import reportWebVitals from "./reportWebVitals";
import { LanguageProvider } from "./translations/LanguageContext.js";
import ErrorFallback from "./features/ErrorPages/ErrorFallback.js";

// Global error handlers
window.addEventListener("error", (event) => {
    console.error("Global error caught:", event.error);
});

window.addEventListener("unhandledrejection", (event) => {
    console.error("Unhandled promise rejection:", event.reason);
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <LanguageProvider>
        <Provider store={store}>
            <ErrorBoundary FallbackComponent={ErrorFallback} onReset={(details) => {}}>
                <Session />
            </ErrorBoundary>
        </Provider>
    </LanguageProvider>
);

reportWebVitals();
