import { useMemo } from "react";
import * as Yup from "yup";
import useEditValues from "../../../common/hooks/useEditValues";
import { getUniqueId } from "../../../common/utils/getUniqueId";
import { FIELD_SIZE_LIMITS } from "../../../constants";

export function useEditedObjective(objective, locked = false) {
    const [editedObjective, setValue, hasChanges, setAllValues] = useEditValues(objective);

    const createObjective = (objectiveName, ownerEmployeeId, cascadedFrom) => {
        const objectiveId = getUniqueId();
        const newObjective = {
            id: objectiveId,
            isComplete: false,
            ownerEmployeeId,
            result: null,
            confidence: null,
            objectiveName: objectiveName,
            measures: [],
            status: "not_started",
            cascadeType: "individual",
            dueOn: null,
        };
        if (cascadedFrom) {
            newObjective.parentId = cascadedFrom.id;
            newObjective.parentName = cascadedFrom.objectiveName;
            newObjective.blockCascades = cascadedFrom.cascadeType === "branch";
        }
        setAllValues(newObjective);
        return newObjective;
    };

    const updateObjective = (action, val) => {
        if (locked) return;
        switch (action) {
            case "removeMeasure":
                let newMeasures = editedObjective.measures.filter((m) => m.id !== val.id);
                setValue("measures", newMeasures);
                break;
            case "editOrAddMeasure":
                const existsAleady = editedObjective.measures.find((m) => m.id === val.id);
                if (existsAleady) {
                    const newMeasures = editedObjective.measures.map((m) => (m.id === val.id ? val : m));
                    setValue("measures", newMeasures);
                } else {
                    setValue("measures", [...editedObjective.measures, val]);
                }
                break;
            case "status":
                const isComplete = val === "complete";
                const withChanges = {
                    ...editedObjective,
                    isComplete: isComplete,
                    status: val,
                    result: isComplete ? 100 : null,
                };
                setAllValues(withChanges);
                break;
            case "result":
                let completedObjective = {
                    ...editedObjective,
                    result: val,
                };
                setAllValues(completedObjective);
                break;
            default:
                // action is just a key in default case
                setValue(action, val);
                break;
        }
    };

    const objectiveValidation = useMemo(() => {
        return {
            objectiveName: Yup.string()
                .required("Name is required")
                .min(3, "Name is too short or missing")
                .max(FIELD_SIZE_LIMITS.GENERIC_LONG_NAME, "Name is too long"),
            dueOn: Yup.date().required("Achieve By Date is Required"),
        };
    }, []);

    return { createObjective, editedObjective, updateObjective, hasChanges, objectiveValidation, setAllValues };
}
