import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { MyModal, MyModalBody, MyModalHeader } from "../../../components/MyComponents/Modal";
import { sortArrayOfObjects } from "../../../helpers/helpers";
import ScheduledActionItem from "../../ScheduledActions/ScheduledActionItem";

const ActionGroup = (props) => {
    const {
        trait,
        incompletedActionIds = [],
        completedActionIds = [],
        onScheduleAction,
        fetchAction,
    } = props;
    const actions = useSelector((state) => state.workspace.actions);
    const { linkedActionIds = [] } = trait;

    const includedActions = useMemo(() => {
        const unsorted = linkedActionIds
            .filter((actionId) => !incompletedActionIds.includes(actionId))
            .map((actionId) => {
                return actions[actionId];
            })
            .filter(Boolean);
        return sortArrayOfObjects(unsorted, "actionName");
    }, [incompletedActionIds, linkedActionIds, actions]);

    const handleScheduledAction = (actionId) => {
        onScheduleAction(actionId);
    };

    if (!includedActions.length) return null;

    return (
        <div className="py-4 px-8 space-y-2">
            <h5>{trait.traitName}</h5>
            {includedActions.map((action) => (
                <ScheduledActionItem
                    key={action.id}
                    mode="browse"
                    done={completedActionIds.includes(action.id)}
                    fetchAction={fetchAction}
                    actionId={action.id}
                    actionName={action.actionName}
                    onSchedule={handleScheduledAction}
                />
            ))}
        </div>
    );
};

const ScheduleActionModal = ({ activeEmployee, isOpen, toggle, fetchAction, onCreate, onReopen }) => {
    const traits = useSelector((state) => state.workspace.traits);
    const { completedActionIds = [], scheduledActionIds = [] } = activeEmployee || {};
    const sortedTraits = useMemo(() => {
        return sortArrayOfObjects(Object.values(traits), "traitName");
    }, [traits]);

    return (
        <MyModal isOpen={isOpen} size="xl">
            <MyModalHeader onToggle={toggle}>Schedule Action</MyModalHeader>
            <MyModalBody>
                <div className="flex-1 h-full flex flex-col overflow-scroll">
                    {sortedTraits.map((trait) => (
                        <ActionGroup
                            key={trait.id}
                            trait={trait}
                            completedActionIds={completedActionIds}
                            incompletedActionIds={scheduledActionIds}
                            fetchAction={fetchAction}
                            onScheduleAction={onCreate}
                        />
                    ))}
                </div>
            </MyModalBody>
        </MyModal>
    );
};

export default ScheduleActionModal;
