import { writeBatch } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { PUBLIC_FIELDS } from "shared";
import { db } from "../../../firebase/firebase";
import { getUniqueId } from "../../../helpers/helpers";
import { buildInfoDocUpdate } from "../../Workspace/utils/buildInfoDocUpdate";
import { deleteTalentItem, writeTalentItem, writeTraitMap } from "../../Workspace/utils/workspaceDb";
import { REMOVE_TALENT_ITEMS, SET_TRAIT_MAP, UPDATE_ACTION } from "../../Workspace/utils/workspaceSlice";
import { useActionRefs } from "../refs/useActionRefs";
import { addTraitLinks, removeAllLinksToActions } from "../utils/actionLinkingUtils";

export function useActions() {
    const dispatch = useDispatch();
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const actions = useSelector((state) => state.workspace.actions);
    const traitMap = useSelector((state) => state.workspace.traitMap);
    const { actionsInfoRef } = useActionRefs();

    function getAction(actionId) {
        const action = actions[actionId];
        return action;
    }

    async function updateAction(actionId, update) {
        if (!actionId) return;
        // eslint-disable-next-line no-unused-vars
        const { linkedTraitIds, traitId, ...dataToSave } = update;

        let batch = writeTalentItem(workspaceId, "actions", actionId, dataToSave);
        try {
            const result = await batch.commit();
            dispatch({ type: UPDATE_ACTION, payload: [actionId, update] });
            return result;
        } catch (error) {
            console.error("Error updating action", error);
            return error;
        }
    }

    async function createAction(action, linkedTraitIds = []) {
        const actionId = getUniqueId();
        let batch = writeTalentItem(workspaceId, "actions", actionId, action);
        let traitMapUpdate = addTraitLinks(traitMap, linkedTraitIds, actionId);
        batch = writeTraitMap(workspaceId, traitMapUpdate, batch);

        try {
            await batch.commit();
            dispatch({ type: SET_TRAIT_MAP, payload: traitMapUpdate });
            dispatch({ type: UPDATE_ACTION, payload: [actionId, action] });
            return actionId;
        } catch (error) {
            console.error("Error creating action", error);
            return error;
        }
    }

    async function deleteActions(actionIds) {
        // Convert to array if needed
        actionIds = Array.isArray(actionIds) ? actionIds : [actionIds];

        let batch = writeBatch(db);
        let actionInfoUpdate = { ...actions };
        actionIds.forEach((actionId) => {
            // Don't delete if it doesn't exist
            if (!actions[actionId]) return;

            // Delete the document
            batch = deleteTalentItem(workspaceId, "actions", actionId, batch);

            // Delete the info record
            delete actionInfoUpdate[actionId];
        });
        // Update the trait map
        const traitMapUpdate = removeAllLinksToActions(traitMap, actionIds);
        batch = writeTraitMap(workspaceId, traitMapUpdate, batch);

        // Update the action info record
        const infoDocUpdate = buildInfoDocUpdate(actionInfoUpdate, PUBLIC_FIELDS.actions);
        batch.set(actionsInfoRef, infoDocUpdate);

        try {
            await batch.commit();
            dispatch({ type: REMOVE_TALENT_ITEMS, payload: ["actions", actionIds] });
        } catch (error) {
            console.error("Error deleting actions", error);
        }
    }
    return { getAction, createAction, updateAction, deleteActions };
}
