import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { LoadingIndicator, WordCloud } from "../../../../components";
import { useFetched } from "../../../../hooks/useFetched";
import { getEveryoneConfig } from "../../../Config/utils/configSelectors";

const TraitWordCloud = (props) => {
    const ratings = props.ratings;
    const traits = useSelector((state) => state.workspace.traits);
    const { assessmentColors } = useSelector(getEveryoneConfig);
    const [fetchTrait] = useFetched("traits");
    const [data, setData] = useState([]);

    useEffect(() => {
        let newData = [];
        let newHighestRating = 0;
        ratings.forEach((rating) => {
            const trait = traits[rating.id];
            newData.push({
                word: trait && trait.traitName,
                traitId: rating.id,
                rating: rating.rating,
            });
            newHighestRating = Math.max(newHighestRating, rating.rating);
        });
        newData = orderBy(newData, ["value", "desc"]);
        setData(newData);
    }, [ratings, traits]);

    const handleClickTag = async (word) => {
        const fullTrait = await fetchTrait(word.traitId);
        confirmAlert({
            title: fullTrait.traitName,
            message: fullTrait.description,
            buttons: [
                {
                    label: "Done",
                },
            ],
        });
    };

    if (props.isPending) {
        return (
            <div className="flex-1 flex justify-center">
                <LoadingIndicator icon="spinner" color="base-500" />;
            </div>
        );
    }

    return <WordCloud wordEntries={data} colors={assessmentColors} onWordClick={handleClickTag} />;
};

TraitWordCloud.propTypes = {
    ratings: PropTypes.array,
    maxSize: PropTypes.number,
};

TraitWordCloud.defaultProps = {
    ratings: [],
    maxSize: 32,
};

export default memo(TraitWordCloud);
