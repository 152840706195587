import { useMemo } from "react";
import { getDocRef, getSubCollectionRef } from "../../../firebase/firebaseActions";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";

export function useActionRefs(actionId) {
    const workspaceRef = useWorkspaceRef();

    const actionsInfoRef = useMemo(() => {
        if (!workspaceRef) return null;
        const talentInfoRef = getSubCollectionRef(workspaceRef, "talentInfo");
        return getDocRef(talentInfoRef, "actions");
    }, [workspaceRef]);

    const actionsRef = useMemo(() => {
        if (!workspaceRef) return;
        return getSubCollectionRef(workspaceRef, "actions");
    }, [workspaceRef]);

    const actionRef = useMemo(() => {
        if (!actionsRef || !actionId) return;
        return getDocRef(actionsRef, actionId);
    }, [actionsRef, actionId]);

    const actionUnitDocsRef = useMemo(() => {
        if (!actionRef) return;
        return getSubCollectionRef(actionRef, "actionTracking");
    }, [actionRef]);

    return { actionsRef, actionsInfoRef, actionUnitDocsRef, actionRef };
}
