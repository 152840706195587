import { serverTimestamp } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { batchSet } from "../../../firebase/firebaseActions";
import { useListener } from "../../../firebase/hooks/useListener";
import { prepDocSnapshot, prepQuerySnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { useFetched } from "../../../hooks/useFetched";
import { useCycleRefs } from "../../Cycles/refs/useCycleRefs";
import { useReviewsRef } from "../../Cycles/refs/useReviewsRefs";
import { useIterationNotesRefs } from "../../Notes/refs/useIterationNotesRefs";
import { normalizeRatings } from "../../Snapshots/utils/normalizeRating";

function useCycle(cycleId) {
    const { cyclesRef } = useCycleRefs();
    const [cycle, setCycle] = useState(null);
    const [ fetchDoc ] = useFetched(cyclesRef)
    useEffect(() => {
        async function fetchCycle() {
            const fetchedCycle = await fetchDoc(cycleId);
            setCycle(fetchedCycle);
        }
        if (cycleId) {
            fetchCycle();
        }
        return () => {
            setCycle(null);
        }
    }, [cycleId, fetchDoc]);

    return cycle;
}

function useNotesListener(iterationNotesRef, setNotes) {
    const handleDataReceived = useCallback(
        (querySnapshot) => {
            const notesData = prepQuerySnapshot(querySnapshot);
            setNotes(Object.values(notesData) || {});
        },
        [setNotes]
    );

    return useListener(iterationNotesRef, handleDataReceived);
}

function useReviewListener(reviewerRef, setSavedReview) {
    const handleDataReceived = useCallback(
        (doc) => {
            const reviewData = prepDocSnapshot(doc);
            let { ratings, ...rest } = reviewData;
            if (ratings) {
                rest.ratings = normalizeRatings(ratings);
            }
            setSavedReview(reviewData);
        },
        [setSavedReview]
    );

    return useListener(reviewerRef, handleDataReceived);
}

// eslint-disable-next-line no-unused-vars
function useUpdateLastViewed(revieweeRef, cycle, iterationId) {
    const updatedAlreadyRef = useRef(false);
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const { currentIterationId } = cycle || {};
    if (!currentIterationId) return null;
    if (currentIterationId !== iterationId) return null;
    if (updatedAlreadyRef.current) return null;
    const reviewUpdate = { lastViewedOn: { [userEmployeeId]: serverTimestamp() } };
    const batch = batchSet(revieweeRef, reviewUpdate);
    batch.commit();
    updatedAlreadyRef.current = true;
}

export function useReviewListeners(employeeId, cycleId, iterationId, reviewerId) {
    const { revieweeRef, reviewerRef } = useReviewsRef(cycleId, iterationId, employeeId, reviewerId);
    const { iterationNotesRef } = useIterationNotesRefs(iterationId, employeeId);
    const [notes, setNotes] = useState([]);
    const [savedReview, setSavedReview] = useState();
    const cycle = useCycle(cycleId);

    // Update the review doc with the time you last viewed it
    //useUpdateLastViewed(revieweeRef, cycle, iterationId);

    // Setup listeners
    const notesListenerStatus = useNotesListener(iterationNotesRef, setNotes);
    const reviewListenerStatus = useReviewListener(reviewerRef, setSavedReview);

    // Check if listeners are ready
    const listenersAreReady = useMemo(() => {
        const allStatus = [notesListenerStatus, reviewListenerStatus];
        return allStatus.every((status) => status === "success");
    }, [notesListenerStatus, reviewListenerStatus]);

    return {
        revieweeRef,
        reviewerRef,
        savedReview,
        cycle,
        notes,
        listenersAreReady,
    };
}
