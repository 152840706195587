import React from 'react';
import { Each } from '../../../../../common/components';
import MyEmptyState from '../../../../../components/MyComponents/EmptyState';
import EmployeeCard from '../../../../Employees/components/EmployeeCard';
import TalentAreaLegend from '../../../../TalentAreas/TalentAreaLegend';

const DirectReportCards = ({ directReports, onViewEmployee }) => (
    <div className="p-4 bg-base-100 border rounded flex-1">
        <div className="grid grid-cols-12">
            <Each
                of={directReports}
                renderEmpty={() => (
                    <div className="col-span-12">
                        <MyEmptyState message="No direct reports" className="border-none" />
                    </div>
                )}
                render={(employee) => (
                    <div key={employee.id} className="grid-item p-4">
                        <EmployeeCard employee={employee} onView={onViewEmployee} />
                    </div>
                )}
            />
        </div>
        <div className="flex justify-start">
            <TalentAreaLegend />
        </div>
    </div>
);

export default DirectReportCards;