import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { DownloadIcon } from "../../../../../components/Icons/MyIcons";
import InfiniteTableContextMenu from "./InfiniteTableContextMenu";

const InfiniteTableHeader = ({
    tableId,
    columns,
    showCheckboxes,
    bulkActions = [],
    hideExport,
    allChecked,
    getStyle,
    onCheckAll,
    checkedIds,
    onHeaderClick,
    onExportClick,
}) => {
    const disableMenu = checkedIds.length === 0;

    const tableActions = useMemo(() => {
        let actions = [
            {
                groupId: "export",
                id: "export",
                label: "Export Checked",
                icon: DownloadIcon,
                onClick: onExportClick,
            },
            ...bulkActions,
        ];
        return actions.filter((action) => !hideExport || action.groupId !== "export");
    }, [onExportClick, bulkActions, hideExport]);

    const handleHeaderClick = (col) => {
        if (col.onHeaderClick) {
            col.onHeaderClick();
        } else {
            onHeaderClick(col.id);
        }
    };

    function renderHeader(col) {
        if (col.headerRenderer) {
            return col.headerRenderer(col);
        } else {
            switch (col.size) {
                case "sm":
                    return `${col.label.substring(0, 3)}.`;
                default:
                    return col.label;
            }
        }
    }

    return (
        <div className="flex bg-blue-100 overflow-visible">
            {showCheckboxes && (
                <div className="w-12 bg-blue-100 border border-solid middle">
                    <input
                        className="checkbox checkbox-primary checkbox-xs"
                        checked={allChecked}
                        type="checkbox"
                        onChange={onCheckAll}
                    />
                </div>
            )}
            {columns.map((col) => {
                const style = getStyle(col);
                const flexSize = col.size === "sm" ? 1 : col.size === "lg" ? 3 : 2;
                const flexClass = `flex-${flexSize}`;
                return (
                    <div
                        data-tip={col.tooltip || col.label}
                        data-for={tableId}
                        key={`${col.id}-header`}
                        className={classNames(
                            "ps-2 py-2 clickable border uppercase overflow-x-scroll text-sm tracking-tighter border-solid font-semibold whitespace-nowrap hide-scroll-bars",
                            col.className,
                            { [flexClass]: !col.width }
                        )}
                        onClick={() => handleHeaderClick(col)}
                        style={style}
                    >
                        {renderHeader(col)}
                    </div>
                );
            })}
            <div
                className={classNames("w-12 border border-solid middle")}
                data-tip="export"
                data-for={tableId}
            >
                <InfiniteTableContextMenu disabled={disableMenu} menuId="header" highlightEnabled actions={tableActions} />
            </div>
        </div>
    );
};

InfiniteTableHeader.propTypes = {
    tableId: PropTypes.string.isRequired,
    columns: PropTypes.array,
    showCheckboxes: PropTypes.bool,
    allChecked: PropTypes.bool,
    onCheckAll: PropTypes.func,
    onHeaderClick: PropTypes.func,
    getStyle: PropTypes.func,
};

InfiniteTableHeader.defaultProps = {
    showCheckboxes: false,
    columns: [],
    allChecked: false,
    getStyle: () => {},
    onCheckAll: () => {},
    onHeaderClick: () => {},
};

export default InfiniteTableHeader;
