import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import MyDropdown from "../../components/MyComponents/Dropdown";
import { MyMenuGroup, MyMenuItem } from "../../components/MyComponents/Menu";
import { useClaims } from "../User/hooks/useClaims";
import { dropWorkspace, userSignOutThunk } from "../User/utils/userThunks";

const AppSettingsDropdown = () => {
    // eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const { isDeveloper } = useClaims();
    const displayName = useSelector((state) => state.user.displayName);
    const initials = displayName
        .split(" ")
        .map((name) => name[0])
        .join("");
    const IS_LOCAL_HOST = window.location.hostname === "localhost";
    const title = IS_LOCAL_HOST ? initials : null;

    const handleSignOut = () => {
        dispatch(userSignOutThunk());
    };

    const handleDropWorkspace = () => {
        dispatch(dropWorkspace());
    };

    const handleClearConfig = () => {
        window.localStorage.clear();
        window.location.reload();
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <MyDropdown highlightEnabled={false} isOpen={isOpen} onToggle={handleToggle} toggleIcon={FaUser} title={title}>
            <MyMenuGroup vertical>
                <MyMenuItem title>{displayName}</MyMenuItem>
                <MyMenuItem hidden={!isDeveloper} onClick={handleDropWorkspace}>
                    Drop Workspace
                </MyMenuItem>
                <MyMenuItem onClick={handleClearConfig}>Clear Local Config</MyMenuItem>
                <MyMenuItem onClick={handleSignOut}>Sign Out</MyMenuItem>
            </MyMenuGroup>
        </MyDropdown>
    );
};

export default AppSettingsDropdown;
