import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import logo from "../../../../assets/img/logo/logo.png";
import MySteps from "../../../../components/MyComponents/Steps";
import { ALT_TEXT } from "../../../../constants";
import { projectId } from "../../../../firebase/firebase";
import { userSignOutThunk } from "../../../User/utils/userThunks";

const STEPS = [
    { id: "1", label: "Request" },
    { id: "2", label: "Check" },
    { id: "3", label: "Sign-In" },
];

const SignInPanel = (props) => {
    const dispatch = useDispatch();

    const handleSignOut = () => {
        if (props.onBailOut) {
            props.onBailOut();
        } else {
            dispatch(userSignOutThunk());
        }
    };

    const renderProjectId = () => {
        const hostname = window.location.hostname;
        const isStaging = hostname.includes("staging.dothings.io");
        const isLocalHost = hostname === "localhost";
        const showProjectId = isStaging || isLocalHost;
        if (showProjectId) {
            return (
                <div className="flex justify-center mt-2 text-xs text-primary">
                    <div>{projectId}</div>
                </div>
            );
        }
    };

    return (
        <div className="flex-1 flex flex-col justify-start py-6 space-y-3">
            <div className="flex flex-col justify-center items-center">
                <div className="flex-1 mb-5 text-primary">
                    <img src={logo} alt={ALT_TEXT.LOGO} width={60} />
                </div>
                <div className="flex-1">
                    <MySteps steps={STEPS} currentStep={props.currentStep} />
                </div>
            </div>
            <div>
                <p className="mt-3 text-center mb-2 text-xl font-bold text-primary">{props.title}</p>
                <div className="mb-4 flex flex-col justify-start">
                    <p className="text-center mb-0">{props.description}</p>
                    <p className={classNames("mt-3 text-center mb-0 fs-6", { hidden: !props.more })}>{props.more}</p>
                </div>
            </div>
            <div className="w-full form-section mx-auto">{props.children}</div>
            {renderProjectId()}
            {!props.hideBailOut && (
                <div className="flex justify-center mt-2 link text-sm">
                    <button label={props.bailOutText} onClick={handleSignOut}>
                        {props.bailOutText}
                    </button>
                </div>
            )}
        </div>
    );
};

SignInPanel.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    more: PropTypes.string,
    signInStatus: PropTypes.oneOf(["signed-in", "requested", "confirming", "not-requested"]),
    hideBailOut: PropTypes.bool,
    onBailOut: PropTypes.func,
    bailOutText: PropTypes.string,
};

SignInPanel.defaultProps = {
    signInStatus: "not-requested",
    bailOutText: "Sign Out",
};

export default SignInPanel;
