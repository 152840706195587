import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useRoleRelationships } from "./useRoleRelationships";

export function useFilteredRoles({
    baseRoleId,
    excludeSelf,
    excludeParent,
    excludeDirectReports,
    excludeIds,
    excludeBranch,
    onlyBranch = true,
}) {
    const roles = useSelector((state) => state.businessUnit.roles);
    const { allRoleIdsBelow, childRoleIds } = useRoleRelationships(baseRoleId);
    return useMemo(() => {
        // Filter out roles based on props
        const shouldIncludeRole = (role) => {
            // Exclude/include the base role itself
            const isBaseRole = role.id === baseRoleId;
            if (isBaseRole) return !excludeSelf;

            // Exclude if the role is the parent of the base role
            if (excludeParent) {
                const parentRoleId = roles[baseRoleId]?.parentRoleId;
                if (role.id === parentRoleId) {
                    return false;
                }
            }
            // Exclude if the role is in the excludeIds list
            if (excludeIds && excludeIds.includes(role.id)) {
                return false;
            }

            // Exclude if the role is a direct report of the base role
            if (excludeDirectReports && childRoleIds.includes(role.id)) {
                return false;
            }

            // Exclude if the role is in the branch of the base role
            if (excludeBranch && allRoleIdsBelow.includes(role.id)) {
                return false;
            }

            if (!excludeBranch && onlyBranch) {
                // include if no other conditions met
                return allRoleIdsBelow.includes(role.id);
            } else {
                return true;
            }
        };

        // Filter out roles based on the helper function
        return Object.values(roles).filter(shouldIncludeRole);
    }, [
        roles,
        baseRoleId,
        excludeSelf,
        excludeParent,
        excludeDirectReports,
        excludeBranch,
        onlyBranch,
        excludeIds,
        childRoleIds,
        allRoleIdsBelow,
    ]);
}
