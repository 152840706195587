import { doc, writeBatch } from "firebase/firestore";
import { updateAuthProfile } from "../../../firebase/auth";
import { db } from "../../../firebase/firebase";

const writeUser = (uid, update, batch = writeBatch(db)) => {
    // If the name has changed, update the authUser to match
    if (update.firstname || update.surname) {
        updateAuthProfile({
            firstname: update.firstname,
            surname: update.surname,
        });
    }
    const userDocRef = doc(db, "users", uid);
    batch.set(userDocRef, update, { merge: true });
    return batch;
};

export { writeUser };
