import classNames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button } from "../../common/components";
import Checkbox from "../../common/components/atoms/Checkbox/Checkbox";
import { RichText } from "../../components";
import { CheckIcon, DeleteIcon, PlusIcon, ReopenIcon } from "../../components/Icons/MyIcons";
import { MyCollapse, MyCollapseContent, MyCollapseTitle } from "../../components/MyComponents/Collapse";

const ActionFooter = ({
    mode,
    done,
    disabled,
    actionsAreComplete,
    actionId,
    status,
    checked,
    onToggleChecked,
    onComplete,
    onSchedule,
    onDelete,
}) => {
    const handleDeleteAction = () => {
        if (disabled) return;
        onDelete(actionId);
    };

    const handleCompleteAction = () => {
        if (disabled) return;
        onComplete(actionId);
    };

    const handleScheduledAction = () => {
        if (disabled) return;
        onSchedule(actionId);
    };

    const handleToggleChecked = () => {
        if (disabled) return;
        onToggleChecked();
    };

    const renderPlanAction = () => {
        switch (status) {
            case "completed":
            case "in_progress":
                return (
                    <span className="text-success tracking-tight text-sm">
                        {status === "completed" ? "Done" : "In Progress"}
                    </span>
                );
            default:
                return <Checkbox label="Add To Plan" checked={checked} onChange={handleToggleChecked} size="sm" />;
        }
    };

    switch (mode) {
        case "view":
            return (
                <div className="mt-3 border-t p-3 pb-0 flex space-x-4 justify-end">
                    <Button tooltip="Cancel" shape="circle" size="xs" onClick={handleDeleteAction}>
                        <DeleteIcon />
                    </Button>
                    <Button
                        hidden={!actionsAreComplete}
                        tooltip="Reopen"
                        shape="circle"
                        size="xs"
                        onClick={handleScheduledAction}
                    >
                        <ReopenIcon />
                    </Button>
                    <Button
                        hidden={actionsAreComplete}
                        tooltip="Complete"
                        color="primary"
                        shape="circle"
                        size="xs"
                        onClick={handleCompleteAction}
                    >
                        <CheckIcon />
                    </Button>
                </div>
            );
        case "browse":
            return (
                <div className="mt-3 border-t p-3 pb-0 flex justify-end">
                    <Button
                        tooltip={done ? "Repeat Action" : "Schedule Action"}
                        shape="circle"
                        size="xs"
                        onClick={handleScheduledAction}
                    >
                        {done ? <ReopenIcon /> : <PlusIcon size={18} />}
                    </Button>
                </div>
            );
        default:
            return <div className="mt-3 border-t p-3 pb-0 flex justify-end">{renderPlanAction()}</div>;
    }
};

export const ScheduledActionItem = ({
    mode,
    done,
    actionId,
    disabled,
    status,
    actionsAreComplete,
    fetchAction,
    actionName,
    checked,
    onToggleChecked,
    onComplete,
    onDelete,
    onReopen,
    onSchedule,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [actionDoc, setActionDoc] = useState();

    const handleExpandAction = async () => {
        const newOpen = !isOpen;
        if (newOpen) {
            const action = await fetchAction(actionId);
            if (action) {
                setActionDoc(action);
            } else {
                setActionDoc({});
            }
        }
        setIsOpen(newOpen);
    };

    const handleToggleChecked = () => {
        onToggleChecked(actionId);
        setIsOpen(false);
    };

    return (
        <MyCollapse
            open={isOpen}
            onToggle={handleExpandAction}
            checked={checked}
            type="checkbox"
            disabled={disabled}
            className={classNames("bg-base-100 border border-base-500", { "border rounded-sm py-2": isOpen })}
        >
            <MyCollapseTitle className="py-2 px-6 rounded-box">
                <h6 className={classNames("font-bold")}>
                    {actionName}
                    <span className={classNames("ms-2", { "hidden text-base-500": !checked, "text-primary": checked })}>
                        (included)
                    </span>
                </h6>
            </MyCollapseTitle>
            <MyCollapseContent className="p-0 px-3">
                <RichText content={actionDoc?.description || ""} readOnly minHeight={36} />
                <ActionFooter
                    mode={mode}
                    done={done}
                    disabled={disabled}
                    status={status}
                    actionsAreComplete={actionsAreComplete}
                    actionId={actionId}
                    checked={checked}
                    onToggleChecked={handleToggleChecked}
                    onComplete={onComplete}
                    onDelete={onDelete}
                    onReopen={onReopen}
                    onSchedule={onSchedule}
                />
            </MyCollapseContent>
        </MyCollapse>
    );
};

ScheduledActionItem.propTypes = {
    mode: PropTypes.oneOf(["edit", "view", "browse"]),
    done: PropTypes.bool,
    actionId: PropTypes.string.isRequired,
    actionsAreComplete: PropTypes.bool,
    disabled: PropTypes.bool,
    fetchAction: PropTypes.func.isRequired,
    actionName: PropTypes.string.isRequired,
    checked: PropTypes.bool,
};

ScheduledActionItem.defaultProps = {
    disabled: false,
    checked: false,
    onToggleChecked: () => {},
    onComplete: () => {},
    onDelete: () => {},
    onReopen: () => {},
    onSchedule: () => {},
};

export default ScheduledActionItem;
