import { useEffect, useState } from "react";

export function usePreserveOrder(items) {
    const [ordered, setOrdered] = useState([]);
    useEffect(() => {
        setOrdered((prevItems) => {
            const currentIds = items.map((item) => item.id);
            const newItems = items.filter((item) => !prevItems.some((prevItem) => prevItem.id === item.id));
            const updatedItems = prevItems.filter((prevItem) => currentIds.includes(prevItem.id));
            return [...updatedItems, ...newItems];
        });
    }, [items]);

    return ordered;
}
