import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CheckIcon, CrossIcon, EditIcon } from "../Icons/MyIcons";
import MyButton from "../MyComponents/Button";
import MyTextInput from "../MyComponents/TextInput";
import RichText from "../RichText/RichTextContainer";

const TextFieldEditor = ({
    value,
    placeholder,
    fieldId,
    maxLength,
    required,
    fieldType,
    className,
    textClassName,
    hideFocusBorder,
    lockedClassNames,
    editingClassNames,
    cannotMatch = [],
    errorMessage,
    onChange,
}) => {
    const [hovered, setHovered] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value);
    const [valueIsInUse, setValueIsInUse] = useState(false);
    const ICON_SIZE = 22;
    const isRichText = fieldType === "richText";

    useEffect(() => {
        setEditedValue(value);
    }, [value]);

    const handleRichTextChange = (update) => {
        setEditedValue(update);
    };

    const handleTextAreaChange = (_, update) => {
        setEditedValue(update);
    };

    const handleSave = () => {
        const matches = cannotMatch.some((item) => item.toLowerCase() === editedValue.toLowerCase());
        if (matches) {
            setValueIsInUse(true);
        } else {
            onChange(fieldId, editedValue);
            setEditing(false);
        }
    };

    const handleEditButtonClick = () => {
        setEditing(true);
    };

    const handleDiscard = () => {
        setEditedValue(value);
        setEditing(false);
    };

    const renderField = () => {
        switch (fieldType) {
            case "textArea":
            case "text":
                return (
                    <MyTextInput
                        className={classNames("cursor-pointer inline-block w-full border-0", textClassName, {
                            [editingClassNames]: editing,
                            [lockedClassNames]: !editing,
                        })}
                        hideFocusBorder={hideFocusBorder}
                        type={fieldType}
                        name="textFieldEditor"
                        maxLength={maxLength}
                        required={required}
                        placeholder={placeholder}
                        readOnly={!editing}
                        onChange={handleTextAreaChange}
                        value={editedValue}
                        autoComplete="off"
                    />
                );
            case "richText":
                if (!value && !editing) {
                    return <div className="p-4 font-italic text-neutral">{placeholder}</div>;
                } else {
                    return (
                        <RichText
                            className={classNames("flex-1 cursor-pointer rounded-lg", textClassName, {
                                [editingClassNames]: editing,
                                [lockedClassNames]: !editing,
                            })}
                            content={editedValue}
                            readOnly={!editing}
                            onChange={handleRichTextChange}
                        />
                    );
                }
            default:
                return null;
        }
    };

    return (
        <div
            className={classNames(
                "cursor-pointer relative inline-block",
                {
                    border: !hideFocusBorder,
                    "border-transparent": (isRichText && (!hovered || editing)) || (!hovered && !editing),
                },
                className
            )}
            onMouseOut={() => setHovered(false)}
            onMouseOver={() => setHovered(true)}
        >
            {hovered && (
                <div className="absolute top-2 right-2">
                    <MyButton
                        extraClasses={classNames({ hidden: editing })}
                        size="sm"
                        shape="circle"
                        tooltip="Edit"
                        label={"edit"}
                        onClick={handleEditButtonClick}
                    >
                        <EditIcon size={ICON_SIZE} />
                    </MyButton>
                </div>
            )}
            {editing && (
                <div className="absolute top-2 right-2">
                    <MyButton
                        extraClasses={classNames({ hidden: !editing })}
                        size="sm"
                        shape="circle"
                        tooltip="Discard Changes"
                        label="cancel"
                        onClick={handleDiscard}
                    >
                        <CrossIcon size={ICON_SIZE} />
                    </MyButton>
                    <MyButton
                        extraClasses={classNames({ hidden: !editing || editedValue === value })}
                        size="sm"
                        shape="circle"
                        tooltip="Save Changes"
                        label="save"
                        onClick={handleSave}
                    >
                        <CheckIcon size={ICON_SIZE} />
                    </MyButton>
                </div>
            )}
            <div className="flex flex-col h-full">{renderField()}</div>
            {valueIsInUse && <p className="text-danger">{errorMessage}</p>}
        </div>
    );
};

TextFieldEditor.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fieldId: PropTypes.string.isRequired,
    className: PropTypes.string,
    lockedClassNames: PropTypes.string,
    editingClassNames: PropTypes.string,
    textClassName: PropTypes.string,
    cannotMatch: PropTypes.array,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    fieldType: PropTypes.oneOf(["text", "textArea", "richText"]),
    onSave: PropTypes.func,
    errorMessage: PropTypes.string,
};

TextFieldEditor.defaultProps = {
    value: "",
    cannotMatch: [],
    maxLength: 500,
    fieldType: "text",
    errorMessage: "Value is already in use",
    onSave: () => {},
};

export default TextFieldEditor;
