import { useOrgNodeContext } from "../../../../../../pages/organisation/outlets/OrgNode/OrgNodePage";
import { useReviewEditorContext } from "../../ReviewEditor";
import TalentAssessmentStepTemplate from "../../templates/TalentAssessmentStepTemplate";

const ManagerAssessmentStep = () => {
    const { values, actions } = useReviewEditorContext();
    const { currentScheduledActionIds, completedActionIds } = useOrgNodeContext();
    const enhancedValues = { ...values, currentScheduledActionIds, completedActionIds };
    return <TalentAssessmentStepTemplate values={enhancedValues} actions={actions} />;
};

export default ManagerAssessmentStep;
