import React from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import { useSelector } from "react-redux";
import { confirmFirst } from "../../../../../common/utils/confirmFirst";
import { DeleteIcon, DownloadIcon, EditIcon, PlusIcon } from "../../../../../components/Icons/MyIcons";
import MyButton from "../../../../../components/MyComponents/Button";
import { useOrgContext } from "../../../../../pages/organisation/OrgPage";
import { useTalentMapActions } from "../../../hooks/useTalentMapActions";
import { useTalentMapConfig } from "../../../hooks/useTalentMapConfig";
import TalentMapPicker from "../../atoms/TalentMapPicker";

const iconSize = 16;

const TalentMapToolbar = ({ talentMapRef, setEditMode, hideControls }) => {
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const { setConfigValue } = useOrgContext();
    const { removeTalentMap } = useTalentMapActions();
    const { talentMapConfig } = useTalentMapConfig();
    const { readOnly, shared } = talentMapConfig || {};
    const disableEdits = !talentMapConfig || readOnly || (shared && !isAdmin);
    const activeTalentMapId = talentMapConfig?.id;

    const handleChangeActiveMap = (id) => {
        setConfigValue("activeTalentMapId", id);
    };

    const handleEditTalentMap = () => {
        setEditMode("edit");
    };

    const handleCopyTalentMap = () => {
        setEditMode("copy");
    };

    const handleDeleteTalentMap = () => {
        function onConfirm() {
            removeTalentMap(activeTalentMapId, shared);
        }
        const { shared } = talentMapConfig;
        const sharedTitle = "Delete Shared Talent Map";
        const sharedMessage = "Are you sure? It will be deleted for all users.";
        const privateTitle = "Delete Talent Map";
        const privateMessage = "Are you sure you want to delete this Talent Map?";
        confirmFirst({
            title: shared ? sharedTitle : privateTitle,
            message: shared ? sharedMessage : privateMessage,
            onConfirm,
        });
    };

    const handleExportTalentMap = () => {
        exportComponentAsPNG(talentMapRef);
    };

    return (
        <div className="flex flex-1 items-center justify-between space-x-2">
            {hideControls && <h3>Talent Map</h3>}
            <div className="flex flex-1 space-x-2 items-center justify-end">
                <TalentMapPicker
                    selectedId={activeTalentMapId}
                    className="w-80"
                    onChange={handleChangeActiveMap}
                />
                {!hideControls && (
                    <div className="flex space-x-2 items-center">
                        <MyButton
                            shape="circle"
                            size="sm"
                            tooltip="Delete Talent Map"
                            color="error"
                            onClick={handleDeleteTalentMap}
                            disabled={disableEdits}
                        >
                            <DeleteIcon size={iconSize} />
                        </MyButton>
                        <MyButton
                            shape="circle"
                            size="sm"
                            tooltip="Edit Talent Map"
                            onClick={handleEditTalentMap}
                            color="secondary"
                            disabled={disableEdits}
                        >
                            <EditIcon size={iconSize} />
                        </MyButton>
                        <MyButton
                            color="secondary"
                            shape="circle"
                            size="sm"
                            tooltip="Create Talent Map"
                            onClick={handleCopyTalentMap}
                        >
                            <PlusIcon size={iconSize} />
                        </MyButton>
                        <MyButton
                            color="secondary"
                            shape="circle"
                            size="sm"
                            tooltip="Export Talent Map"
                            onClick={handleExportTalentMap}
                        >
                            <DownloadIcon size={iconSize} />
                        </MyButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TalentMapToolbar;
