import * as d3 from "d3";
import React, { useEffect, useMemo, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { isChartReady } from "../../../common/utils/chartUtils";
import { getUniqueId } from "../../../helpers/helpers";

const RadialBarChart = ({ data, width, height, disableTooltip }) => {
    const ref = useRef();
    const tooltipId = useMemo(() => getUniqueId(), []);

    useEffect(() => {
        const chartIsReady = isChartReady(data, height, width);
        if (chartIsReady) {
            const innerRadius = 30;
            const ringWidth = 18;

            d3.select(ref.current).selectAll("*").remove();
            const svg = d3
                .select(ref.current)
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform", `translate(${width / 2},${height / 2})`);

            data.forEach((d, i) => {
                const total = d.data.reduce((sum, dd) => sum + dd.y, 0);

                let cumulative = 0;
                d.data.forEach((dd) => {
                    const arc = d3
                        .arc()
                        .innerRadius(innerRadius + i * ringWidth)
                        .outerRadius(innerRadius + (i + 1) * ringWidth)
                        .startAngle((cumulative / total) * 0.75 * 2 * Math.PI)
                        .endAngle(((cumulative + dd.y) / total) * 0.75 * 2 * Math.PI);

                    svg.append("path")
                        .attr("d", arc)
                        .attr("fill", dd.color)
                        .attr("stroke", "#fff") // Add white border
                        .attr("stroke-width", 2) // Set border width
                        .attr("data-tip", `${dd.x} (${dd.y}%)`)
                        .attr("data-for", tooltipId)
                        .style("outline", "none") // Disable focus outline
                        .on("focus", function () {
                            d3.select(this).style("pointer-events", "none");
                        })
                        .on("blur", function () {
                            d3.select(this).style("pointer-events", "auto");
                        });

                    cumulative += dd.y;
                });

                const labelXPosition = -5; // Adjust to move the label slightly to the left
                const labelYPosition = -(innerRadius + i * ringWidth + ringWidth / 2);

                svg.append("text")
                    .attr("x", labelXPosition)
                    .attr("y", labelYPosition)
                    .attr("text-anchor", "end") // Right-align text
                    .attr("alignment-baseline", "middle")
                    .text(d.id)
                    .style("font-size", "12px")
                    .style("font-weight", "600")
                    .style("fill", "#000")
                    .style("pointer-events", "none"); // Disable pointer events
            });

            ReactTooltip.rebuild();
        }
    }, [data, width, height, tooltipId]);

    return (
        <>
            <svg ref={ref}></svg>
            {!disableTooltip && <ReactTooltip id={tooltipId} delayShow={300} />}
        </>
    );
};

export default RadialBarChart;
