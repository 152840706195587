import React, { useEffect, useState } from "react";
import { Button } from "../../../common/components";
import LikertInput from "../../../common/components/organisms/LikertInput/LikertInput";
import { RichText } from "../../../components";
import { LeftChevronIcon, RightChevronIcon } from "../../../components/Icons/MyIcons";
import { RATING_OPTIONS } from "../../../constants";
import TalentAreaLegend from "../../TalentAreas/TalentAreaLegend";

const PrevNextButtons = ({ selectedTraitId, includedTraits, selectTrait }) => {
    const indexOfSelectedTrait = includedTraits.findIndex((trait) => trait.id === selectedTraitId);
    const handlePrev = () => {
        if (indexOfSelectedTrait === 0) {
            const nextTrait = includedTraits[includedTraits.length - 1];
            selectTrait(nextTrait.id);
        } else {
            const nextTrait = includedTraits[indexOfSelectedTrait - 1];
            selectTrait(nextTrait.id);
        }
    };

    const handleNext = () => {
        if (indexOfSelectedTrait === includedTraits.length - 1) {
            const nextTrait = includedTraits[0];
            selectTrait(nextTrait.id);
        } else {
            const nextTrait = includedTraits[indexOfSelectedTrait + 1];
            selectTrait(nextTrait.id);
        }
    };
    const iconSize = 14;
    return (
        <div className="flex items-center space-x-6">
            <Button size="xs" color="ghost" shape="circle" onClick={handlePrev}>
                <LeftChevronIcon size={iconSize} />
            </Button>
            <Button size="xs" color="ghost" shape="circle" onClick={handleNext}>
                <RightChevronIcon size={iconSize} />
            </Button>
        </div>
    );
};

const RatingCard = ({
    onChangeRating,
    ratings,
    selectedTraitId,
    selectedTrait,
    includedTraits,
    fetchTrait,
    selectTrait,
}) => {
    const [fullTrait, setFullTrait] = useState(null);
    const traitRating = ratings?.[selectedTraitId];

    useEffect(() => {
        async function fetchData() {
            const trait = await fetchTrait(selectedTraitId);
            setFullTrait(trait || null);
        }
        if (selectedTraitId) {
            fetchData();
        } else {
            setFullTrait(null);
        }
    }, [selectedTraitId, fetchTrait]);

    if (!selectedTraitId) return null;

    return (
        <div className="w-full flex-1 h-full clickable flex flex-col bg-base-100 p-6 divide-y">
            <div className="flex flex-col items-center space-y-3 mb-6">
                <h3>{fullTrait?.traitName}</h3>
                <PrevNextButtons
                    selectedTraitId={selectedTraitId}
                    includedTraits={includedTraits}
                    selectTrait={selectTrait}
                />
                <p className="text-xs">How often do you observe evidence of this trait?</p>
                <LikertInput onChange={onChangeRating} value={traitRating} options={RATING_OPTIONS} />
            </div>
            <div className="flex-1 space-y-8 py-4 overflow-scroll">
                <div className="space-y-1">
                    <h6 className="font-bold">Trait Description</h6>
                    <p className="text-sm">{fullTrait?.description || "No description provided"}</p>
                </div>
                <div className="space-y-1">
                    <h6 className="font-bold">Rating Guidance</h6>
                    <div>
                        <RichText removePadding readOnly content={fullTrait?.guidance || "No guidance provided"} />
                    </div>
                </div>
            </div>
            <div className="flex justify-center pt-5">
                <TalentAreaLegend selectedAreaId={selectedTrait?.linkedTalentAreaId} highlightSelected />
            </div>
        </div>
    );
};

export default RatingCard;
