import { useSelector } from "react-redux";

export function useTopLevelRoleId() {
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const claims = useSelector((state) => state.user.claims);
    const { topLevelAccess, developer, roleId } = claims;
    if (developer || topLevelAccess) {
        return rootRoleId;
    } else if (roleId) {
        return roleId;
    } else {
        return null;
    }
}
