import React, { useMemo } from "react";
import { getBlockerGroups } from "../../utils/blockerUtils";
import { flattenCascadeTree, getCascadeTree } from "../../utils/cascadeUtils";
import CascadeCard from "../organisms/CascadeCard/CascadeCard";
import BlockersBarChart from "../../containers/charts/BlockersBarChart";
import CascadesRadarChart from "../../containers/charts/CascadesRadarChart";

const chartHeight = 240;

const CascadeTemplate = ({
    objective,
    employeeSnapshots,
    getEmployee,
    getRole,
    getRoleOrgLevel,
    getDirectReportIds,
}) => {
    //const [filteredEmployeeIds, setFilteredEmployeeIds] = useState([]);
    //const [selectedBlockerId, setSelectedBlockerId] = useState(null);
    const cascadeTree = useMemo(() => {
        return getCascadeTree(objective, employeeSnapshots, getEmployee, getRoleOrgLevel);
    }, [objective, employeeSnapshots, getEmployee, getRoleOrgLevel]);
    const allCascades = flattenCascadeTree(cascadeTree);
    const blockerGroups = getBlockerGroups(cascadeTree?.cascades || []);

    /*
    const handleSelectBlocker = (blocker) => {
        const { id, group } = blocker;
        const deselecting = id === selectedBlockerId;
        if (deselecting) {
            setSelectedBlockerId(null);
            setFilteredEmployeeIds(false);
        } else {
            setSelectedBlockerId(id);
            setFilteredEmployeeIds(group || false);
        }
    };
    */

    return (
        <div className="flex-1 flex-col space-y-4 py-4 px-8">
            <div className="flex space-x-6 items-stretch">
                <div className="flex-1 space-y-2">
                    <h5 className="font-medium capitalize">Confidence</h5>
                    <div className="border rounded-lg">
                        <CascadesRadarChart allCascades={allCascades} height={chartHeight} />
                    </div>
                </div>
                <div className="flex-2 flex flex-col space-y-2">
                    <h5 className="font-medium capitalize">Blockers</h5>
                    <div className="flex flex-1 border rounded-lg">
                        <BlockersBarChart liveBlockers={blockerGroups} showXLabels />
                    </div>
                </div>
            </div>
            <div className="space-y-2">
                <h5 className="font-medium capitalize">Contributor Confidence</h5>
                <CascadeCard
                    entry={cascadeTree}
                    allCascades={allCascades}
                    getEmployee={getEmployee}
                    getRole={getRole}
                    getDirectReportIds={getDirectReportIds}
                />
            </div>
        </div>
    );
};

export default CascadeTemplate;
