import React from "react";
import { useSelector } from "react-redux";
import { PageSection } from "../../../common/components";
import { LoadingIndicator } from "../../../components";
import MyEmptyState from "../../../components/MyComponents/EmptyState";
import TalentAreaLegend from "../../../features/TalentAreas/TalentAreaLegend";
import FlagsDisplay from "../../CustomFields/components/organisms/FlagsDisplay";
import RatingChangeChart from "../../Cycles/containers/charts/RatingChangeChart";
import RadarCharts from "../../Employees/components/RadarCharts";
import { useEmployees } from "../../Employees/hooks/useEmployees";
import { makeRatingsArray } from "../../Snapshots/utils/snapshotHelpers";
import TalentStrength from "../../Traits/components/organisms/TalentStrength";

const ViewTalentAssessment = ({ activeEmployee, snapshot, thisAssessment, compareAssessment }) => {
    const employeeId = activeEmployee.id;
    const pendingSnapshots = useSelector((state) => state.snapshots.pendingSnapshots);
    const snapshotIsUpdating = pendingSnapshots.includes(employeeId);
    const talentAreas = makeRatingsArray(snapshot?.talentAreas || {});
    const ratings = makeRatingsArray(snapshot?.ratings || {});
    const { getEmployee } = useEmployees();
    const employee = getEmployee(employeeId);
    const { linkedTalentBoardIds } = employee || {};
    const ratingsArray = makeRatingsArray(thisAssessment?.ratings);
    const comparisonArray = makeRatingsArray(compareAssessment?.ratings);
    const showComparison = Object.keys(compareAssessment?.ratings || {}).length > 0;

    if (snapshotIsUpdating) {
        return <LoadingIndicator fullscreen message="Employee is Updating" />;
    }

    if (Object.keys(thisAssessment || {}).length === 0) {
        return <MyEmptyState message="No Assessment" className="flex-1" />;
    }

    return (
        <div className="flex-1 flex flex-col space-y-8">
            <div>
                <FlagsDisplay employeeId={employeeId} snapshot={snapshot} />
            </div>
            <PageSection
                hidden={!linkedTalentBoardIds?.length}
                title="Talent Board Ratings"
                help="Shows how the employee has been rated against all assigned Talent Boards."
            >
                <div className="flex-1 flex space-x-4">
                    <div className="bg-base-100 flex-3 flex flex-col justify-between rounded-lg border shadow overflow-hidden">
                        <div className="pt-6 px-4">
                            <RadarCharts
                                employeeId={employeeId}
                                snap={snapshot}
                                employeeTalentBoardIds={linkedTalentBoardIds}
                            />
                        </div>
                        <div className="p-3 bg-base-150 border-t">
                            <TalentAreaLegend />
                        </div>
                    </div>
                </div>
            </PageSection>
            <PageSection title="Manager Assessment Vs Self-Assessment" hidden={!showComparison}>
                <div style={{ height: 380 }} className="flex bg-white border rounded">
                    <RatingChangeChart
                        prevData={comparisonArray}
                        newData={ratingsArray}
                        prevLabel="Self"
                        newLabel="Manager"
                        diffLabel="Difference"
                    />
                </div>
            </PageSection>
            <PageSection title="Trait Ratings" help="Shows the ratings assigned to each relevant trait.">
                <div className="flex-2 flex flex-col">
                    <TalentStrength
                        horizontal
                        wordCloudId="employeeTab"
                        className="bg-base-100 rounded-lg overflow-hidden"
                        talentAreaRatings={talentAreas}
                        ratings={ratings}
                    />
                </div>
            </PageSection>
        </div>
    );
};

export default ViewTalentAssessment;
