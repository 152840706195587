import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";

// Save all unsaved changes if no specific change is provided
function writeOwnConfig(uid, workspaceId, update, batch = writeBatch(db)) {
    const userConfigRef = doc(db, "users", uid, "config", workspaceId);
    batch.set(userConfigRef, update, { merge: true });
    return batch;
}

const writeEveryoneConfig = (workspaceId, update, batch = writeBatch(db)) => {
    const configRef = doc(db, "workspaces", workspaceId, "config", "workspaceConfig");
    batch.set(configRef, update, { merge: true });
    return batch;
};

export { writeOwnConfig, writeEveryoneConfig };
