// Migrate to this eventually: only every receive refs, not the workspaceId

import { writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getDocRef, getSubCollectionRef } from "../../../firebase/firebaseActions";
import { removeUndefined } from "../../../helpers/helpers";

export const archiveSnapshot = (employeesRef, employeeId, snapshot, batch = writeBatch(db)) => {
    if (employeesRef && employeeId && snapshot) {
        const { flags, ratings, objectives, experience } = snapshot;
        const preppedSnapshot = {
            flags,
            ratings,
            obj: objectives,
            exp: experience,
        };
        removeUndefined(preppedSnapshot);
        const employeeRef = getDocRef(employeesRef, employeeId);
        const snapshotsRef = getSubCollectionRef(employeeRef, "archived");
        const snapshotRef = getDocRef(snapshotsRef, "snapshot");
        batch.set(snapshotRef, preppedSnapshot);
        return batch;
    }
};
