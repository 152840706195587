import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const MySwitch = (props) => {
    const { disabled, checkFirst, onLabel, offLabel, checked, neutralColor, onChange } = props;
    const [isChecked, setIsChecked] = useState();
    const label = isChecked ? onLabel : offLabel;

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleToggle = () => {
        const controlled = Object.keys(props).includes("checked");
        if (disabled) return;
        if (!controlled) {
            setIsChecked(!isChecked);
        }
        onChange(!checked);
    };

    return (
        <div className="form-control">
            <label className={classNames("label cursor-pointer space-x-2 flex", { "flex-row-reverse": !checkFirst })}>
                <input
                    type="checkbox"
                    className={classNames("toggle", {
                        "toggle-success": checked && !neutralColor,
                        "ms-2": !checkFirst,
                    })}
                    checked={checked}
                    onChange={handleToggle}
                />
                {label && <div className={classNames("label-text min-w-fit")}>{label}</div>}
            </label>
        </div>
    );

    /*
    return (
        <button
            className={classNames(
                "border-base-100 border-2 rounded-xl py-2 px-3 flex items-stretch bg-opacity-80",
                `${prefix}-${color}`,
                { "bg-base-100": colorLabels },
                className
            )}
            disabled={disabled}
            onClick={handleToggle}
        >
            <div
                className={classNames(
                    { "order-2 ms-2": isChecked, "me-2": !isChecked },
                    "h-6 w-6 border-base-100 bg-base-150 shadow-sm border-2 rounded-full aspect-w-1 aspect-h-1 flex items-center justify-between"
                )}
            />
            <div className="flex-1 font-bold uppercase">{label}</div>
        </button>
    );
    */
};

MySwitch.propTypes = {
    onColor: PropTypes.string,
    offColor: PropTypes.string,
    onLabel: PropTypes.string,
    offLabel: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

MySwitch.defaultProps = {
    onColor: "success",
    offColor: "error",
    onLabel: "on",
    offLabel: "off",
    checked: false,
    onChange: () => {},
};

export default MySwitch;
