const ALL_EMPLOYEES_TABLE = {
    tableName: "All Employees",
    columns: ["isUser", "employeeNumber", "displayName", "email", "jobTitle"],
    filters: [],
    readOnly: true,
    shared: true,
};

const ALL_USERS_TABLE = {
    tableName: "All Users",
    columns: ["isUser", "employeeNumber", "displayName", "email", "jobTitle"],
    filters: [{ fieldType: "binary", fieldId: "isUser", matchAny: ["true"] }],
    readOnly: true,
    shared: true,
};

const ACTIVE_EMPLOYEES_TABLE = {
    tableName: "Active Employees",
    columns: ["isUser", "employeeNumber", "displayName", "email", "jobTitle"],
    filters: [{ fieldType: "binary", fieldId: "roleStatus", matchAny: ["true"] }],
    readOnly: true,
    shared: true,
};

const INACTIVE_EMPLOYEES_TABLE = {
    tableName: "Inactive Employees",
    columns: ["isUser", "employeeNumber", "displayName", "email", "jobTitle"],
    filters: [{ fieldType: "binary", fieldId: "roleStatus", matchAny: [false] }],
    readOnly: true,
    shared: true,
};

const NINE_BOX_TEMPLATE = [
    { color: "#FB2F02", title: "Under Performer", col: 1, row: 1 },
    { color: "#FD9800", title: "Dilemma", col: 1, row: 2 },
    { color: "#FFD83A", title: "Enigma", col: 1, row: 3 },
    { color: "#FD9800", title: "Effective Specialist", col: 2, row: 1 },
    { color: "#FFD83A", title: "Core Employee", col: 2, row: 2 },
    { color: "#8CD000", title: "Growth Employee", col: 2, row: 3 },
    { color: "#FFD83A", title: "Trusted Professional", col: 3, row: 1 },
    { color: "#8CD000", title: "High Impact Performer", col: 3, row: 2 },
    { color: "#3AC048", title: "Future Leader", col: 3, row: 3 },
];

const FOUR_BOX_TEMPLATE = [
    { color: "#FB2F02", title: "Under Performer", col: 1, row: 1 },
    { color: "#FD9800", title: "Enigma", col: 1, row: 2 },
    { color: "#FFD83A", title: "Trust Professional", col: 2, row: 1 },
    { color: "#3AC048", title: "Future Leader", col: 2, row: 2 },
];

const DEFAULT_TABLE_ID = "allEmp";

export {
    ALL_EMPLOYEES_TABLE,
    ALL_USERS_TABLE,
    ACTIVE_EMPLOYEES_TABLE,
    INACTIVE_EMPLOYEES_TABLE,
    NINE_BOX_TEMPLATE,
    FOUR_BOX_TEMPLATE,
    DEFAULT_TABLE_ID,
};
