import { useSelector } from "react-redux";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";
import { useMemo } from "react";
import { collection, doc } from "firebase/firestore";

export function useBusinessUnitRefs() {
    const businessUnitId = useSelector((state) => state.user.activeBusinessUnitId);
    const workspaceRef = useWorkspaceRef();

    const businessUnitsRef = useMemo(() => {
        if (!workspaceRef) return null;
        return collection(workspaceRef, "businessUnits");
    }, [workspaceRef]);

    const businessUnitRef = useMemo(() => {
        if (!businessUnitsRef || !businessUnitId) return null;
        return doc(businessUnitsRef, businessUnitId);
    }, [businessUnitsRef, businessUnitId]);

    const employeeInfoRef = useMemo(() => {
        if (!businessUnitRef) return null;
        return collection(businessUnitRef, "employeeInfo");
    }, [businessUnitRef]);

    const roleInfoRef = useMemo(() => {
        if (!businessUnitRef) return null;
        return collection(businessUnitRef, "roleInfo");
    }, [businessUnitRef]);

    const boardMapRef = useMemo(() => {
        if (!businessUnitRef) return null;
        return doc(businessUnitRef, "maps", "boardMap");
    }, [businessUnitRef]);

    const parentMapRef = useMemo(() => {
        if (!businessUnitRef) return null;
        return doc(businessUnitRef, "maps", "parentMap");
    }, [businessUnitRef]);

    return {
        businessUnitsRef,
        businessUnitRef,
        employeeInfoRef,
        roleInfoRef,
        boardMapRef,
        parentMapRef,
    };
}
