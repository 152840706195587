import classNames from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SIDEBAR_WIDTH } from "../../constants";
import { projectId, useEmulators } from "../../firebase/firebase";
import { IS_DEV } from "../../helpers/helpers";
import NotificationsDropdown from "../Notifications/NotificationsDropdown";
import AppSettingsDropdown from "./AppSettingsDropdown";
import Breadcrumbs from "./Breadcrumbs";
import GlobalSearchField from "./GlobalSearchField";

const AppNavBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentPageIsAnAssessment = location.pathname.includes("talent-assessment");
    const hideNotifications = currentPageIsAnAssessment;

    function getColor() {
        const isDanger = IS_DEV && !useEmulators;
        if (!isDanger) return null;
        return projectId === "dothings-staging-app" ? "bg-secondary" : "bg-error";
    }

    const handleSelectEmployee = (empId) => {
        navigate(`/organisation/chart?employeeId=${empId}`);
    };

    return (
        <div style={{ paddingLeft: SIDEBAR_WIDTH + 20 }} className={classNames("navbar border-b pe-8", getColor())}>
            <div className="flex-1 flex">
                <Breadcrumbs />
            </div>
            <div className="flex justify-end min-w-fit space-x-6 items-center">
                <GlobalSearchField placeholder="Search Employees" onSelect={handleSelectEmployee} />
                {!hideNotifications && <NotificationsDropdown />}
                <AppSettingsDropdown />
            </div>
        </div>
    );
};

export default AppNavBar;
