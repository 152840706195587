import { cloneDeep } from "lodash";

export function removeAllLinksToActions(originalTraitMap, actionIdsToRemove = []) {
    const traitMapUpdate = cloneDeep(originalTraitMap);
    actionIdsToRemove = new Set(actionIdsToRemove);
    for (const traitId in traitMapUpdate) {
        if (traitMapUpdate.hasOwnProperty(traitId)) {
            traitMapUpdate[traitId].linkedActionIds = traitMapUpdate[traitId].linkedActionIds.filter(
                (actionId) => !actionIdsToRemove.has(actionId)
            );
        }
    }
    return traitMapUpdate;
}

export function removeTraitLinks(originalTraitMap, affectedTraits, actionId) {
    let traitMapUpdate = cloneDeep(originalTraitMap);
    for (let traitId of affectedTraits) {
        traitMapUpdate[traitId].linkedActionIds = traitMapUpdate[traitId].linkedActionIds.filter(
            (id) => id !== actionId
        );
    }
    return traitMapUpdate;
}

export function addTraitLinks(originalTraitMap, affectedTraits, actionId) {
    let traitMapUpdate = cloneDeep(originalTraitMap);
    for (let traitId of affectedTraits) {
        traitMapUpdate[traitId].linkedActionIds = [...traitMapUpdate[traitId].linkedActionIds, actionId];
    }
    return traitMapUpdate;
}
