import React from "react";
import ReportPrintModal from "../../Reporting/ReportPrintModal";
import EmployeeActionReport from "../../Reporting/Reports/EmployeeActionReport";

const ScheduledActionModal = ({ employee, isOpen, toggle, reportType }) => {
    const showCompletedReport = reportType === "completed";
    return (
        <ReportPrintModal title="Employee Actions" isOpen={isOpen} toggle={toggle}>
            <EmployeeActionReport employee={employee} complete={showCompletedReport} />
        </ReportPrintModal>
    );
};

export default ScheduledActionModal;
