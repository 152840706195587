import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Each } from "../../../common/components";
import { confirmFirst } from "../../../common/utils/confirmFirst";
import { DownloadIcon, PlusIcon } from "../../../components/Icons/MyIcons";
import { useToggle } from "../../../hooks/useToggle";
import ScheduledActionCard from "../../ScheduledActions/ScheduledActionCard";
import ScheduledActionItem from "../../ScheduledActions/ScheduledActionItem";
import useScheduledActions from "../../ScheduledActions/hooks/useScheduledActions";
import ScheduledActionModal from "../containers/ScheduledActionModal";
import ScheduleActionModal from "./ScheduleActionModal";
import noTasks from "../../../assets/img/emptystates/NoTasks.png";

const EmptyPlan = ({ toggleModal }) => (
    <div className="h-full bg-white flex-1 flex flex-col items-center justify-center space-y-4">
        <h3>No Actions Scheduled</h3>
        <div className="w-40 h-40">
            <img src={noTasks} alt="A simple illustration of a sleeping cloud" />
        </div>
        <p className="tracking-tight">Browse the Library and start adding Actions.</p>
        <Button onClick={toggleModal}>
            <PlusIcon />
        </Button>
    </div>
);

const CreateActionCard = ({ toggleModal }) => {
    return (
        <div className="bg-base-100 card h-40 flex flex-col justify-center items-center border rounded-lg t p-4">
            <Button shape="circle" onClick={toggleModal}>
                <PlusIcon />
            </Button>
            Add to Plan
        </div>
    );
};

const DevelopmentPlan = ({ activeEmployee, completeActions, cancelActions, scheduleActions }) => {
    const employeeId = activeEmployee.id;
    const [reportType, setReportType] = useState("scheduled");
    const [showModal, toggleModal] = useToggle(false);
    const [showReport, toggleReport] = useToggle(false);
    const { scheduledActionIds, completedActionIds } = activeEmployee;
    const { fetchAction, completedActions, incompleteActions } = useScheduledActions(
        employeeId,
        scheduledActionIds,
        completedActionIds
    );

    const handleScheduleAction = (actionId) => {
        const title = "Schedule Action";
        const message = "Are you sure you want to schedule this action?";
        confirmFirst({
            title,
            message,
            onConfirm: () => {
                const batch = scheduleActions([actionId]);
                return batch.commit();
            },
            onAfter: () => {
                toast.success("Action has been scheduled");
            },
        });
    };

    const handleCompleteAction = (actionId) => {
        const title = "Complete Action";
        const message = "Are you sure you want to mark this action as complete?";
        confirmFirst({
            title,
            message,
            onConfirm: () => {
                const batch = completeActions([actionId]);
                return batch.commit();
            },
            onAfter: () => {
                toast.success("Action has been completed");
            },
        });
    };

    const handleDeleteAction = (actionId) => {
        const title = "Cancel Action";
        const message = "Are you sure you want to cancel this action?";
        confirmFirst({
            title,
            message,
            onConfirm: () => {
                const batch = cancelActions([actionId]);
                return batch.commit();
            },
            onAfter: () => {
                toast.success("Action has been cancelled");
            },
        });
    };

    const handleShowScheduledReport = () => {
        setReportType("scheduled");
        toggleReport();
    };

    const handleShowCompletedReport = () => {
        setReportType("completed");
        toggleReport();
    };

    const noScheduledActions = incompleteActions.length === 0;
    const noCompletedActions = completedActions.length === 0;

    return (
        <div className="flex-1 flex items-stretch overflow-auto divide-x divide-border">
            <div className="flex flex-col flex-1 h-full py-6">
                <div className="flex justify-between pb-1 px-6">
                    <h5>Scheduled Actions</h5>
                    <div className="flex space-x-1 overflow-y-scroll">
                        <button className="btn btn-circle btn-sm btn-ghost" onClick={toggleModal}>
                            <PlusIcon size={16} />
                        </button>
                        <button
                            className="btn btn-circle btn-sm btn-ghost"
                            disabled={noScheduledActions}
                            onClick={handleShowScheduledReport}
                        >
                            <DownloadIcon />
                        </button>
                    </div>
                </div>
                <div className="flex-1 space-y-4 px-6 overflow-y-scroll">
                    <Each
                        of={incompleteActions}
                        renderEmpty={() => <EmptyPlan toggleModal={toggleModal} />}
                        render={(action) => (
                            <div key={action.id} className="flex-1">
                                <ScheduledActionCard
                                    status="scheduled"
                                    fetchAction={fetchAction}
                                    actionId={action.id}
                                    actionName={action.actionName}
                                    onComplete={handleCompleteAction}
                                    onDelete={handleDeleteAction}
                                />
                            </div>
                        )}
                    />
                    {incompleteActions.length > 0 && (
                        <div className="flex-1">
                            <CreateActionCard toggleModal={toggleModal} />
                        </div>
                    )}
                </div>
            </div>
            <div className="h-full flex flex-col overflow-auto flex-1 max-w-md bg-blue-100 border-e border-primary py-6">
                <div className="flex justify-between pb-1 px-6">
                    <h5>Completed Actions</h5>
                    <button
                        className="btn btn-circle btn-ghost btn-sm"
                        disabled={noCompletedActions}
                        onClick={handleShowCompletedReport}
                    >
                        <DownloadIcon />
                    </button>
                </div>
                <div className="flex-1 overflow-y-scroll px-6 space-y-2">
                    <Each
                        of={completedActions}
                        emptyStateProps={{ className: "flex-1 h-full", message: "No completed actions" }}
                        render={(action) => (
                            <ScheduledActionItem
                                mode="browse"
                                done={true}
                                fetchAction={fetchAction}
                                actionId={action.id}
                                actionName={action.actionName}
                                onComplete={handleCompleteAction}
                                onDelete={handleDeleteAction}
                            />
                        )}
                    />
                </div>
            </div>
            <ScheduleActionModal
                activeEmployee={activeEmployee}
                toggle={toggleModal}
                isOpen={showModal}
                fetchAction={fetchAction}
                onCreate={handleScheduleAction}
            />
            <ScheduledActionModal
                isOpen={showReport}
                toggle={toggleReport}
                employee={activeEmployee}
                reportType={reportType}
            />
        </div>
    );
};

export default DevelopmentPlan;
