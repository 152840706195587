import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { calculateDaysPassed, formatDate } from "../../helpers/helpers";


export function dateAge(checkDate, days) {
    const date = new Date(checkDate);
    const today = new Date();
    const daysAgo = new Date(today.setDate(today.getDate() - days));
    return date < daysAgo;
}

const DateDisplay = ({ date, daysAgo, className }) => {
    let prepped = formatDate(date);
    if(daysAgo) {
        prepped = calculateDaysPassed(date);
    }
    return <span className={classNames(className)}>{prepped}</span>;
};

DateDisplay.propTypes = {
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    daysAgo: PropTypes.bool,
    className: PropTypes.string,
};

export default DateDisplay;
