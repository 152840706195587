import classNames from "classnames";
import { useState } from "react";
import { FaEllipsisH, FaMinus } from "react-icons/fa";
import MyButton from "../MyComponents/Button";

function ShowMore({ children, className, stacked, itemLimit = 1 }) {
    const [showAll, setShowAll] = useState(false);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const firstAllowed = children.slice(0, itemLimit);
    const rest = children.slice(itemLimit);

    return (
        <div className={classNames("space-x-1 w-full flex items-center justify-between", className)}>
            <div className={classNames({ "flex space-x-1": !stacked })}>
                {firstAllowed}
                {showAll && rest}
            </div>
            {children.length > itemLimit && (
                <MyButton size="xs" color="ghost" shape="circle" label={showAll ? "Show Less" : "Show More"} onClick={toggleShowAll}>
                    {showAll ? <FaMinus /> : <FaEllipsisH />}
                </MyButton>
            )}
        </div>
    );
}
export default ShowMore;
