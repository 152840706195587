/*
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ObjectiveView from "../../../../../../features/Objectives/Views/ObjectiveView";
import { selectObjective } from "../../../../../../redux/appSlice";
import { useOrgNodeContext } from "../../OrgNodePage";

const ObjectiveViewPage = () => {
    const { liveObjectives } = useOrgNodeContext();
    const params = useParams();
    const dispatch = useDispatch();
    const { objectiveId } = params;
    const objective = useMemo(() => objectives.find((o) => o.id === objectiveId), [objectives, objectiveId]);

    // Select / deselect objective
    useEffect(() => {
        dispatch(selectObjective(objective));
        return () => dispatch(selectObjective(null));
    }, [dispatch, objective]);

    return <ObjectiveView objective={objective} />;
};

export default ObjectiveViewPage;
*/

const ObjectiveViewPage = () => {
    return <div></div>
}

export default ObjectiveViewPage;