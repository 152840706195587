import React, { useRef, useEffect } from "react";
import classNames from "classnames";

export const StepperProgress = ({
    stepIndex,
    hidden,
    steps,
    vertical = false,
    className = "bg-transparent",
    selectedClassName = "text-white bg-primary",
    onItemClick,
}) => {
    const selectedRef = useRef(null);

    useEffect(() => {
        if (selectedRef.current) {
            selectedRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [stepIndex]);

    const handleItemClick = (index) => {
        onItemClick(index);
    };

    if (hidden) return null;
    return (
        <div
            className={classNames(
                "tracking-tight font-medium text-sm hide-scroll-bars border-b rounded-none",
                className,
                {
                    "join overflow-x-scroll": !vertical,
                    "h-full": vertical,
                }
            )}
        >
            {steps.map((step, i) => {
                const isSelected = stepIndex === i;
                const label = step.label || "Step " + (i + 1);
                return (
                    <div
                        key={`stepper-progress-${i}`}
                        ref={isSelected ? selectedRef : null}
                        className={classNames("cursor-pointer join-item min-w-fit px-4 py-3", {
                            selected: isSelected,
                            "bg-transparent": !isSelected,
                            [selectedClassName]: isSelected,
                        })}
                        onClick={() => handleItemClick(i)}
                    >
                        {label}
                    </div>
                );
            })}
        </div>
    );
};

export default StepperProgress;