import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-daisyui";
import MyButton from "./Button";
import { LessMoreIcon, PlusMinusIcon } from "../Icons/MyIcons";

function getIcon(variant, isOpen) {
    switch (variant) {
        case "expandPlus":
            return <PlusMinusIcon checked={isOpen} size={16} />;
        default:
            return <LessMoreIcon checked={isOpen} size={16} />;
    }
}

export const MyCollapseTitle = ({
    className = "p-1",
    closedClassName,
    toggleOnClick,
    hideToggle,
    variant,
    children,
    isOpen,
    onToggle,
}) => {
    const handleToggle = () => {
        if (onToggle) {
            onToggle();
        }
    };

    const handleClick = () => {
        if (!toggleOnClick) return;
        handleToggle();
    }

    return (
        <Collapse.Title
            onClick={handleClick}
            className={classNames("flex justify-between min-h-0", className, { [closedClassName]: !isOpen })}
        >
            <div className="flex-1 flex items-center">{children}</div>
            <div className="flex items-center">
                <MyButton hidden={hideToggle} size="xs" shape="circle" active={isOpen} onClick={handleToggle}>
                    {getIcon(variant, isOpen)}
                </MyButton>
            </div>
        </Collapse.Title>
    );
};

export const MyCollapseContent = ({ className, children }) => (
    <Collapse.Content className={className}>{children}</Collapse.Content>
);

export const MyCollapse = (props) => {
    const [isOpen, setIsOpen] = useState();
    const { titleClassName, contentClassName, open, className, onToggle, overflow, ...cleanProps } = props;

    useEffect(() => {
        setIsOpen(props.open);
    }, [props.open]);

    const controlled = Object.keys(props).includes("open");

    const handleToggle = () => {
        if (!controlled) {
            setIsOpen(!isOpen);
        }
        onToggle();
    };

    const enhancedChildren = React.Children.map(props.children, (child) => {
        if (!child) return null;
        return React.cloneElement(child, {
            onToggle: handleToggle,
            isOpen: isOpen,
        });
    });

    const style = {
        overflow: overflow,
    };

    return (
        <Collapse {...cleanProps} open={isOpen} className={classNames(className)} style={style}>
            {enhancedChildren}
        </Collapse>
    );
};

MyCollapse.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.oneOf(["arrow", "plus"]),
    checkbox: PropTypes.bool,
    checked: PropTypes.bool,
    open: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onToggle: PropTypes.func,
};

MyCollapse.defaultProps = {
    className: "cursor-pointer border border-base-800 bg-base-100",
    overflow: "hidden",
    icon: null,
    checkbox: false,
    onOpen: () => {},
    onClose: () => {},
    onToggle: () => {},
};
