import classNames from "classnames";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_ROLE_LEVELS, OBJECTIVE_FIELD_OPTIONS } from "../../../constants";
import { useCustomFields } from "../../CustomFields/hooks/useCustomFields";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";
import AssessmentBadge from "../../Snapshots/components/AssessmentBadge";
import { useSnapshots } from "../../Snapshots/hooks/useSnapshots";
import { UserStatusIcon } from "../../User/components/atoms/UserStatusIcon";
import { useEmployeeRelationships } from "./useEmployeeRelationships";
import ToggleComponent from "../../../common/components/molecules/ToggleComponent/ToggleComponent";
import { CheckIcon, CrossIcon } from "../../../components/Icons/MyIcons";

function useBaseFields() {
    const { getCustomFieldValueLabel, customEmployeeFields } = useCustomFields();
    const { getManager, getEmployeeRole, getDirectReportIds } = useEmployeeRelationships();
    const { getAllDepartmentsAbove } = useRoleRelationships();

    const mainFields = useMemo(() => {
        return [
            {
                id: "isUser",
                width: "110px",
                label: "User Status",
                filterOptions: {
                    fieldType: "binary",
                    valueOptions: [
                        { id: "false", label: "Not a User" },
                        { id: "true", label: "Is a User" },
                    ],
                },
                getValue: (employee) => {
                    return !!employee?.isUser;
                },
                cellRenderer: (employee) => {
                    const { inviteAccepted } = employee;
                    return (
                        <div
                            className={classNames("flex w-full justify-center items-center", {
                                "text-info": !inviteAccepted,
                                "text-primary": inviteAccepted,
                            })}
                        >
                            <UserStatusIcon employee={employee} size={22} />
                        </div>
                    );
                },
            },
            {
                id: "isManager",
                label: "Manager Status",
                minWidth: "110px",
                filterOptions: {
                    fieldType: "binary",
                    valueOptions: [
                        { id: "false", label: "Not a Manager" },
                        { id: "true", label: "Is a Manager" },
                    ],
                },
                getValue: (employee) => {
                    const childRoleId = getDirectReportIds(employee?.id);
                    return childRoleId.length > 0;
                },
                cellRenderer: (employee) => {
                    const toggled = employee?.isManager;
                    return <ToggleComponent toggled={toggled} component={CheckIcon} flippedComponent={CrossIcon} />;
                },
            },
            {
                id: "firstname",
                label: "Firstname",
                minWidth: "200px",
            },
            {
                id: "surname",
                label: "Surname",
                minWidth: "200px",
            },
            {
                id: "displayName",
                label: "Full Name",
                minWidth: "200px",
            },
            {
                id: "employeeNumber",
                label: "Employee Number",
            },
            {
                id: "linkedTalentBoardIds",
                label: "Linked Board Ids",
            },
            {
                id: "jobTitle",
                label: "Job Title",
                minWidth: "220px",
                getValue: (employee) => {
                    const role = getEmployeeRole(employee?.id);
                    return role?.jobTitle;
                },
                cellRenderer: (employee) => {
                    const role = getEmployeeRole(employee?.id);
                    const jobTitle = role?.jobTitle || "";
                    const trimmed = jobTitle?.length > 32 && jobTitle.substring(0, 32);
                    return trimmed ? `${trimmed}...` : jobTitle;
                },
            },
            {
                id: "department",
                label: "Department",
                minWidth: "220px",
                getValue: (employee) => {
                    const role = getEmployeeRole(employee?.id);
                    return role?.department;
                },
            },
            {
                id: "parentDepartments",
                label: "Parent Departments",
                minWidth: "220px",
                getValue: (employee) => {
                    return getAllDepartmentsAbove(employee?.roleId);
                },
                cellRenderer: (employee) => {
                    return employee.parentDepartments?.join(", ");
                },
            },
            {
                id: "managerId",
                getValue: (employee) => {
                    const manager = getManager(employee?.id);
                    return manager?.id;
                },
            },
            {
                id: "hasManager",
                getValue: (employee) => {
                    const manager = getManager(employee?.id);
                    return !!manager;
                },
            },
            {
                id: "managerName",
                minWidth: "200px",
                label: "Manager",
                getValue: (employee) => {
                    const manager = getManager(employee?.id);
                    return manager?.displayName;
                },
            },
            {
                id: "managerEmail",
                minWidth: "200px",
                label: "Manager Email",
                getValue: (employee) => {
                    const manager = getManager(employee?.id);
                    return manager?.email;
                },
            },
            {
                id: "roleStatus",
                label: "Role Status",
                size: "sm",
                filterOptions: {
                    fieldType: "binary",
                    valueOptions: [
                        { id: "false", label: "Not in Role" },
                        { id: "true", label: "In Role" },
                    ],
                },
                getValue: (employee) => {
                    return !!employee?.roleId;
                },
                cellRenderer: (employee) => {
                    return employee.roleStatus ? "In Role" : "Not in Role";
                },
            },
            {
                id: "email",
                label: "Email",
                minWidth: "220px",
            },
            {
                id: "roleLevel",
                label: "Role Level",
                size: "sm",
                filterOptions: {
                    fieldType: "select",
                    valueOptions: DEFAULT_ROLE_LEVELS,
                },
                getValue: (employee) => {
                    const role = getEmployeeRole(employee?.id);
                    return role?.level;
                },
                cellRenderer: (employee) => {
                    const label = DEFAULT_ROLE_LEVELS.find((level) => level.value === employee.roleLevel)?.label;
                    return label;
                },
            },
            {
                id: "successorCount",
                label: "Successor Count",
                size: "sm",
                filterOptions: {
                    fieldType: "range",
                    step: 1,
                    min: 0,
                    max: 10,
                },
                getValue: (employee) => {
                    const role = getEmployeeRole(employee?.id);
                    return role?.successorCount || 0;
                },
            },
        ];
    }, [getManager, getEmployeeRole, getDirectReportIds, getAllDepartmentsAbove]);

    const customFieldFields = useMemo(() => {
        return customEmployeeFields.map((customField) => {
            const { id, label, fieldType, options } = customField;
            return {
                id,
                label,
                filterOptions: {
                    fieldType: fieldType,
                    valueOptions: options,
                },
                getValue: (employee) => {
                    return employee?.custom?.[id];
                },
                exportRenderer: (employee) => {
                    let fieldValue = employee?.custom?.[id];
                    const label = getCustomFieldValueLabel(id, fieldValue);
                    return label;
                },
                cellRenderer: (employee) => {
                    let fieldValue = employee?.[id];
                    const label = getCustomFieldValueLabel(id, fieldValue);
                    return label;
                },
            };
        });
    }, [customEmployeeFields, getCustomFieldValueLabel]);

    return useMemo(() => {
        const allFields = [...mainFields, ...customFieldFields];
        return allFields.map((field) => ({ category: "Employee Fields", ...field }));
    }, [mainFields, customFieldFields]);
}

function useRatingFields() {
    const traits = useSelector((state) => state.workspace.traits);
    const talentAreas = useSelector((state) => state.workspace.talentAreas);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const overallRatingLabel = useSelector((state) => state.config.everyoneConfig.overallRatingLabel);
    const { getSnapshot } = useSnapshots();
    const overallField = useMemo(() => {
        return {
            id: "overallRating",
            label: overallRatingLabel,
            minWidth: "40px",
            size: "sm",
            cellClassBuilder: () => {
                return "justify-center";
            },
            getValue: (employee) => {
                const snapshot = getSnapshot(employee.id);
                const overallRating = parseInt(snapshot?.overallRating);
                return isNaN(overallRating) ? 0 : overallRating;
            },
            cellRenderer: (employee) => {
                return <AssessmentBadge rating={employee.overallRating} />;
            },
        };
    }, [overallRatingLabel, getSnapshot]);

    const traitFields = useMemo(() => {
        return Object.values(traits).map((trait) => {
            const { id, traitName } = trait;
            return {
                id,
                label: traitName,
                size: "sm",
                minWidth: "40px",
                subCategory: "Traits",
                cellClassBuilder: () => {
                    return "justify-center";
                },
                getValue: (employee) => {
                    const snapshot = getSnapshot(employee.id);
                    return snapshot?.ratings?.[id];
                },
                cellRenderer: (employee) => {
                    const rating = employee[id];
                    return <AssessmentBadge rating={rating} />;
                },
            };
        });
    }, [getSnapshot, traits]);

    const talentAreaFields = useMemo(() => {
        return Object.values(talentAreas).map((talentArea) => {
            const { id, talentAreaName } = talentArea;
            return {
                id,
                label: talentAreaName,
                size: "sm",
                minWidth: "40px",
                subCategory: "Talent Areas",
                cellClassBuilder: () => {
                    return "justify-center";
                },
                getValue: (employee) => {
                    const snapshot = getSnapshot(employee.id);
                    return snapshot?.talentAreas?.[id];
                },
                cellRenderer: (employee) => {
                    const rating = employee[id];
                    return <AssessmentBadge rating={rating} />;
                },
            };
        });
    }, [talentAreas, getSnapshot]);

    const talentBoardFields = useMemo(() => {
        return Object.values(talentBoards).map((talentBoard) => {
            const { id, boardName } = talentBoard;
            return {
                id,
                label: boardName,
                size: "sm",
                minWidth: "40px",
                subCategory: "Talent Boards",
                cellClassBuilder: () => {
                    return "justify-center";
                },
                getValue: (employee) => {
                    const snapshot = getSnapshot(employee.id);
                    const talentBoardRating = snapshot?.talentBoards?.[id];
                    const { overall } = talentBoardRating || {};
                    return overall;
                },
                cellRenderer: (employee) => {
                    const rating = employee[id];
                    return <AssessmentBadge rating={rating} />;
                },
            };
        });
    }, [talentBoards, getSnapshot]);

    return useMemo(() => {
        const allFields = [overallField, ...traitFields, ...talentAreaFields, ...talentBoardFields];
        return allFields.map((field) => ({
            category: "Ratings",
            filterOptions: {
                filterGroup: "rating",
                fieldType: "range",
                min: 0,
                max: 100,
            },
            ...field,
        }));
    }, [overallField, traitFields, talentAreaFields, talentBoardFields]);
}

function useFlagFieldFields() {
    const { flagFields, getCustomFieldValueLabel } = useCustomFields();
    const { getSnapshot } = useSnapshots();
    const allFields = useMemo(() => {
        return flagFields.map((flagField) => {
            const { id, label, fieldType, options } = flagField;
            return {
                id,
                label: label,
                filterOptions: {
                    fieldType: fieldType,
                    valueOptions: options,
                },
                getValue: (employee) => {
                    const snapshot = getSnapshot(employee.id);
                    return snapshot?.flags?.[id];
                },
                exportRenderer: (employee) => {
                    const value = employee?.[id];
                    return getCustomFieldValueLabel(id, value);
                },
                cellRenderer: (employee) => {
                    const value = employee?.[id];
                    return getCustomFieldValueLabel(id, value);
                },
            };
        });
    }, [flagFields, getSnapshot, getCustomFieldValueLabel]);

    return useMemo(() => {
        return allFields.map((field) => ({
            category: "Indicators",
            ...field,
        }));
    }, [allFields]);
}

function useObjectiveFieldFields() {
    const { getSnapshot } = useSnapshots();
    const allFields = useMemo(() => {
        return OBJECTIVE_FIELD_OPTIONS.map((objectiveField) => {
            const { id, label, fieldType, min, max } = objectiveField;
            return {
                id,
                label: label,
                size: "sm",
                minWidth: "40px",
                filterOptions: {
                    fieldType: fieldType,
                    min: min,
                    max: max,
                },
                cellClassBuilder: () => {
                    return "justify-center";
                },
                getValue: (employee) => {
                    const snapshot = getSnapshot(employee.id);
                    return snapshot?.objectives?.[id];
                },
                cellRenderer: (employee) => {
                    const value = employee?.[id];
                    switch (id) {
                        case "projected":
                            return <AssessmentBadge rating={value} />;
                        case "count":
                        default:
                            return value;
                    }
                },
            };
        });
    }, [getSnapshot]);

    return useMemo(() => {
        return allFields.map((field) => ({ category: "Objectives", ...field }));
    }, [allFields]);
}

export function useEmployeeFields(includedFieldIds) {
    const baseFields = useBaseFields();
    const ratingFields = useRatingFields();
    const flagFieldFields = useFlagFieldFields();
    const objectiveFieldFields = useObjectiveFieldFields();

    const allFields = useMemo(() => {
        return [...baseFields, ...ratingFields, ...flagFieldFields, ...objectiveFieldFields];
    }, [baseFields, ratingFields, flagFieldFields, objectiveFieldFields]);

    const filteredFields = useMemo(() => {
        if (!includedFieldIds) return [];
        return allFields.filter((field) => {
            return includedFieldIds.includes(field.id);
        });
    }, [includedFieldIds, allFields]);

    const filterableFields = useMemo(() => {
        return allFields.filter((field) => field.filterOptions);
    }, [allFields]);

    return { allFields, filteredFields, filterableFields };
}
