import { HelperDropdown, RichText } from "../../../../common/components";
import { useReviewEditorContext } from "../../containers/modals/ReviewEditor";

const ReviewHelper = ({ title, segmentId, showWhenEmpty }) => {
    const { values } = useReviewEditorContext();
    const { cycle, relationship } = values;
    function getGuidance(segmentId) {
        const fieldName = `${relationship}Guidance`;
        return cycle[fieldName]?.[segmentId];
    }
    const guidance = getGuidance(segmentId);
    const hidden = !guidance && !showWhenEmpty;
    const disabled = !guidance;
    return (
        <div className="px-8 pt-4 pb-2 flex justify-between border-b ">
            <h3>{title}</h3>
            <HelperDropdown hidden={hidden} disabled={disabled}>
                <h5 className="mb-1 font-semibold">{title}</h5>
                <RichText readOnly removePadding content={guidance} />
            </HelperDropdown>
        </div>
    );
};

export default ReviewHelper;
