import classNames from "classnames";
import { useMemo } from "react";
import { Each } from "../../../../../common/components";
import ToggleComponent from "../../../../../common/components/molecules/ToggleComponent/ToggleComponent";
import ExpandableCard from "../../../../../common/components/organisms/ExpandableCard/ExpandableCard";
import { formatDate } from "../../../../../common/utils/dateUtils";
import MyBadge from "../../../../../components/MyComponents/Badge";
import { useToggle } from "../../../../../hooks/useToggle";

const ReviewerRow = ({ reviewer, review, userEmployeeId, onClick }) => {
    const userIsReviewer = userEmployeeId === reviewer.id;
    const userHasCompleted = review.completedIds.includes(userEmployeeId);

    const handleViewClick = () => {
        if (userIsReviewer) {
            onClick(review, reviewer);
        }
    };

    return (
        <div className="py-2 flex justify-between items-center">
            <div className="flex-1">
                <h4 className="tracking-tight text-sm font-medium">{reviewer?.displayName}</h4>
            </div>
            <div className="space-x-2">
                <button
                    className={classNames("hyperlink text-xs capitalize tracking-tight", {
                        hidden: !userIsReviewer || !userHasCompleted,
                    })}
                    onClick={handleViewClick}
                >
                    view / edit
                </button>
            </div>
        </div>
    );
};

const ReviewHistoryItem = ({ userEmployeeId, review, getEmployee, onReviewClick }) => {
    const [toggled, onToggle] = useToggle(false);
    const { completedOn, cycleName, responderIds = [] } = review;
    const responderCount = responderIds.length;
    const reviewers = useMemo(() => responderIds.map(getEmployee), [responderIds, getEmployee]);
    const badgeColor = "success";

    const handleToggle = () => {
        onToggle();
    };

    const renderTitle = () => (
        <div
            className={classNames("flex items-center justify-between px-6 py-4 cursor-pointer", {
                "border-b": toggled,
            })}
        >
            <div>
                <h4 className="tracking-tight">{cycleName}</h4>
                <h6 className="tracking-tighter font-light">
                    Completed on <span className="text-info">{formatDate(completedOn)}</span>
                </h6>
            </div>
            <div className="flex space-x-2">
                <div className="flex-1 flex space-x-4">
                    <div className="space-x-2 flex text-sm items-center">
                        <MyBadge color={badgeColor} size="small">
                            <div className="flex items-center space-x-2">
                                <div>Complete</div>
                                <div className="space-x-2 flex text-sm items-center min-w-18">
                                    {`${responderCount} / ${responderCount}`}
                                </div>
                            </div>
                        </MyBadge>
                    </div>
                </div>
                <ToggleComponent toggled={toggled} />
            </div>
        </div>
    );

    return (
        <ExpandableCard
            headerComponent={renderTitle()}
            className={classNames("bg-base-100", { "mb-6": toggled })}
            onHeaderClick={handleToggle}
            toggled={toggled}
        >
            <div className="py-3 px-6 divide-y">
                <h6 className="tracking-tighter text-xs mb-2 flex justify-between items-center">
                    <div>Responses were received from the following:</div>
                </h6>
                <Each
                    of={reviewers}
                    render={(reviewer) => (
                        <ReviewerRow
                            reviewer={reviewer}
                            review={review}
                            userEmployeeId={userEmployeeId}
                            onClick={onReviewClick}
                        />
                    )}
                />
            </div>
        </ExpandableCard>
    );
};

export default ReviewHistoryItem;
