import { useCallback, useMemo } from "react";
import { useListener } from "../../../firebase/hooks/useListener";
import { prepQuerySnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";

export function useLiveObjectivesListener(employeeId, onDataReceived) {
    const { liveObjectivesRef } = useEmployeeRefs(employeeId);

    const shouldListen = useMemo(() => {
        return !!employeeId;
    }, [employeeId]);

    const handleDataReceieved = useCallback(
        (querySnapshot) => {
            const data = prepQuerySnapshot(querySnapshot);
            onDataReceived(data);
        },
        [onDataReceived]
    );

    return useListener(liveObjectivesRef, handleDataReceieved, shouldListen);
}
