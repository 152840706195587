import React, { createContext, lazy, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { confirmFirst } from "../../common/utils/confirmFirst";
import MyDrawer from "../../components/MyComponents/Drawer";
import { setActiveSideArea } from "../../redux/appSlice";
import useCycleListeners from "../Cycles/hooks/useCycleListeners";
const AppModals = lazy(() => import("../../containers/AppModals"));
const AppSideArea = lazy(() => import("../../containers/AppSideArea"));

const WorkspaceContext = createContext();
export function useWorkspaceContext() {
    return useContext(WorkspaceContext);
}

const WorkspaceContent = () => {
    const dispatch = useDispatch();
    const [unsavedEdits, setUnsavedEdits] = useState(false);
    const [sideAreaProps, setSideAreaProps] = useState(null);
    const [modalProps, setModalProps] = useState(null);
    const activeSideArea = useSelector((state) => state.app.activeSideArea);
    useCycleListeners();

    useEffect(() => {
        setUnsavedEdits(false);
    }, [activeSideArea]);

    const handleCloseSideArea = () => {
        function closeSideArea() {
            setUnsavedEdits(false);
            dispatch(setActiveSideArea(null));
        }

        if (activeSideArea) {
            if (unsavedEdits) {
                confirmFirst({
                    title: "Unsaved changes",
                    message: "You have unsaved changes. Are you sure you want to leave?",
                    onConfirm: closeSideArea,
                });
            } else {
                closeSideArea();
            }
        }
    };

    const value = {
        setSideAreaProps,
        setModalProps,
        setUnsavedEdits,
        closeSideArea: handleCloseSideArea,
    };



    return (
        <WorkspaceContext.Provider value={value}>
            <MyDrawer
                setIsOpen={handleCloseSideArea}
                isOpen={!!activeSideArea}
                side={
                    <AppSideArea {...sideAreaProps} setUnsavedEdits={setUnsavedEdits} onClose={handleCloseSideArea} />
                }
            >
                <div className="flex flex-col flex-1 overflow-auto">
                    <Outlet />
                </div>
            </MyDrawer>
            <ToastContainer
                position="bottom-left"
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <AppModals {...modalProps} />
        </WorkspaceContext.Provider>
    );
};

export default WorkspaceContent;
