import { useSelector } from "react-redux";
import { useEmployeeRelationships } from "../../Employees/hooks/useEmployeeRelationships";
import { useMemo } from "react";

// Removes any review that the user has been invited to, but is not longer authorized to view
// Handles manager changes after a review has been initiated

export function useReviewQueue() {
    const { checkRelationship } = useEmployeeRelationships();
    const reviewQueue = useSelector((state) => state.workspace.reviewQueue);
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const approvedReviewQueue = useMemo(() => {
        return reviewQueue
            .map((review) => {
                const revieweeId = review?.revieweeId;
                const relationship = checkRelationship(userEmployeeId, revieweeId);
                return {
                    ...review,
                    relationship,
                };
            })
            .filter((review) => review.relationship === "employee" || review.relationship === "manager");
    }, [reviewQueue, userEmployeeId, checkRelationship]);

    return approvedReviewQueue;
}
