import classNames from "classnames";
import { darken } from "polished";
import React, { useMemo } from "react";
import { Each } from "../../../../../common/components";
import ToggleComponent from "../../../../../common/components/molecules/ToggleComponent/ToggleComponent";
import ExpandableCard from "../../../../../common/components/organisms/ExpandableCard/ExpandableCard";
import { convertStringToInt } from "../../../../../common/utils/stringUtils";
import MyProgress from "../../../../../components/MyComponents/Progress";
import { useToggle } from "../../../../../hooks/useToggle";

const BASE_COLOR = "#FAF4FF";

const CascadeCard = ({ entry, allCascades, getEmployee, getRole, getDirectReportIds, depth = 0 }) => {
    const [toggled, toggle] = useToggle(false);
    const { empId, ownerEmployeeId, confidence } = entry;
    const employee = getEmployee(ownerEmployeeId || empId);
    const role = getRole(employee?.roleId);
    const jobTitle = role?.jobTitle || "Unknown Role";
    let confidenceVal = convertStringToInt(confidence);

    const cascades = useMemo(() => {
        const directReportIds = getDirectReportIds(employee?.id);
        return allCascades.filter((c) => directReportIds.includes(c.empId));
    }, [allCascades, employee, getDirectReportIds]);

    const noCascades = cascades.length === 0;
    let averageConfidence = cascades.reduce((sum, obj) => sum + obj.confidence, 0) / cascades.length;
    averageConfidence = isNaN(averageConfidence) ? 0 : averageConfidence;
    const backgroundColor = darken(0.03 * depth, BASE_COLOR);
    const indicatorWidth = 20 + depth * 5;

    return (
        <ExpandableCard
            className="border-none cursor-pointer shadow-none"
            toggled={toggled}
            headerComponent={
                <div className="flex space-x-6">
                    <div className="w-8 flex items-stretch">
                        <div style={{ backgroundColor: backgroundColor, width: indicatorWidth }} />
                    </div>
                    <div className="flex-1 flex space-x-6 px-6 py-2">
                        <div className="w-80">
                            <h5>{employee?.displayName || "Unknown"}</h5>
                            <span className="text-sm font-medium text-base-800 tracking-tight leading-tight">
                                {jobTitle}
                            </span>
                        </div>
                        <div className="flex-1">
                            <h6>Own Confidence</h6>
                            <MyProgress value={confidenceVal} />
                        </div>
                        <div className={classNames("flex-1", { hidden: noCascades })}>
                            <h6>{`Direct Report's Confidence (${cascades.length})`}</h6>
                            <MyProgress value={averageConfidence} />
                        </div>
                        <div className={classNames({ hidden: noCascades })}>
                            <ToggleComponent toggled={toggled} onToggle={toggle} />
                        </div>
                    </div>
                </div>
            }
        >
            <div className={classNames("divide-y border-t", { "border-b": toggled })}>
                {toggled && (
                    <Each
                        of={cascades}
                        emptyStateProps={{ hidden: true }}
                        render={(cascade) => (
                            <CascadeCard
                                entry={cascade}
                                allCascades={allCascades}
                                getEmployee={getEmployee}
                                getRole={getRole}
                                getDirectReportIds={getDirectReportIds}
                                depth={depth + 1}
                            />
                        )}
                    />
                )}
            </div>
        </ExpandableCard>
    );
};

export default CascadeCard;
