import { limit as firestoreLimit, startAfter as firestoreStartAfter, orderBy, query, where } from "firebase/firestore";

/**
 * Builds a Firestore query with specified where and orderBy conditions.
 * @param {CollectionReference} collectionRef - The Firestore collection reference.
 * @param {Array} whereConditions - An array of where conditions. Each condition is an array of [field, operator, value].
 * @param {Array} orderByConditions - An array of orderBy conditions. Each condition is an array of [field, direction].
 * @param {DocumentSnapshot} startAfterDoc - The document snapshot to start after (for pagination).
 * @param {number} limitNum - The number of documents to limit the query to.
 */


function buildQuery(collectionRef, whereConditions = [], orderByConditions = [], startAfterDoc = null, limitNum = null) {
    let constructedQuery = collectionRef;

    // Apply where conditions
    whereConditions.forEach(([field, operator, value]) => {
        constructedQuery = query(constructedQuery, where(field, operator, value));
    });

    // Apply orderBy conditions
    orderByConditions.forEach(([field, direction]) => {
        constructedQuery = query(constructedQuery, orderBy(field, direction));
    });

    // Apply startAfter condition if provided
    if (startAfterDoc) {
        constructedQuery = query(constructedQuery, firestoreStartAfter(startAfterDoc));
    }

    // Apply limit condition if provided
    if (limitNum) {
        constructedQuery = query(constructedQuery, firestoreLimit(limitNum));
    }

    return constructedQuery;
}

export default buildQuery;
