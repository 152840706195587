import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFilteredEmployees } from "../../Employees/hooks/useFilteredEmployees";

export function useFilteredSnapshots(employees, filters) {
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const snapshots = useSelector((state) => state.snapshots.employeeSnapshots);
    const { filteredEmployees } = useFilteredEmployees(filters, employees);

    const includedEmployeeIds = useMemo(() => {
        return filteredEmployees.filter((employee) => employee.id !== userEmployeeId).map((employee) => employee.id);
    }, [filteredEmployees, userEmployeeId]);

    let filteredSnapshots = useMemo(() => {
        let returnVal = {};
        Object.entries(snapshots)
            .filter(([empId, _]) => includedEmployeeIds.includes(empId))
            .forEach(([empId, snap]) => {
                returnVal[empId] = snap;
            });
        return returnVal;
    }, [snapshots, includedEmployeeIds]);

    return filteredSnapshots;
}
