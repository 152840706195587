import classNames from "classnames";
import ObjectiveScopeBadge from "../../atoms/ObjectiveScopeBadge";
import { ObjectiveStatusBadge } from "../../atoms/ObjectiveStatus";

const ObjectiveReviewHeader = ({ objective, className, onClick = () => {} }) => {
    const { objectiveName, result, status } = objective;

    const handleClick = () => {
        onClick(objective);
    };

    return (
        <div className={classNames(className)} onClick={handleClick}>
            <div className="flex-1 flex space-x-2 items-center h-8">
                <div className="w-28 flex items-center">
                    <ObjectiveScopeBadge objective={objective} />
                </div>
                <span className="font-medium tracking-tight capitalize">{objectiveName}</span>
            </div>
            <div className="flex space-x-12 items-center">
                <ObjectiveStatusBadge result={result} status={status} />
            </div>
        </div>
    );
};

export default ObjectiveReviewHeader;
