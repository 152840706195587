import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";

const getBusinessUnitRef = (workspaceId, unitId) => {
    const workspaceRef = doc(db, "workspaces", workspaceId);
    const businessUnitRef = doc(workspaceRef, "businessUnits", unitId);
    return businessUnitRef;
};

const writeBusinessUnit = (workspaceId, unitId, update, batch = writeBatch(db)) => {
    const businessUnitRef = getBusinessUnitRef(workspaceId, unitId);
    batch.set(businessUnitRef, update, { merge: true });
    return batch;
};

const writeReminderDates = (workspaceId, unitId, update, batch = writeBatch(db)) => {
    const businessUnitRef = getBusinessUnitRef(workspaceId, unitId);
    const remindersRef = doc(businessUnitRef, "reminderDates", "talentAssessment");
    batch.set(remindersRef, update, { merge: true });
    return batch;
};

const writeBoardMap = (workspaceId, unitId, update, batch = writeBatch(db)) => {
    const businessUnitRef = getBusinessUnitRef(workspaceId, unitId);
    const boardMapRef = doc(businessUnitRef, "maps", "boardMap");
    batch.set(boardMapRef, update, { merge: true });
    return batch;
};

export { writeBusinessUnit, writeReminderDates, writeBoardMap };
