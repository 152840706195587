import ObjectiveSettingContent from "./ObjectiveSettingContent";
import ObjectiveSettingHeader from "./ObjectiveSettingHeader";
import ObjectiveCreatorHeader from "./ObjectiveCreatorHeader";
import ObjectiveCascadeHeader from "./ObjectiveCascadeHeader";

const ObjectiveSetting = {
    Creator: ObjectiveCreatorHeader,
    Header: ObjectiveSettingHeader,
    Content: ObjectiveSettingContent,
    Cascade: ObjectiveCascadeHeader,
};

export default ObjectiveSetting;
