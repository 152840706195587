import classNames from "classnames";
import React from "react";
import doneImg from "../../assets/img/emptystates/Done.png";
import emptyInbox from "../../assets/img/emptystates/EmptyInbox.png";
import errorImg from "../../assets/img/emptystates/Error.png";
import noConnection from "../../assets/img/emptystates/NoConnection.png";
import noDocuments from "../../assets/img/emptystates/NoDocuments.png";
import noMessages from "../../assets/img/emptystates/NoMessages.png";
import noSearchResult from "../../assets/img/emptystates/NoSearchResult.png";
import noTasks from "../../assets/img/emptystates/NoTasks.png";

const Image = ({ type, height }) => {
    let style = { height: height || "auto" };
    if (height) {
        style.width = "auto";
    } else {
        style.maxWidth = "50%";
    }
    switch (type) {
        case "done":
            return <img src={doneImg} alt="Done" style={style} />;
        case "error":
            return <img src={errorImg} alt="Error" style={style} />;
        case "connection":
            return <img src={noConnection} alt="No Connection" style={style} />;
        case "documents":
            return <img src={noDocuments} alt="No Documents" style={style} />;
        case "messages":
            return <img src={noMessages} alt="No Messages" style={style} />;
        case "tasks":
            return <img src={noTasks} alt="No Tasks" style={style} />;
        case "inbox":
            return <img src={emptyInbox} alt="Empty Inbox" style={style} />;
        case "noIcon":
            return null;
        case "noSearchResult":
        default:
            return <img src={noSearchResult} alt="No Search Result" style={style} />;
    }
};

export const MyEmptyState = ({
    hidden = false,
    type,
    message = "Nothing to display",
    imageHeight = 84,
    className = "bg-base-100",
}) => {
    if (hidden) return null;
    return (
        <div
            className={classNames(
                "w-full bg-white flex border p-2 rounded items-center justify-center flex-col text-base-500",
                className
            )}
        >
            <div className="flex items-center justify-center w-full">
                <Image type={type} height={imageHeight} />
            </div>
            <div className="font-medium space-x-2 flex justify-center items-center capitalize text-base-500">
                <span className="tracking-tight">{message}</span>
            </div>
        </div>
    );
};

export default MyEmptyState;
