import classNames from "classnames";
import React from "react";
import {
    FaDotCircle as AbandonedIcon,
    FaDotCircle,
    FaRunning as InProgressIcon,
    FaRegPauseCircle as NotStartedIcon,
} from "react-icons/fa";
import { TbTargetArrow as AchievedIcon, TbTargetOff as FailedIcon } from "react-icons/tb";
import MyRadioButtons from "../../../../components/MyComponents/RadioButtons";
import { CheckIcon } from "../../../../components/Icons/MyIcons";

export const NOT_STARTED = "not_started";
export const IN_PROGRESS = "in_progress";
export const COMPLETE = "complete";

const STATUS_OPTIONS = [
    { label: "Not Started", value: NOT_STARTED, color: "neutral", icon: NotStartedIcon },
    { label: "In Progress", value: IN_PROGRESS, color: "neutral", icon: InProgressIcon },
    { label: "Finished", value: COMPLETE, color: "neutral", icon: CheckIcon },
];

const PASS_FAIL = [
    { label: "Abandoned", value: null, color: "neutral", icon: AbandonedIcon },
    { label: "Not Achieved", value: 0, color: "error", icon: FailedIcon },
    { label: "Achieved", value: 100, color: "success", icon: AchievedIcon },
];

const WITH_PARTIAL = [
    { label: "Abandoned", value: null, color: "neutral", icon: AbandonedIcon },
    { label: "Not Achieved", value: 0, color: "error", icon: FailedIcon },
    { label: "Partially Achieved", value: 50, color: "secondary", icon: AchievedIcon },
    { label: "Achieved", value: 100, color: "success", icon: AchievedIcon },
];

export function getResultOptions(range) {
    switch (range) {
        case "allow-partial":
            return WITH_PARTIAL;
        case "pass-fail":
        default:
            return PASS_FAIL;
    }
}

const ObjectiveStatusSelector = ({ status, onChange }) => {
    const handleChange = (value) => {
        onChange("status", value);
    };

    return <MyRadioButtons size="xs" options={STATUS_OPTIONS} value={status} onChange={handleChange} />;
};

const ObjectiveResultSelector = ({ status, result, resultRange = "allow-partial", onChange }) => {
    const resultOptions = getResultOptions(resultRange);

    const handleChange = (value) => {
        onChange("result", value);
    };

    function getResult() {
        if (status !== "complete") return false;
        return result;
    }

    return (
        <MyRadioButtons
            size="xs"
            disabled={status !== "complete"}
            options={resultOptions}
            value={getResult()}
            onChange={handleChange}
        />
    );
};

const ObjectiveStatusBadge = ({ result, status, resultRange = "allow-partial" }) => {
    const resultOptions = getResultOptions(resultRange);
    const isComplete = status === "complete";
    const resultOption = resultOptions.find((o) => o.value === result);
    const statusOption = STATUS_OPTIONS.find((o) => o.value === status);
    const { icon: statusIcon = NotStartedIcon } = statusOption || {};
    const { icon: resultIcon = FaDotCircle } = resultOption || {};
    const Icon = isComplete ? resultIcon : statusIcon;
    const label = isComplete ? resultOption?.label : statusOption?.label || "Not Started";
    return (
        <div className={classNames("min-w-32 justify-end flex items-center space-x-2 tracking-tighter font-medium")}>
            <span>{label}</span>
            <Icon size={24} className={classNames(`text-${resultOption?.color}`)} />
        </div>
    );
};

export { ObjectiveResultSelector, ObjectiveStatusBadge, ObjectiveStatusSelector };
