export const addBlankTargetToLinks = (rawContent) => {
    const content = rawContent;
    const entityMap = content.entityMap;
    const keys = Object.keys(entityMap || {});
    keys.forEach((key) => {
        const entity = entityMap[key];
        if (entity.type === "LINK") {
            entity.data.targetOption = "_blank";
        }
    });
    return content;
};
