import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import MyTooltip from "./Tooltip";

export const MyMenuItem = ({ children, header, title, linkTo, divider, hidden, disabled, onClick }) => {
    if (hidden) return null;

    const handleClick = () => {
        if (disabled || !onClick) return;
        if (onClick) onClick();
    };

    function getClassName() {
        if (title) return "text-left text-primary uppercase w-full no-marker mb-1 text-lg font-bold hover:bg-base-100";
        if (header) return "text-left uppercase w-full no-marker font-semibold hover:bg-base-100";
        if (divider) return "flex items-center h-10";
        if (linkTo) return "text-sm text-left w-full font-medium no-marker";
        if (onClick) return "text-sm py-2 text-left w-full font-medium no-marker cursor-pointer hover:bg-base-100";
        return "text-sm text-left w-full font-medium no-marker flex justify-center items-start";
    }

    const className = getClassName();

    switch (true) {
        case linkTo:
            return (
                <li className={className}>
                    <Link to={linkTo} className="w-full text-left">
                        {children}
                    </Link>
                </li>
            );
        case divider:
            return <hr />;
        default:
            return (
                <li
                    className={classNames(className, {
                        disabled: disabled,
                    })}
                    onClick={handleClick}
                >
                    {children}
                </li>
            );
    }
};

MyMenuItem.propTypes = {
    disabled: PropTypes.bool,
    header: PropTypes.bool,
    divider: PropTypes.bool,
    title: PropTypes.bool,
    dropdownItem: PropTypes.bool,
    linkTo: PropTypes.string,
    className: PropTypes.string,
    extraClasses: PropTypes.string,
    tooltip: PropTypes.string,
    hidden: PropTypes.bool,
    onClick: PropTypes.func,
};

MyMenuItem.defaultProps = {
    className: "flex items-center",
    extraClasses: "",
    tooltipPosition: "left",
};

//////////////////////////////////////////////////////////////////////

export const MyMenuGroup = ({ vertical, disabled, size, hidden, tooltipPosition, children }) => {
    if (hidden) return null;

    // Disable every items if the menu is disabled
    const enhancedChildren = React.Children.map(children, (child) => {
        if (!child) return null;
        return (
            <MyTooltip tooltip={child.props.tooltip} position={tooltipPosition}>
                {React.cloneElement(child, { disabled: disabled || child.props.disabled })}
            </MyTooltip>
        );
    });

    return (
        <ul
            className={classNames("menu", `menu-${size}`, {
                "menu-horizontal space-x-2": !vertical,
            })}
        >
            {enhancedChildren}
        </ul>
    );
};
MyMenuGroup.propTypes = {
    className: PropTypes.string,
    flex: PropTypes.number,
    justify: PropTypes.string,
    vertical: PropTypes.bool,
    disabled: PropTypes.bool,
    removePadding: PropTypes.bool,
    tooltipPosition: PropTypes.string,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

MyMenuGroup.defaultProps = {
    tooltipPosition: "left",
    justify: "start",
    size: "md",
};

/*
 const flexClass = flex && `flex-${flex}`;
    const justifyClass = `justify-${justify}`;
    const alignClass = `items-${align}`;
    const spacingClass = vertical ? "space-y-2" : "space-x-2";
    const paddingClass = removePadding ? "py-0" : vertical ? "px-2" : "py-2";
    const dropdownClasses = "flex-1 flex flex-col w-full";
    const orientationClasses = vertical ? dropdownClasses : flexClass;

*/
