import { collection, doc } from "firebase/firestore";
import { useMemo } from "react";
import { useCycleRefs } from "./useCycleRefs";

export function useIterationRefs(cycleId, iterationId) {
    const { cycleRef } = useCycleRefs(cycleId);

    const iterationsRef = useMemo(() => {
        if (!cycleRef) return null;
        return collection(cycleRef, "iterations");
    }, [cycleRef]);

    const iterationRef = useMemo(() => {
        if (!iterationId || !iterationsRef) return null;
        return doc(iterationsRef, iterationId);
    }, [iterationsRef, iterationId]);

    const reviewsRef = useMemo(() => {
        if (!iterationRef) return null;
        return collection(iterationRef, "reviews");
    }, [iterationRef]);

    const trackingRef = useMemo(() => {
        if (!iterationRef) return null;
        return collection(iterationRef, "tracking");
    }, [iterationRef]);

    return { iterationsRef, iterationRef, reviewsRef, trackingRef };
}
