import React, { useEffect, useMemo, useState } from "react";
import WaitingImg from "../../../../../assets/img/emptystates/NoConnection.png";
import { Each } from "../../../../../common/components";
import ObjectiveContent from "../../../../Objectives/components/organisms/ObjectiveReview/ObjectiveReviewContent";
import ObjectiveHeader from "../../../../Objectives/components/organisms/ObjectiveReview/ObjectiveReviewHeader";
import NoteEditorBlock from "../../../components/organisms/NoteEditorBlock/NoteEditorBlock";

const EmptyState = () => (
    <div className="flex-1 flex flex-col items-center justify-center space-y-4">
        <h2>No Objectives To Review</h2>
        <img src={WaitingImg} alt="A simple illustration of a sleeping cloud" />
        <p className="tracking-tight">
            If this is the first time you're completing this Cycle, there won't be any objectives to review.
        </p>
        <p className="tracking-tight font-semibold">Click next to start setting Objectives.</p>
    </div>
);

const CurrentObjectivesStepTemplate = ({ values, actions }) => {
    const [selectedObjective, setSelectedObjective] = useState(null);
    const { userEmployeeId, locked, relationship, objectivesToReview = {}, cycle, notes = [], draft } = values;
    const { updateNote, setNavigateBlocked, setCloseBlocked, updateObjective } = actions;
    const { cycleId, iterationId } = draft;
    const { requiredWhenReviewing } = cycle;
    const lockCheckIn = relationship !== "employee";
    const showCheckIn = requiredWhenReviewing === "require_check_in";
    const requireResults = requiredWhenReviewing === "require_results";

    const viewingObjective = useMemo(() => !!selectedObjective, [selectedObjective]);

    const requirementsMet = useMemo(() => {
        if (!requireResults) return true;
        const allResults = objectivesToReview.every((objective) => objective.status === "complete");
        return allResults;
    }, [objectivesToReview, requireResults]);

    useEffect(() => {
        const blockNavigation = !requirementsMet || viewingObjective;
        setNavigateBlocked(blockNavigation);
    }, [requirementsMet, setNavigateBlocked, viewingObjective]);

    function handleSaveNote(note) {
        updateNote(note);
    }

    const handleSaveObjective = (objective) => {
        updateObjective(objective);
    };

    const handleViewObjective = (objective) => {
        setSelectedObjective(objective || null);
        setCloseBlocked(!!objective);
    };

    if (selectedObjective) {
        return (
            <div className="h-full flex flex-1 py-4 px-6 overflow-auto">
                <div className="flex flex-col flex-1 border rounded-box bg-white overflow-auto">
                    <ObjectiveContent
                        showHeader
                        showFooter
                        userEmployeeId={userEmployeeId}
                        cycleId={cycleId}
                        iterationId={iterationId}
                        showCheckIn={showCheckIn}
                        lockCheckIn={lockCheckIn}
                        objective={selectedObjective}
                        notes={notes}
                        onToggle={handleViewObjective}
                        onSaveNote={handleSaveNote}
                        onSaveObjective={handleSaveObjective}
                        locked={locked}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="flex-1 flex flex-col relative fade h-full w-full items-stretch">
            <div className="flex items-stretch flex-1 divide-x overflow-auto">
                <div className="flex flex-col flex-1 px-8 py-6 space-y-6 overflow-scroll">
                    <div className="flex-1 flex flex-col">
                        <h5>Current Objectives</h5>
                        <div className="flex flex-col mb-4 flex-1">
                            <Each
                                of={objectivesToReview}
                                renderEmpty={() => <EmptyState />}
                                render={(objective) => {
                                    return (
                                        <ObjectiveHeader
                                            className="w-full cursor-pointer border rounded bg-white flex items-center justify-between px-6 py-2"
                                            objective={objective}
                                            onClick={handleViewObjective}
                                        />
                                    );
                                }}
                            />
                            {!requirementsMet && (
                                <h6 className="text-end py-1 text-error">All Objectives Must Be Completed</h6>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex-1 min-w-md max-w-md px-8 py-4 bg-white overflow-scroll">
                    <NoteEditorBlock values={values} actions={actions} segmentId="objectiveReview" />
                </div>
            </div>
        </div>
    );
};

export default CurrentObjectivesStepTemplate;
