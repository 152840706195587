import { utils, writeFile } from "xlsx";

export function exportTableToXl(tableData, columns, tableName) {
    const filename = tableName ? `${tableName}.xlsx` : "export.xlsx";
    /// Manage the export
    const headerRow = columns.map((col) => col.label);
    const dataRows = tableData.map((entry) => {
        return columns.map((column) => {
            const value = entry[column.id] || "-";
            return column.exportRenderer ? column.exportRenderer(entry) : value;
        });
    });
    const exportData = [headerRow, ...dataRows];

    const worksheet = utils.aoa_to_sheet(exportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, filename);
}
