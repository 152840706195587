import { RichText } from "../../../../components";

const ObjectiveDescription = ({
    description,
    readOnly,
    placeholder = "No description provided",
    className = "flex-1 border-none",
    onChange = () => {},
}) => {
    return (
        <RichText
            content={description}
            readOnly={readOnly}
            className={className}
            minHeight={120}
            maxHeight={180}
            onChange={onChange}
            removePadding
            placeholder={placeholder}
        />
    );
};

export default ObjectiveDescription;
