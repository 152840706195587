import React, { useMemo } from "react";
import useEditValues from "../../../../../common/hooks/useEditValues";
import NoteContent from "../../../../Notes/components/atoms/NoteContent";

const ReviewNote = ({
    useOwnNote = false,
    notes,
    userEmployeeId,
    reviewNoteId,
    cycleId,
    cycleName,
    iterationId,
    isPrivate = false,
    readOnly = false,
    onUpdate,
    className,
    bgClass,
}) => {
    const note = useMemo(() => {
        const forThisTopic = notes.filter((n) => n.reviewNoteId === reviewNoteId);
        const ownNote = forThisTopic.find((n) => n.authorId === userEmployeeId);
        const counterpartNote = forThisTopic.find((n) => n.authorId !== userEmployeeId);
        const prevNote = useOwnNote ? ownNote : counterpartNote;
        return prevNote || { cycleId, iterationId, reviewNoteId, authorId: userEmployeeId, content: "" };
    }, [notes, useOwnNote, cycleId, iterationId, reviewNoteId, userEmployeeId]);

    const [editedNote, setNoteValue] = useEditValues(note);

    const handleChange = (newContent) => {
        const updatedNote = { ...editedNote, content: newContent, cycleName, isPrivate };
        setNoteValue("content", newContent);
        onUpdate(updatedNote);
    };

    return (
        <NoteContent
            note={editedNote}
            readOnly={readOnly}
            onChange={handleChange}
            className={className}
            bgClass={bgClass}
            placeholder="No Comment Added Yet."
        />
    );
};

export default ReviewNote;
