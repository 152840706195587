import classNames from "classnames";
import { ContentState, convertFromHTML, convertToRaw, EditorState, Modifier } from "draft-js";
import { debounce, isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "./rich-text-editor.scss";
import { createEditorState } from "./utils/createEditorState";
import { moveSelectionToEnd } from "./utils/moveSelectionToEnd";

const TOOLBAR_OPTIONS = {
    options: ["inline", "list", "link"],
    inline: {
        options: ["bold", "italic", "underline"],
    },
    list: { options: ["unordered"] },
    link: { options: ["link"], showOpenOptionOnHover: false },
};

const cleanData = (data) => {
    if (Array.isArray(data)) {
        return data.map(cleanData);
    } else if (data && typeof data === "object") {
        return Object.keys(data).reduce((acc, key) => {
            if (key !== "_map") {
                acc[key] = cleanData(data[key]);
            }
            return acc;
        }, {});
    } else {
        return data;
    }
};

const RichTextEditor = (props) => {
    const {
        content,
        showBorder,
        focusOnMount,
        maxLength,
        wrapperStyle,
        textSize,
        bgClass,
        className,
        alwaysShowToolbar,
        onChange,
    } = props;
    const [editorFocused, setEditorFocused] = useState(false);
    const [editorState, setEditorState] = useState(() => createEditorState(content));
    const debouncedUpdateRef = useRef();
    const cleanContentRef = useRef();
    const focusRef = useRef();

    useEffect(() => {
        if (focusRef.current && focusOnMount) {
            focusRef.current.focus();
        }
    }, [focusOnMount]);

    useEffect(() => {
        const mismatched = !isEqual(content, cleanContentRef.current);
        if (mismatched) {
            setEditorState(() => createEditorState(content));
        }
    }, [content]);

    if (!debouncedUpdateRef.current) {
        debouncedUpdateRef.current = debounce((stateUpdate) => {
            const rawContent = convertToRaw(stateUpdate.getCurrentContent());
            const cleanContent = cleanData(rawContent);
            cleanContentRef.current = cleanContent;
            onChange(cleanContent);
        }, 500);
    }
    const handlePastedText = (text, html) => {
        if (html) {
            const blocksFromHTML = convertFromHTML(html);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );

            // Get the current selection (cursor position)
            const currentContent = editorState.getCurrentContent();
            const selectionState = editorState.getSelection();

            // Insert the new content at the selection
            const newContentState = Modifier.replaceWithFragment(
                currentContent,
                selectionState,
                contentState.getBlockMap()
            );

            // Push the new content into the editor state
            const newEditorState = EditorState.push(editorState, newContentState, "insert-fragment");
            setEditorState(newEditorState);
            return true; // Signal that the paste was handled
        }
        return false; // Fallback to default behavior if no HTML is present
    };

    const handleBeforeInput = (input) => {
        if (maxLength && editorState.getCurrentContent().getPlainText().length > maxLength) {
            // use to enforce max length etc
            return "handled";
        }
    };

    const handleEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        debouncedUpdateRef.current(newEditorState);
    };

    const handleBlur = () => {
        setEditorFocused(false);
        debouncedUpdateRef.current.flush();
    };

    const handleFocus = () => {
        setEditorFocused(true);
        setEditorState((prevState) => moveSelectionToEnd(prevState));
    };

    return (
        <Editor
            tabIndex={0}
            editorRef={(ref) => {
                focusRef.current = ref;
            }}
            toolbarHidden={!editorFocused && !alwaysShowToolbar}
            onFocus={handleFocus}
            onBlur={handleBlur}
            editorState={editorState}
            wrapperStyle={wrapperStyle}
            handlePastedText={handlePastedText}
            wrapperClassName={classNames(className, bgClass, " overflow-scroll rounded-lg text-editor-wrapper")}
            editorClassName={classNames("prose rounded-lg p-3", "text-editor", `text-${textSize}`, {
                "p-0": props.removePadding,
                border: showBorder,
            })}
            onEditorStateChange={handleEditorStateChange}
            handleBeforeInput={handleBeforeInput}
            toolbar={TOOLBAR_OPTIONS}
        />
    );
};

export default RichTextEditor;
