import _ from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import RadialBarChart from "../../../../components/Charts/BaseCharts/RadialBarChart";
import { CONFIDENCE_LABELS } from "../../../../constants";
import { getAssessmentColors } from "../../../Config/utils/configSelectors";
import { showRatingColor } from "../../../Snapshots/utils/snapshotHelpers";
import ChartContainer from "../../../../components/Charts/ChartContainer";

function getLabel(level) {
    switch (level) {
        case "0":
        case "1":
            return "Owner Confidence";
        case "2":
            return "Direct Reports";
        default:
            return `Cascade Level ${level - 1}`;
    }
}

const CascadesRadarChart = ({ allCascades }) => {
    const allColors = useSelector(getAssessmentColors);

    const data = useMemo(() => {
        const noInactive = allCascades.filter((cascade) => cascade.orgLevel);
        const levelGroups = _.groupBy(noInactive, "orgLevel");
        const chartData = [];
        Object.entries(levelGroups).forEach(([level, cascades]) => {
            const totalCascadesAtLevel = cascades.length;
            const groupByConfidence = _.groupBy(cascades, "confidence");
            const levelData = Object.entries(groupByConfidence).map(([confidence, cascades]) => {
                const cascadeCount = cascades.length;
                const cascadePercentage = Math.round((cascadeCount / totalCascadesAtLevel) * 100);
                const color = showRatingColor(allColors, confidence);
                return {
                    x: CONFIDENCE_LABELS[confidence],
                    color: color,
                    y: cascadePercentage,
                };
            });
            const idLabel = getLabel(level);
            chartData.push({
                id: idLabel,
                data: levelData,
            });
        });
        return chartData.reverse();
    }, [allCascades, allColors]);

    return (
        <ChartContainer>
            <RadialBarChart data={data} />
        </ChartContainer>
    );
};

export default CascadesRadarChart;
