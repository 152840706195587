import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import { isChartReady } from "../../../common/utils/chartUtils";

const RadarChart = ({ data, height, width, hideLegend }) => {
    const chartRef = useRef();

    useEffect(() => {
        const chartIsReady = isChartReady(data, height, width);
        if (chartIsReady) {
            const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

            const radius = Math.min(width, height) / 2;
            const levels = 5;
            const maxValue = d3.max(data, (d) => d3.max(d.data, (i) => i.value));
            const angleSlice = (Math.PI * 2) / data[0].data.length;

            const svg = d3
                .select(chartRef.current)
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform", `translate(${width / 2},${height / 2})`);

            const radarLine = d3
                .lineRadial()
                .curve(d3.curveCardinalClosed)
                .radius((d) => radius * (d.value / maxValue))
                .angle((d, i) => i * angleSlice);

            // Draw the circular grid
            const axisGrid = svg.append("g").attr("class", "axisWrapper");
            axisGrid
                .selectAll(".levels")
                .data(d3.range(1, levels + 1).reverse())
                .enter()
                .append("circle")
                .attr("class", "gridCircle")
                .attr("r", (d) => (radius / levels) * d)
                .style("fill", "#CDCDCD")
                .style("stroke", "#CDCDCD")
                .style("fill-opacity", 0.1);

            // Draw the axes
            const axis = axisGrid.selectAll(".axis").data(data[0].data).enter().append("g").attr("class", "axis");

            axis.append("line")
                .attr("x1", 0)
                .attr("y1", 0)
                .attr("x2", (d, i) => radius * Math.cos(angleSlice * i - Math.PI / 2))
                .attr("y2", (d, i) => radius * Math.sin(angleSlice * i - Math.PI / 2))
                .attr("class", "line")
                .style("stroke", "white")
                .style("stroke-width", "2px");

            axis.append("text")
                .attr("class", "legend")
                .text((d) => d.label)
                .attr("text-anchor", "middle")
                .attr("dy", "0.35em")
                .attr("x", (d, i) => (radius + 10) * Math.cos(angleSlice * i - Math.PI / 2))
                .attr("y", (d, i) => (radius + 10) * Math.sin(angleSlice * i - Math.PI / 2));

            // Draw the radar chart blobs
            data.forEach((series, i) => {
                const radarBlobWrapper = svg.append("g").attr("class", `radarWrapper radarWrapper${i}`);

                radarBlobWrapper
                    .append("path")
                    .attr("class", "radarArea")
                    .attr("d", radarLine(series.data))
                    .style("fill", series.color || colorScale(i))
                    .style("fill-opacity", 0.1);

                radarBlobWrapper
                    .append("path")
                    .attr("class", "radarStroke")
                    .attr("d", radarLine(series.data))
                    .style("stroke-width", "2px")
                    .style("stroke", series.color || colorScale(i))
                    .style("fill", "none");

                radarBlobWrapper
                    .selectAll(".radarCircle")
                    .data(series.data)
                    .enter()
                    .append("circle")
                    .attr("class", "radarCircle")
                    .attr("r", 4)
                    .attr("cx", (d, i) => radius * (d.value / maxValue) * Math.cos(angleSlice * i - Math.PI / 2))
                    .attr("cy", (d, i) => radius * (d.value / maxValue) * Math.sin(angleSlice * i - Math.PI / 2))
                    .style("fill", series.color || colorScale(i))
                    .style("fill-opacity", 0.8);
            });

            // Draw legend
            if (!hideLegend) {
                const legend = svg
                    .append("g")
                    .attr("class", "legendWrapper")
                    .attr("transform", `translate(${radius}, ${-radius / 2})`);

                legend
                    .selectAll(".legendSquare")
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("class", "legendSquare")
                    .attr("x", 0)
                    .attr("y", (d, i) => i * 20)
                    .attr("width", 10)
                    .attr("height", 10)
                    .style("fill", (d, i) => d.color || colorScale(i));

                legend
                    .selectAll(".legendLabel")
                    .data(data)
                    .enter()
                    .append("text")
                    .attr("class", "legendLabel")
                    .attr("x", 20)
                    .attr("y", (d, i) => i * 20 + 9)
                    .attr("dy", "0.35em")
                    .text((d) => d.label)
                    .attr("text-anchor", "start");
            }
        }
    }, [data, height, width, hideLegend]);

    return <svg ref={chartRef} />;
};

RadarChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.number.isRequired,
                })
            ).isRequired,
            color: PropTypes.string,
        })
    ).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    hideLegend: PropTypes.bool,
};

RadarChart.defaultProps = {
    hideLegend: false,
};

export default RadarChart;
