import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import applogger from "../../../common/utils/applogger";
import { ensureSerializable } from "../../../common/utils/objectUtils";
import { formatName } from "../../../common/utils/stringUtils";
import { getDocRef, getUsersCollectionRef } from "../../../firebase/firebaseActions";
import { useListener } from "../../../firebase/hooks/useListener";
import { setUserDataThunk, userSignOutThunk } from "../utils/userThunks";
import { auth } from "../../../firebase/firebase";

export function useUserListener() {
    const dispatch = useDispatch();
    const uid = useSelector((state) => state.app.uid);
    const signedIn = useSelector((state) => state.app.signedIn);
    const [status, setStatus] = useState("loading");

    const userRef = useMemo(() => {
        if (!uid) return null;
        const usersRef = getUsersCollectionRef();
        const userRef = getDocRef(usersRef, uid);
        return userRef;
    }, [uid]);

    const shouldListen = useMemo(() => {
        return signedIn && uid;
    }, [signedIn, uid]);

    const handleUserDataReceived = useCallback(
        async (userDoc) => {
            const userDocData = userDoc.data();
            if (!userDocData) {
                setStatus("failed");
            } else {
                try {
                    const { claims, ...rest } = userDocData;
                    const authUser = auth.currentUser;
                    const token = await authUser.getIdTokenResult(true);
                    const actualClaims = token.claims;
                    const userData = {
                        ...rest,
                        claims: actualClaims,
                        id: userDoc.id,
                        displayName: formatName(userDoc.data()),
                    };
                    const preppedData = ensureSerializable(userData);
                    dispatch(setUserDataThunk(preppedData));
                } catch (error) {
                    applogger.error("Error getting current user", error);
                    dispatch(userSignOutThunk());
                }
                setStatus("success");
            }
        },
        [dispatch]
    );

    useListener(userRef, handleUserDataReceived, shouldListen);
    return status;
}
