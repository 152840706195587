import { useMemo } from "react";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";
import { query, where } from "firebase/firestore";

export function useIterationNotesRefs(iterationId, revieweeId) {
    const { notesRef } = useEmployeeRefs(revieweeId);
    const iterationNotesRef = useMemo(() => {
        if (!notesRef || !iterationId || !revieweeId) return null;
        const queryRef = query(notesRef, where("iterationId", "==", iterationId));
        return queryRef;
    }, [notesRef, iterationId, revieweeId]);

    return { iterationNotesRef };
}
