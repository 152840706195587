import React from "react";
import MyRange from "./Range";

const MinMaxRange = ({ disabled, minValue, maxValue, step = 20, minLimit = 0, maxLimit = 100, onChange }) => {

    const handleSliderChange = (field, val) => {
        let updates = { [field]: val };
        if (field === "minValue" && val > maxValue) {
            updates.maxValue = val;
        }
        if (field === "maxValue" && val < minValue) {
            updates.minValue = val;
        }
        const currentMinMax = { minValue, maxValue };
        onChange({ ...currentMinMax, ...updates });
    };

    return (
        <div className="flex space-x-4">
            <div className="flex-1 flex flex-col space-y-1">
                <div className="font-semibold flex items-center uppercase">Min</div>
                <MyRange
                    size="xs"
                    step={step}
                    min={minLimit}
                    max={maxLimit}
                    disabled={disabled}
                    value={minValue}
                    onChange={(val) => handleSliderChange("minValue", val)}
                />
            </div>
            <div className="flex-1 flex flex-col space-y-1">
                <div className="font-semibold flex items-center uppercase">Max</div>
                <MyRange
                    size="xs"
                    step={step}
                    min={minLimit}
                    max={maxLimit}
                    disabled={disabled}
                    value={maxValue}
                    onChange={(val) => handleSliderChange("maxValue", val)}
                />
            </div>
        </div>
    );
};

export default MinMaxRange;
