import classNames from "classnames";
import React, { useMemo } from "react";
import { Each, HelperDropdown, RichText } from "../../../../../common/components";
import CustomFieldDisplay from "../../../../CustomFields/CustomFieldDisplay";
import ReviewHelper from "../../../components/atoms/ReviewHelper";
import NoteEditorBlock from "../../../components/organisms/NoteEditorBlock/NoteEditorBlock";

function getRequiredFlags(cycle, relationship) {
    switch (relationship) {
        case "employee":
            return cycle?.employeeFlags;
        case "manager":
            return cycle?.managerFlags;
        default:
            return [];
    }
}

const NotesAndFlagsStepTemplate = ({ values = {}, actions = {} }) => {
    const { locked, relationship, draft } = values;
    const { cycle, assessmentFields, hideStepNotes } = values;
    const { updateFlag } = actions;
    const { flags = [] } = draft || {};
    const ownFlags = useMemo(() => getRequiredFlags(cycle, relationship), [cycle, relationship]);

    const requiredFlags = useMemo(() => {
        if (!ownFlags) return [];
        return ownFlags.map((fieldId) => {
            return assessmentFields.find((field) => field.id === fieldId);
        });
    }, [ownFlags, assessmentFields]);

    const handleChangeFlagValue = (field, val) => {
        updateFlag(field, val);
    };

    return (
        <div className="flex fade h-full w-full items-stretch divide-x">
            <div className="flex items-stretch flex-1 divide-x overflow-auto">
                <div className="flex-1 overflow-auto">
                    <ReviewHelper segmentId={"notesAndFlags"} title="Required Indicator Updates" />
                    <div className="flex-1 p-6 space-y-4">
                        <Each
                            of={requiredFlags}
                            emptyStateProps={{
                                className: "flex-1 border-none flex",
                                type: "noIcon",
                                message: "No Indicators to Update",
                            }}
                            render={(field) => {
                                return (
                                    <div
                                        className="py-2 flex-1 flex justify-between items-center bg-white rounded-lg px-4 border space-x-6"
                                        key={field.id}
                                    >
                                        <div className="flex-1">
                                            <h6>{field.label}</h6>
                                        </div>
                                        <div className="flex-2 flex max-w-sm">
                                            <CustomFieldDisplay
                                                className="justify-end"
                                                disabled={locked}
                                                field={field}
                                                value={flags[field?.id]}
                                                onChange={handleChangeFlagValue}
                                            />
                                        </div>
                                        <div>
                                            <HelperDropdown size="sm" title="Field Guidance" disabled={!field?.help}>
                                                <RichText
                                                    readOnly
                                                    content={field?.help || "No Help Provided"}
                                                    removePadding
                                                />
                                            </HelperDropdown>
                                        </div>
                                    </div>
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            <div
                className={classNames("flex-1 min-w-md max-w-md px-8 py-4 bg-white overflow-scroll", {
                    hidden: hideStepNotes,
                })}
            >
                <NoteEditorBlock values={values} actions={actions} segmentId="managerAssessment" />
            </div>
        </div>
    );
};

export default NotesAndFlagsStepTemplate;
