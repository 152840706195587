import { collection, doc, limit, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";

export function useEmployeeRefs(employeeId) {
    const workspaceRef = useWorkspaceRef();

    const employeesRef = useMemo(() => {
        if (!workspaceRef) return null;
        return collection(workspaceRef, "employees");
    }, [workspaceRef]);

    const employeeRef = useMemo(() => {
        if (!employeesRef || !employeeId) return null;
        return doc(employeesRef, employeeId);
    }, [employeesRef, employeeId]);

    const notesRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "notes");
    }, [employeeRef]);

    const assessmentsRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "assessments");
    }, [employeeRef]);

    const selfAssessmentRef = useMemo(() => {
        if (!assessmentsRef) return null;
        const assessmentQuery = query(
            assessmentsRef,
            where("relationship", "==", "employee"),
            orderBy("updatedOn", "desc"),
            limit(1)
        );
        return assessmentQuery;
    }, [assessmentsRef]);

    const talentAssessmentRef = useMemo(() => {
        if (!assessmentsRef) return null;
        const assessmentQuery = query(
            assessmentsRef,
            where("relationship", "==", "manager"),
            orderBy("updatedOn", "desc"),
            limit(1)
        );
        return assessmentQuery;
    }, [assessmentsRef]);

    const objectivesRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "objectives");
    }, [employeeRef]);

    const notificationsRef = useMemo(() => {
        if (!employeeRef) return null;
        return collection(employeeRef, "notifications");
    }, [employeeRef]);

    const liveObjectivesRef = useMemo(() => {
        if (!objectivesRef) return null;
        const queryRef = query(objectivesRef, where("isComplete", "==", false));
        return queryRef;
    }, [objectivesRef]);

    return {
        employeesRef,
        employeeRef,
        selfAssessmentRef,
        talentAssessmentRef,
        objectivesRef,
        liveObjectivesRef,
        notificationsRef,
        notesRef,
        assessmentsRef,
    };
}
