import { useMemo } from "react";
import { useSelector } from "react-redux";
import LollipopChart from "../../../../components/Charts/BaseCharts/LollipopChart";
import ChartContainer from "../../../../components/Charts/ChartContainer";
import { showRatingColor } from "../../../Snapshots/utils/snapshotHelpers";

const RatingChangeChart = ({ prevData, newData, prevLabel, newLabel, diffLabel }) => {
    const traits = useSelector((state) => state.workspace.traits);
    const assessmentColors = useSelector((state) => state.config.everyoneConfig.assessmentColors);

    const handleGetColor = (val) => {
        return showRatingColor(assessmentColors, val);
    };

    const chartData = useMemo(() => {
        const comparisonData = prevData || [];
        const prevDataMap = new Map(comparisonData.map((d) => [d.id, d.rating]));
        const newDataMap = new Map(newData.map((d) => [d.id, d.rating]));

        // Get all unique IDs
        const allIds = new Set([...newDataMap.keys(), ...prevDataMap.keys()]);

        // Map to the required format
        const chartData = Array.from(allIds).map((id) => {
            const newVal = newDataMap.get(id) || 0;
            const prevVal = prevDataMap.get(id) || 0;
            const change = newVal - prevVal;
            const label = traits[id]?.traitName || "Deleted Trait";
            return {
                id,
                newVal,
                prevVal,
                change,
                label: label || id,
            };
        });

        return chartData;
    }, [newData, prevData, traits]);

    return (
        <div className="flex-1 flex flex-col overflow-x-scroll">
            <ChartContainer className="flex-1" responsiveHeight>
                <LollipopChart data={chartData} prevLabel={prevLabel} newLabel={newLabel} diffLabel={diffLabel} getColor={handleGetColor} />
            </ChartContainer>
        </div>
    );
};

export default RatingChangeChart;
