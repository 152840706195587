import { ContentState, EditorState, convertFromRaw } from "draft-js";
import { addBlankTargetToLinks } from "./addBlankTargetToLinks";

export const createEditorState = (content) => {
    const contentType = typeof content;

    function prepContent(parsed) {
        const preppedContent = addBlankTargetToLinks(parsed);
        return convertFromRaw(preppedContent);
    }

    switch (contentType) {
        case "string":
            try {
                const parsedToJSON = JSON.parse(content);
                const preppedContent = prepContent(parsedToJSON);
                return EditorState.createWithContent(preppedContent);
            } catch (e) {
                return EditorState.createWithContent(ContentState.createFromText(content));
            }
        case "object":
            try {
                const preppedContent = prepContent(content);
                return EditorState.createWithContent(preppedContent);
            } catch (e) {
                return EditorState.createEmpty();
            }
        default:
            return EditorState.createEmpty();
    }
};
