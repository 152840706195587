import { arrayUnion, deleteDoc, serverTimestamp } from "firebase/firestore";
import { batchSet, createBatch, getDocRef } from "../../../firebase/firebaseActions";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";
import { useSelector } from "react-redux";

export function useObjectiveActions(employeeId) {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const { objectivesRef } = useEmployeeRefs(employeeId);

    function saveObjective(objective, iterationId = null, batch = createBatch()) {
        let { id, ...update } = objective;
        if (!update.createdOn) update.createdOn = serverTimestamp();
        if (update.isComplete && !update.completedInIterationId && !!iterationId) {
            update.completedInIterationId = iterationId;
            update.completedOn = serverTimestamp();
        }
        if (iterationId) {
            update.includedInIterationIds = arrayUnion(iterationId);
        }
        update.workspaceId = workspaceId;
        const objectiveRef = getDocRef(objectivesRef, id);
        batch = batchSet(objectiveRef, update, batch);
        return batch;
    }

    async function removeObjective(objective) {
        try {
            const objectiveRef = getDocRef(objectivesRef, objective.id);
            return await deleteDoc(objectiveRef);
        } catch (error) {
            console.error("Failed to remove objective", error);
            return null;
        }
    }

    return { saveObjective, removeObjective };
}
