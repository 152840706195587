import { useMemo } from "react";
import { getCollectionGroupRef } from "../../../firebase/firebaseActions";
import { useEmployeeRelationships } from "../../Employees/hooks/useEmployeeRelationships";
import { query, where } from "firebase/firestore";
import { useSelector } from "react-redux";

export function useCascadeRefs(employeeId) {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const { getAllEmployeeIdsAbove, getManager } = useEmployeeRelationships();
    const collectionGroupRef = useMemo(() => getCollectionGroupRef("objectives"), []);

    const managerId = useMemo(() => {
        if (!employeeId) return null;
        const manager = getManager(employeeId);
        return manager?.id;
    }, [employeeId, getManager]);

    const allEmployeeIdsAbove = useMemo(() => {
        if (!employeeId) return null;
        return getAllEmployeeIdsAbove(employeeId);
    }, [employeeId, getAllEmployeeIdsAbove]);

    const teamCascadedRef = useMemo(() => {
        if (!managerId || !collectionGroupRef) return null;
        return query(
            collectionGroupRef,
            where("workspaceId", "==", workspaceId),
            where("ownerEmployeeId", "==", managerId),
            where("cascadeType", "==", "team"),
            where("isComplete", "==", false)
        );
    }, [workspaceId, managerId, collectionGroupRef]);

    const branchCascadeRef = useMemo(() => {
        if (!allEmployeeIdsAbove || !collectionGroupRef) return null;
        if (allEmployeeIdsAbove.length === 0) return null;
        return query(
            collectionGroupRef,
            where("workspaceId", "==", workspaceId),
            where("ownerEmployeeId", "in", allEmployeeIdsAbove),
            where("cascadeType", "==", "branch"),
            where("isComplete", "==", false)
        );
    }, [workspaceId, allEmployeeIdsAbove, collectionGroupRef]);

    return { teamCascadedRef, branchCascadeRef };
}
