import { doc, getDoc } from "firebase/firestore";
import { useCallback, useMemo, useRef } from "react";
import { readTracker } from "../common/utils/activityTracker";
import { getSubCollectionRef } from "../firebase/firebaseActions";
import { useWorkspaceRef } from "../firebase/hooks/useWorkspaceRef";
import { checkIfCollectionOrQueryRef } from "../firebase/utils/identifyFirestoreRef";

export function useFetched(fetchFrom) {
    const workspaceRef = useWorkspaceRef();

    const collectionRef = useMemo(() => {
        const isValidRef = checkIfCollectionOrQueryRef(fetchFrom);
        if (!isValidRef) {
            // Use workspaceRef when passed a collection name
            if (!workspaceRef) return null;
            return getSubCollectionRef(workspaceRef, fetchFrom);
        } else {
            return fetchFrom;
        }
    }, [workspaceRef, fetchFrom]);

    const attemptStatus = useRef("idle");
    const items = useRef([]);

    const fetchItem = useCallback(
        async (id) => {
            if (!id || !collectionRef) return;
            if (attemptStatus.current === "fetching") return;
            attemptStatus.current = "fetching";
            const currentItems = items.current;
            let alreadyFetched = currentItems.find((item) => item.id === id);
            if (alreadyFetched) {
                attemptStatus.current = "idle";
                return alreadyFetched;
            }
            readTracker(collectionRef);
            const docRef = doc(collectionRef, id);
            const fetchedDoc = await getDoc(docRef);
            if (fetchedDoc.exists()) {
                const fetchedData = { id: fetchedDoc.id, ...fetchedDoc.data() };
                const newItems = [...currentItems, fetchedData];
                items.current = newItems;
                attemptStatus.current = "idle";
                return fetchedData;
            } else {
                attemptStatus.current = "idle";
                return null;
            }
        },
        [collectionRef]
    );

    const clearItem = useCallback((id) => {
        items.current = items.current.filter((item) => item.id !== id);
    }, []);

    return [fetchItem, items, clearItem];
}
