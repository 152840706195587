import { useState } from "react";
import MyButton from "../../../../components/MyComponents/Button";

// Higher-Order Component
const withSubmit = (WrappedComponent) => {
    return ({ onClick, ...props }) => {
        const [isSubmitting, setIsSubmitting] = useState(false);

        const handleClick = async () => {
            setIsSubmitting(true);
            try {
                // Ensure onClick is a function before calling it
                if (typeof onClick === 'function') {
                    await onClick();
                }
            } catch (error) {
                console.error('Error during submission:', error);
            } finally {
                setIsSubmitting(false);
            }
        };

        const disabled = isSubmitting || props.disabled;

        return <WrappedComponent {...props} onClick={handleClick} loading={isSubmitting} disabled={disabled} />;
    };
};

const Button = (props) => {
    return <MyButton {...props} />;
};

export const SubmitButton = withSubmit(Button);

export default Button;
