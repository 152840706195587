import classNames from "classnames";
import sharpLogo from "../../assets/img/logo/logo-sharp.png";
import { ALT_TEXT } from "../../constants";

const UserMessage = ({ title, message, inset = 0 }) => {
    return (
        <div className={classNames("fixed flex justify-center items-center", `inset-${inset}`)}>
            <div className="max-w-5xl flex justify-center border-primary border-2">
                <div className="flex-1 max-w-sm">
                    <img src={sharpLogo} alt={ALT_TEXT.LOGO} />
                </div>
                <div className="flex-1 max-w-sm px-8 text-center flex items-center flex-col justify-center">
                    <h1 className="text-primary mb-2">{title}</h1>
                    <p className="px-3">{message}</p>
                </div>
            </div>
        </div>
    );
};

export default UserMessage;
