import PropTypes from "prop-types";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import MyEmptyState from "../../../components/MyComponents/EmptyState";
import { useSnapshots } from "../../Snapshots/hooks/useSnapshots";
import TraitPolarAreaChart from "../../Traits/containers/TraitPolarAreaChart";

const EmployeeCard = ({ employee, onView }) => {
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const { getSnapshot } = useSnapshots();
    const snapshot = getSnapshot(employee?.id);
    const { ratings = {} } = snapshot || {};
    const isSelf = employee?.id === userEmployeeId;

    const handleViewEmployee = () => {
        onView(employee?.roleId, employee?.id);
    };

    if (!employee) return <MyEmptyState />;

    return (
        <button
            onClick={handleViewEmployee}
            className="h-full w-full flex justify-between flex-col items-stretch card rounded-lg"
        >
            <TraitPolarAreaChart ratings={isSelf ? {} : ratings} />
            <div className="font-medium py-4 text-center">{employee.displayName}</div>
        </button>
    );
};

EmployeeCard.propTypes = {
    employeeId: PropTypes.string,
    cardBorder: PropTypes.bool,
    onView: PropTypes.func,
};

EmployeeCard.defaultProps = {
    onView: () => {},
};

export default memo(EmployeeCard);
