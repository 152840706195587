import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import MyButton from "../MyComponents/Button";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../MyComponents/Modal";
import FormStep from "./FormStep";
import StepperProgress from "./StepperProgress";

const FormStepper = (props) => {
    const {
        size,
        height,
        width,
        className,
        validationSchemas,
        invalidOveride,
        submitNowOverride,
        hideStepProgress,
        values,
        isOpen,
        onSubmit,
    } = props;
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [isValid, setIsValid] = useState(false);
    const Steps = React.Children.toArray(props.children);
    const CurrentStep = Steps && Steps[activeStepIndex];
    const isFirstStep = activeStepIndex === 0;
    const isLastStep = activeStepIndex === Steps.length - 1;
    const buttonsDisabled = props.buttonsDisabled || props.isWaiting;

    useEffect(() => {
        const validationSchemaBase = validationSchemas[activeStepIndex];
        const validationSchema = Yup.object().shape(validationSchemaBase);
        if (validationSchema) {
            validationSchema.isValid(values).then((valid) => {
                setIsValid(valid && !invalidOveride);
            });
        } else {
            setIsValid(!invalidOveride);
        }
    }, [values, invalidOveride, activeStepIndex, validationSchemas]);

    if (!isOpen) return null;

    const handleNext = () => {
        const steps = React.Children.toArray(props.children);
        setActiveStepIndex((prevIdx) => Math.min(prevIdx + 1, steps.length - 1));
    };

    const handlePrevious = () => {
        setActiveStepIndex((prevIdx) => Math.max(0, prevIdx - 1));
    };

    const handleSubmit = () => {
        onSubmit();
    };

    return (
        <MyModal size={size} height={height} width={width} isOpen={isOpen} toggle={props.toggle} className={className}>
            <div className="h-full flex flex-col bg-base-100 align-stretch overflow-hidden">
                <MyModalHeader onToggle={props.toggle}>{props.headerText}</MyModalHeader>
                <MyModalBody className="flex-1 flex flex-col overflow-scroll">
                    <StepperProgress steps={Steps} currentStep={activeStepIndex} hideStepProgress={hideStepProgress} />
                    {props.isWaiting ? (
                        <LoadingIndicator />
                    ) : (
                        <div className={classNames("flex-1")}>{React.cloneElement(CurrentStep)}</div>
                    )}
                </MyModalBody>
                <MyModalFooter>
                    {!isFirstStep && (
                        <MyButton size="sm" disabled={buttonsDisabled} onClick={handlePrevious} variant="outline">
                            Previous
                        </MyButton>
                    )}
                    {!isLastStep && !submitNowOverride && (
                        <MyButton
                            size="sm"
                            disabled={!isValid || buttonsDisabled}
                            variant="outline"
                            onClick={handleNext}
                        >
                            Next
                        </MyButton>
                    )}
                    {(isLastStep || submitNowOverride) && (
                        <MyButton
                            size="sm"
                            type="submit"
                            disabled={(!isValid || buttonsDisabled) && !invalidOveride}
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {props.doneLabel}
                        </MyButton>
                    )}
                </MyModalFooter>
            </div>
        </MyModal>
    );
};

FormStepper.propTypes = {
    isOpen: PropTypes.bool,
    isWaiting: PropTypes.bool,
    buttonsDisabled: PropTypes.bool,
    values: PropTypes.object,
    toggle: PropTypes.func,
    onClosed: PropTypes.func,
    className: PropTypes.string,
    centered: PropTypes.bool,
    backdrop: PropTypes.string,
    updatingId: PropTypes.string,
    size: PropTypes.string,
    stepInfo: PropTypes.object,
    hideStepProgress: PropTypes.bool,
    invalidOveride: PropTypes.bool,
    submitNowOverride: PropTypes.bool,
    sidePanel: PropTypes.elementType,
    validationSchemas: PropTypes.array,
    doneLabel: PropTypes.string,
};

FormStepper.defaultProps = {
    isOpen: false,
    values: {},
    onClosed: () => {},
    className: "",
    centered: true,
    backdrop: "static",
    stepInfo: {},
    hideStepProgress: false,
    validationSchemas: [],
    size: "lg",
    doneLabel: "Done",
    children: function (props, propName, componentName) {
        const prop = props[propName];
        let error = null;
        React.Children.forEach(prop, function (child) {
            if (!(child.type.prototype instanceof FormStep)) {
                error = new Error("`" + componentName + "` children should be of type `FormStep`.");
            }
        });
        return error;
    },
};

export default FormStepper;
