import { deleteField } from "firebase/firestore";
import { useSelector } from "react-redux";
import { useConfig } from "../../Config/hooks/useConfig";
import { writeEveryoneConfig, writeOwnConfig } from "../../Config/utils/configDb";

export function useTalentMapActions() {
    const { id: uid } = useSelector((state) => state.user);
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const sharedTalentMaps = useSelector((state) => state.config.everyoneConfig.talentMaps);
    const ownTalentMaps = useSelector((state) => state.config.ownConfig.talentMaps);
    const { setConfigValue } = useConfig();

    async function updateTalentMap(fullMap) {
        const { id, shared, ...talentMapToSave } = fullMap;
        const talentMapsToUpdate = shared ? sharedTalentMaps : ownTalentMaps;

        let updateTalentMaps = {
            ...talentMapsToUpdate,
            [id]: talentMapToSave,
        };
        const configUpdate = {
            talentMaps: updateTalentMaps,
        };
        let batch;
        if (shared) {
            batch = writeEveryoneConfig(workspaceId, configUpdate);
        } else {
            batch = writeOwnConfig(uid, workspaceId, configUpdate);
        }
        try {
            await batch.commit();
            setConfigValue("activeTalentMapId", id);
        } catch (error) {
            console.error("Error updating talent map", error);
        }
    }

    async function removeTalentMap(talentMapId, shared) {
        const talentMapsToUpdate = shared ? sharedTalentMaps : ownTalentMaps;
        let newTalentMaps = { ...talentMapsToUpdate };
        newTalentMaps[talentMapId] = deleteField();
        const configUpdate = {
            talentMaps: newTalentMaps,
        };
        let batch;
        if (shared) {
            batch = writeEveryoneConfig(workspaceId, configUpdate);
        } else {
            batch = writeOwnConfig(uid, workspaceId, configUpdate);
        }
        try {
            await batch.commit();
            setConfigValue("activeTalentMapId", null);
        } catch (error) {
            console.error("Error removing talent map", error);
        }
    }

    return { updateTalentMap, removeTalentMap };
}
