import { createSlice } from "@reduxjs/toolkit";

export const UPDATE_EMPLOYEE_SNAPSHOT = "snapshots/updateEmployeeSnapshot";
export const SET_PENDING_SNAPSHOT = "snapshots/setPendingSnapshot";
export const ADD_EMPLOYEE_SNAPSHOTS = "snapshots/addEmployeeSnapshots";

const initialState = {
    employeeSnapshots: {},
    talentBoardScores: {},
    pendingSnapshots: [],
    snapshotsReady: false,
};

const slice = createSlice({
    name: "snapshots",
    initialState,
    reducers: {
        resetSnapshots: () => initialState,
        removeEmployeeSnapshots(state, action) {
            const liveIds = action.payload;
            let newSnapshots = { ...state.employeeSnapshots };
            Object.keys(newSnapshots).forEach((employeeId) => {
                if (!liveIds.includes(employeeId)) {
                    delete newSnapshots[employeeId];
                }
            });
            state.employeeSnapshots = newSnapshots;
        },
        setTalentBoardScores(state, action) {
            state.talentBoardScores = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(ADD_EMPLOYEE_SNAPSHOTS, (state, action) => {
            state.employeeSnapshots = { ...state.employeeSnapshots, ...action.payload };
            state.snapshotsReady = true;
            state.pendingSnapshots = [];
        });
        builder.addCase(UPDATE_EMPLOYEE_SNAPSHOT, (state, action) => {
            const [empId, snapshot] = action.payload;
            const currentSnapshot = state.employeeSnapshots[empId] || {};
            state.employeeSnapshots[empId] = { ...currentSnapshot, ...snapshot };
            if (state.pendingSnapshots.includes(empId)) {
                state.pendingSnapshots = state.pendingSnapshots.filter((id) => id !== empId);
            }
        });
        builder.addCase(SET_PENDING_SNAPSHOT, (state, action) => {
            const employeeId = action.payload;
            state.pendingSnapshots = [...state.pendingSnapshots, employeeId];
        });
    },
});

const { reducer, actions } = slice;

export const { resetSnapshots, removeEmployeeSnapshots, setTalentBoardScores } = actions;

export default reducer;
