import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEmployees } from "../../../../../../features/Employees/hooks/useEmployees";
import { useSelectOrgNodes } from "../../../../../../features/Organisation/hooks/useSelectOrgNodes";
import ReviewHistoryTemplate from "../../../../../../features/Reviews/components/templates/ReviewHistoryTemplate";
import { getCollectionGroupRef } from "../../../../../../firebase/firebaseActions";
import { usePaginatedFetched } from "../../../../../../hooks/usePaginatedFetched";
import { useOrgNodeContext } from "../../OrgNodePage";

function useReviewQuery(employeeId, isSelf) {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    return useMemo(() => {
        const baseQuery = [
            ["workspaceId", "==", workspaceId],
            ["isComplete", "==", true],
            ["revieweeId", "==", employeeId],
        ];

        // When viewing own history, exclude reviews you didn't participate in
        if (isSelf) {
            baseQuery.push(["completedIds", "array-contains", employeeId]);
        }
        return baseQuery;
    }, [employeeId, workspaceId, isSelf]);
}

const ReviewHistoryPage = () => {
    const navigate = useNavigate();
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const {getEmployee} = useEmployees();
    const { isSelf, employeeId } = useOrgNodeContext();
    const { getEmployeePath } = useSelectOrgNodes();
    const reviewCollectionGroupRef = getCollectionGroupRef("reviews");
    const whereConditions = useReviewQuery(employeeId, isSelf);
    const [pastReviews, fetchMore, hasMore, fetchStatus] = usePaginatedFetched(reviewCollectionGroupRef, whereConditions); // eslint-disable-line no-unused-vars

    const handleGetEmployee = (employeeId) => {
        return getEmployee(employeeId);
    };

    const handleReviewClick = (review, reviewer) => {
        const { cycleId, iterationId, revieweeId } = review;
        const { id: reviewerId } = reviewer;
        const employeePath = getEmployeePath(revieweeId);
        if (reviewerId === userEmployeeId) {
            const reviewPath = `/${employeePath}/reviews/${cycleId}/${iterationId}/${reviewerId}`;
            navigate(reviewPath);
        }
    };

    return (
        <div className="page page-padding">
            <ReviewHistoryTemplate
                userEmployeeId={userEmployeeId}
                pastReviews={pastReviews}
                fetchStatus={fetchStatus}
                hasMore={hasMore}
                fetchMore={fetchMore}
                getEmployee={handleGetEmployee}
                onReviewClick={handleReviewClick}
            />
        </div>
    );
};

export default ReviewHistoryPage;
