import React, { useMemo } from "react";
import classNames from "classnames";
import { AiOutlineCloudDownload as DownloadIcon } from "react-icons/ai";
import { getUniqueId } from "../../../utils/getUniqueId";
import { HelperDropdown } from "../../";

const PageSection = ({ title, largeSection, icon, children, help, className, titleClassName, hidden, onExport }) => {
    const sectionId = useMemo(() => getUniqueId(), []);
    if (hidden) return null;

    const renderHelp = () => {
        if (!help) return null;
        // Convert help to array if it is a string
        if (typeof help === "string") {
            help = [help];
        }
        return (
            <HelperDropdown title={title} color="info" size="xs">
                {help.map((textBlock, i) => (
                    <p key={`${sectionId}-${i}`}>{textBlock}</p>
                ))}
            </HelperDropdown>
        );
    };

    const renderExport = () => {
        if (!onExport) return null;
        return (
            <button className="btn btn-circle btn-sm" onClick={onExport}>
                <DownloadIcon />
            </button>
        );
    };

    const Icon = icon && icon;
    const titleContainerClasses = largeSection && "h-fit border-b pb-2";
    const titleClasses = largeSection ? "text-lg items-center" : "items-center label";

    return (
        <div className={classNames(`section-${sectionId}`, className)}>
            <div className={classNames("flex w-full justify-between ", titleContainerClasses)}>
                <div className={classNames(titleClasses, titleClassName)}>
                    <div className="flex space-x-2">
                        {Icon && <Icon />}
                        <h5>{title}</h5>
                    </div>
                </div>
                <div className="flex items-center">
                    {renderHelp()}
                    {renderExport()}
                </div>
            </div>
            {children}
        </div>
    );
};

export default PageSection;
