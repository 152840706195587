import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { removeUndefined } from "../../../common/utils/objectUtils";
import { FIRST_BUSINESS_UNIT_ID } from "shared";

export const ADD_ROLE = "businessUnits/addRole";
export const MOVE_ROLE = "businessUnits/moveRole";
export const REMOVE_ROLE = "businessUnits/removeRole";
export const VACATE_ROLE = "businessUnits/vacateRole";
export const FILL_ROLE = "businessUnits/fillRole";

function createBoardLinks(employees, roles, boardMap) {
    function extractRoleAndLevel(roleId) {
        const roleOnly = roleId.slice(0, 7);
        if (roleId.length === 9 && roleId[7] === "-") {
            // Role has been assigned a board level; return level and role
            return [roleId[8], roleOnly];
        } else {
            // Role hasn't been assigned board level; Return the Role Level, or 1 if not found
            const role = roles[roleId];
            const roleLevel = role?.level || 1;
            return [roleLevel, roleOnly];
        }
    }

    function updateRole(roleId, level, boardId) {
        const role = roles[roleId];
        if (role) {
            const linkedTalentBoardIds = role.linkedTalentBoardIds || [];
            const boardLevels = role.boardLevels || {};
            if (!linkedTalentBoardIds.includes(boardId)) {
                linkedTalentBoardIds.push(boardId);
            }
            boardLevels[boardId] = level;
            roles[roleId] = { ...role, linkedTalentBoardIds, boardLevels };
            const incumbentId = role.incumbentId;
            if (incumbentId) {
                const incumbent = employees[incumbentId] || { id: incumbentId };
                incumbent.linkedTalentBoardIds = linkedTalentBoardIds;
                incumbent.boardLevels = boardLevels;
                employees[incumbentId] = incumbent;
            }
        }
    }

    Object.entries(boardMap).forEach(([boardId, linkedRoleIds]) => {
        const rolesAndLevels = linkedRoleIds.map(extractRoleAndLevel);
        for (let [level, roleId] of rolesAndLevels) {
            updateRole(roleId, level, boardId);
        }
    });
}

const initialState = {
    businessUnitId: FIRST_BUSINESS_UNIT_ID,
    externalUnitDocs: {},
    boardMap: {},
    parentMap: {},
    employees: {},
    roles: {},
    employeesReady: false,
    rolesReady: false,
    boardsMapped: false,
    orgReady: false,
    pendingRoleChanges: [],
    pendingEmployeeChanges: [],
};

const slice = createSlice({
    name: "businessUnit",
    initialState,
    reducers: {
        resetBusinessUnit() {
            return initialState;
        },
        setBusinessUnitId(state, action) {
            Object.assign(state, initialState);
            state.businessUnitId = action.payload;
        },
        setEmployees(state, action) {
            const { employees, liveEmployeeIds } = action.payload;
            // Set new employees data, but don't overwrite dynamic fields

            let updatedPending = [...state.pendingEmployeeChanges];
            Object.values(employees).forEach((employee) => {
                const currentEmployee = state.employees[employee.id] || {};
                const { roleId, linkedTalentBoardIds, isUser } = currentEmployee;
                if (roleId) {
                    employee.isUser = isUser || false;
                    employee.roleId = roleId;
                    employee.linkedTalentBoardIds = linkedTalentBoardIds || [];
                }
                state.employees[employee.id] = employee;

                // Remove the employee from the pending list
                const pendingIndex = updatedPending.indexOf(employee.id);
                if (pendingIndex > -1) {
                    updatedPending.splice(pendingIndex, 1);
                }
            });

            // Remove employees that are no longer live
            const newLiveEmployeeIds = new Set(liveEmployeeIds);
            Object.keys(state.employees).forEach((employeeId) => {
                if (!newLiveEmployeeIds.has(employeeId)) {
                    delete state.employees[employeeId];
                }
            });
            state.pendingEmployeeChanges = updatedPending;
            state.employeesReady = true;
            state.orgReady = state.rolesReady && state.boardsMapped;
        },
        setRoles(state, action) {
            const { roles, liveRoleIds } = action.payload;
            let newRoleState = cloneDeep(state.roles);
            let updatedPending = [...state.pendingRoleChanges];

            Object.values(roles).forEach((role) => {
                // Add the role to state
                const currentRole = newRoleState[role.id] || {};
                const { linkedTalentBoardIds, parentRoleId } = currentRole;
                role.parentRoleId = parentRoleId;
                role.linkedTalentBoardIds = linkedTalentBoardIds;
                removeUndefined(role);
                newRoleState[role.id] = role;

                // Add the roleId to the incumbent's employee record
                const { incumbentId } = role;
                if (incumbentId) {
                    const update = { id: incumbentId, roleId: role.id };
                    state.employees[incumbentId] = { ...state.employees[incumbentId], ...update };
                }

                // Remove the role from the pending list
                const pendingIndex = updatedPending.indexOf(role.id);
                if (pendingIndex > -1) {
                    updatedPending.splice(pendingIndex, 1);
                }
            });

            // Remove roles that are no longer live
            const newLiveRoleIds = new Set(liveRoleIds);
            Object.keys(newRoleState).forEach((roleId) => {
                if (!newLiveRoleIds.has(roleId)) {
                    delete newRoleState[roleId];
                }
            });
            Object.assign(state.roles, newRoleState);
            state.pendingRoleChanges = updatedPending;
            state.rolesReady = true;
            state.orgReady = state.employeesReady && state.boardsMapped;
        },
        setPendingEmployeeChange(state, action) {
            const employeeId = action.payload;
            state.pendingEmployeeChanges = [...state.pendingEmployeeChanges, employeeId];
        },
        setPendingRoleChange(state, action) {
            const roleId = action.payload;
            state.pendingRoleChanges = [...state.pendingRoleChanges, roleId];
        },
        setParentMap(state, action) {
            const parentMap = action.payload || {};
            Object.entries(parentMap).forEach(([roleId, parentId]) => {
                const role = state.roles[roleId];
                if (parentId) {
                    role.parentRoleId = parentId;
                    state.roles[roleId] = role;
                }
            });
            state.parentMap = parentMap;
        },
        setBoardMap(state, action) {
            state.boardMap = action.payload || {};
            createBoardLinks(state.employees, state.roles, state.boardMap);
            state.boardsMapped = true;
            state.orgReady = state.employeesReady && state.rolesReady;
        },
        deleteRoleFromParentMap(state, action) {
            const roleId = action.payload;
            const newParentMap = { ...state.parentMap };
            delete newParentMap[roleId];
            state.parentMap = newParentMap;
        },
    },
    extraReducers(builder) {
        builder.addCase(ADD_ROLE, (state, action) => {
            const [parentMap, role, employee] = action.payload;
            const addEmployee = {
                ...employee,
                roleId: role.id,
            };
            state.employees = { ...state.employees, [employee.id]: addEmployee };
            state.roles = { ...state.roles, [role.id]: role };
            state.parentMap = { ...state.parentMap, ...parentMap };
        });
        builder.addCase(REMOVE_ROLE, (state, action) => {
            const roleId = action.payload;
            delete state.parentMap[roleId];
            delete state.roles[roleId];
        });
        builder.addCase(VACATE_ROLE, (state, action) => {
            const roleId = action.payload;
            const currentRole = state.roles[roleId];
            if (currentRole) {
                const incumbentId = currentRole.incumbentId;
                const incumbent = state.employees[incumbentId];
                if (incumbent) {
                    const updatedIncumbent = { ...incumbent, roleId: null };
                    state.employees = { ...state.employees, [incumbentId]: updatedIncumbent };
                }
                const updatedRole = { ...currentRole, incumbentId: null };
                state.roles = { ...state.roles, [roleId]: updatedRole };
            }
        });
        builder.addCase(FILL_ROLE, (state, action) => {
            const [roleId, employeeId] = action.payload;
            const role = state.roles[roleId];
            const employee = state.employees[employeeId];
            if (role && employee) {
                role.incumbentId = employeeId;
                employee.roleId = roleId;
                state.roles = { ...state.roles, [roleId]: role };
                state.employees = { ...state.employees, [employeeId]: employee };
            }
        });
        builder.addCase(MOVE_ROLE, (state, action) => {
            const [roleId, newParentId] = action.payload;
            const role = state.roles[roleId];
            if (role) {
                role.parentRoleId = newParentId;
                state.parentMap[roleId] = newParentId;
                state.roles = { ...state.roles, [roleId]: role };
            }
        });
    },
});

const { actions, reducer } = slice;

export const {
    resetBusinessUnit,
    setBusinessUnitId,
    setUnitLoadStatus,
    setEmployees,
    setRoles,
    setPendingEmployeeChange,
    setPendingRoleChange,
    removeEmployeeRole,
    setParentMap,
    setBoardMap,
    deleteRoleFromParentMap,
} = actions;

export default reducer;
