import { createSelector } from "@reduxjs/toolkit";

const getRootRoleId = createSelector(
    (state) => state.workspace.rootRoleId,
    (rootRoleId) => rootRoleId
);

const getWorkspaceId = createSelector(
    (state) => state.workspace.workspaceId,
    (workspaceId) => workspaceId
);

const getSelectedTraitId = createSelector(
    (state) => state.app.selectedTraitId,
    (selectedTraitId) => selectedTraitId
);

const getSelectedTalentAreaId = createSelector(
    (state) => state.app.selectedTalentAreaId,
    (selectedTalentAreaId) => selectedTalentAreaId
);

const getSelectedActionId = createSelector(
    (state) => state.app.selectedActionId,
    (selectedActionId) => selectedActionId
);

const getSelectedTrait = createSelector(
    [(state) => state.workspace.traits, getSelectedTraitId],
    (traits, selectedTraitId) => traits[selectedTraitId]
);

const getSelectedTalentArea = createSelector(
    [(state) => state.workspace.talentAreas, getSelectedTalentAreaId],
    (talentAreas, selectedTalentAreaId) => talentAreas[selectedTalentAreaId]
);

const getSelectedAction = createSelector(
    [(state) => state.workspace.actions, getSelectedActionId],
    (actions, selectedActionId) => actions[selectedActionId]
);

const getTalentAreas = createSelector(
    (state) => state.workspace.talentAreas,
    (talentAreas) => talentAreas
);

const getTraits = createSelector(
    (state) => state.workspace.traits,
    (traits) => traits
);

const getTalentBoards = createSelector(
    (state) => state.workspace.talentBoards,
    (talentBoards) => talentBoards
);

const getActions = createSelector(
    (state) => state.workspace.actions,
    (actions) => actions
);

const getTalent = createSelector(
    (state) => {
        const { traits, talentAreas, talentBoards, actions } = state.workspace;
        return { traits, talentAreas, talentBoards, actions };
    },
    (talent) => talent
);

const getTraitMap = createSelector(
    (state) => state.workspace.traitMap,
    (traitMap) => traitMap
);

const getTrait = createSelector(
    (state, traitId) => state.workspace.traits[traitId],
    (trait) => trait
);

const getTalentArea = createSelector(
    (state, talentAreaId) => state.workspace.talentAreas[talentAreaId],
    (talentArea) => talentArea
);

const getTalentBoard = createSelector(
    (state, talentBoardId) => state.workspace.talentBoards[talentBoardId],
    (talentBoard) => talentBoard
);

const getAction = createSelector(
    (state, actionId) => state.workspace.actions[actionId],
    (action) => action
);

const getActiveTalentAreaIds = createSelector([getTalentAreas], (talentAreas) => {
    return Object.entries(talentAreas)
        .filter(([_, area]) => {
            const linkedTraitIds = area.linkedTraitIds || [];
            return linkedTraitIds.length > 0;
        })
        .map(([id, _]) => id);
});

export {
    getAction,
    getActions,
    getActiveTalentAreaIds,
    getRootRoleId,
    getSelectedAction,
    getSelectedTalentArea,
    getSelectedTrait,
    getTalent,
    getTalentArea,
    getTalentAreas,
    getTalentBoard,
    getTalentBoards,
    getTrait,
    getTraitMap,
    getTraits,
    getWorkspaceId,
};
