import classNames from "classnames";
import * as d3 from "d3";
import { darken, lighten } from "polished";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";

const PolarInputChart = ({
    chartId,
    data,
    max = 100,
    onItemClick,
    height,
    width,
    onHoverItem,
    selectedId,
    disableTooltip,
}) => {
    const svgRef = useRef();
    const radius = Math.min(width, height) / 2;
    const [hoveredId, setHoveredId] = useState(null);
    const [provisionalData, setProvisionalData] = useState(data);

    useEffect(() => {
        if (hoveredId) {
            // Ensure smooth transition between traits
            const timeout = setTimeout(() => {
                onHoverItem(hoveredId);
            }, 150);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [hoveredId, onHoverItem]);

    useEffect(() => {
        const getFillColor = (data, i, ignoreSelected) => {
            if (!ignoreSelected && selectedId === data[i].id) {
                return darken(0.1, data[i].color);
            }
            if (data[i].color) {
                return data[i].color;
            } else {
                return i % 2 === 0 ? "grey" : "darkgrey";
            }
        };

        const handleMouseOver = (event, segment) => {
            if (segment?.id) setHoveredId(segment.id);
        };

        const handleMouseOut = (id, other) => {
            setHoveredId();
        };

        const handleBackgroundClick = (event, segment) => {
            const [x, y] = d3.pointer(event);
            const distanceFromCenter = Math.sqrt(x * x + y * y);
            const clickedRating = Math.floor((distanceFromCenter / radius) * 100);
            let rating = Math.max(10, Math.min(100, clickedRating));
            rating = Math.ceil(rating / 10) * 10;
            onItemClick(segment?.id, rating);
        };

        const getBackgroundFillColor = (data, i) => {
            const baseColor = getFillColor(data, i, true);
            return lighten(0.2, baseColor);
        };

        if (!isNaN(width) && !isNaN(height)) {
            const svg = d3.select(svgRef.current);
            const chartWidth = width;
            const chartHeight = height;
            const radius = Math.min(chartWidth, chartHeight) / 2;
            const centerX = chartWidth / 2;
            const centerY = chartHeight / 2;

            const angleScale = d3
                .scaleLinear()
                .range([0, 2 * Math.PI])
                .domain([0, data.length]);

            const radiusScale = d3.scaleLinear().range([0, radius]).domain([0, max]);

            const pie = d3
                .pie()
                .value((d) => d.valueOf)
                .sort(null);

            const arc = d3
                .arc()
                .innerRadius(0)
                .outerRadius((d) => radiusScale(d.data.value))
                .startAngle((d) => angleScale(d.index))
                .endAngle((d) => angleScale(d.index + 1));

            const backgroundArc = d3
                .arc()
                .innerRadius(0)
                .outerRadius(radius)
                .startAngle((d, i) => angleScale(i))
                .endAngle((d, i) => angleScale(i + 1));

            const gridLines = d3.range(0, radius, radius / 10);

            svg.selectAll("*").remove();

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("path.background")
                .data(data)
                .join("path")
                .attr("class", classNames("background", { clickable: !!selectedId }))
                .attr("d", backgroundArc)
                .attr("fill", (d, i) => getBackgroundFillColor(data, i))
                .style("opacity", 0.3)
                .on("click", handleBackgroundClick)
                .on("mouseover", function (event, d) {
                    handleMouseOver(event, d);
                })
                .on("mouseout", function (d, i) {
                    handleMouseOut(i?.id);
                });

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("path")
                .data(pie(data))
                .join("path")
                .attr("d", arc)
                .attr("fill", (d, i) => getFillColor(data, i))
                .attr("stroke", "white")
                .style("stroke-width", "2px")
                .attr("data-tip", (d) => `${d.data.label} (${d.data.value})`)
                .attr("data-for", chartId)
                .attr("class", "focus:outline-none clickable")
                .on("click", function (event, d, i) {
                    handleBackgroundClick(event, d.data, i);
                })
                .on("mouseover", function (event, d) {
                    handleMouseOver(event, d.data);
                })
                .on("mouseout", function (e, d) {
                    handleMouseOut(d?.data?.id);
                });

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("circle")
                .data(gridLines)
                .join("circle")
                .attr("cx", 0)
                .attr("cy", 0)
                .attr("r", (d) => d)
                .attr("fill", "none")
                .attr("stroke", "#a3abb3")
                .style("stroke-dasharray", "1,1")
                .style("opacity", 0.3);

            svg.append("g")
                .attr("transform", `translate(${centerX}, ${centerY})`)
                .selectAll("line")
                .data(data)
                .join("line")
                .attr("x1", 0)
                .attr("y1", 0)
                .attr("x2", (d, i) => radius * Math.sin(angleScale(i)))
                .attr("y2", (d, i) => -radius * Math.cos(angleScale(i)))
                .attr("stroke", "#a3abb3")
                .style("stroke-dasharray", "2,2")
                .style("opacity", 0.3);

            // Add a ring around the chart
            svg.append("circle")
                .attr("cx", centerX)
                .attr("cy", centerY)
                .attr("r", radius - 2)
                .attr("fill", "none")
                .attr("stroke", "white") // You can change the color as you prefer
                .attr("stroke-width", 4); // You can change the width of the ring

            !disableTooltip && ReactTooltip.rebuild();
        }
    }, [
        chartId,
        radius,
        data,
        selectedId,
        max,
        width,
        height,
        disableTooltip,
        onItemClick,
        provisionalData,
        setProvisionalData,
        setHoveredId,
    ]);

    if (isNaN(width) || isNaN(height)) return null;

    return (
        <div className="flex flex-col flex-1 justify-center items-center">
            {/*{!disableTooltip && <ReactTooltip id={chartId} place="top" effect="solid" />}*/}
            <svg ref={svgRef} width={width} height={height}></svg>
        </div>
    );
};

PolarInputChart.propTypes = {
    data: PropTypes.array,
    onItemClick: PropTypes.func,
    onItemHover: PropTypes.func,
};

PolarInputChart.defaultProps = {
    data: [],
    onItemClick: () => {},
    onItemHover: () => {},
};

export default memo(PolarInputChart);
