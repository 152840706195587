import { darken } from "polished";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

const StyledWidget = ({ label, stat, description, color, icon }) => {
    const styles = useMemo(() => {
        const iconColumnStyle = {
            backgroundColor: darken(0.1, color),
            color: "white",
        };
        const primaryColumnStyle = {
            backgroundColor: color,
            color: darken(0.3, color),
        };
        return { iconColumnStyle, primaryColumnStyle };
    }, [color]);

    const { iconColumnStyle, primaryColumnStyle } = styles;

    const WidgetIcon = icon;

    return (
        <div className="card text-base-100 flex flex-row">
            <div className="p-4 flex items-center" style={iconColumnStyle}>
                <WidgetIcon size={24} />
            </div>
            <div className="flex-1 p-3" style={primaryColumnStyle}>
                <span className="text-lg font-bold block">{stat}</span>
                <span className="text-sm">{description}</span>
            </div>
        </div>
    );
};

StyledWidget.propTypes = {
    label: PropTypes.string.isRequired,
    stat: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
};

StyledWidget.defaultProps = {
    color: "#0DCAF0",
};

export default StyledWidget;
