// The following fields are stored on the primary document, but are not included in the public snapshots
const rootRoleId = "eLKBas";
const ownerEmployeeId = "pUrmk";
const firstBusinessUnitId = "fnorp";
const firstRoleDocId = "peBn15";
const firstEmployeeDocId = "fuynke";

// TODO: remove parentRoleId just rely on the parentMap
const publicRoleFields = [
    "rootRole",
    "jobTitle",
    "department",
    "incumbentId",
    "level",
    "successorCount",
    "custom",
];
const publicEmployeeFields = [
    "firstname",
    "surname",
    "email",
    "employeeNumber",
    "userRole",
    "inviteAccepted",
    "custom",
];

const publicTraitFields = ["traitName"];

const publicTalentAreaFields = ["talentAreaName", "description", "color", "excludeFromOverview"];

const publicActionFields = ["actionName"];

const publicTalentBoardFields = ["boardName", "customThresholds", "defaultThresholds", "auto"];

const defaultWorkspacePrefs = {
    nodeView: {},
};

const workspaceTemplate = {
    ownerId: ownerEmployeeId,
    rootRoleId: rootRoleId,
    active: true,
    configured: false,
};

const workspaceConfigTemplate = {
    defaultBusinessUnitId: firstBusinessUnitId,
    enabledModules: ["talent"],
    subscribedModules: ["talent"],
};

const productUrl = "https://app.dothings.io";
const productName = "Talent Mapper";
const actionUrl = "https://app.dothings.io/sign-in/request";
const supportEmail = "support@dothings.io";
const companyName = "DoThings Ltd";
const companyAddress = "3rd Floor, 86-90 Paul St, London EC2A 4NE";

module.exports = {
    PUBLIC_FIELDS: {
        roleInfo: publicRoleFields,
        employeeInfo: publicEmployeeFields,
        talentBoards: publicTalentBoardFields,
        traits: publicTraitFields,
        talentAreas: publicTalentAreaFields,
        actions: publicActionFields,
    },
    SUPPORT_EMAIL: supportEmail,
    PRODUCT_URL: productUrl,
    BASE_EMAIL_TEMPLATE: {
        product_url: productUrl,
        product_name: productName,
        action_url: actionUrl,
        support_email: supportEmail,
        company_name: companyName,
        company_address: companyAddress,
    },
    PUBLIC_ROLE_FIELDS: publicRoleFields,
    PUBLIC_EMPLOYEE_FIELDS: publicEmployeeFields,
    ROOT_ROLE_ID: rootRoleId,
    OWNER_EMPLOYEE_ID: ownerEmployeeId,
    FIRST_BUSINESS_UNIT_ID: firstBusinessUnitId,
    FIRST_ROLE_DOC_ID: firstRoleDocId,
    FIRST_EMPLOYEE_DOC_ID: firstEmployeeDocId,
    DEFAULT_WORKSPACE_PREFS: defaultWorkspacePrefs,
    WORKSPACE_TEMPLATE: workspaceTemplate,
    WORKSPACE_CONFIG_TEMPLATE: workspaceConfigTemplate,
};
