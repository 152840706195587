import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "./reporting.scss";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../../components/MyComponents/Modal";
import MyButton from "../../components/MyComponents/Button";

const PrintComponent = React.forwardRef((props, ref) => {
    return <div ref={ref}>{props.children}</div>;
});

const ReportPrintModal = ({ isOpen, title, toggle, children }) => {
    const ref = useRef();
    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });

    return (
        <MyModal isOpen={isOpen} size="fullscreen">
            <MyModalHeader onToggle={toggle}>{title}</MyModalHeader>
            <MyModalBody className="flex-1">
                <PrintComponent ref={ref}>{children}</PrintComponent>
            </MyModalBody>
            <MyModalFooter>
                <MyButton label="print/save" color="secondary" onClick={handlePrint}>
                    Print / Save
                </MyButton>
            </MyModalFooter>
        </MyModal>
    );
};

export default ReportPrintModal;
