import { useLocalStorage } from "../../../../hooks/useLocalStorage";

export function useTableSort(tableId, defaultSortKey) {
    const [tableSortInfo, setTableSortInfo] = useLocalStorage(tableId, { sortKey: defaultSortKey, sortOrder: "asc" });

    const setSorting = (key) => {
        const { sortKey, sortOrder } = tableSortInfo;
        if (key === sortKey) {
            setTableSortInfo((prev) => ({ ...prev, sortOrder: sortOrder === "asc" ? "desc" : "asc" }));
        } else {
            setTableSortInfo({ sortKey: key, sortOrder: "asc" });
        }
    };

    return {
        sortKey: tableSortInfo?.sortKey || defaultSortKey,
        sortOrder: tableSortInfo?.sortOrder || "asc",
        setSorting: setSorting,
    };
}
