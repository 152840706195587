import React, { createContext, useContext, useMemo, useState } from "react";
import { StepperModal } from "../../../../common/components";
import { mergeArrays } from "../../../../common/utils/arrayUtils";
import { CheckIcon } from "../../../../components/Icons/MyIcons";

const ReviewEditorContext = createContext();

export function useReviewEditorContext() {
    return useContext(ReviewEditorContext);
}

const ReviewEditor = ({ isOpen, values, actions, submitLabel, removePadding, children }) => {
    const {
        locked,
        isSubmitting,
        draftReview,
        draftSaving,
        savedNotes,
        draftObjectives,
        activeEmployee,
        preview,
        cycle,
    } = values;
    const { onSaveDraft, onSubmit, onToggle } = actions;
    const [navigateBlocked, setNavigateBlocked] = useState(false);
    const [closeBlocked, setCloseBlocked] = useState(false);
    const headerText = `${activeEmployee?.displayName}: ${cycle?.cycleName}` || "Review";
    const allValid = true;

    // Merges the saved and draft notes so they can both be accessed
    const notes = useMemo(() => draftReview?.draft?.notes || [], [draftReview]);
    const mergedNotes = useMemo(() => {
        return mergeArrays(savedNotes, notes);
    }, [notes, savedNotes]);

    const handleClose = () => {
        onSaveDraft();
        onToggle();
    };

    const handleSubmit = async () => {
        onSubmit(draftReview.draft);
    };

    const handleStepChange = async () => {
        onSaveDraft();
    };

    const handleSetNavigateBlocked = (value) => {
        setNavigateBlocked(value);
    };

    function getInfoMessage() {
        const draftLabel = "Draft Auto-Save Status:";
        if (preview) {
            return "This is a preview. No changes will be saved";
        }
        if (locked) {
            return "This review iteration is complete and no longer accepting edits";
        }
        if (draftSaving) {
            return (
                <div className="space-x-2 flex items-center">
                    <span className="text-neutral">{draftLabel}</span>
                    <span className="text-info">Saving changes</span>
                    <span className="loading loading-spinner loading-xs text-info"></span>
                </div>
            );
        }
        if (disableSaveDraft) {
            return (
                <div className="space-x-2 flex items-center">
                    <span className="text-neutral">{draftLabel}</span>
                    <span className="text-success">All Changes Have Been Saved to Draft</span>
                    <CheckIcon className="w-4 h-4 text-success" />
                </div>
            );
        }
        if (!disableSaveDraft) {
            return (
                <div className="space-x-2 flex items-center">
                    <span className="text-neutral">{draftLabel}</span>
                    <span className="text-error">Draft Has Unsaved Changes</span>
                </div>
            );
        }
    }

    const enhancedValues = {
        ...values,
        objectives: draftObjectives,
        notes: mergedNotes,
        draft: draftReview.draft,
    };

    const enhancedActions = {
        ...actions,
        setNavigateBlocked: handleSetNavigateBlocked,
        setCloseBlocked,
        updateNote: draftReview.updateNote,
        updateFlag: draftReview.updateFlag,
        updateRatings: draftReview.updateRatings,
        toggleActionId: draftReview.toggleActionId,
    };

    const value = {
        values: enhancedValues,
        actions: enhancedActions,
    };

    const disableSaveDraft = draftSaving || !draftReview.draftHasChanged;

    return (
        <ReviewEditorContext.Provider value={value}>
            <StepperModal
                isOpen={isOpen}
                isWaiting={isSubmitting}
                headerText={headerText}
                navigateBlocked={navigateBlocked}
                closeBlocked={closeBlocked}
                allowSubmit={allValid && !locked && !draftSaving && !isSubmitting}
                progressAtTop
                removePadding={removePadding}
                submitLabel={submitLabel}
                extraButtons={[
                    {
                        label: disableSaveDraft ? "No Changes to Save" : "Save Private Draft",
                        color: "ghost",
                        onClick: onSaveDraft,
                        disabled: disableSaveDraft,
                    },
                ]}
                size="fullscreen"
                onToggle={handleClose}
                leftInfoMessage={getInfoMessage()}
                infoMessage={getInfoMessage()}
                onStepChange={handleStepChange}
                onSubmit={handleSubmit}
            >
                {children}
            </StepperModal>
        </ReviewEditorContext.Provider>
    );
};

export default ReviewEditor;
