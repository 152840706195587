import { collection, doc } from "firebase/firestore";
import { useMemo } from "react";
import { useIterationRefs } from "./useIterationRefs";

export function useReviewsRef(cycleId, iterationId, revieweeId, reviewerId) {
    const { iterationRef } = useIterationRefs(cycleId, iterationId);

    const reviewsRef = useMemo(() => {
        if (!iterationRef) return null;
        return collection(iterationRef, "reviews");
    }, [iterationRef]);

    const revieweeRef = useMemo(() => {
        if (!reviewsRef || !revieweeId) return null;
        return doc(reviewsRef, revieweeId);
    }, [reviewsRef, revieweeId]);

    const reviewerRef = useMemo(() => {
        if (!revieweeRef || !reviewerId) return null;
        const submissionsRef = collection(revieweeRef, "submissions");
        return doc(submissionsRef, reviewerId);
    }, [revieweeRef, reviewerId]);

    return { reviewsRef, revieweeRef, reviewerRef };
}
