import { interpolate, range } from "d3";

function prepAssessmentSettings(assessmentConfig, colors) {
    const { cycleDays, lateThreshold, ratingScale } = assessmentConfig;
    const thresholdDays = cycleDays || 180;
    const lateDays = lateThreshold || 14;
    const today = new Date();
    const thresholdDate = new Date();

    thresholdDate.setDate(today.getDate() - thresholdDays);

    const lateDate = new Date();
    lateDate.setDate(today.getDate() - (thresholdDays + lateDays));
    let scaleColors = [];
    let ratingPoint = 99 / (ratingScale - 1);
    for (let i = 0; i <= 99; i += ratingPoint) {
        scaleColors.push(colors[Math.round(i)]);
    }
    ratingPoint = Math.round(ratingPoint);

    return {
        ...assessmentConfig,
        thresholdDate: thresholdDate.toISOString(),
        lateDate: lateDate.toISOString(),
        scaleColors,
        ratingPoint,
    };
}

function getAllColors() {
    const RATING_COLORS = {
        firstColor: "#FF1414",
        midColor: "#edeb12",
        lastColor: "#13cc10",
    };
    const { firstColor, midColor, lastColor } = RATING_COLORS;
    const firstHalf = interpolate(firstColor, midColor);
    const part1 = range(0, 1, 0.02).map((t) => firstHalf(t));
    const secondHalf = interpolate(midColor, lastColor);
    const part2 = range(0, 1, 0.02).map((t) => secondHalf(t));
    const allColors = part1.concat(part2);
    return allColors;
}

function getColors(assessmentColors, steps) {
    const stepSize = assessmentColors.length / steps;
    const colorArray = [];
    for (let i = 0; i < steps; i++) {
        const index = Math.floor(i * stepSize);
        colorArray.push(assessmentColors[index]);
    }
    return colorArray;
}

export { prepAssessmentSettings, getAllColors, getColors };
