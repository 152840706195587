import { useDispatch, useSelector } from "react-redux";
import { useFetched } from "../../../hooks/useFetched";
import { useMemo } from "react";
import { setActiveModal } from "../../../redux/appSlice";

function useScheduledActions(employeeId, scheduledActionIds, completedActionIds) {
    const dispatch = useDispatch();
    const actions = useSelector((state) => state.workspace.actions);
    const [fetchAction] = useFetched("actions");
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const isSelf = userEmployeeId === employeeId;

    const incompleteActions = useMemo(() => {
        if (!scheduledActionIds) return [];
        const incompleteActions = scheduledActionIds.map((id) => {
            const action = actions[id];
            return { ...action, actionId: id, employeeId: employeeId, complete: false };
        });
        return incompleteActions;
    }, [actions, employeeId, scheduledActionIds]);

    const completedActions = useMemo(() => {
        if (!completedActionIds) return [];
        const completeActions = completedActionIds.map((id) => {
            const action = actions[id];
            return { ...action, actionId: id, employeeId: employeeId, complete: true };
        });
        return completeActions;
    }, [actions, employeeId, completedActionIds]);

    const handleViewReport = (showingComplete) => {
        if (isSelf) {
            dispatch(setActiveModal("employeeOwnActionsReport"));
        } else if (showingComplete) {
            dispatch(setActiveModal("employeeCompleteActionsReport"));
        } else {
            dispatch(setActiveModal("employeeScheduledActionsReport"));
        }
    };

    return {
        fetchAction,
        completedActions,
        incompleteActions,
        viewReport: handleViewReport,
    };
}
export default useScheduledActions;
