import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function ensureArray(value) {
    if (Array.isArray(value)) {
        return value;
    } else {
        return Object.values(value);
    }
}

export function usePreppedEmployees(employees, fieldsToMap) {
    const snapshotsReady = useSelector((state) => state.snapshots.snapshotsReady);
    const [preppedEmployees, setPreppedEmployees] = useState([]);

    useEffect(() => {
        function prepEmployees() {
            const initialEmployees = ensureArray(employees || {});

            if (!initialEmployees || !fieldsToMap) {
                setPreppedEmployees([]);
            }

            // Prep the main employee fields first
            const prepped = initialEmployees.map((employee) => {
                let employeeRow = { ...employee };
                fieldsToMap.forEach((column) => {
                    const columnVal = column.getValue ? column.getValue(employee) : employee[column.id];
                    if (columnVal !== undefined) {
                        employeeRow[column.id] = columnVal;
                    }
                });
                return employeeRow;
            });
            setPreppedEmployees(prepped);
        }

        // run once data is stable
        const timer = setTimeout(() => {
            if (snapshotsReady) prepEmployees();
        }, 500);

        return () => clearTimeout(timer);
    }, [employees, fieldsToMap, snapshotsReady]);

    return preppedEmployees;
}
