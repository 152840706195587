import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTopLevelRoleId } from "../../BusinessUnits/hooks/useTopLevelRoleId";
import { useRoleRelationships } from "../../Roles/hooks/useRoleRelationships";

export function useSubordinates(topLevelRoleId, includeTopRole = true) {
    const employees = useSelector((state) => state.businessUnit.employees);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const { getAllRoleIdsBelow } = useRoleRelationships();
    const highestAllowedRoleId = useTopLevelRoleId();

    const subordinates = useMemo(() => {
        const roleId = topLevelRoleId || highestAllowedRoleId;
        let included = [];
        if (!roleId || roleId === rootRoleId) {
            // When no role filtering is needed, include all employees with a role
            included = Object.values(employees).filter((employee) => !!employee.roleId);
        } else {
            // When there is filtering, include all employees below the selected role
            const subordinateRoleIds = getAllRoleIdsBelow(roleId);
            included = Object.values(employees).filter((employee) => {
                return subordinateRoleIds.includes(employee.roleId);
            });

            // If the selected role should be included, add it
            if (includeTopRole) {
                const topEmployee = Object.values(employees).find((employee) => employee.roleId === roleId);
                if (topEmployee) {
                    included.push(topEmployee);
                }
            }
        }
        return included;
    }, [rootRoleId, employees, highestAllowedRoleId, topLevelRoleId, includeTopRole, getAllRoleIdsBelow]);

    return subordinates;
}
