import classNames from "classnames";
import React from "react";
import { Button } from "../../../../../common/components";
import { DeleteIcon } from "../../../../../components/Icons/MyIcons";
import ObjectiveScopeBadge from "../../atoms/ObjectiveScopeBadge";

const ICON_SIZE = 16;

const ObjectiveSettingHeader = ({ objective, locked = false, onClick = () => {}, onRemove = () => {} }) => {
    const { objectiveName, parentName } = objective;

    const handleHeaderClick = (e) => {
        onClick(objective);
    };

    const handleRemove = (e) => {
        e.stopPropagation();
        onRemove(objective);
    };

    return (
        <div
            className={classNames(
                "fade-in move-up border items-center bg-base-100 flex px-6 py-2 space-x-3 cursor-pointer",
            )}
            onClick={handleHeaderClick}
        >
            <div className="flex-1 py-1">
                <h5 className="tracking-tight font-medium">{objectiveName}</h5>
                {parentName && <span className="text-info text-sm tracking-tight">{`Cascaded from: ${parentName}`}</span>}
            </div>
            <div className="w-fit flex space-x-2">
                <ObjectiveScopeBadge objective={objective} />
                <Button color="ghost" hidden={locked} size="xs" shape="circle" onClick={handleRemove}>
                    <DeleteIcon size={ICON_SIZE} />
                </Button>
            </div>
        </div>
    );
};

export default ObjectiveSettingHeader;
