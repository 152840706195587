import classNames from "classnames";
import React, { useEffect, useState } from "react";
import MyButton from "../../../../../../components/MyComponents/Button";
import { useToggle } from "../../../../../../hooks/useToggle";

const MeasureValueInput = ({ hidden, fieldName, value, label, min, onChange }) => {
    const [editedValue, setEditedValue] = useState(value);

    useEffect(() => {
        setEditedValue(value);
    }, [value]);

    const handleChange = (e) => {
        const intVal = parseInt(e.target.value);
        const isNumber = !isNaN(intVal);
        setEditedValue(isNumber ? intVal : "");
    };

    const handleBlur = (e) => {
        let validatedValue = Math.max(0, editedValue);
        if (validatedValue < min) validatedValue = min;
        setEditedValue(validatedValue);
        onChange(fieldName, validatedValue);
    };

    if (hidden) return null;
    return (
        <div className="h-full flex space-x-2 items-center py-2">
            <span className="font-medium text-xs">{label}</span>
            <label className="input input-bordered flex items-center h-8">
                <input
                    type="number"
                    name={fieldName}
                    className="max-w-28"
                    min={0}
                    value={editedValue === undefined ? "" : editedValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </label>
        </div>
    );
};

const Edit = ({ measure, onUpdateMeasure }) => {
    const [showTargetVals, toggleShowTargetVals] = useToggle(false);
    const { currentVal, targetVal } = measure || {};

    const handleUpdateValues = (fieldName, value) => {
        onUpdateMeasure(fieldName, value);
    };

    const handleToggleTarget = () => {
        toggleShowTargetVals();
    };

    return (
        <div className="flex space-x-2">
            <MyButton hidden={true} size="xs" color="ghost" onClick={handleToggleTarget}>
                Set Target Value
            </MyButton>
            <MeasureValueInput
                hidden={!showTargetVals}
                fieldName="currentVal"
                value={currentVal || 0}
                label="Current"
                onChange={handleUpdateValues}
            />
            <MeasureValueInput
                hidden={!showTargetVals}
                fieldName="targetVal"
                min={currentVal === undefined ? 1 : currentVal + 1}
                value={targetVal}
                label="Target"
                onChange={handleUpdateValues}
            />
        </div>
    );
};

const View = ({ measure }) => {
    if (!measure?.hasTarget) return null;
    const { currentVal, targetVal } = measure || {};
    const percentComplete = (currentVal / targetVal) * 100;
    const isDone = currentVal === targetVal || currentVal > targetVal;
    return (
        <div className="min-w-80 px-4">
            <progress
                className={classNames("progress", {
                    "progress-success": isDone,
                    "progress-primary": !isDone,
                })}
                value={percentComplete}
                max="100"
            ></progress>
            <div className="flex justify-between">
                <span className="text-tiny text-base-800 font-bold">{`Current: ${currentVal}`}</span>
                <span className="text-tiny text-base-800 font-bold">{`Target: ${targetVal}`}</span>
            </div>
        </div>
    );
};

const MeasureValues = ({ viewMode, measure, onUpdateMeasure, setViewMode }) => {
    switch (viewMode) {
        case "edit":
            return <Edit measure={measure} onUpdateMeasure={onUpdateMeasure} setViewMode={setViewMode} />;
        case "view":
            return <View measure={measure} />;
        case "create":
        default:
            return null;
    }
};

export default MeasureValues;
