import React, { useState } from "react";
import { convertObjectToArray } from "../../../../common/utils/objectUtils";
import CascadeModal from "../../containers/modals/CascadeModal";
import ObjectiveSettingModal from "../../containers/modals/ObjectiveSettingModal";
import ObjectiveViewCardGroup from "../organisms/ObjectiveCards/ObjectiveViewCard/ObjectiveViewCardGroup";
import ObjectiveCreatorHeader from "../organisms/ObjectiveSetting/ObjectiveCreatorHeader";

const ObjectivesTemplate = ({
    employeeId,
    isSelf,
    hasChildren,
    liveObjectives = [],
    completeObjectives = [],
    itemsPerPage,
    fetchStatus,
    hasMore,
    allowCreate,
    viewingComments,
    onSaveObjective = () => {},
    onViewComments = () => {},
    fetchMore = () => {},
}) => {
    const [newObjective, setNewObjective] = useState(null);
    const [selectedObjective, setSelectedObjective] = useState(null);
    const liveObjectiveArray = convertObjectToArray(liveObjectives);

    // Complete objectives only fetch, so if they are made live again they need filtering out
    const cleanedCompleteObjectives = completeObjectives.filter((objective) => !liveObjectives[objective.id]);

    const handleViewCascades = (objective) => {
        setSelectedObjective(objective);
    };

    const handleCreateObjective = (fullNewObjective) => {
        onSaveObjective(fullNewObjective);
        setNewObjective(null);
    };

    return (
        <div className="flex-1 space-y-8 flex flex-col">
            <div className="space-y-1">
                <h5>Live Objectives</h5>
                <ObjectiveViewCardGroup
                    objectives={liveObjectiveArray}
                    viewingComments={viewingComments}
                    onViewCascades={handleViewCascades}
                    onViewComments={onViewComments}
                />
                {allowCreate && <ObjectiveCreatorHeader ownerEmployeeId={employeeId} onSubmit={setNewObjective} />}
            </div>
            <div className="space-y-1 flex-1 flex flex-col">
                <h5>Completed Objectives</h5>
                <div className="flex-1 flex flex-col">
                    <ObjectiveViewCardGroup
                        objectives={cleanedCompleteObjectives}
                        viewingComments={viewingComments}
                        hasMore={hasMore}
                        fetchStatus={fetchStatus}
                        itemsPerPage={itemsPerPage}
                        fetchMore={fetchMore}
                        onViewCascades={handleViewCascades}
                        onViewComments={onViewComments}
                    />
                </div>
            </div>
            <ObjectiveSettingModal
                isOpen={!!newObjective}
                objective={newObjective}
                isSelf={isSelf}
                hasChildren={hasChildren}
                onToggle={() => setNewObjective(null)}
                onSubmit={handleCreateObjective}
            />
            <CascadeModal
                isOpen={!!selectedObjective}
                objective={selectedObjective}
                onToggle={() => handleViewCascades(null)}
            />
        </div>
    );
};

export default ObjectivesTemplate;
