import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { OrgIcon } from "../Icons/MyIcons";
import MyButton from "../MyComponents/Button";

const FloatingActionButton = (props) => {
    const [isLeaving, setIsLeaving] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // Close the FAB after .75 seconds of mouse leaving
    useEffect(() => {
        if (isLeaving) {
            const timer = setTimeout(() => {
                if (isLeaving) {
                    setIsOpen(false);
                    setIsLeaving(false);
                }
            }, 750);
            return () => clearTimeout(timer);
        }
    }, [isLeaving]);


    const renderIcon = () => {
        if (props.icon) {
            const Icon = props.icon;
            return <Icon size={18} />;
        } else {
            return <OrgIcon size={18} />;
        }
    };

    const childCount = React.Children.count(props.children);

    const handleOpen = () => {
        if (childCount > 0 && !props.disabled) {
            setIsOpen(true);
        }
    };

    const handleMouseOut = () => {
        setIsLeaving(true);
    }

    const handleMouseOver = () => {
        setIsLeaving(false);
    }

    return (
        <div className="fixed bottom-10 right-10 z-10" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <div
                className={classNames(
                    "space-y-2 relative border bg-base-100 rounded-xl py-4 -top-2 flex-col flex items-center",
                    {
                        hidden: !isOpen,
                    }
                )}
            >
                {props.children}
            </div>
            <div onMouseEnter={handleOpen}>
                <MyButton color="primary" shape="circle" size="lg" disabled={props.disabled}>
                    {renderIcon()}
                </MyButton>
            </div>
        </div>
    );
};

export default FloatingActionButton;
