import { groupBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { Select } from "../../../../..";

const ColumnsStep = ({ columnOptions, selectedColumnIds, setDisableButtons, onChange }) => {
    const columnGroups = useMemo(() => {
        const cleanedOptions = columnOptions.filter((col) => !!col.label);
        return groupBy(cleanedOptions, "category");
    }, [columnOptions]);

    useEffect(() => {
        setDisableButtons(selectedColumnIds.length === 0);
    }, [selectedColumnIds, setDisableButtons]);

    const handleColumnChange = (category, newIds) => {
        const categoryOptionIds = columnGroups[category].map((col) => col.id);
        let newSelectedIds = selectedColumnIds.filter((id) => !categoryOptionIds.includes(id));
        const newColIds = [...newSelectedIds, ...newIds];
        onChange("columns", newColIds);
    };

    return (
        <div className="flex-1 space-y-4">
            {Object.entries(columnGroups).map(([category, columns]) => {
                const key = category.toLowerCase().replace(/\s/g, "");
                return (
                    <div key={key}>
                        <label className="text-xs font-medium">{category}</label>
                        <Select
                            options={columns}
                            groupByField="subCategory"
                            selectedIds={selectedColumnIds}
                            placeholder="choose columns"
                            onChange={(newCols) => handleColumnChange(category, newCols)}
                            isMulti
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ColumnsStep;
