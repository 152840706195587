import _ from "lodash";
import { sortArrayOfObjects } from "../../../helpers/helpers";

// Preps a trait map update
function buildTraitMapUpdate(traitMap, linkedTraitIds, linkId, linkType, remove) {
    let traitMapUpdate = {};
    const updateTraitIds = Array.isArray(linkedTraitIds) ? linkedTraitIds : [linkedTraitIds];
    updateTraitIds.forEach((traitId) => {
        let allTraitLinks = { ...traitMap?.[traitId] };
        const affectedLinks = allTraitLinks?.[linkType] || [];
        let newLinks;
        if (remove) {
            newLinks = affectedLinks.filter((affectedLinkId) => affectedLinkId !== linkId);
        } else {
            newLinks = [...affectedLinks, linkId];
            newLinks = [...new Set(newLinks)];
        }
        allTraitLinks[linkType] = newLinks;
        traitMapUpdate[traitId] = allTraitLinks;
    });
    return traitMapUpdate;
}

function linkDeleteHelper(traitMap, currentData, id, linkKey, traitMapUpdate) {
    const linkedTraitIds = currentData?.linkedTraitIds;
    if (linkedTraitIds) {
        const thisUpdate = buildTraitMapUpdate(traitMap, linkedTraitIds, id, linkKey, true);
        traitMapUpdate = { ...traitMapUpdate, ...thisUpdate };
    }
    return traitMapUpdate;
}

function getBoardTraitIds(boardsIds = [], talentBoards, traits) {
    let traitIds = [];
    boardsIds.forEach((boardId) => {
        const talentBoard = talentBoards[boardId];
        const boardTraitIds = talentBoard?.linkedTraitIds || [];
        traitIds = [...traitIds, ...boardTraitIds];
    });
    traitIds = [...new Set(traitIds)];

    // Remove traits that don't have a talent area
    traitIds = traitIds.filter((traitId) => !!traits[traitId]?.linkedTalentAreaId);

    // Sort by talent area id so they're all grouped
    let sortedTraits = traitIds.map((traitId) => traits[traitId]);
    sortedTraits = sortArrayOfObjects(sortedTraits, "linkedTalentAreaId", "desc");
    return sortedTraits.map((trait) => trait.id);
}

export { buildTraitMapUpdate, linkDeleteHelper, getBoardTraitIds };
