import classNames from "classnames";
import React from "react";
import { useResizeDetector } from "react-resize-detector";
import PropTypes from "prop-types";

const ResponsiveContainer = (props) => {
    const { defaultClasses, className, style, pX = 0, pY = 0 } = props;
    const { width, height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 100 });
    return (
        <div ref={ref} className={classNames(defaultClasses, className)} style={style}>
            {React.Children.map(props.children, (child) => {
                const paddedWidth = width - pX;
                const paddedHeight = height - pY;
                return React.cloneElement(child, {
                    style: { width: paddedWidth, height: paddedHeight },
                    height: paddedHeight,
                    width: paddedWidth,
                });
            })}
        </div>
    );
};

ResponsiveContainer.propTypes = {
    defaultClasses: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
};

ResponsiveContainer.defaultProps = {
    style: {},
    defaultClasses: "relative h-full w-full flex-grow-1 overflow-auto",
};

export default ResponsiveContainer;
