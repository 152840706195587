// logger.js
const applogger = {
    info: (...args) => {
        console.log(...args);
    },
    error: (...args) => {
        console.error(...args);
    },
    warn: (...args) => {
        console.warn(...args);
    },
    table: (data) => {
        console.table(data);
    },
};

// Add a placeholder for production logging
if (process.env.NODE_ENV === "production") {
    applogger.info = () => {}; // Override logger methods to do nothing in production
    applogger.error = () => {};
    applogger.warn = () => {};
    applogger.table = () => {};
}

export default applogger;
