import { signOut } from "firebase/auth";
import { writeUser } from "../../features/User/utils/userDb";
import { auth } from "../../firebase/firebase";
import applogger from "./applogger";
import { identifyFirestoreRef } from "../../firebase/utils/identifyFirestoreRef";
const READ_THRESHOLD = 100000;
const WRITE_THRESHOLD = 100000;
const LISTENER_THRESHOLD = 1000;

let activeListenerCount = 0;
let readCount = 0;
let writeCount = 0;
let totalListenerCount = 0;
let listenerCounts = {};

const TRACK_READS = true;
const TRACK_WRITES = true;

async function blockUser() {
    try {
        const update = { blocked: true };
        const batch = writeUser(auth.currentUser.uid, update);
        await batch.commit();
    } catch (error) {
        applogger.error("Failed to block user", error);
    }
    signOut(auth);
}

// Keeps track of the total document reads
export const readTracker = async (firestoreRef, increment = 1) => {
    const trackerId = identifyFirestoreRef(firestoreRef);
    readCount += increment;
    if (TRACK_READS) {
        applogger.info(`READ ::: ${trackerId} - ${increment} ::: TOTAL READS: (${readCount})`);
    }

    // Logout & block any user who exceeds the read threshold (100,000 reads)
    if (readCount >= READ_THRESHOLD) {
        blockUser();
    }
};

export const writeTracker = async (firestoreRef, increment = 1) => {
    const trackerId = identifyFirestoreRef(firestoreRef);
    writeCount += increment;
    if (TRACK_WRITES) {
        applogger.info(trackerId, `WRITE ::: ${increment} ::: TOTAL WRITES: (${writeCount})`);
    }

    // Logout & block any user who exceeds the write threshold (100,000 writes)
    if (writeCount >= WRITE_THRESHOLD) {
        blockUser();
    }
};

// Keeps track of when a global listerer is setup and warns if it's done twice
export const listenerTracker = async (firestoreRef) => {
    const trackerId = identifyFirestoreRef(firestoreRef);
    let count = listenerCounts[trackerId] || 0;
    count++;
    listenerCounts[trackerId] = count;
    totalListenerCount++;
    activeListenerCount++;
    if (count > 10) {
        applogger.info(`WARNING: ${trackerId} has been setup ${count} times`);
    }
    applogger.info(`LISTENING ::: ${trackerId} ::: TOTAL LISTENERS: (${activeListenerCount})`);

    // Logout & block any user who exceeds the listener threshold (1000 listeners)
    if (totalListenerCount >= LISTENER_THRESHOLD) {
        blockUser();
    }
};

export const dropListener = (firestoreRef) => {
    const trackerId = identifyFirestoreRef(firestoreRef);
    activeListenerCount -= 1;
    applogger.info(`DROPPED LISTENER ::: ${trackerId} ::: TOTAL LISTENERS: (${activeListenerCount})`);
};
