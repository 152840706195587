import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useTopLevelRoleId } from "../../BusinessUnits/hooks/useTopLevelRoleId";

export function useEmployeeRelationships() {
    const { isAdmin, userEmployeeId } = useSelector((state) => state.user);
    const employees = useSelector((state) => state.businessUnit.employees);
    const roles = useSelector((state) => state.businessUnit.roles);
    const parentMap = useSelector((state) => state.businessUnit.parentMap);
    const highestAllowedRoleId = useTopLevelRoleId();

    const getChildRoleIds = useCallback(
        (roleId) => {
            let childRoleIds = Object.entries(parentMap || {})
                .filter(([, parentRoleId]) => parentRoleId === roleId)
                .map(([childRoleId]) => childRoleId);
            return childRoleIds;
        },
        [parentMap]
    );

    const isThisRoleAboveMe = useCallback(
        (empId, matchRoleId) => {
            const employee = employees[empId];
            const roleId = employee?.roleId;
            if (!roleId) return false;
    
            let parentRoleId = parentMap[roleId];
            const evaluatedRoles = new Set();
    
            while (parentRoleId) {
                if (evaluatedRoles.has(parentRoleId)) {
                    throw new Error(`Cycle detected in the role hierarchy: role ${parentRoleId} appears more than once.`);
                }
                evaluatedRoles.add(parentRoleId);
    
                if (parentRoleId === matchRoleId) return true;
                parentRoleId = parentMap[parentRoleId];
            }
            return false;
        },
        [parentMap, employees]
    );

    const getAllRoleIdsAbove = useCallback(
        (roleId) => {
            let parentRoleIds = [];
            let parentRoleId = parentMap[roleId];
            while (parentRoleId) {
                parentRoleIds.push(parentRoleId);
                parentRoleId = parentMap[parentRoleId];
            }
            return parentRoleIds;
        },
        [parentMap]
    );

    const getAllEmployeeIdsAbove = useCallback(
        (empId) => {
            const employee = employees[empId];
            const allParentRoleIds = getAllRoleIdsAbove(employee?.roleId, parentMap);
            const allEmployeeIds = allParentRoleIds.map((roleId) => {
                const role = roles[roleId];
                return role?.incumbentId;
            });
            return allEmployeeIds.filter(Boolean);
        },
        [employees, getAllRoleIdsAbove, parentMap, roles]
    );

    const getAllRoleIdsBelow = useCallback(
        (roleId) => {
            let allChildRoles = [];
            function helper_recursive_child_roles(roleId) {
                const childRoles = getChildRoleIds(roleId, parentMap);
                allChildRoles.push(...childRoles);
                childRoles.forEach((childRoleId) => {
                    helper_recursive_child_roles(childRoleId);
                });
            }
            helper_recursive_child_roles(roleId);
            return allChildRoles;
        },
        [parentMap, getChildRoleIds]
    );

    const getAllEmployeeIdsBelow = useCallback(
        (roleId) => {
            const allRoleIdsBelow = getAllRoleIdsBelow(roleId, parentMap);
            const allEmployeeIdsBelow = allRoleIdsBelow.map((roleId) => roles[roleId]?.incumbentId);
            return allEmployeeIdsBelow.filter(Boolean);
        },
        [getAllRoleIdsBelow, parentMap, roles]
    );

    const getDirectReportIds = useCallback(
        (empId) => {
            const employee = employees[empId];
            const employeeRole = roles[employee?.roleId];
            const childRoleIds = getChildRoleIds(employeeRole?.id);
            const directReportIds = Object.values(employees)
                .filter((employee) => childRoleIds.includes(employee?.roleId))
                .map((employee) => employee?.id);
            return directReportIds;
        },
        [employees, getChildRoleIds, roles]
    );

    const getManager = useCallback(
        (empId) => {
            const employee = employees[empId];
            const employeeRole = roles[employee?.roleId];
            const managerRole = roles[employeeRole?.parentRoleId];
            return employees[managerRole?.incumbentId];
        },
        [employees, roles]
    );

    const getEmployeeRole = useCallback(
        (empId) => {
            const employee = employees[empId];
            return roles[employee?.roleId];
        },
        [employees, roles]
    );

    const checkCanManageEmployee = useCallback(
        (empId) => {
            if (isAdmin) return true;
            const allowedEmployeeIds = getAllEmployeeIdsBelow(highestAllowedRoleId);
            return allowedEmployeeIds.includes(empId);
        },
        [isAdmin, highestAllowedRoleId, getAllEmployeeIdsBelow]
    );

    const checkCanManageRole = useCallback(
        (roleId) => {
            if (isAdmin) return true;
            const allowedRoleIds = getAllRoleIdsBelow(highestAllowedRoleId);
            return allowedRoleIds.includes(roleId);
        },
        [isAdmin, highestAllowedRoleId, getAllRoleIdsBelow]
    );

    const checkIsSelf = useCallback(
        (empId) => {
            return empId === userEmployeeId;
        },
        [userEmployeeId]
    );

    const checkRelationship = useCallback(
        (employeeOne, employeeTwo) => {
            if (employeeOne === employeeTwo) return "employee";
            if (employeeOne === getManager(employeeTwo)?.id) return "manager";
            if (employeeTwo === getManager(employeeOne)?.id) return "subordinate";
            return "peer";
        },
        [getManager]
    );

    return {
        getManager,
        getEmployeeRole,
        checkCanManageEmployee,
        checkCanManageRole,
        checkRelationship,
        getAllEmployeeIdsAbove,
        getAllEmployeeIdsBelow,
        getAllRoleIdsBelow,
        getAllRoleIdsAbove,
        getDirectReportIds,
        isThisRoleAboveMe,
        checkIsSelf,
    };
}
