import React from "react";
import { useSelector } from "react-redux";
import { getAssessmentColors } from "../../../../../../features/Config/utils/configSelectors";
import { useEmployeeFields } from "../../../../../../features/Employees/hooks/useEmployeeFields";
import { usePreppedEmployees } from "../../../../../../features/Employees/hooks/usePreppedEmployees";
import { useSubordinates } from "../../../../../../features/Employees/hooks/useSubordinates";
import RoleOverviewTemplate from "../../../../../../features/Roles/components/templates/RoleOverviewTemplate";
import { useDepartmentTree } from "../../../../../../features/Roles/hooks/useDepartmentTree";
import { useRoleRelationships } from "../../../../../../features/Roles/hooks/useRoleRelationships";
import { useSnapshots } from "../../../../../../features/Snapshots/hooks/useSnapshots";
import { showRatingColor } from "../../../../../../features/Snapshots/utils/snapshotHelpers";
import { useOrgNodeContext } from "../../OrgNodePage";

function getDepartmentValues(cohort) {
    let hasConfidenceVal = cohort.filter((emp) => emp.hasOwnProperty("projected"));
    let confidence = hasConfidenceVal.reduce((acc, emp) => acc + emp.projected, 0) / hasConfidenceVal.length;
    confidence = isNaN(confidence) ? "N/A" : Math.round(confidence);
    return { confidence };
}

const INCLUDE_TOP_ROLE = false;

const includedFields = ["parentDepartments", "projected"];

const RoleOverviewPage = () => {
    const { roleId, navigateToOrgNode } = useOrgNodeContext();
    const { childRoleIds, subDepartments } = useRoleRelationships(roleId);
    const subordinates = useSubordinates(roleId, INCLUDE_TOP_ROLE);
    const traits = useSelector((state) => state.workspace.traits);
    const talentAreas = useSelector((state) => state.workspace.talentAreas);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const { getFilteredSnapshots } = useSnapshots();
    const enabledModules = useSelector((state) => state.config.everyoneConfig.enabledModules);
    const showObjectives = enabledModules.includes("objectives");
    const { filteredFields } = useEmployeeFields(includedFields);
    const preppedEmployees = usePreppedEmployees(subordinates, filteredFields);
    const departmentTree = useDepartmentTree(roleId, preppedEmployees, getDepartmentValues);
    const assessmentColors = useSelector(getAssessmentColors);
    const hasSubDepartments = subDepartments.length > 0;

    const handleGetColor = (value) => {
        return showRatingColor(assessmentColors, value);
    };

    // snapshots where the live array on objectives is not empty
    const snapshots = useSelector((state) => state.snapshots.employeeSnapshots);
    const hasObjectives = Object.values(snapshots).filter((snap) => snap.objectives.live.length > 0);

    return (
        <div className="page page-padding">
            <RoleOverviewTemplate
                roleId={roleId}
                subordinates={preppedEmployees}
                childRoleIds={childRoleIds}
                traits={traits}
                talentAreas={talentAreas}
                talentBoards={talentBoards}
                showObjectives={showObjectives}
                departmentTree={departmentTree}
                hasSubDepartments={hasSubDepartments}
                hasObjectives={hasObjectives}
                getColor={handleGetColor}
                getFilteredSnapshots={getFilteredSnapshots}
                onNavigateToOrgNode={navigateToOrgNode}
            />
        </div>
    );
};

export default RoleOverviewPage;
