import { EditorState, SelectionState } from 'draft-js';

export const moveSelectionToEnd = (editorState) => {
    const content = editorState.getCurrentContent();
    const selection = SelectionState.createEmpty(content.getLastBlock().getKey());
    const newSelection = selection.merge({
        anchorKey: content.getLastBlock().getKey(),
        anchorOffset: content.getLastBlock().getLength(),
        focusKey: content.getLastBlock().getKey(),
        focusOffset: content.getLastBlock().getLength(),
    });
    return EditorState.forceSelection(editorState, newSelection);
};