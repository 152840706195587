import React from "react";
import { useSelector } from "react-redux";
import MyCheckbox from "../../../../../../../components/MyComponents/Checkbox";
import MyInputGroup from "../../../../../../../components/MyComponents/InputGroup";
import MyTextInput from "../../../../../../../components/MyComponents/TextInput";

const DetailsStep = ({ table, onChange }) => {
    const { tableName, shared } = table;
    const isAdmin = useSelector((state) => state.user.isAdmin);


    const handleChangeName = (field, newVal) => {
        onChange(field, newVal);
    };

    const handleChangeShared = () => {
        onChange("shared", !shared);
    };

    return (
        <div className="space-y-6">
            <MyInputGroup label="Table Name" name="tableName">
                <MyTextInput value={tableName || ""} onChange={handleChangeName} />
            </MyInputGroup>
            <div className="max-w-xs">
                {isAdmin && (
                    <MyCheckbox
                        label="Share With Workspace"
                        checked={shared}
                        onChange={handleChangeShared}
                        className="max-w-xs"
                    />
                )}
            </div>
        </div>
    );
};

export default DetailsStep;
