import { createSelector } from "@reduxjs/toolkit";
import { sortArrayOfObjects } from "../../../helpers/helpers";
import { getAssessmentColors } from "../../Config/utils/configSelectors";
import { getEmployees, getSelectedEmployeeId } from "../../Employees/utils/employeeSelectors";
import { getRoles } from "../../Roles/utils/roleSelectors";
import { showRatingColor } from "./snapshotHelpers";

const getEmployeeSnapshots = createSelector(
    [(state) => state.snapshots.employeeSnapshots],
    (employeeSnapshots) => employeeSnapshots
);

const getSelectedSnapshot = createSelector(
    [getEmployeeSnapshots, getSelectedEmployeeId],
    (snapshots, selectedEmployeeId) => snapshots[selectedEmployeeId] || {}
);

/// NOTE: This filters out employees who have a manager who is not a user
// This should be changed so when reminders are sent to those managers, they get invites
const getOverdueAssessments = createSelector(
    [getEmployees, getRoles, getEmployeeSnapshots],
    (employees, roles, snapshots) => {
        let overdueEmployees = Object.values(employees || {})
            .filter((employee) => {
                const role = roles[employee.roleId];
                const parentRole = roles[role?.parentRoleId];
                //const manager = employees[parentRole?.incumbentId];
                const hasManager = parentRole?.incumbentId;
                //                const managerIsUser = manager?.isUser;
                const hasParent = role?.parentRoleId;
                const isRoot = role?.rootRole;
                const doNotAssess = role?.doNotAssess;
                //    return role && hasManager && managerIsUser && hasParent && !isRoot && !doNotAssess;
                return role && hasManager && hasParent && !isRoot && !doNotAssess;
            })
            .map((employee) => {
                const role = roles[employee.roleId];
                const parentRole = roles[role.parentRoleId];
                const manager = employees[parentRole?.incumbentId];
                const managerName = manager?.displayName;
                const snapshot = snapshots[employee.id];
                const { date, expired } = snapshot || {};
                return {
                    employeeId: employee.id,
                    displayName: employee.displayName,
                    managerName: managerName,
                    assessedDate: date,
                    expired: !snapshot || expired,
                    parentRoleId: role?.parentRoleId,
                };
            })
            .filter((employee) => {
                return employee.expired;
            });

        overdueEmployees = sortArrayOfObjects(overdueEmployees, "assessedDate", true);

        return overdueEmployees;
    }
);

const getRatingColor = createSelector([getAssessmentColors, (state, rating) => rating], (colors, rating) => {
    return showRatingColor(colors, rating);
});

export { getEmployeeSnapshots, getOverdueAssessments, getRatingColor, getSelectedSnapshot };
