import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import applogger from "../../../../../common/utils/applogger";
import { sortArrayOfObjects } from "../../../../../common/utils/arrayUtils";
import { LoadingIndicator } from "../../../../../components";
import { useFetched } from "../../../../../hooks/useFetched";
import { selectTrait } from "../../../../../redux/appSlice";
import { useEmployee } from "../../../../Employees/hooks/useEmployee";
import { useTrait } from "../../../../Traits/hooks/useTrait";
import { getBoardTraitIds } from "../../../../Workspace/utils/workspaceHelpers";
import { useDraftReview } from "../../../hooks/useDraftReview";
import ReviewEditor from "../ReviewEditor";
import ManagerAssessmentStep from "./steps/ManagerAssessmentStep";
import NotesAndFlagsStep from "./steps/NotesAndFlagsStep";
import SelfAssessmentStep from "./steps/SelfAssessmentStep";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const allSteps = [
    { segmentId: "managerAssessment", label: "Talent Assessment", component: ManagerAssessmentStep },
    { segmentId: "selfAssessment", label: "Self Assessment", component: SelfAssessmentStep },
];

const notesStep = { segmentId: "notes", label: "Comments & Indicators", component: NotesAndFlagsStep };

function getSteps(relationship, includeNotesPage) {
    function helper_getSegmentId() {
        switch (relationship) {
            case "manager":
                return "managerAssessment";
            case "employee":
                return "selfAssessment";
            default:
                return null;
        }
    }
    const segmentId = helper_getSegmentId();
    let segmentSteps = allSteps.filter((step) => step.segmentId === segmentId);
    if (includeNotesPage) {
        segmentSteps.push(notesStep);
    }

    return segmentSteps;
}

const TalentReviewContainer = ({ values, actions }) => {
    const navigate = useNavigate();
    const [draftSaving, setDraftSaving] = useState(false);
    const dispatch = useDispatch();
    const {
        relationship,
        savedReview,
        savedNotes,
        activeEmployee,
        initialFlagsAndRatings,
        unsubmittedSaves,
        includeNotesPage,
        preview,
    } = values;
    const { onSaveDraft, onSubmit } = actions;
    const steps = useMemo(() => getSteps(relationship, includeNotesPage), [relationship, includeNotesPage]);
    const traits = useSelector((state) => state.workspace.traits);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const learningInterventions = useSelector((state) => state.workspace.actions);
    const selectedTraitId = useSelector((state) => state.app.selectedTraitId);
    const selectedTrait = useTrait(selectedTraitId);
    const linkedActionIds = selectedTrait?.linkedActionIds;
    const [fetchTrait] = useFetched("traits");
    const [fetchAction] = useFetched("actions");
    const draftReview = useDraftReview(savedReview, initialFlagsAndRatings);
    const { updateRatings, toggleActionId } = draftReview;
    const { selectedEmployee } = useEmployee(activeEmployee.id);
    const linkedBoardIds = selectedEmployee.linkedTalentBoardIds;
    const hasUnsubmittedSaves = unsubmittedSaves > 0;
    const shouldSubmit = draftReview.draftHasChanged || hasUnsubmittedSaves;
    const confirmLabel = "Submit";

    const traitIdsToAssess = useMemo(() => {
        return getBoardTraitIds(linkedBoardIds, talentBoards, traits);
    }, [linkedBoardIds, talentBoards, traits]);

    const includedTraits = useMemo(() => {
        const unsorted = traitIdsToAssess.map((traitId) => {
            return traits[traitId];
        });
        return sortArrayOfObjects(unsorted, "linkedTalentAreaId", "desc");
    }, [traitIdsToAssess, traits]);

    // Navigate away if selectedTrait is undefined after 5 seconds
    useEffect(() => {
        if (traitIdsToAssess.length === 0) {
            toast.error("No traits to assess, exited review");
            navigate(-1);
        }
    }, [navigate, traitIdsToAssess]);

    // delselect trait on unmount
    useEffect(() => {
        return () => {
            dispatch(selectTrait(null));
        };
    }, [dispatch]);

    // If no trait is selected, select the first one
    useEffect(() => {
        const firstTraitId = traitIdsToAssess[0];
        if (!selectedTraitId) {
            dispatch(selectTrait(firstTraitId));
        }
    }, [dispatch, selectedTraitId, traitIdsToAssess]);

    // Gather the recommended actions for the selected trait
    const recommendedActions = useMemo(() => {
        const ids = linkedActionIds || [];
        return ids
            .map((actionId) => {
                return learningInterventions[actionId];
            })
            .filter(Boolean);
    }, [learningInterventions, linkedActionIds]);

    const handleSelectTrait = (traitId) => {
        dispatch(selectTrait(traitId));
    };

    const handleChangeRating = (traitId, rating) => {
        const newRatings = { [traitId]: rating };
        updateRatings(newRatings);
        handleSelectTrait(traitId);
    };

    const handleScheduleAction = (actionId) => {
        toggleActionId(actionId);
    };

    const handleSaveDraft = async () => {
        let notifySaving = false;
        if (draftReview.draftHasChanged) {
            try {
                applogger.info("Draft has changed, saving...");
                onSaveDraft(draftReview.draft);
                notifySaving = true;
            } catch (error) {
                applogger.error("Error saving draft", error);
            }
        }
        if (notifySaving) {
            setDraftSaving(true);
            setTimeout(() => setDraftSaving(false), 750);
        }
    };

    const handleSubmit = async () => {
        if (preview) return;
        onSubmit(draftReview.draft, shouldSubmit);
    };

    const enhancedValues = {
        ...values,
        draftSaving,
        savedNotes,
        draftNotes: draftReview?.draft?.notes || [],
        draftReview,
        traitIdsToAssess,
        includedTraits,
        selectedTrait,
        selectedTraitId,
        recommendedActions,
    };

    const enhancedActions = {
        ...actions,
        fetchTrait,
        fetchAction,
        selectTrait: handleSelectTrait,
        changeRating: handleChangeRating,
        toggleScheduledAction: handleScheduleAction,
        onSaveDraft: handleSaveDraft,
        onSubmit: handleSubmit,
    };

    if (!selectedTrait) return <LoadingIndicator fullscreen />;

    return (
        <ReviewEditor
            isOpen={true}
            values={enhancedValues}
            actions={enhancedActions}
            submitLabel={confirmLabel}
            removePadding
        >
            {steps.map((step, index) => {
                const StepComponent = step.component;
                return <StepComponent step={step} key={`cycle-editor-${index}`} />;
            })}
        </ReviewEditor>
    );
};

export default TalentReviewContainer;
