// src/utils/localStorage.js
const defaultConfig = {
    orgView: "chart",
    activeOrgTableId: "allEmp",
    nodeView: {
        fieldId: "overallRating",
        fieldValue: "overallRating",
    },
    autoCloseSiblings: true,
    ratingFilter: "employee",
    showName: true,
    showJobTitle: false,
};

export const loadLocalConfig = (uid, activeWorkspaceId) => {
    try {
        if (!uid || !activeWorkspaceId) return defaultConfig;
        const prevUid = localStorage.getItem("userId");
        if (prevUid !== uid) {
            // saved config is for a different user
            localStorage.clear();
            return defaultConfig;
        } else {
            // load the previously saved workspace config for this user
            const serializedState = localStorage.getItem(activeWorkspaceId);
            if (serializedState === null) {
                return defaultConfig;
            }
            const localConfig = JSON.parse(serializedState);
            // eslint-disable-next-line no-unused-vars
            const { talentMaps, orgTables, ...rest } = localConfig;
            return { ...defaultConfig, ...rest };
        }
    } catch (err) {
        console.error("Could not load config", err);
        return defaultConfig;
    }
};

export const saveLocalConfig = (key, value) => {
    try {
        switch (key) {
            case "userId":
                localStorage.setItem("userId", value);
                break;
            default:
                const serializedState = JSON.stringify(value);
                localStorage.setItem(key, serializedState);
        }
    } catch (err) {
        console.error("Could not save config", err);
    }
};
