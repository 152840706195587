import { collection, doc, getDoc, getDocs, collectionGroup, writeBatch } from "firebase/firestore";
import _, { chunk } from "lodash";
import applogger from "../common/utils/applogger";
import { db } from "./firebase";

export function getWorkspaceInfoRef() {
    return doc(db, "metaData","workspaceInfo");
}

export function getUsersCollectionRef() {
    return collection(db, "users");
}

export function getWorkspacesCollectionRef() {
    return collection(db, "workspaces");
}

export function getCollectionRef(workspaceId, subCollection) {
    return collection(db, "workspaces", workspaceId, subCollection);
}

export function getSubCollectionRef(ref, subCollection) {
    return collection(ref, subCollection);
}

export function getCollectionGroupRef(collectionName) {
    return collectionGroup(db, collectionName);
}

export function getDocRef(collectionRef, docId) {
    if (docId) {
        return doc(collectionRef, docId);
    } else {
        return doc(collectionRef);
    }
}

export async function fetchDoc(docRef, callback) {
    const doc = await getDoc(docRef);
    if (doc.exists()) {
        callback({ id: doc.id, ...doc.data() });
    } else {
        callback(false);
    }
}

export function createSubmissionBatches(transactionQueue, batchSize = 400) {
    if (batchSize > 400) throw new Error("Batch size must be less than 400");
    const submissionBatches = chunk(transactionQueue, batchSize).map((group) => {
        let batch = writeBatch(db);
        group.forEach((transaction) => {
            if (transaction.deleteDoc) {
                batch.delete(transaction.ref);
            } else {
                batch.set(transaction.ref, transaction.data, { merge: true });
            }
        });
        return batch;
    });
    return submissionBatches;
}

export async function submitBatches(batches) {
    try {
        if (batches.length > 0) {
            const promises = batches.map((batch) => batch.commit());
            await Promise.all(promises);
            applogger.info("Batches submitted");
            return true;
        } else {
            return false;
        }
    } catch (error) {
        applogger.error("Error submitting batches", error);
        return false;
    }
}

export async function fetchCollection(collectionRef, callback) {
    const docs = await getDocs(collectionRef);
    let docData = [];
    docs.forEach((doc) => {
        docData.push({ id: doc.id, ...doc.data() });
    });
    callback(docData);
}

export function createBatch() {
    return writeBatch(db);
}

export function batchSet(docRef, data, batch = writeBatch(db)) {
    batch.set(docRef, data, { merge: true });
    return batch;
}

export function disableDoc(docRef, batch = writeBatch(db)) {
    // Cloud function will handle cleanup
    batch.set(docRef, { disabled: true }, { merge: true });
    return batch;
}

// Firestore has a batch size limit of 500 operations; this breaks batches down into chunks of 400
export function createPromiseChunks(transactions, writeOperation) {
    const batchArray = _.chunk(transactions, 400).map((transactionBatch) => {
        let batch = writeBatch(db);
        transactionBatch.forEach((transaction) => {
            const { employeeId } = transaction;
            batch = writeOperation(employeeId, transaction, batch);
        });
        return batch;
    });
    const promises = batchArray.map((batch) => batch.commit());
    return promises;
}

export function editDoc(docRef, receivedData, batch = writeBatch(db)) {
    // Excludes fields that may have been added dynamically for use in-app
    const {
        id,
        displayName,
        linkedTraitIds,
        linkedActionIds,
        linkedTalentBoardIds,
        linkedTalentAreaId,
        employeeValues,
        isLinked,
        parentDepartment,
        ...validData
    } = receivedData // eslint-disable-line no-unused-vars

    // Remove keys if they're undefined or empty strings
    Object.keys(validData).forEach((key) => {
        const value = validData[key];
        if (validData[key] === undefined || value === "") {
            delete validData[key];
        }
    });

    // Skip update if there's nothing to change
    if (Object.keys(validData).length === 0) return batch;
    batch.set(docRef, validData, { merge: true });

    return batch;
}

// Do a deep comparison to previous values, and replace all null values or removed keys with deleteField()
/*
function deleteNullOrRemoved(dirty, prevVals = {}) {
    let clean = { ...dirty };

    // Set null values to deleteField()
    Object.keys(clean).forEach((key) => {
        if (clean[key] === null) {
            clean[key] = deleteField();
        } else if (typeof clean[key] === "object") {
            deleteNullOrRemoved(clean[key], prevVals[key]);
        }
    });

    // Remove deleted keys
    if (prevVals) {
        Object.keys(prevVals).forEach((key) => {
            if (prevVals[key] && !Object.keys(clean).includes(key)) {
                clean[key] = deleteField();
            }
        });
    }
    return clean;
}
*/
