import classNames from "classnames";
import React, { forwardRef, useEffect, useState } from "react";

const PromptInput = forwardRef(
    (
        {
            locked,
            value,
            size = "xl",
            disabled,
            maxLength = 800,
            placeholder,
            onChange = () => {},
            onSubmit = () => {},
        },
        ref
    ) => {
        const [newPrompt, setNewPrompt] = useState(value);

        useEffect(() => {
            setNewPrompt(value);
        }, [value]);

        const handleSubmit = () => {
            if (newPrompt && newPrompt.length > 0) {
                onSubmit(newPrompt);
                setNewPrompt(value);
            } else {
                // Empty; reset to inital value and don't submit
                setNewPrompt(value);
            }
        };

        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                event.target.blur();
            }
        };

        const handleNameBlur = () => {
            handleSubmit();
        };

        const handleChange = (e) => {
            const newVal = e.target.value;
            if (newVal.length > maxLength) {
                return;
            }
            setNewPrompt(e.target.value);
            onChange(e.target.value);
        };

        if (locked) {
            return <div className={classNames(`text-${size}`, "bg-base-100 w-full font-medium")}>{value}</div>;
        }

        return (
            <input
                ref={ref}
                className={classNames(
                    "bg-transparent font-semibold w-full",
                    "focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none",
                    `text-${size}`
                )}
                max={maxLength}
                value={newPrompt || ""}
                disabled={disabled}
                placeholder={placeholder}
                onBlur={handleNameBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        );
    }
);

export default PromptInput;
