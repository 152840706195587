export function countBlockerOccurrences(snapshots) {
    const blockerCount = {};
    snapshots.forEach((snapshot) => {
        snapshot?.objectives?.live?.forEach((item) => {
            item?.blockers?.forEach((blockerId) => {
                if (!blockerCount[blockerId]) {
                    blockerCount[blockerId] = 1;
                } else {
                    blockerCount[blockerId]++;
                }
            });
        });
    });
    return blockerCount;
}

export function getBlockerGroups(arr = [], result = {}) {
    arr.forEach((item) => {
        const itemBlockers = item.blockers || [];
        itemBlockers.forEach((blocker) => {
            if (!result[blocker]) {
                result[blocker] = [];
            }
            if (!result[blocker].includes(item.empId)) {
                result[blocker].push(item.empId);
            }
        });
        if (item.cascades && item.cascades.length > 0) {
            getBlockerGroups(item.cascades, result);
        }
    });
    return result;
}
