import Fuse from "fuse.js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./search.scss";
import classNames from "classnames";

const SearchField = (props) => {
    const [textEntered, setTextEntered] = useState();
    const [itemList, setItemList] = useState([]);
    const { items, displayKey, searchKeys, idKey, placeholder, onChange } = props;


    useEffect(() => {
        const list = Object.values(items).map((item) => {
            return {
                name: item[displayKey],
                value: item[idKey],
                ...item,
            };
        });
        setItemList(list);
    }, [items, displayKey, idKey]);

    function fuzzySearch(value) {
        const fuse = new Fuse(itemList, {
            keys: searchKeys,
            threshold: 0.2,
            ignoreLocation: true,
        });
        if (!value.length) {
            return [];
        }
        const filtered = fuse.search(value);
        const returnList = filtered.map((item) => {
            return { ...item.item };
        });
        return returnList;
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setTextEntered(value);
        const filteredItems = fuzzySearch(value);
        const filteredIds = filteredItems.map((item) => item.value);
        onChange(value.length > 0 ? filteredIds : false);
    };

    const style = { minWidth: props.minWidth };

    return (
        <div style={style}>
            <label
                className={classNames(
                    "bg-white px-4 rounded-lg border h-10 flex items-center gap-2",
                    "focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none"
                )}
            >
                <input
                    value={textEntered || ""}
                    type="text"
                    className="grow font-normal focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none"
                    placeholder={placeholder}
                    onChange={handleChange}
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="w-4 h-4 opacity-70"
                >
                    <path
                        fillRule="evenodd"
                        d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                        clipRule="evenodd"
                    />
                </svg>
            </label>
        </div>
    );
};

SearchField.propTypes = {
    searchKeys: PropTypes.array,
    displayKey: PropTypes.string,
    hideBorder: PropTypes.bool,
    idKey: PropTypes.string,
    placeholder: PropTypes.string,
    minWidth: PropTypes.number,
    onItemClick: PropTypes.func,
};

SearchField.defaultProps = {
    searchKeys: ["name"],
    displayKey: "name",
    hideBorder: true,
    idKey: "id",
    placeholder: "Search",
    minWidth: 200,
    onItemClick: () => {},
};

export default SearchField;
