import { useMemo } from "react";
import { db } from "../firebase";
import { useSelector } from "react-redux";
import { doc } from "firebase/firestore";

export function useWorkspaceRef() {
    const workspaceId = useSelector((state) => state.user.activeWorkspaceId);

    return useMemo(() => {
        if (!workspaceId || !db) return null;
        const workspaceRef = doc(db, "workspaces", workspaceId);
        return workspaceRef;
    }, [workspaceId]);
}
