import { useRef } from "react";
import { Button, Each } from "../../../../../common/components";
import ReviewHistoryItem from "./ReviewHistoryItem";

const ReviewHistoryGroup = ({
    userEmployeeId,
    reviews,
    fetchStatus,
    hasMore,
    fetchMore,
    getEmployee,
    onReviewClick,
}) => {
    const endOfListRef = useRef(null);

    const handleFetchMore = () => {
        fetchMore();
        endOfListRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="space-y-6 flex-1 flex flex-col">
            <div className="space-y-1 flex-1 flex flex-col">
                <Each
                    of={reviews}
                    emptyStateProps={{ className: "flex-1", message: "No Completed Reviews" }}
                    render={(review) => (
                        <ReviewHistoryItem
                            review={review}
                            userEmployeeId={userEmployeeId}
                            getEmployee={getEmployee}
                            onReviewClick={onReviewClick}
                        />
                    )}
                />
            </div>
            <div ref={endOfListRef} className="flex justify-center">
                {!hasMore ? null : (
                    <Button onClick={handleFetchMore} disabled={fetchStatus === "loading" || !hasMore}>
                        {fetchStatus === "loading" ? "Loading..." : "Load More"}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ReviewHistoryGroup;
