import React from "react";
import DevelopmentPlan from "../../../../../../features/Actions/View/DevelopmentPlan";
import { useOrgNodeContext } from "../../OrgNodePage";

const DevelopmentPlanPage = () => {
    const { activeEmployee, loading, completeActions, cancelActions, scheduleActions } = useOrgNodeContext();
    if (loading) return null;
    return (
        <div className="page">
            <DevelopmentPlan
                activeEmployee={activeEmployee}
                completeActions={completeActions}
                cancelActions={cancelActions}
                scheduleActions={scheduleActions}
            />
        </div>
    );
};

export default DevelopmentPlanPage;
