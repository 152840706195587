import { removeUndefined } from "../../../common/utils/objectUtils";

export function calculateObjectivePerformance(objectives = []) {
    const objectivesWithResults = objectives.filter((obj) => obj.result !== null && obj.result !== undefined);
    const totalObjectiveCount = objectives.length;
    const scoreTotal = objectivesWithResults.reduce((acc, obj) => acc + obj?.result, 0);
    const performanceScore = scoreTotal / totalObjectiveCount;
    return Math.round(performanceScore);
}


export function calculateObjectiveConfidence(objectives = []) {
    const objectivesWithConfidence = objectives.filter((obj) => obj.confidence !== null && obj.confidence !== undefined);
    const totalObjectiveCount = objectives.length;
    const scoreTotal = objectivesWithConfidence.reduce((acc, obj) => acc + obj?.confidence, 0);
    const confidenceScore = scoreTotal / totalObjectiveCount;
    return Math.round(confidenceScore);
}

export function createObjectiveSnapshot(objectiveSnapshot) {
    if (!objectiveSnapshot) return null;
    const { l: live, r: result } = objectiveSnapshot;
    let liveCount = 0;
    let liveObjectives = [];
    let completeObjectives = [];
    let totalConfidence = 0;

    ///

    Object.entries(live || {}).forEach(([key, obj]) => {
        const { b: blockers, c: confidence = 0, p: parentId, r: outcome } = obj;
        const preppedObjective = { id: key, confidence, blockers, parentId };
        // check if outcome if a number
        const hasOutcome = typeof outcome === "number";
        removeUndefined(preppedObjective);
        preppedObjective.result = hasOutcome ? outcome : null;
        if (!hasOutcome) {
            liveCount += 1;
            totalConfidence += confidence;
            liveObjectives.push(preppedObjective);
        } else {
            completeObjectives.push(preppedObjective);
        }
    });

    ///

    // Calculate overall objective confidence and performance
    let avgConfidence = Math.round(totalConfidence / liveCount);
    let returnVal = {
        count: liveCount,
        projected: isNaN(avgConfidence) ? 0 : avgConfidence,
        live: liveObjectives,
        complete: completeObjectives,
    };

    // Previous performance if there is any
    if (result) {
        returnVal.result = result;
    }

    return returnVal;
}

export function prepObjectiveSnapshot(objectives = {}) {
    const objectivesArray = Array.isArray(objectives) ? objectives : Object.values(objectives);

    let objectivesSnapshot = {};
    let live = {};
    objectivesArray.forEach((obj) => {
        let update = {
            c: obj.confidence || null,
            b: obj.blockers || [],
            r: obj.result || null,
        };
        if (obj.parentId) update.p = obj.parentId;
        live[obj.id] = update;
    });
    objectivesSnapshot.l = live;

    // Record the result of this objective review
    const completedObjectives = objectivesArray.filter((obj) => obj.isComplete);
    if (completedObjectives.length > 0) {
        const objectivePerformance = calculateObjectivePerformance(completedObjectives);
        objectivesSnapshot.r = objectivePerformance;
    }
    return objectivesSnapshot;
}
