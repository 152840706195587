import { query, where } from "firebase/firestore";
import { useCallback, useMemo } from "react";
import { useListener } from "../../../firebase/hooks/useListener";
import { prepQuerySnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { useEmployeeRefs } from "../../Employees/refs/useEmployeeRefs";

export function useIterationObjectiveListener(employeeId, iterationId, onDataReceived) {
    const { objectivesRef } = useEmployeeRefs(employeeId);

    const shouldListen = useMemo(() => {
        if (!iterationId) return false;
    }, [iterationId]);

    const iterationObjectivesRef = useMemo(() => {
        if (!iterationId || !objectivesRef) return null;
        return query(objectivesRef, where("includedInIterationIds", "array-contains", iterationId));
    }, [iterationId, objectivesRef]);

    const handleDataReceieved = useCallback((querySnapshot) => {
        const data = prepQuerySnapshot(querySnapshot);
        onDataReceived(data);
    }, [onDataReceived]);

    return useListener(iterationObjectivesRef, handleDataReceieved, shouldListen);
}
