import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ROOT_ROLE_ID } from "shared";

function createTreeNode(roleId, parentMap) {
    const childRoles = Object.entries(parentMap)
        .filter(([_, parentRoleId]) => parentRoleId === roleId)
        .map(([roleId]) => roleId);
    let node = {
        roleId: roleId,
    };
    if (roleId === ROOT_ROLE_ID) {
        node.rootRole = true;
    }
    if (childRoles.length > 0) {
        const sortedChildRoles = [...childRoles].sort();
        node.nodes = sortedChildRoles.map((childRoleId) => {
            return createTreeNode(childRoleId, parentMap);
        });
    }
    return node;
}

export function useOrgChart(topLevelRoleId) {
    const parentMap = useSelector((state) => state.businessUnit.parentMap);
    const orgTree = useMemo(() => {
        return createTreeNode(topLevelRoleId, parentMap);
    }, [topLevelRoleId, parentMap]);

    return { orgTree };
}
