import classNames from "classnames";
import { darken } from "polished";
import React, { createRef, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getAssessmentColors } from "../../../Config/utils/configSelectors";
import { showRatingColor } from "../../../Snapshots/utils/snapshotHelpers";

const TraitScroll = ({
    traits,
    selectedTraitId,
    onSelectTrait,
    ratings,
    showRatingColors,
}) => {
    const assessmentColors = useSelector(getAssessmentColors);
    const refs = useRef({});
    traits.forEach((trait) => {
        refs.current[trait.id] = refs.current[trait.id] || createRef();
    });

    useEffect(() => {
        // If the selected trait ID changes and the ref exists, scroll it into view
        if (traits && selectedTraitId && refs.current[selectedTraitId]) {
            refs.current[selectedTraitId].current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
            });
        }
    }, [selectedTraitId, traits]);

    function getStyle(traitId, isSelected) {
        let style = {};
        if (ratings && isSelected && showRatingColors) {
            const rating = parseInt(ratings?.[traitId] || 0);
            let ratingColor = showRatingColor(assessmentColors, rating);
            const textColor = darken(0.3, ratingColor);
            style = {
                backgroundColor: ratingColor,
                color: textColor,
            };
        }
        return style;
    }

    return (
        <div className={classNames("h-full overflow-y-auto hide-scroll-bars flex-1 bg-base-300 py-3")}>
            {traits.map((trait) => {
                const isSelected = selectedTraitId === trait.id;
                const style = getStyle(trait.id, isSelected);
                return (
                    <div key={trait.id} className="flex w-full items-center justify-stretch">
                        <button
                            ref={refs.current[trait.id]}
                            onClick={() => onSelectTrait(trait.id)}
                            style={style}
                            className={classNames("py-1 font-medium tracking-tight text-sm border-none rounded-none w-full", {
                                "bg-primary": isSelected && !showRatingColors,
                            })}
                        >
                            {trait.traitName}
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

export default TraitScroll;
