import classNames from "classnames";
import { groupBy } from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";

const TalentMapPicker = ({ className, classNamePrefix, onChange, selectedId }) => {
    const talentMaps = useSelector((state) => state.config.talentMaps);

    const talentMapOptions = useMemo(() => {
        const allTableOptions = Object.entries(talentMaps).map(([id, talentMap]) => {
            return {
                value: id,
                id: id,
                label: talentMap.talentMapName || "Default Talent Map",
                shared: talentMap.shared ? "Shared" : "Private",
            };
        });
        const optionGroups = groupBy(allTableOptions, "shared");
        const options = Object.entries(optionGroups).map(([key, options]) => {
            return {
                label: key,
                value: key,
                options: options,
            };
        });
        return options;
    }, [talentMaps]);

    const value = useMemo(() => {
        if (!selectedId) return [];
        const allOptions = talentMapOptions.flatMap((optionGroup) => optionGroup.options);
        return allOptions.find((option) => option.id === selectedId);
    }, [talentMapOptions, selectedId]);

    const handleChange = (option) => {
        onChange(option?.id);
    };

    return (
        <Select
            className={classNames("h-10 field-picker", className)}
            classNamePrefix={classNames("field-picker", classNamePrefix)}
            maxMenuHeight={320}
            type="select"
            onChange={handleChange}
            options={talentMapOptions}
            value={value}
        />
    );
};

export default TalentMapPicker;
