import classNames from "classnames";
import { darken } from "polished";
import { useSelector } from "react-redux";
import { getAssessmentColors } from "../../Config/utils/configSelectors";
import { showRatingColor } from "../utils/snapshotHelpers";

const AssessmentBadge = ({ rating }) => {
    const allColors = useSelector(getAssessmentColors);
    let style = {};
    if (rating) {
        const backgroundColor = showRatingColor(allColors, rating);
        const color = darken(0.3, backgroundColor);
        style = { backgroundColor: backgroundColor, color: color };
    }
    return (
        <div
            style={style}
            className={classNames("rating-badge text-xs flex items-center justify-center", {
                rated: !!rating,
                hidden: !rating,
            })}
        >
            {rating || "-"}
        </div>
    );
};

export default AssessmentBadge;
