import React, { Suspense, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import applogger from "./common/utils/applogger";
import { LoadingIndicator } from "./components";
import Page404 from "./Page404";
import {
    BusinessUnitPages,
    EmployeePages,
    LiveCyclePages,
    OrgPages,
    RolePages,
    SchedulePages,
    SettingsPages,
    TalentPages,
    WorkspacePages,
} from "./pages";
import "./scss/style.scss";
import { useSessionContext } from "./Session";
import WorkspaceFallback from "./features/Workspace/Components/WorkspaceFallback";

const App = () => {
    const navigate = useNavigate();
    const { authStatus, userEmployeeId } = useSessionContext();
    const claimsAreReady = useSelector((state) => state.user.claimsAreReady);
    const todoLink = `/organisation/view/employees/${userEmployeeId}/things-to-do`;
    const dashboardLink = userEmployeeId ? todoLink : "/organisation/chart";

    useEffect(() => {
        switch (authStatus) {
            case "signed-out":
                applogger.info("user is signed out; navigated to sign-in request");
                navigate("/sign-in/request");
                break;
            default:
                break;
        }
    }, [navigate, authStatus]);

    const renderRoleRoutes = () => (
        <Route path="roles">
            <Route path=":roleId">
                <Route path="role-overview" element={<RolePages.RoleOverview />} />
                <Route path="talent-map" element={<RolePages.TalentMap />} />
            </Route>
        </Route>
    );

    const renderEmployeeRoutes = () => (
        <Route path="employees">
            <Route path=":employeeId">
                <Route path="objectives">
                    <Route index element={<EmployeePages.Objectives />} />
                    <Route path=":objectiveId" element={<EmployeePages.ObjectiveView />} />
                </Route>
                <Route path="development-plan" element={<EmployeePages.DevelopmentPlan />} />
                <Route path="self-assessment" element={<EmployeePages.SelfAssessment />} />
                <Route path="talent-assessment" element={<EmployeePages.ManagerAssessment />} />
                <Route path="things-to-do" element={<EmployeePages.ReviewQueue />} />
                <Route path="reviews">
                    <Route path=":cycleId">
                        <Route path=":iterationId">
                            <Route path=":reviewerId" element={<EmployeePages.Review />} />
                        </Route>
                        <Route path="preview">
                            <Route path=":reviewerId" element={<EmployeePages.Preview />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="history" element={<EmployeePages.History />} />
                <Route path="comments" element={<EmployeePages.Comments />} />
            </Route>
        </Route>
    );

    const renderCycleRoutes = () => (
        <Route path="cycles">
            <Route path="live" element={<LiveCyclePages.ListContainer />}>
                <Route index element={<LiveCyclePages.CycleList />} />
                <Route path=":cycleId" element={<LiveCyclePages.CyclePage />}>
                    <Route path="iterations" element={<LiveCyclePages.Iterations />}>
                        <Route index element={<LiveCyclePages.IterationList />} />
                    </Route>
                    <Route path="tracking" element={<LiveCyclePages.Tracking />} />
                </Route>
            </Route>
        </Route>
    );

    if (!claimsAreReady) return <LoadingIndicator fullscreen />;
    return (
        <Suspense fallback={<WorkspaceFallback />}>
            <Routes>
                <Route path="*" element={<WorkspacePages.Workspace />} />
                <Route path="/" element={<WorkspacePages.Workspace />}>
                    <Route index element={<Navigate to={dashboardLink} />} />
                    <Route path="organisation" element={<OrgPages.Org />}>
                        <Route index element={<Navigate to="chart" />} />
                        <Route path="table" element={<OrgPages.OrgTable />} />
                        <Route path="chart" element={<OrgPages.OrgChart />} />
                        <Route path="map" element={<OrgPages.TalentMap />} />
                        <Route path="view" element={<OrgPages.OrgNode />}>
                            {renderRoleRoutes()}
                            {renderEmployeeRoutes()}
                        </Route>
                        <Route path="*" element={<Navigate to="chart" />} />
                    </Route>
                    <Route path="settings" element={<SettingsPages.Index />}>
                        <Route path="profile" element={<SettingsPages.Profile />} />
                        <Route path="upload-workspace" element={<SettingsPages.Upload />} />
                        <Route path="maintenance" element={<SettingsPages.Maintenance />} />
                        <Route path="workspace" element={<SettingsPages.Workspace />} />
                        <Route path="custom-fields" element={<SettingsPages.CustomFields />} />
                    </Route>
                    <Route path="business-units">
                        <Route index element={<BusinessUnitPages.BusinessUnits />} />
                        <Route path=":unitId" element={<BusinessUnitPages.BusinessUnits />} />
                    </Route>
                    <Route path="traits">
                        <Route index element={<TalentPages.Traits />} />
                    </Route>
                    <Route path="talentboards">
                        <Route index element={<TalentPages.TalentBoards />} />
                        <Route path=":boardId" element={<TalentPages.TalentBoard />}>
                            <Route path="bench" element={<TalentPages.BenchStrength />} />
                            <Route path="levels" element={<TalentPages.LevelConfig />} />
                            <Route path="settings" element={<TalentPages.BoardConfig />} />
                        </Route>
                    </Route>
                    <Route path="actions">
                        <Route index element={<TalentPages.Actions />} />
                        <Route path=":actionId" element={<TalentPages.Action />}>
                            <Route path="linked-traits" element={<TalentPages.ActionLinkedTraits />} />
                            <Route path="completed" element={<TalentPages.ActionCompleted />} />
                            <Route path="scheduled" element={<TalentPages.ActionScheduled />} />
                            <Route path="candidates" element={<TalentPages.ActionCandidates />} />
                            <Route path="config" element={<TalentPages.ActionConfig />} />
                        </Route>
                    </Route>
                    <Route path="talent-areas">
                        <Route index element={<TalentPages.TalentAreas />} />
                    </Route>
                    {renderCycleRoutes()}
                    <Route path="schedule">
                        <Route index element={<SchedulePages.Schedule />} />
                    </Route>
                </Route>
                <Route path="*" element={<Page404 />} />
            </Routes>
        </Suspense>
    );
};

export default App;
