export function getCascadeTree(objective, employeeSnapshots, getEmployee, getRoleOrgLevel) {
    const objectiveSnaps = Object.entries(employeeSnapshots).flatMap(([empId, snapshot]) => {
        const objectives = snapshot.objectives?.live || [];
        return objectives.map((objective) => ({ empId, ...objective }));
    });

    function helperGetCascaded(objective, level = 1) {
        const cascades = objectiveSnaps.filter((o) => {
            return o.parentId === objective.id;
        });
        const employee = getEmployee(objective?.empId || objective?.ownerEmployeeId);
        const orgLevel = getRoleOrgLevel(employee?.roleId);

        if (cascades.length > 0) {
            return {
                ...objective,
                cascadeLevel: level,
                orgLevel: orgLevel || null,
                cascades: cascades.map((cascade) => helperGetCascaded(cascade, level + 1)),
            };
        } else {
            return { ...objective, cascades: [], cascadeLevel: level, orgLevel: orgLevel };
        }
    }

    return helperGetCascaded(objective);
}

export function flattenCascadeTree(cascadeTree) {
    let objectives = [];
    function helperFlatten(objective) {
        // Add the current objective to the flat list
        objectives.push(objective);

        // If this objective has cascades, flatten them recursively
        if (objective.cascades && objective.cascades.length > 0) {
            objective.cascades.forEach(helperFlatten);
        }
    }
    helperFlatten(cascadeTree);
    return objectives;
}
