import classNames from "classnames";
import React from "react";
import { Button, Each } from "../../../../../common/components";
import ExpandableCard from "../../../../../common/components/organisms/ExpandableCard/ExpandableCard";
import { useToggle } from "../../../../../hooks/useToggle";
import { useEditedObjective } from "../../../hooks/useEditedObjective";
import ObjectiveDescription from "../../atoms/ObjectiveDescription";

const ObjectiveCascadeHeader = ({ objective, ownerEmployeeId, onCascade = () => {} }) => {
    const { createObjective } = useEditedObjective();
    const { objectiveName, description, measures } = objective;
    const [toggled, toggle] = useToggle(false);

    const handleCascade = (e) => {
        e.stopPropagation();
        const cascaded = createObjective("", ownerEmployeeId, objective);
        onCascade(cascaded);
    };

    const renderHeader = () => (
        <div
            className={classNames(
                "fade-in move-up items-center bg-base-100 flex px-6 py-2 space-x-3 border rounded-sm cursor-pointer"
            )}
        >
            <div className={classNames("flex items-center flex-2 text-md h-8")}>
                <h5 className="tracking-tight font-medium">{objectiveName}</h5>
            </div>
            <div className="flex space-x-2">
                <Button color="primary" variant="outline" extraClasses="border-none" size="xs" onClick={handleCascade}>
                    Create Linked Objective
                </Button>
            </div>
        </div>
    );

    return (
        <ExpandableCard headerComponent={renderHeader()} toggled={toggled} onHeaderClick={toggle}>
            <div className="px-6 py-4 bg-white space-y-4">
                <div>
                    <h6 className="text-sm text-base-800 font-semibold tracking-tight">Description</h6>
                    <ObjectiveDescription description={description} readOnly={true} />
                </div>
                <hr className="border-secondary" />
                <div>
                    <h6>Measures</h6>
                    <Each
                        of={measures}
                        emptyStateProps={{ hidden: true }}
                        render={(measure) => <span className="text-sm">{measure.measureName}</span>}
                    />
                </div>
            </div>
        </ExpandableCard>
    );
};

export default ObjectiveCascadeHeader;
