import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BiChevronDown as DefaultToggleIcon } from "react-icons/bi";

const ToggleComponent = ({
    component = <DefaultToggleIcon size={24} />,
    flippedComponent = null,
    animate = "rotate",
    toggled = undefined,
    onToggle = null,
}) => {
    const isControlled = toggled !== undefined;
    const [isToggled, setIsToggled] = useState(toggled || false);

    useEffect(() => {
        if (isControlled) {
            setIsToggled(toggled);
        }
    }, [toggled, isControlled]);

    const handleToggle = (e) => {
        const newToggledState = !isToggled;
        if (!isControlled) {
            setIsToggled(newToggledState);
        }
        if (onToggle) {
            onToggle(e);
        }
    };

    const animationClasses = classNames({
        "transform transition-transform duration-500": animate,
        "rotate-180": animate === "rotate" && isToggled,
        "rotate-0": animate === "rotate" && !isToggled,
    });

    return (
        <div className="relative cursor-pointer" onClick={handleToggle}>
            <div className={animationClasses}>{isToggled ? flippedComponent || component : component}</div>
        </div>
    );
};

ToggleComponent.propTypes = {
    component: PropTypes.element,
    flippedComponent: PropTypes.element,
    animate: PropTypes.oneOf(["rotate"]),
    toggled: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default ToggleComponent;
