import { Children } from "react";
import MyEmptyState from "../../../../components/MyComponents/EmptyState";

const Each = ({ render, of, filter, renderEmpty, emptyStateProps = {} }) => {
    if (!of) return null;
    if (filter) {
        return Children.toArray(
            of.filter(filter).map((item, index) => {
                return render(item, index);
            })
        );
    }
    if (of.length === 0) {
        const preppedEmptyStateProps = {
            message: "No Items Found",
            className: "h-full",
            ...emptyStateProps,
        };
        if (renderEmpty) {
            return renderEmpty();
        } else {
            return <MyEmptyState {...preppedEmptyStateProps} />;
        }
    }
    return Children.toArray(
        of.map((item, index) => {
            return render(item, index);
        })
    );
};

export default Each;
