import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ScatterChart } from "../../../../../components";
import ChartContainer from "../../../../../components/Charts/ChartContainer";
import { getAssessmentColors } from "../../../../Config/utils/configSelectors";
import { getDefaultEmployeePageLink } from "../../../../Employees/hooks/useEmployees";
import { listAxisValue, showRatingColor } from "../../../../Snapshots/utils/snapshotHelpers";
import { useFieldLabels } from "../../../../Workspace/hooks/useFieldLabels";
import { useTalentMapConfig } from "../../../hooks/useTalentMapConfig";
import { useEmployee } from "../../../../Employees/hooks/useEmployee";

const CustomTooltip = ({ data }) => {
    const { getFieldLabel } = useFieldLabels();
    const { id, x, y, z } = data || {};
    const { selectedEmployee } = useEmployee(id);
    const { talentMapConfig } = useTalentMapConfig();
    const xLabel = getFieldLabel(talentMapConfig.x);
    const yLabel = getFieldLabel(talentMapConfig.y);
    const zLabel = getFieldLabel(talentMapConfig.z);

    const renderDataRow = (label, value) => (
        <div className="space-x-1">
            <span className="me-2">{label}</span>
            <span className="me-2">{value}</span>
        </div>
    );

    if (!selectedEmployee) return null;
    return (
        <div className="bg-info text-info-content rounded-lg w-60 border p-3">
            <h5 className="text-sm font-bold mb-2">{selectedEmployee.displayName}</h5>
            <div key={`${id}-scatter-dot`} className="flex flex-col space-y-2">
                {renderDataRow(xLabel, x)}
                {renderDataRow(yLabel, y)}
                {renderDataRow(zLabel, z)}
            </div>
        </div>
    );
};

const TalentScatterChart = (props) => {
    const navigate = useNavigate();
    const { talentMapConfig } = useTalentMapConfig();
    const { snapshots, filter } = props;
    const { x, y, z } = talentMapConfig;
    const [data, setData] = useState([]);
    const assessmentColors = useSelector(getAssessmentColors);
    const employees = useSelector((state) => state.businessUnit.employees);

    useEffect(() => {
        function createChartData(snapshots, x, y, z) {
            let allData = Object.entries(snapshots).map(([empId, snap]) => {
                const xVal = listAxisValue(snap, x);
                const yVal = listAxisValue(snap, y);
                const zVal = listAxisValue(snap, z);
                const color = showRatingColor(assessmentColors, zVal);
                const employee = employees[empId];
                const displayName = employee?.displayName;
                return { id: empId, label: displayName, x: xVal, y: yVal, z: zVal, color: color };
            });
            return allData.filter((d) => d.x && d.y && d.z);
        }
        const data = createChartData(snapshots, x, y, z);
        setData(data);
    }, [snapshots, employees, assessmentColors, filter, x, y, z]);

    const handleDotDoubleClick = (data) => {
        const link = getDefaultEmployeePageLink(data.id);
        navigate(link);
    };

    return (
        <ChartContainer responsiveHeight padding={10}>
            <ScatterChart data={data} onDotDoubleClick={handleDotDoubleClick} customTooltip={CustomTooltip} />
        </ChartContainer>
    );
};

export default TalentScatterChart;
