import { RATING_SCALE } from "../../../constants";

function cleanLegacyRatings(rating) {
    switch (rating) {
        case 20:
            return 1;
        case 40:
            return 25;
        case 60:
            return 50;
        case 80:
            return 75;
        default:
            return rating;
    }
}


// Legacy assessments might have ratings that don't match the current pattern
// Normalizes to ensure all ratings are valid
export function normalizeRatings(ratings) {
    if (!ratings) return null;
    const newRatings = {};
    Object.keys(ratings).forEach((traitId) => {
        const rating = ratings[traitId];
        const normalizedRating = normalizeRating(rating);
        if (rating) {
            newRatings[traitId] = normalizedRating;
        }
    });
    return newRatings;
}

export function normalizeAssessment(assessment) {
    if (!assessment) return null;
    const { ratings = {} } = assessment;
    const newRatings = normalizeRatings(ratings);
    return { ...assessment, ratings: newRatings };
}

export function normalizeRating(ratingValue) {
    // Ensure the value is within the expected range

    if (ratingValue < 0 || ratingValue > 100) {
        throw new Error("Value must be between 0 and 100");
    }
    let value = cleanLegacyRatings(ratingValue);

    // Define the target set of values
    const targetValues = RATING_SCALE;

    // Find the closest value in the target set
    let closestValue = targetValues[0];
    let smallestDifference = Math.abs(value - closestValue);

    for (let i = 1; i < targetValues.length; i++) {
        const currentDifference = Math.abs(value - targetValues[i]);
        if (currentDifference < smallestDifference) {
            smallestDifference = currentDifference;
            closestValue = targetValues[i];
        }
    }

    return closestValue;
}
