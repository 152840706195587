import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userSignOutThunk } from "../User/utils/userThunks";
import ErrorDefault from "./ErrorDefault";
import SignInErrorPage from "./SignInErrorPage";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    const dispatch = useDispatch();

    // clear local config on error
    useEffect(() => {
        if (error) {
            window.localStorage.clear();
        }
    }, [error]);

    const handleSignOut = () => {
        dispatch(userSignOutThunk());
        window.location.href = "/sign-in/request";
    };

    const handleTryAgain = () => {
        window.location.reload();
    };

    const handleGoHome = () => {
        window.location.href = "/";
    };

    switch (error?.message) {
        case "unauthorised":
            return <SignInErrorPage onSignOut={handleSignOut} />;
        default:
            return (
                <ErrorDefault
                    error={error}
                    onSignOut={handleSignOut}
                    onTryAgain={handleTryAgain}
                    onGoHome={handleGoHome}
                />
            );
    }
};

export default ErrorFallback;
