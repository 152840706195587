import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ensureSerializable } from "../../../common/utils/objectUtils";
import { formatName } from "../../../common/utils/stringUtils";
import { useListener } from "../../../firebase/hooks/useListener";
import { normalizeAssessment } from "../../Snapshots/utils/normalizeRating";
import { useIsThisMe } from "../../User/hooks/useIsThisMe";
import { useEmployeeRefs } from "../refs/useEmployeeRefs";

function normalizeAssessmentDoc(prepped) {
    let { talentAssessment, selfAssessment, ...normalized } = prepped;
    if (talentAssessment) {
        normalized.talentAssessment = normalizeAssessment(talentAssessment);
    }
    if (selfAssessment) {
        normalized.selfAssessment = normalizeAssessment(selfAssessment);
    }
    return normalized;
}

export function useEmployeeListener(empId) {
    const [employeeData, setEmployeeData] = useState(null);
    const [selfAssessment, setSelfAssessment] = useState(null);
    const [talentAssessment, setManagerAssessment] = useState(null);
    const { employeeRef, selfAssessmentRef, talentAssessmentRef } = useEmployeeRefs(empId);
    const enableSelfAssessment = useSelector((state) => state.config.everyoneConfig.featureFlips.selfAssessment);
    const isSelf = useIsThisMe(empId);

    const shouldListenEmployee = useMemo(() => {
        return !!empId;
    }, [empId]);

    const shouldListenManager = useMemo(() => {
        return !!empId && !isSelf;
    }, [empId, isSelf]);

    const shouldListenSelf = useMemo(() => {
        return !!empId && enableSelfAssessment;
    }, [empId, enableSelfAssessment]);

    const handleEmployeeDataReceived = useCallback((doc) => {
        let employeeData = doc.data();
        if (!employeeData) {
            setEmployeeData(null);
        } else {
            const employeeId = doc.id;
            employeeData = ensureSerializable(employeeData);
            const displayName = formatName(employeeData);
            const prepped = { ...employeeData, id: employeeId, displayName };
            setEmployeeData(prepped);
        }
    }, []);

    const handleAssessmentReceived = useCallback((snapshot) => {
        const doc = snapshot.docs[0];
        if (doc) {
            let talentAssessment = doc.data();
            talentAssessment = ensureSerializable(talentAssessment);
            const normalized = normalizeAssessmentDoc(talentAssessment);
            setManagerAssessment(normalized);
        } else {
            setManagerAssessment(null);
        }
    }, []);

    const handleSelfAssessmentReceived = useCallback((snapshot) => {
        const doc = snapshot.docs[0];
        if (doc) {
            let talentAssessment = doc.data();
            talentAssessment = ensureSerializable(talentAssessment);
            const normalized = normalizeAssessmentDoc(talentAssessment);
            setSelfAssessment(normalized);
        } else {
            setManagerAssessment(null);
        }
    }, []);

    const employeeStatus = useListener(employeeRef, handleEmployeeDataReceived, shouldListenEmployee);
    const selfStatus = useListener(selfAssessmentRef, handleSelfAssessmentReceived, shouldListenSelf);
    const managerStatus = useListener(talentAssessmentRef, handleAssessmentReceived, shouldListenManager);

    const employeeReady = employeeStatus === "success";
    const selfReady = !shouldListenSelf || selfStatus === "success";
    const managerStatusReady = !shouldListenManager || managerStatus === "success";
    const all = [employeeReady, selfReady, managerStatusReady];
    const allReady = all.every((status) => status);
    if (!allReady) return null;
    if (!employeeData) return "failed";
    const fullEmployee = { ...employeeData };
    if (selfAssessment) fullEmployee.selfAssessment = selfAssessment;
    if (talentAssessment) fullEmployee.talentAssessment = talentAssessment;
    return fullEmployee;
}
