import { getDoc } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../common/components";
import applogger from "../../../common/utils/applogger";
import { LoadingIndicator } from "../../../components";
import MyInputGroup from "../../../components/MyComponents/InputGroup";
import MyTextInput from "../../../components/MyComponents/TextInput";
import { FIELD_SIZE_LIMITS } from "../../../constants";
import SignInPanel from "../../../features/SignIn/components/templates/SignInPanel";
import { joinWorkspaceThunk } from "../../../features/User/utils/userThunks";
import { userSignOut } from "../../../firebase/auth";
import { getDocRef, getWorkspacesCollectionRef } from "../../../firebase/firebaseActions";

const JoinWorkspace = () => {
    const dispatch = useDispatch();
    const [failed, setFailed] = useState(false);
    const [invite, setInvite] = useState(null);
    const [userDetails, setUserDetails] = useState({});
    const [inviteAccepted, setInviteAccepted] = useState(false);
    const uid = useSelector((state) => state.app.uid);
    const { invites } = useSelector((state) => state.user);
    const workspacesRef = useMemo(() => getWorkspacesCollectionRef(), []);

    useEffect(() => {
        if (failed) {
            userSignOut();
        }
    }, [failed]);

    const firstInvite = useMemo(() => {
        if (!invites || invites.length === 0) return null;
        return invites[0];
    }, [invites]);

    const workspaceIdToJoin = firstInvite?.workspaceId;

    useEffect(() => {
        async function fetchWorkspace() {
            try {
                const workspaceRef = getDocRef(workspacesRef, workspaceIdToJoin);
                const workspaceDoc = await getDoc(workspaceRef);
                if (workspaceDoc.exists()) {
                    const workspaceData = workspaceDoc.data();
                    const { workspaceName } = workspaceData;
                    setInvite({ ...firstInvite, workspaceName });
                }
            } catch (error) {
                applogger.error("error fetching workspace", error);
                setFailed();
            }
        }
        if (workspaceIdToJoin) {
            fetchWorkspace();
        }
    }, [firstInvite, workspacesRef, workspaceIdToJoin]);

    useEffect(() => {
        const { firstname, surname } = invite || {};
        setUserDetails({ firstname, surname });
    }, [invite]);

    const handleSetValue = (field, value) => {
        const newUserDetails = { ...userDetails, [field]: value };
        setUserDetails(newUserDetails);
    };

    const handleJoin = () => {
        setInviteAccepted(true);
        const joinDetails = {
            ...invite,
            ...userDetails,
            uid: uid,
        };
        dispatch(joinWorkspaceThunk(joinDetails));
    };

    if (failed) return <LoadingIndicator fullscreen message="No Invite Found for this Email Address. Signing Out." />;
    if (inviteAccepted) return <LoadingIndicator fullscreen message="Joining Workspace" />;
    if (!invite) return <LoadingIndicator fullscreen message="Checking Invite Status" />;

    return (
        <SignInPanel
            title={`Join ${invite?.workspaceName}`}
            description="You've been invited to join this workspace"
            signInStatus="signed-in"
            currentStep={3}
        >
            <div className="flex flex-col space-y-8 justify-center items-center">
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <MyInputGroup label="First Name" name="firstname" required>
                            <MyTextInput
                                maxLength={FIELD_SIZE_LIMITS.GENERIC_NAME}
                                onChange={handleSetValue}
                                placeholder="required"
                                value={userDetails.firstname}
                            />
                        </MyInputGroup>
                    </div>
                    <div className="flex-1">
                        <MyInputGroup label="Surname" name="surname" required>
                            <MyTextInput
                                maxLength={FIELD_SIZE_LIMITS.GENERIC_NAME}
                                onChange={handleSetValue}
                                placeholder="required"
                                value={userDetails.surname}
                            />
                        </MyInputGroup>
                    </div>
                </div>
                <Button loading={inviteAccepted} extraClasses="w-40" size="sm" color="primary" onClick={handleJoin}>
                    Join
                </Button>
            </div>
        </SignInPanel>
    );
};

export default JoinWorkspace;
