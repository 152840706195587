import React from "react";

const withIndicator = (IconComponent) => {
    return ({ count, ...props }) => {
        return (
            <div className="indicator">
                {count > 0 && <span className="indicator-item badge badge-primary">{count}</span>}
                <IconComponent {...props} />
            </div>
        );
    };
};

export default withIndicator;
