import _ from "lodash";
import { useMemo } from "react";
import { useCustomFields } from "../../CustomFields/hooks/useCustomFields";
import { useFilteredSnapshots } from "../../Snapshots/hooks/useFilteredSnapshots";
import { listAxisValue } from "../../Snapshots/utils/snapshotHelpers";
import { prepSingleAxisGridData } from "../utils/prepSingleAxisGrid";

//// Helpers
function prepGridData(theseSnapshots, config, customFields) {
    const placements = Object.entries(theseSnapshots).map(([empId, snap]) => {
        return buildGridMemberDetails(empId, snap, config, customFields);
    });
    return placements.filter((entry) => entry);
}

function showGridColor(y, x, grid) {
    const boxInfo = grid.find((box) => box.row === y && box.col === x);
    const result = boxInfo && boxInfo.color;
    return result;
}

function getLikertScaleBoxValue(customFieldId, value, boxCount, customFields = []) {
    const customField = customFields.find((field) => field.id === customFieldId);
    const customFieldOptions = customField?.options || [];
    const optionCount = customFieldOptions.length;
    // Calculate the ratio of boxes per option
    const ratio = boxCount / optionCount;

    // Determine the box for the given value
    let boxValue;
    if (ratio === 1) {
        boxValue = value + 1; // Simple case: one box per option
    } else {
        boxValue = Math.floor(value * ratio) + 1;
    }
    // Ensure the boxValue does not exceed the boxCount
    if (boxValue > boxCount) {
        boxValue = boxCount;
    }
    return isNaN(boxValue) ? 0 : Math.round(boxValue);
}

function whichBoxShouldIGoIn(value, axisInfo, boxCount, customFields) {
    if (value == null) return null;
    if (!axisInfo) return null;
    if (axisInfo.fieldId === "flags") {
        const customFieldId = axisInfo.fieldValue;
        return getLikertScaleBoxValue(customFieldId, value, boxCount, customFields);
    }
    switch (boxCount) {
        case 2:
            if (value < 50) return 1;
            else return 2;
        case 3:
            if (value < 25) return 1;
            if (value < 75) return 2;
            else return 3;
        default:
            const range = Math.ceil(100 / boxCount);
            return Math.ceil(value / range);
    }
}

function getBoxCounts(grid) {
    const groupedColGrid = _.groupBy(grid, "col");
    const colCount = Object.keys(groupedColGrid || {}).length;
    const groupedRowGrid = _.groupBy(grid, "row");
    const rowCount = Object.keys(groupedRowGrid || {}).length;
    return { colCount, rowCount };
}

function buildGridMemberDetails(empId, snap, config = {}, customFields = []) {
    const { grid, x, y, z } = config;
    const { colCount, rowCount } = getBoxCounts(grid);
    const zVal = listAxisValue(snap, z);
    const xVal = listAxisValue(snap, x);
    const xBox = whichBoxShouldIGoIn(xVal, x, colCount, customFields);
    let returnValue = null;
    if (xBox) {
        const yVal = listAxisValue(snap, y);
        const yBox = whichBoxShouldIGoIn(yVal, y, rowCount, customFields);
        if (yBox) {
            const gridColor = showGridColor(xBox, yBox, grid);
            let entry = { employeeId: empId, x: xBox, y: yBox, z: zVal, gridColor: gridColor };
            returnValue = entry;
        } else {
            returnValue = null;
        }
    } else {
        returnValue = null;
    }
    return returnValue;
}

function createGrid(gridPlacements, config) {
    const { grid } = config;
    const groupedByCol = _.groupBy(grid, "col");
    let gridColumns = Object.entries(groupedByCol).map(([col, rows]) => {
        col = parseInt(col);
        const colCohort = gridPlacements.filter((entry) => entry.x && entry.x === col);
        return rows.map((box) => {
            const row = box.row;
            const rowCohort = colCohort.filter((entry) => entry.y && entry.y === row);
            let percent = Math.round((rowCohort.length / gridPlacements.length) * 100);
            percent = isNaN(percent) ? 0 : percent;
            return { x: col, y: row, cohort: rowCohort, tile: box, percent: percent };
        });
    });
    return gridColumns;
}

export function useTalentMap(employees, talentMapConfig = {}) {
    const { filters = [] } = talentMapConfig;
    const { customFields } = useCustomFields();
    const filteredSnapshots = useFilteredSnapshots(employees, filters);

    // Create the talent grid
    const grid = useMemo(() => {
        const configMissing = !talentMapConfig || Object.keys(talentMapConfig).length === 0;
        if (!configMissing) {
            let gridPlacements = [];

            if (talentMapConfig.singleAxis) {
                gridPlacements = prepSingleAxisGridData(filteredSnapshots, talentMapConfig);
            } else {
                gridPlacements = prepGridData(filteredSnapshots, talentMapConfig, customFields);
            }
            return createGrid(gridPlacements, talentMapConfig);
        } else {
            return [];
        }
    }, [filteredSnapshots, customFields, talentMapConfig]);

    return {
        grid,
        scatterData: filteredSnapshots,
    };
}
