import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const LoadingIndicator = ({ fullscreen, message, icon = "infinity", color = "primary" }) => {
    const [timedOut, setTimedOut] = useState(false);

    // timeout if not unmounted after 10 seconds
    useEffect(() => {
        const timeout = setTimeout(() => {
            setTimedOut(true);
        }, 15000);
        return () => clearTimeout(timeout);
    }, []);

    if (timedOut) throw new Error("timedOut");

    return (
        <div
            className={classNames(
                { "fixed top-0 bottom-0 right-0 left-0": fullscreen },
                "h-full w-full flex flex-col items-center justify-center"
            )}
        >
            {message && <h5 className="font-light text-lg text-center">{message}</h5>}
            <div className="flex space-x-2">
                <span className={classNames("loading loading-lg", `text-${color}`, `loading-${icon}`)} />
            </div>
        </div>
    );
};

LoadingIndicator.propTypes = {
    fullscreen: PropTypes.bool,
    message: PropTypes.string,
};

export default LoadingIndicator;
