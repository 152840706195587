import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import PolarAreaChart from "../../../components/Charts/BaseCharts/PolarAreaChart";
import { sortArrayOfObjects } from "../../../helpers/helpers";
import ChartContainer from "../../../components/Charts/ChartContainer";

const TraitPolarAreaChart = ({
    chartId,
    ratings,
    padding,
    selected,
    onSegmentHover,
    onItemClick,
    onBackgroundClick,
    includedTraits,
}) => {
    const traits = useSelector((state) => state.workspace.traits);
    const talentAreas = useSelector((state) => state.workspace.talentAreas);
    const selectedTraitId = useSelector((state) => state.app.selectedTraitId);
    const data = useMemo(() => {
        let traitsToUse = includedTraits || Object.keys(ratings).map((traitId) => traits[traitId]);
        const unsortedData = traitsToUse
            .filter((trait) => !!trait.linkedTalentAreaId)
            .map((trait) => {
                const traitId = trait.id;
                const rating = ratings[traitId];
                const linkedTalentAreaId = trait?.linkedTalentAreaId;
                const talentArea = talentAreas[linkedTalentAreaId];
                const { color } = talentArea || {};
                return {
                    id: traitId,
                    linkedTalentAreaId: linkedTalentAreaId,
                    label: trait && trait.traitName,
                    value: rating,
                    color: color,
                };
            });
        return sortArrayOfObjects(unsortedData, "linkedTalentAreaId", "desc");
    }, [traits, talentAreas, includedTraits, ratings]);

    const handleItemHover = (item) => {
        const id = item?.data?.id;
        onSegmentHover(id);
    };

    const handleItemClick = (item) => {
        const id = item?.data?.id;
        onItemClick(id);
    };

    const handleBackgroundClick = (item) => {
        const id = item?.id;
        onBackgroundClick(id);
    };

    const selectedId = selected || selectedTraitId;

    return (
        <ChartContainer padding={padding}>
            <PolarAreaChart
                chartId={chartId}
                max={100}
                data={data}
                selected={selectedId}
                clickableItems={!!onItemClick}
                clickableBackground={!!onBackgroundClick}
                onBackgroundClick={handleBackgroundClick}
                onItemClick={handleItemClick}
                onItemEnter={handleItemHover}
                onItemLeave={handleItemHover}
            />
        </ChartContainer>
    );
};

TraitPolarAreaChart.propTypes = {
    padding: PropTypes.number,
    selected: PropTypes.string,
    onSegmentHover: PropTypes.func,
    onItemClick: PropTypes.func,
    onBackgroundClick: PropTypes.func,
};

TraitPolarAreaChart.defaultProps = {
    ratings: {},
    padding: 0,
    selected: null,
    onSegmentHover: () => {},
    onItemClick: () => {},
    onBackgroundClick: () => {},
};

export default TraitPolarAreaChart;
