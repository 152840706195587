import classNames from "classnames";
import { useMemo, useState } from "react";
import { FaArrowLeft, FaArrowRight, FaBorderAll, FaCog, FaDna, FaSitemap, FaTasks } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { IoCalendarOutline } from "react-icons/io5";
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../assets/img/logo/logo-50x50.png";
import { DashboardIcon, TalentAreaIcon } from "../components/Icons/MyIcons";
import withIndicator from "../components/Icons/withIndicator";
import { ALT_TEXT } from "../constants";
import { useReviewQueue } from "../features/Reviews/hooks/useReviewQueue";
import "./containers.scss";
import { useClaims } from "../features/User/hooks/useClaims";
import { GrPowerCycle } from "react-icons/gr";

const BellIconWithIndicator = withIndicator(DashboardIcon);

const AppSideBar = ({ width }) => {
    const navigate = useNavigate();
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const orgView = useSelector((state) => state.config.ownConfig.orgView);
    const { isAdmin, isDeveloper } = useClaims();
    const [expanded, setExpanded] = useState(false);
    const [keepExpanded, setKeepExpanded] = useState(false);
    const [delayHandler, setDelayHandler] = useState(null);
    const featureFlips = useSelector((state) => state.config.everyoneConfig.featureFlips);
    const reviewQueue = useReviewQueue();
    const reviewQueueCount = reviewQueue ? reviewQueue.length : 0;
    const disableAutoOpen = true;

    const allowAccess = useMemo(() => {
        return {
            talentOptions: isAdmin,
        };
    }, [isAdmin]);

    const handleMouseEnter = () => {
        if (!keepExpanded && !disableAutoOpen) {
            setDelayHandler(
                setTimeout(() => {
                    setExpanded(true);
                }, 1400)
            );
        }
    };

    const handleGoHome = () => [navigate("/people")];

    const handleMouseLeave = () => {
        if (!keepExpanded && !disableAutoOpen) {
            clearTimeout(delayHandler);
            setExpanded(false);
        }
    };

    const handleSetKeepExpanded = () => {
        setKeepExpanded(!expanded);
        setExpanded(!expanded);
    };

    const style = !expanded
        ? {
              width: width,
              minWidth: width,
          }
        : {};

    function goToDashboard() {
        if (userEmployeeId) {
            const dashboardLink = `organisation/view/employees/${userEmployeeId}/things-to-do`;
            navigate(dashboardLink);
        } else {
            toast.info("You don't have an employee record");
        }
    }

    const orgLink = `organisation/${orgView || "chart"}`;
    return (
        <ProSidebar collapsed={!expanded} style={style}>
            <SidebarHeader>
                <img className="clickable logo" src={logo} alt={ALT_TEXT.LOGO} onClick={handleGoHome} />
            </SidebarHeader>
            <SidebarContent onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <Menu iconShape="circle">
                    <MenuItem
                        title="Dashboard"
                        icon={<BellIconWithIndicator count={reviewQueueCount} />}
                        onClick={goToDashboard}
                    >
                        Dashboard
                    </MenuItem>
                    <MenuItem title="Organisation" icon={<FaSitemap />}>
                        Organisation
                        <Link to={orgLink} />
                    </MenuItem>
                    {allowAccess.talentOptions && (
                        <>
                            {featureFlips.businessUnits && (
                                <MenuItem title="Business Units" icon={<ImOffice />}>
                                    Business Units
                                    <Link to="business-units" />
                                </MenuItem>
                            )}
                            <MenuItem title="Traits" icon={<FaDna />}>
                                Traits
                                <Link to="traits" />
                            </MenuItem>
                            <MenuItem title="Talent Areas" icon={<TalentAreaIcon />}>
                                Talent Areas
                                <Link to="talent-areas" />
                            </MenuItem>
                            <MenuItem title="Talent Boards" icon={<FaBorderAll />}>
                                Talent Boards
                                <Link to="talentboards" />
                            </MenuItem>
                            <MenuItem title="Actions" icon={<FaTasks />}>
                                Actions
                                <Link to="actions" />
                            </MenuItem>
                            <MenuItem title="Cycles" icon={<GrPowerCycle />}>
                                Cycles
                                <Link to="cycles/live" />
                            </MenuItem>
                            {isDeveloper && (
                                <MenuItem title="Review Schedule" icon={<IoCalendarOutline />}>
                                    Review Schedule
                                    <Link to="schedule" />
                                </MenuItem>
                            )}
                        </>
                    )}
                    <MenuItem title="Settings" icon={<FaCog />}>
                        Settings
                        <Link to="settings/profile" />
                    </MenuItem>
                </Menu>
            </SidebarContent>
            {disableAutoOpen && (
                <SidebarFooter className={classNames(expanded && "expanded")}>
                    <Menu>
                        <MenuItem onClick={handleSetKeepExpanded}>
                            <span className="w-full flex justify-end">
                                {expanded ? <FaArrowLeft /> : <FaArrowRight />}
                            </span>
                        </MenuItem>
                    </Menu>
                </SidebarFooter>
            )}
        </ProSidebar>
    );
};

export default AppSideBar;
