import classNames from "classnames";
import React, { useState } from "react";
import { HelperDropdown, RichText } from "../../common/components";

export const MyInput = (props) => {
    const handleChange = (e) => {
        let newVal = e.target.value;
        props.onChange(props.name, newVal);
    };

    return <input {...props} onChange={handleChange} />;
};

const Description = ({ description, helperPosition, inlineDescription }) => {
    if (!description || inlineDescription) return null;
    return (
        <HelperDropdown title="Info" size="xs" position={helperPosition}>
            <RichText readOnly content={description} removePadding />
        </HelperDropdown>
    );
};

const MyInputGroup = ({
    className,
    groupClassName = "grid grid-cols-1 gap-1 px-1",
    labelClassName = "col-span-1 text-sm font-medium",
    inputClassName = "col-span-1 flex flex-col",
    errorClassName = "mt-1 text-xs text-error",
    placeholder,
    description,
    inlineDescription,
    helperPosition = "left",
    label,
    hideLabel,
    hidden,
    name,
    errors,
    children, // The input element is passed as children
}) => {
    const [touched, setTouched] = useState(false);
    const forId = label && label.toLowerCase().replace(/[^a-zA-Z0-9]/g, "");
    const invalid = !!errors;
    if (hidden) return null;

    const handleBlur = () => {
        setTouched(true);
    };

    return (
        <div className={classNames(groupClassName, className)}>
            <div className="flex justify-between">
                <label
                    htmlFor={forId}
                    className={classNames(
                        "capitalize font-medium text-base-800 text-sm tracking-tight",
                        labelClassName,
                        {
                            hidden: hideLabel,
                        }
                    )}
                >
                    {label}
                </label>
                <Description
                    description={description}
                    helperPosition={helperPosition}
                    inlineDescription={inlineDescription}
                />
            </div>
            <div className={inputClassName}>
                {React.cloneElement(children, {
                    id: forId,
                    name: name,
                    label: label,
                    placeholder: placeholder,
                    onBlur: (fieldName, newVal) => {
                        handleBlur();
                        if (children.props.onBlur) {
                            children.props.onBlur(fieldName, newVal);
                        }
                    },
                    "aria-invalid": !!invalid,
                    "aria-describedby": description,
                })}
            </div>
            <p className={classNames(errorClassName, { hidden: !invalid || !touched })}>{errors}</p>
            {inlineDescription && <p className="text-xs">{description}</p>}
        </div>
    );
};

export default MyInputGroup;
