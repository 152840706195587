import React, { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import ReactTooltip from "react-tooltip";
import { isChartReady } from "../../../common/utils/chartUtils";
import { getUniqueId } from "../../../common/utils/getUniqueId";

const BarChart = ({
    seriesA,
    seriesB,
    height,
    width,
    chartId,
    showXLabels,
    xAxisLabel,
    seriesAColor = "#4878ff",
    seriesBColor = "#ff7850",
}) => {
    const ref = useRef();

    const tooltipId = useMemo(() => chartId || getUniqueId(), [chartId]);

    useEffect(() => {
        const chartIsReady = isChartReady([...seriesA, ...(seriesB || [])], height, width);
        if (chartIsReady) {
            const svg = d3.select(ref.current);
            svg.selectAll("*").remove(); // Clear SVG contents

            const svgWidth = svg.node().clientWidth;
            const svgHeight = svg.node().clientHeight;

            // Adjusting left margin to accommodate y-axis labels
            const margin = { top: 20, right: 0, bottom: 110, left: 50 };

            // Create chart area
            const chart = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

            // Filter seriesB to match seriesA IDs
            const filteredSeriesB = seriesB
                ? seriesA.map((d) => {
                      const matchingB = seriesB.find((b) => b.id === d.id);
                      return matchingB ? { ...d, valueB: matchingB.value } : { ...d, valueB: null };
                  })
                : seriesA;

            // Set up the scales
            const xScale = d3
                .scaleBand()
                .range([0, svgWidth - margin.left - margin.right])
                .domain(seriesA.map((d) => d.id))
                .padding(0.2);

            const yMax = d3.max([...seriesA, ...filteredSeriesB], (d) => Math.max(d.value, d.valueB || 0));
            const yScale = d3
                .scaleLinear()
                .range([svgHeight - margin.top - margin.bottom, 0])
                .domain([0, yMax]);

            // Calculate the number of ticks based on the yMax value
            const yAxisTicks = Math.min(10, yMax); // Adjust the number of ticks as necessary

            // Draw bars for seriesA
            chart
                .selectAll(".barA")
                .data(seriesA)
                .enter()
                .append("rect")
                .attr("class", "barA")
                .attr("x", (d) => xScale(d.id))
                .attr("y", (d) => yScale(d.value))
                .attr("width", xScale.bandwidth() / (seriesB ? 2 : 1))
                .attr("height", (d) => svgHeight - margin.top - margin.bottom - yScale(d.value))
                .attr("fill", seriesAColor)
                .attr("data-tip", (d) => d.label)
                .attr("data-for", tooltipId)
                .attr("rx", 5) // Rounded corners
                .attr("ry", 5); // Rounded corners

            if (seriesB) {
                chart
                    .selectAll(".barB")
                    .data(filteredSeriesB)
                    .enter()
                    .append("rect")
                    .attr("class", "barB")
                    .attr("x", (d, i) => xScale(seriesA[i].id) + xScale.bandwidth() / 2)
                    .attr("y", (d) => yScale(d.valueB))
                    .attr("width", xScale.bandwidth() / 2)
                    .attr("height", (d) =>
                        d.valueB !== null ? svgHeight - margin.top - margin.bottom - yScale(d.valueB) : 0
                    )
                    .attr("fill", seriesBColor)
                    .attr("data-tip", (d) => d.label)
                    .attr("data-for", tooltipId)
                    .attr("rx", 5) // Rounded corners
                    .attr("ry", 5); // Rounded corners
            }

            // Create the y-axis
            const yAxis = d3.axisLeft(yScale).ticks(yAxisTicks);
            chart.append("g").call(yAxis);

            // Create the x-axis
            const xAxis = d3.axisBottom(xScale).tickFormat((d, i) => seriesA[i].label);
            const xAxisGroup = chart
                .append("g")
                .attr("transform", `translate(0, ${svgHeight - margin.top - margin.bottom})`)
                .call(xAxis);

            // Rotate x-axis labels if showXLabels is true
            if (showXLabels) {
                xAxisGroup
                    .selectAll("text")
                    .attr("transform", "rotate(-45)")
                    .style("text-anchor", "end")
                    .attr("dx", "-0.8em")
                    .attr("dy", "0.15em");

                if (!seriesB || seriesB.length === 0) {
                    // Center ticks if seriesB is empty or missing
                    xAxisGroup.selectAll(".tick").attr("transform", function (d) {
                        return `translate(${xScale(d) + xScale.bandwidth() / 2}, 0)`;
                    });
                }
            } else {
                xAxisGroup.selectAll("text").remove();
            }

            // Add x-axis label
            svg.append("text")
                .attr(
                    "transform",
                    `translate(${(svgWidth - margin.left - margin.right) / 2 + margin.left}, ${svgHeight - 10})`
                )
                .style("text-anchor", "middle")
                .text(xAxisLabel);

            ReactTooltip.rebuild();
        }
    }, [seriesA, seriesB, seriesAColor, seriesBColor, height, width, tooltipId, showXLabels, xAxisLabel]);

    return (
        <>
            <ReactTooltip id={tooltipId} place="top" effect="solid" delayShow={300} />
            <svg ref={ref} style={{ width: "100%", height: "100%" }}></svg>
        </>
    );
};

export default BarChart;
