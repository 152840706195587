import classNames from "classnames";
import React, { useMemo } from "react";
import { Each, HelperDropdown, RichText } from "../../../../../common/components";
import ReviewNote from "../ReviewNote/ReviewNote";

function getNoteLabel(note) {
    if (note.isPrivate) return `${note.label} (Hidden from Employee)`;
    return note.label;
}

function getRequiredNotes(cycle, relationship, segmentId) {
    let notes = [];
    switch (relationship) {
        case "employee":
            notes = cycle?.employeeNotes || [];
            break;
        case "manager":
            notes = cycle?.managerNotes || [];
            break;
        default:
            notes = [];
            break;
    }
    if (segmentId) {
        return notes.filter((note) => note.segmentId === segmentId);
    } else {
        return notes;
    }
}

function getCounterpart(cycle, relationship, segmentId) {
    switch (relationship) {
        case "employee":
            const notes = getRequiredNotes(cycle, "manager", segmentId);
            return notes.filter((note) => !note.isPrivate);
        case "manager":
            return getRequiredNotes(cycle, "employee", segmentId);
        default:
            return [];
    }
}

const NoteEditorBlock = ({ values, actions, segmentId, includeAllNotes }) => {
    const { locked, relationship, notes } = values;
    const { userEmployeeId, cycle, cycleId, iterationId } = values;
    const { updateNote } = actions;
    const requiredNotes = useMemo(() => {
        return getRequiredNotes(cycle, relationship, !includeAllNotes && segmentId) || [];
    }, [cycle, relationship, segmentId, includeAllNotes]);

    const counterpartNotes = useMemo(() => {
        return getCounterpart(cycle, relationship, !includeAllNotes && segmentId) || [];
    }, [cycle, relationship, segmentId, includeAllNotes]);

    const handleUpdateNote = (updatedNote) => {
        updateNote(updatedNote);
    };

    function getCounterpartLabel() {
        switch (relationship) {
            case "employee":
                return "Manager's Comments";
            case "manager":
                return "Employee's Comments";
            default:
                return "Counterpart's Comments";
        }
    }

    return (
        <div className="flex flex-col relative fade w-full items-stretch space-y-6">
            <div className="space-y-2">
                <h5 className="border-b">Your Comments</h5>
                <div className="rounded space-y-4">
                    <Each
                        of={requiredNotes}
                        emptyStateProps={{
                            className: "border-none p-0",
                            type: "noIcon",
                            message: "No Comments Required",
                        }}
                        render={(requiredNote) => {
                            return (
                                <div className="flex-1 flex flex-col min-h-40">
                                    <div className="flex justify-between px-1 pb-1">
                                        <h6 className="capitalize font-semibold mb-1">{getNoteLabel(requiredNote)}</h6>
                                        <HelperDropdown size="xs" title="Guidance">
                                            <RichText removePadding readOnly content={requiredNote.noteHelp} />
                                        </HelperDropdown>
                                    </div>
                                    <ReviewNote
                                        useOwnNote={true}
                                        notes={notes}
                                        userEmployeeId={userEmployeeId}
                                        reviewNoteId={requiredNote.id}
                                        isPrivate={requiredNote.isPrivate}
                                        cycleName={cycle?.cycleName || "Cycle"}
                                        cycleId={cycleId}
                                        iterationId={iterationId}
                                        readOnly={locked}
                                        onUpdate={handleUpdateNote}
                                        className={classNames("flex-1 rounded border", { "bg-editing": !locked })}
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
            <hr className="border-secondary" />
            <div className={classNames("space-y-2", { hidden: !counterpartNotes.length })}>
                <h5 className="border-b">{getCounterpartLabel()}</h5>
                <div className="rounded space-y-4">
                    <Each
                        of={counterpartNotes}
                        emptyStateProps={{
                            className: "border-none",
                            type: "noIcon",
                            message: "None requested",
                        }}
                        render={(requiredNote) => {
                            return (
                                <div className="flex-1 flex flex-col min-h-40">
                                    <div className="flex justify-between px-1">
                                        <h6 className="capitalize font-semibold mb-1">{getNoteLabel(requiredNote)}</h6>
                                    </div>
                                    <ReviewNote
                                        useOwnNote={false}
                                        notes={notes}
                                        userEmployeeId={userEmployeeId}
                                        reviewNoteId={requiredNote.id}
                                        cycleId={cycleId}
                                        iterationId={iterationId}
                                        readOnly={true}
                                        className="flex-1 rounded border bg-white"
                                    />
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default NoteEditorBlock;
