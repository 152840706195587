import { PropTypes } from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IdPicker from "../../../components/Picker/IdPicker";
import { listAllEmployeeIdsBelow, listChildEmployeeIds } from "../../Roles/utils/roleHelpers";
import { useTopLevelRoleId } from "../../BusinessUnits/hooks/useTopLevelRoleId";

const EmployeePicker = (props) => {
    const {
        selectedIds,
        isClearable,
        isMulti,
        includeAll,
        excludeSelf,
        filter,
        onlyOrphans,
        includeOrphans,
        directReportsOf,
    } = props;
    const [excludeIds, setExcludeIds] = useState([]);
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const topLevelRoleId = useTopLevelRoleId();
    const employees = useSelector((state) => state.businessUnit.employees);
    const roles = useSelector((state) => state.businessUnit.roles);
    const parentMap = useSelector((state) => state.businessUnit.parentMap);

    // If Include all, ignore the users topLevelRoleId and include all roles
    const topLevelFilterId = includeAll ? rootRoleId : topLevelRoleId;

    // Determine the additional ids to exclude based on the props provided
    useEffect(() => {
        let extraExcluded = [];
        const employeeIdList = Object.keys(employees);
        if (onlyOrphans) {
            extraExcluded = employeeIdList.filter((empId) => !!employees[empId].roleId);
        } else if (!includeOrphans) {
            let includeIds = [];
            if (directReportsOf) {
                includeIds = listChildEmployeeIds(parentMap, roles, directReportsOf);
            } else {
                includeIds = listAllEmployeeIdsBelow(parentMap, roles, topLevelFilterId);
            }
            if (!excludeSelf) {
                includeIds.push(userEmployeeId);
            }
            extraExcluded = employeeIdList.filter((empId) => !includeIds.includes(empId));
        }
        setExcludeIds(extraExcluded);
    }, [
        topLevelFilterId,
        employees,
        roles,
        filter,
        onlyOrphans,
        includeOrphans,
        directReportsOf,
        excludeSelf,
        parentMap,
        userEmployeeId,
        setExcludeIds,
    ]);

    const getLabel = (employee) => {
        return employee && employee.displayName;
    };

    return (
        <IdPicker
            {...props}
            name="employees"
            getLabel={getLabel}
            options={employees}
            selectedIds={Array.isArray(selectedIds) ? selectedIds : [selectedIds]}
            excludeIds={excludeIds.concat(props.excludeIds)}
            isClearable={isClearable}
            isMulti={isMulti}
        />
    );
};

EmployeePicker.propTypes = {
    selectedIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    excludeIds: PropTypes.array,
    includeAll: PropTypes.bool,
    excludeSelf: PropTypes.bool,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

EmployeePicker.defaultProps = {
    selectedEmployeeIds: [],
    excludeIds: [],
    onBlur: () => {},
    onChange: () => {},
};

export default EmployeePicker;
