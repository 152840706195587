import { useSelector } from "react-redux";

export function useClaims() {
    const claims = useSelector((state) => state.user.claims);
    const topLevelAccess = claims?.topLevelAccess;
    const userRole = claims?.userRole;
    const isDeveloper = claims?.developer;
    const isAdmin = userRole === "admin" || userRole === "owner" || isDeveloper;

    return { topLevelAccess, userRole, isAdmin, isDeveloper };
}
