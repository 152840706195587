import React, { useState } from "react";
import { Steps } from "react-daisyui";
import PropTypes from "prop-types";
import classNames from "classnames";

const MySteps = (props) => {
    const { steps, size, currentStep, startingStep, incompleteColor, defaultCompleteColor, onStepClick, ...cleanProps } = props;
    const [furthestStep, setFurthestStep] = useState(props.startingStep);

    const handleStepClick = (step, i) => {
        if (!onStepClick) return;
        if (i - furthestStep > 1) return;
        if (i > furthestStep) setFurthestStep(i);
        onStepClick(step, i);
    };

    return (
        <Steps {...cleanProps}>
            {steps.map((step, i) => {
                let color = incompleteColor;
                const reached = i <= currentStep;
                const active = i === currentStep;
                if (reached) {
                    color = step.completeColor || defaultCompleteColor;
                }
                return (
                    <Steps.Step
                        key={step.id}
                        color={color}
                        onClick={() => handleStepClick(step, i)}
                        className={classNames({ "cursor-pointer": onStepClick })}
                    >
                        <span
                            className={classNames("mx-4", `text-${color}`, {
                                "font-bold": active,
                                "text-base-500": !reached,
                            })}
                        >
                            {step.label}
                        </span>
                    </Steps.Step>
                );
            })}
        </Steps>
    );
};

MySteps.propTypes = {
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            completeColor: PropTypes.string,
        })
    ).isRequired,
    size: PropTypes.string,
    vertical: PropTypes.bool,
    currentStep: PropTypes.number,
    startingStep: PropTypes.number,
    incompleteColor: PropTypes.string,
    defaultCompleteColor: PropTypes.string,
    onStepClick: PropTypes.func,
};

MySteps.defaultProps = {
    steps: [],
    size:"md",
    currentStep: 0,
    startingStep: 0,
    incompleteColor: "base-500",
    defaultCompleteColor: "primary",
};

export default MySteps;
