import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { Modal } from "react-daisyui";
import MyButton from "./Button";
import { CloseIcon } from "../Icons/MyIcons";

const widthClasses = {
    sm: "max-w-lg",
    md: "max-w-2xl",
    lg: "max-w-5xl",
    xl: "max-w-5xl",
    fullscreen: "max-w-screen",
};

const heightClasses = {
    sm: "max-h-4/12",
    md: "max-h-6/12",
    lg: "max-h-8/12",
    xl: "max-h-10/12",
    fullscreen: "max-h-screen",
};

function getSizeClass(size, heightProp, widthProp) {
    const height = heightProp || size;
    const width = widthProp || size;
    return `${widthClasses[width]} ${heightClasses[height]}`;
}

export const MyModalHeader = ({ children, className, closeBlocked, onToggle }) => {
    const handleToggle = () => {
        if (closeBlocked) return;
        onToggle();
    }

    return (
        <Modal.Header
            className={classNames(
                "mb-0 px-5 py-4 border-b font-medium text-lg items-center flex justify-between text-base-800",
                className
            )}
        >
            {children}
            <MyButton disabled={closeBlocked} onClick={handleToggle} size="sm" shape="circle">
                <CloseIcon />
            </MyButton>
        </Modal.Header>
    );
};

export const MyModalBody = ({ children, className, style = {} }) => {
    return (
        <Modal.Body style={style} className={classNames("my-modal-body overflow-x-hidden flex-1", className)}>
            {children}
        </Modal.Body>
    );
};

export const MyModalFooter = ({ children, className }) => {
    return <div className={classNames("p-3 border-t flex space-x-3 justify-end", className)}>{children}</div>;
};

export const MyModal = ({ children, isOpen, size, height, width, className }) => {
    const modalStateRef = useRef(isOpen); // Ref to track open state, if needed elsewhere
    const focusTrapRef = useRef(null); // New ref for the actual modal DOM element for focus trapping

    useEffect(() => {
        modalStateRef.current = isOpen; // Update based on isOpen prop
    }, [isOpen]);

    useEffect(() => {
        const trapFocus = (event) => {
            if (!modalStateRef.current) {
                // Use modalStateRef for checking open state
                return;
            }

            const focusableModalElements = focusTrapRef.current.querySelectorAll(
                "a[href], button:not([disabled]), textarea, input, select"
            );
            const firstElement = focusableModalElements[0];
            const lastElement = focusableModalElements[focusableModalElements.length - 1];

            if (event.key !== "Tab") {
                return;
            }

            if (event.shiftKey) {
                // Shift + Tab
                if (document.activeElement === firstElement) {
                    lastElement.focus();
                    event.preventDefault();
                }
            } else {
                // Tab
                if (document.activeElement === lastElement) {
                    firstElement.focus();
                    event.preventDefault();
                }
            }
        };

        document.addEventListener("keydown", trapFocus);

        return () => {
            document.removeEventListener("keydown", trapFocus);
        };
    }, []); // This effect does not depend on isOpen directly, uses modalStateRef instead

    if (!isOpen) {
        return null;
    }

    const sizeClass = getSizeClass(size, height, width);
    return (
        <Modal
            ref={focusTrapRef}
            open={isOpen}
            className={classNames("p-0 relative overflow-auto flex flex-col w-screen h-screen bg-white rounded-lg", sizeClass, className)}
        >
            <div className="flex-1 overflow-hidden flex flex-col justify-between">{children}</div>
        </Modal>
    );
};

MyModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(["sm", "md", "lg", "xl", "fullscreen"]),
    width: PropTypes.oneOf(["sm", "md", "lg", "xl", "fullscreen"]),
    height: PropTypes.oneOf(["sm", "md", "lg", "xl", "fullscreen"]),
    className: PropTypes.string,
};

MyModal.defaultProps = {
    size: "md",
};
