import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import TraitPolarAreaChart from "../../Traits/containers/TraitPolarAreaChart";

const RadarCharts = ({ snap, employeeTalentBoardIds = [] }) => {
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const talentBoardRatings = useMemo(() => {
        const { ratings = {} } = snap;
        const boards = employeeTalentBoardIds.map((boardId) => {
            let boardRatings = {};
            const board = talentBoards[boardId];
            const boardLabel = board?.boardName ?? "Unknown Board";
            let boardTraitIds = board?.linkedTraitIds || [];
            boardTraitIds.forEach((traitId) => {
                boardRatings[traitId] = ratings[traitId] || 0;
            });
            return {
                boardId: boardId,
                label: boardLabel,
                ratings: boardRatings,
            };
        });
        return boards;
    }, [snap, employeeTalentBoardIds, talentBoards]);

    const style = {
        maxWidth: 240,
    };

    return (
        <div className="w-full grid grid-cols-12">
            {talentBoardRatings.map((board) => {
                const { boardId, ratings } = board;
                return (
                    <div key={`${board.boardId}`} className="grid-item p-4" style={style}>
                        <TraitPolarAreaChart chartId={`board-${boardId}`} ratings={ratings} />
                        <h6 className="mt-3 mb-2 font-medium text-center text-base">{board.label}</h6>
                    </div>
                );
            })}
        </div>
    );
};

export default RadarCharts;
