import { removeUndefined } from "../../../common/utils/objectUtils";

export function buildInfoDocUpdate(originalInfoDoc, includedFieldIds) {
    const newInfoDoc = {};
    for (let itemKey in originalInfoDoc) {
        if (originalInfoDoc.hasOwnProperty(itemKey)) {
            const originalItem = originalInfoDoc?.[itemKey] || {};
            let updatedItem = {};
            includedFieldIds.forEach((field) => {
                updatedItem[field] = originalItem[field];
            });
            updatedItem = removeUndefined(updatedItem);
            if (Object.keys(updatedItem).length > 0) newInfoDoc[itemKey] = updatedItem;
        }
    }

    return newInfoDoc;
}
