import { collection, doc, query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useWorkspaceRef } from "../../../firebase/hooks/useWorkspaceRef";

export function useCycleRefs(cycleId) {
    const workspaceRef = useWorkspaceRef();

    const cyclesRef = useMemo(() => {
        if (!workspaceRef) return null;
        return collection(workspaceRef, "cycles");
    }, [workspaceRef]);

    const cycleRef = useMemo(() => {
        if (!cyclesRef || !cycleId) return null;
        return doc(cyclesRef, cycleId);
    }, [cyclesRef, cycleId]);

    const liveCyclesRef = useMemo(() => {
        if (!cyclesRef) return null;
        return query(cyclesRef, where("isLive", "==", true));
    }, [cyclesRef]);

    const iterationsRef = useMemo(() => {
        if (!cycleRef) return null;
        return collection(cycleRef, "iterations");
    }, [cycleRef]);

    return { cyclesRef, cycleRef, liveCyclesRef, iterationsRef };
}
