import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useRoleOptions(includedRoles = [], shortLabel) {
    const employees = useSelector((state) => state.businessUnit.employees);
    const workspaceName = useSelector((state) => state.workspace.workspaceName);

    return useMemo(() => {
        const getItemLabel = (role) => {
            if (role) {
                if (role.rootRole) {
                    return workspaceName;
                } else {
                    const jobTitle = role.jobTitle || "No Job Title";
                    const incumbent = employees[role.incumbentId];
                    if (!incumbent) {
                        return `${jobTitle}`;
                    } else if (shortLabel) {
                        return incumbent.displayName;
                    } else {
                        return `${incumbent.displayName}, ${jobTitle}`;
                    }
                }
            }
        };

        const allOptions = includedRoles
            .map((role) => {
                return {
                    value: role.id,
                    id: role.id,
                    label: getItemLabel(role),
                };
            })
            .sort((a, b) => a?.label?.localeCompare(b.label));

            return allOptions;

    }, [includedRoles, employees, workspaceName, shortLabel]);
}