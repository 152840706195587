import { SIDEBAR_WIDTH } from "../../../constants";
import AppSideBar from "../../../containers/AppSideBar";
import AppNavBar from "../../AppNavBar/AppNavBar";

const WorkspaceFrame = ({ children }) => (
    <div id="app-workspace" className="fixed inset-0 flex overflow-auto">
        <div className="flex w-full">
            <div className="shrink-0">
                <AppSideBar width={SIDEBAR_WIDTH} />
            </div>
            <div className="flex-1 flex flex-col">
                <div className="shrink-0">
                    <AppNavBar />
                </div>
                <div className="flex-1 overflow-auto flex" style={{ paddingLeft: SIDEBAR_WIDTH }}>
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default WorkspaceFrame;
