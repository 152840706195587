import React from "react";
import BarChart from "../../../../components/Charts/BaseCharts/BarChart";
import ChartContainer from "../../../../components/Charts/ChartContainer";
import MyEmptyState from "../../../../components/MyComponents/EmptyState";
import { OBJECTIVE_BLOCKER_OPTIONS } from "../../../../constants";

const BlockersBarChart = ({ liveBlockers = {}, failedBlockers = {}, showXLabels }) => {
    const liveBlockersChartData = Object.entries(liveBlockers).map(([id, value]) => {
        const fullBlocker = OBJECTIVE_BLOCKER_OPTIONS.find((option) => option.id === id);
        return {
            label: fullBlocker?.label || id,
            value: Array.isArray(value) ? value.length : value,
            employeeIds: value,
            id,
        };
    });

    const failedBlockersChartData = Object.entries(failedBlockers).map(([id, value]) => {
        const fullBlocker = OBJECTIVE_BLOCKER_OPTIONS.find((option) => option.id === id);
        return {
            label: fullBlocker?.label || id,
            value: value.length,
            employeeIds: value,
            id,
        };
    });

    const totalBlockerCount = liveBlockersChartData.length + failedBlockersChartData.length;
    if (!totalBlockerCount) return <MyEmptyState message="No Blockers" type="done" className="border-none" />;

    return (
        <div className="flex-1 flex flex-col">
            <ChartContainer className="flex-1" responsiveHeight>
                <BarChart
                    seriesA={liveBlockersChartData.length > 0 ? liveBlockersChartData : null}
                    seriesB={failedBlockersChartData.length > 0 ? failedBlockersChartData : null}
                    showXLabels={showXLabels}
                    chartId="blocker-chart"
                />
            </ChartContainer>
        </div>
    );
};

export default BlockersBarChart;
