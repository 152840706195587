import { toast } from "react-toastify";
import { useEditedWorkspace } from "../hooks/useEditedWorkspace";
import { createWorkspace } from "../utils/createWorkspace";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../../../components/MyComponents/Modal";
import { Button } from "../../../common/components";

const CreateWorkspaceModal = ({ isOpen, onToggle }) => {
    const [editedWorkspace, updateWorkspace, isValid] = useEditedWorkspace({});

    const handleUpdateField = (key, value) => {
        updateWorkspace(key, value);
    };

    const handleSubmit = async () => {
        const result = await createWorkspace(editedWorkspace);
        if (result) {
            toast.success("Workspace created successfully");
        }
        onToggle();
    };

    return (
        <MyModal isOpen={isOpen} isValid={isValid} onToggle={onToggle}>
            <MyModalHeader>Create Workspace</MyModalHeader>
            <MyModalBody>
                <div className="flex flex-col space-y-4 p-8">
                    <p>This will create a new empty workspace.</p>
                    <input
                        type="text"
                        className="input input-bordered"
                        value={editedWorkspace?.workspaceName || ""}
                        onChange={(e) => handleUpdateField("workspaceName", e.target.value)}
                        placeholder="Workspace Name"
                    />
                </div>
            </MyModalBody>
            <MyModalFooter>
                <Button onClick={onToggle} label="Cancel" color="ghost">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={!isValid} label="Create Workspace" color="primary">
                    Create
                </Button>
            </MyModalFooter>
        </MyModal>
    );
};

export default CreateWorkspaceModal;
