import classNames from "classnames";
import { BsDash } from "react-icons/bs";
import { Each } from "../../../../../common/components";
import ToggleComponent from "../../../../../common/components/molecules/ToggleComponent/ToggleComponent";
import ExpandableCard from "../../../../../common/components/organisms/ExpandableCard/ExpandableCard";
import { CheckIcon } from "../../../../../components/Icons/MyIcons";
import MyBadge from "../../../../../components/MyComponents/Badge";
import { useToggle } from "../../../../../hooks/useToggle";
import { useMemo } from "react";

const ToggleIcon = ({ checked }) => {
    if (checked) {
        return <CheckIcon size={14} className="text-success" />;
    } else {
        return <BsDash size={14} className="text-ghost" />;
    }
};

const ReviewerRow = ({ item, userEmployeeId, getEmployee, onClick }) => {
    const {
        userCompleted,
        userApproved,
        approvalRequired,
        actionRequired,
        revieweeId,
        completedIds = [],
        approvedIds = [],
        responderIds = [],
    } = item;
    const reviewee = getEmployee(revieweeId);

    const actuallyApprovedIds = useMemo(() => {
        const otherCompletedIds = completedIds.filter((id) => id !== userEmployeeId);
        if (otherCompletedIds.length === 0) {
            return approvedIds.filter((id) => id !== userEmployeeId);
        } else {
            return approvedIds;
        }
    }, [completedIds, approvedIds, userEmployeeId]);

    const handleRowClick = () => {
        if (responderIds.includes(userEmployeeId)) {
            onClick({ ...item, responderId: userEmployeeId });
        }
    };

    const renderResponders = (doneIds) => (
        <div className="space-y-1 flex flex-col">
            {responderIds.map((responderId) => {
                const responder = getEmployee(responderId);
                const checked = doneIds.includes(responderId);
                const colorClass = checked ? "success" : "base-500";
                return (
                    <div
                        key={responderId}
                        className={classNames(`border-${colorClass}`, `text-${colorClass}`, "space-x-1 badge")}
                    >
                        <ToggleIcon checked={checked} />
                        <span>{responder.displayName}</span>
                    </div>
                );
            })}
        </div>
    );

    const renderStatus = (statusType) => {
        function statusContent() {
            switch (statusType) {
                case "response":
                    return (
                        <span className={classNames({ "text-primary": actionRequired })}>
                            {userCompleted ? "done" : "action required"}
                        </span>
                    );
                case "approval":
                    if (responderIds.length > 1) {
                        return (
                            <span>{userApproved ? "done" : approvalRequired ? "required" : "not yet required"}</span>
                        );
                    } else {
                        return <span>N/A</span>;
                    }
                default:
                    return null;
            }
        }

        return (
            <div className="flex-1 tracking-tight text-sm font-medium flex space-x-2 items-center">
                <div className="capitalize">{`Your ${statusType}`}:</div>
                {statusContent()}
            </div>
        );
    };

    const renderLabel = () => {
        if (actionRequired) {
            return (
                <div className="flex-1 flex items-center space-x-2 capitalize">
                    <div className="flex-1 tracking-tight text-sm font-medium">{reviewee?.displayName}</div>
                    {renderStatus("response")}
                    {renderStatus("approval")}
                </div>
            );
        } else {
            return (
                <div className="flex-1 flex items-center space-x-2">
                    <div className="flex-1 tracking-tight text-sm font-medium flex flex-col space-y-1">
                        <span>{reviewee?.displayName}</span>
                        {userCompleted && <span className="capitalize text-primary">view/edit</span>}
                    </div>
                    <div className="flex-1 tracking-tight text-sm font-medium items-center space-y-1">
                        <h6>Response: </h6>
                        {renderResponders(completedIds)}
                    </div>
                    <div className="flex-1 tracking-tight text-sm font-medium items-center space-y-1">
                        <h6>Approval: </h6>
                        {renderResponders(actuallyApprovedIds)}
                    </div>
                </div>
            );
        }
    };

    const renderAction = () => <span></span>;

    return (
        <div className="py-2 flex justify-between items-center spaxe-x-6">
            <div className="flex-1 cursor-pointer flex justify-between" onClick={handleRowClick}>
                {renderLabel()}
            </div>
            <div>{renderAction()}</div>
        </div>
    );
};

const ReviewQueueGroup = ({
    defaultToggled = false,
    info = "The following reviews are incomplete:",
    cycleId,
    reviewItems,
    userEmployeeId,
    getEmployee,
    getCycleName,
    onReviewClick,
}) => {
    const [toggled, onToggle] = useToggle(defaultToggled);

    const handleToggle = () => {
        onToggle();
    };

    const renderHeader = () => {
        const cycleName = getCycleName(cycleId);
        return (
            <div className="flex justify-between items-center px-6 py-3 border-b">
                <div className="flex-1 flex items-center justify-between space-x-2">
                    <h5 className="tracking-tighter text-lg font-medium">{cycleName}</h5>
                    <MyBadge color="primary">{reviewItems.length}</MyBadge>
                </div>
                <ToggleComponent toggled={toggled} className="cursor-pointer" />
            </div>
        );
    };

    return (
        <ExpandableCard
            headerComponent={renderHeader()}
            className="bg-base-100 shadow-none"
            onHeaderClick={handleToggle}
            toggled={toggled}
        >
            <div className="py-3 px-6 divide-y">
                <h6 className="tracking-tighter text-xs mb-2 flex justify-between text-primary items-center">
                    <div>{info}</div>
                </h6>
                <Each
                    of={reviewItems}
                    render={(item) => (
                        <ReviewerRow
                            item={item}
                            userEmployeeId={userEmployeeId}
                            onClick={onReviewClick}
                            getEmployee={getEmployee}
                        />
                    )}
                />
            </div>
        </ExpandableCard>
    );
};

export default ReviewQueueGroup;
