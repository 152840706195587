import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useTalentMapConfig(talentMapId) {
    const talentMaps = useSelector((state) => state.config.talentMaps);
    const savedActiveTalentMapId = useSelector((state) => state.config.ownConfig.activeTalentMapId);

    const activeTalentMapId = useMemo(() => {
        if (savedActiveTalentMapId) return savedActiveTalentMapId;
        if (Object.keys(talentMaps).length === 0) return null;
        return Object.keys(talentMaps)[0];
    }, [talentMaps, savedActiveTalentMapId]);

    const activeTalentMap = useMemo(() => {
        if (!activeTalentMapId) return null;
        return talentMaps[activeTalentMapId];
    }, [activeTalentMapId, talentMaps]);

    const talentMapConfig = useMemo(() => {
        if (talentMapId) {
            return talentMaps[talentMapId];
        } else {
            return activeTalentMap || {};
        }
    }, [activeTalentMap, talentMapId, talentMaps]);

    return { talentMapConfig, activeTalentMapId };
}
