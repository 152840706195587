import { createSelector } from "@reduxjs/toolkit";

const getRole = createSelector(
    (state, roleId) => state.businessUnit.roles[roleId],
    (role) => role || {}
);

const getRoles = createSelector(
    (state) => state.businessUnit.roles,
    (roles) => roles || {}
);

const getParentMap = createSelector(
    (state) => state.businessUnit.parentMap,
    (parentMap) => parentMap || {}
);

const getSelectedRoleId = createSelector(
    (state) => state.app.selectedRoleId,
    (selectedRoleId) => selectedRoleId
);

const getSelectedRole = createSelector(
    [getRoles, getSelectedRoleId],
    (roles, selectedRoleId) => roles[selectedRoleId] || {}
);

export { getParentMap, getRole, getRoles,getSelectedRoleId, getSelectedRole };
