import React, { useState } from "react";
import { toast } from "react-toastify";
import MyButton from "../../../../../../components/MyComponents/Button";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../../../../../../components/MyComponents/Modal";
import { useTrackChanges } from "../../../../../../hooks/useTrackChanges";
import ColumnsStep from "./steps/ColumnsStep";
import DetailsStep from "./steps/DetailsStep";
import FiltersStep from "./steps/FiltersStep";

const steps = [
    { id: "s-1", label: "Select Columns", details: "Choose the columns you want included in the table." },
    { id: "s-2", label: "Select Filters", details: "Choose how you would like to filter the data." },
    { id: "s-3", label: "Submit", details: "Confirm and save your table" },
];

const TableEditor = ({ table, columnOptions, filterOptions, isOpen, onToggle, onSave }) => {
    const [stepIndex, setStepIndex] = useState(0);
    const [disableButtons, setDisableButtons] = useState(false);
    const [edits, setEdits, editedTable] = useTrackChanges(table || {});
    const hidePrevious = stepIndex === 0;
    const hideNext = stepIndex === 2;
    const hideFinish = stepIndex !== 2;
    const { columns = [], filters = [], tableName } = editedTable || {};

    const handleChangeField = (fieldName, newValue) => {
        setEdits(fieldName, newValue);
    };

    const handleNext = () => {
        setStepIndex(stepIndex + 1);
    };

    const handlePrev = () => {
        setStepIndex(stepIndex - 1);
    };

    const handleSave = async () => {
        const result = await onSave(editedTable);
        if (!result) {
            toast.error("Error saving table");
        }
        onToggle();
    };

    const renderStep = () => {
        switch (stepIndex) {
            case 0:
                return (
                    <ColumnsStep
                        onChange={handleChangeField}
                        columnOptions={columnOptions}
                        selectedColumnIds={columns}
                        setDisableButtons={setDisableButtons}
                    />
                );
            case 1:
                return (
                    <FiltersStep
                        onChange={handleChangeField}
                        onFilterOpen={setDisableButtons}
                        filterOptions={filterOptions}
                        filters={filters}
                    />
                );
            case 2:
                return (
                    <DetailsStep
                        onChange={handleChangeField}
                        setDisableButtons={setDisableButtons}
                        table={editedTable}
                    />
                );
            default:
                return null;
        }
    };

    const activeStep = steps[stepIndex];

    return (
        <MyModal isOpen={isOpen} size="md" height="xl">
            <MyModalHeader onToggle={onToggle}>Table Editor</MyModalHeader>
            <MyModalBody className="flex-1 flex flex-col pt-4 overflow-hidden">
                <div className="flex-1 flex flex-col overflow-hidden space-y-4 pb-6">
                    <div className="px-6">
                        <h4>{activeStep?.label}</h4>
                        <p className="text-sm">{activeStep?.details}</p>
                    </div>
                    <hr />
                    <div className="flex flex-col flex-1 px-6 overflow-auto">{renderStep()}</div>
                </div>
            </MyModalBody>
            <MyModalFooter>
                {!hidePrevious && (
                    <MyButton disabled={disableButtons} onClick={handlePrev}>
                        Previous
                    </MyButton>
                )}
                {!hideNext && (
                    <MyButton disabled={disableButtons} onClick={handleNext}>
                        Next
                    </MyButton>
                )}
                {!hideFinish && (
                    <MyButton color="primary" disabled={!edits || disableButtons || !tableName} onClick={handleSave}>
                        Finish
                    </MyButton>
                )}
            </MyModalFooter>
        </MyModal>
    );
};

export default TableEditor;
