import React, { useEffect, useMemo, useState } from "react";
import { PageSection } from "../../../../common/components";
//import ObjectiveOverview from "../../../Objectives/components/organisms/ObjectiveOverview/ObjectiveOverview";
import { calculateSnapshotValues, makeRatingsArray } from "../../../Snapshots/utils/snapshotHelpers";
import TalentStrength from "../../../Traits/components/organisms/TalentStrength";
import DirectReportCards from "../organisms/DirectReportCards/DirectReportCards";

const RoleOverviewTemplate = ({
    roleId,
    children,
    subordinates,
    childRoleIds,
    showObjectives,
    traits,
    talentAreas,
    talentBoards,
    departmentTree,
    hasSubDepartments,
    getColor,
    getFilteredSnapshots,
    onNavigateToOrgNode,
}) => {
    const [talentAreaRatings, setTalentAreaRatings] = useState([]);
    const [ratings, setRatings] = useState([]);

    const filteredSnapshots = useMemo(() => {
        return getFilteredSnapshots(roleId);
    }, [roleId, getFilteredSnapshots]);

    const directReports = useMemo(() => {
        return subordinates.filter((subordinate) => childRoleIds.includes(subordinate.roleId));
    }, [subordinates, childRoleIds]);

    useEffect(() => {
        const departmentSnapshot = calculateSnapshotValues(filteredSnapshots, traits, talentAreas, talentBoards);
        setTalentAreaRatings(makeRatingsArray(departmentSnapshot.talentAreas));
        setRatings(makeRatingsArray(departmentSnapshot.ratings));
    }, [filteredSnapshots, traits, talentAreas, talentBoards]);

    const handleViewEmployee = (roleId, employeeId) => {
        onNavigateToOrgNode(roleId, employeeId);
    };

    return (
        <div className="flex-1 flex flex-col space-y-2">
            {children}
            <div className="flex-1 flex flex-col space-y-8">
                <PageSection title="Team Strengths">
                    <TalentStrength
                        horizontal
                        wordCloudId="departmentTabTeam"
                        className="bg-base-100 rounded-lg overflow-hidden"
                        talentAreaRatings={talentAreaRatings}
                        ratings={ratings}
                    />
                </PageSection>
                <PageSection title="Direct Reports">
                    <DirectReportCards directReports={directReports} onViewEmployee={handleViewEmployee} />
                </PageSection>
                {/* Objectives
                <PageSection hidden={!showObjectives} title="Objectives">
                    <ObjectiveOverview
                        showSubDepartments={hasSubDepartments}
                        departmentTree={departmentTree}
                        counts
                        statuses
                        checkins
                        subordinates
                        getColor={getColor}
                    />
                </PageSection>
                */}
            </div>
        </div>
    );
};

export default RoleOverviewTemplate;
