import React from "react";
import TalentMap from "../../organisms/TalentMap/TalentMap";

const TalentMapTemplate = ({
    talentMapConfig,
    grid,
    scatterData,
    talentMapRef,
    getFieldLabel,
    onItemClick,
}) => {
    const handleItemClick = (employeeId) => {
        onItemClick(employeeId);
    };
    return (
        <div className="bg-base-150 flex h-full flex-col flex-1">
            <TalentMap
                mapConfig={talentMapConfig}
                grid={grid}
                scatterData={scatterData}
                talentMapRef={talentMapRef}
                getFieldLabel={getFieldLabel}
                onItemClick={handleItemClick}
            />
        </div>
    );
};

export default TalentMapTemplate;
