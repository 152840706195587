import { ensureSerializable } from "../../common/utils/objectUtils";

function prepQuerySnapshot(querySnapshot) {
    let data = {};
    querySnapshot.forEach((doc) => {
        data[doc.id] = { id: doc.id, ...doc.data() };
    });
    return ensureSerializable(data);
}

function prepDocSnapshot(doc) {
    if (!doc.exists) return {};
    return ensureSerializable({ id: doc.id, ...doc.data() });
}

export { prepDocSnapshot, prepQuerySnapshot };

