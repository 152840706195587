import classNames from "classnames";
import React from "react";
import { useResizeDetector } from "react-resize-detector";

const ChartContainer = ({ children, className, responsiveHeight, padding = 0 }) => {
    const { width, height, ref } = useResizeDetector({ refreshMode: "debounce", refreshRate: 300 });
    let applyHeight = responsiveHeight ? height : width;
    applyHeight = applyHeight - padding;
    const applyWidth = width - padding;

    return (
        <div ref={ref} style={{ width: "100%" }} className={classNames(className, "flex items-center justify-center")}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, { width: applyWidth, height: applyHeight, size: applyWidth });
            })}
        </div>
    );
};

export default ChartContainer;
