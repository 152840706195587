import React from "react";
import { Tabs } from "react-daisyui";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

const MyTabs = (props) => {
    const navigate = useNavigate();
    const { tabs, activeTabKey, tabKeyOverride, color, className, onChange, ...cleanProps } = props;
    // If activeTabKey is an object, use the key
    const activeKey = typeof activeTabKey === "object" ? activeTabKey?.key : activeTabKey;

    const handleChange = (tabKey) => {
        const tab = tabs.find((tab) => tab.key === tabKey);
        if (tab.link) navigate(tab.link);
        if (tab?.disabled) return;
        onChange(tabKey);
    };

    const addColorTo = props.variant === "bordered" ? "border" : "bg";
    const colorClass = `${addColorTo}-${color}`;
    const contentColorClass = `text-${color}-content`;
    const activeClasses = `${colorClass} font-bold ${contentColorClass}`;

    return (
        <Tabs {...cleanProps} onChange={handleChange} value={activeKey} className={className}>
            {tabs.map((tab) => {
                const { key, label, disabled } = tab;
                const isActive = activeKey === key;
                return (
                    <Tabs.Tab
                        className={classNames("rounded-t-lg", {
                            bordered: props.variant === "bordered",
                            [activeClasses]: isActive,
                        })}
                        key={`tab-${key}`}
                        value={key}
                        disabled={disabled}
                    >
                        <span className="tracking-tighter">{label}</span>
                    </Tabs.Tab>
                );
            })}
        </Tabs>
    );
};

MyTabs.propTypes = {
    tabs: PropTypes.array,
    onChange: PropTypes.func,
    boxed: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.oneOf(["primary", "secondary", "success", "warning", "error", "info"]),
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
    variant: PropTypes.oneOf(["bordered", "lifted"]),
    activeTabKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

MyTabs.defaultProps = {
    className: "rounded-none bg-transparent",
    tabs: [],
    color: "secondary",
    onChange: () => {},
};

export default MyTabs;
