import classNames from "classnames";
import React, { forwardRef, useEffect, useState } from "react";
import Button from "../../../../../common/components/atoms/Button/Button";
import PromptInput from "../../../../../common/components/molecules/PromptInput/PromptInput";
import { DeleteIcon } from "../../../../../components/Icons/MyIcons";
import { FIELD_SIZE_LIMITS } from "../../../../../constants";
import { usePrevious } from "../../../../../hooks/usePrevious";
import { useEditedMeasure } from "../../../hooks/useEditedMeasure";
import MeasureValues from "./components/MeasureValues";

const MeasureCard = forwardRef(({ creatorMode, measure, placeholder, hidden, locked, onChange, onRemove }, ref) => {
    const { editedMeasure, updateMeasure, setAllValues } = useEditedMeasure(measure);
    const [viewMode, setViewMode] = useState(measure?.id ? "view" : "create");
    const { measureName, label } = editedMeasure || {};
    const prevEditedId = usePrevious(editedMeasure?.id);

    // Save the measure immediately when it receives an id
    useEffect(() => {
        if (creatorMode && !prevEditedId && !!editedMeasure?.id) {
            onChange(editedMeasure);
            setAllValues({});
            setViewMode("create");
        }
    }, [creatorMode, prevEditedId, editedMeasure, onChange, setAllValues]);

    const handleRemove = () => {
        if (creatorMode) {
            setAllValues({});
            setViewMode("create");
        }
        onRemove(editedMeasure);
    };

    // track changes that haven't been sent back to the parent yet
    const handleNameChange = (measureName) => {
        updateMeasure("measureName", measureName);
    };

    const handleSubmitName = (measureName) => {
        if (viewMode === "create") {
            updateMeasure("create", measureName);
        } else {
            updateMeasure("measureName", measureName);
        }
        setViewMode("view");
    };

    const handleUpdateMeasure = (action, val) => {
        updateMeasure(action, val);
    };

    const handleSetViewMode = (mode) => {
        setViewMode(mode);
    };

    const handleNameBlur = () => {
        if (!measureName || measureName.length === 0) {
            handleRemove();
        } else {
            onChange(editedMeasure);
        }
    };

    const renderInput = () => {
        if (creatorMode) {
            return (
                <PromptInput
                    ref={ref}
                    disabled={locked}
                    value={measureName || label}
                    placeholder={placeholder}
                    maxLength={FIELD_SIZE_LIMITS.GENERIC_LONG_NAME}
                    onSubmit={handleSubmitName}
                    size="sm"
                />
            );
        } else {
            return (
                <input
                    className={classNames(
                        "bg-base-100 w-full font-medium text-sm",
                        "focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none"
                    )}
                    max={FIELD_SIZE_LIMITS.GENERIC_DESCRIPTION}
                    onChange={(e) => handleNameChange(e.target.value)}
                    onBlur={handleNameBlur}
                    value={measureName || label || ""}
                    disabled={locked}
                />
            );
        }
    };

    if (hidden) return null;
    return (
        <div
            className={classNames("bg-base-100 items-center border rounded-box flex px-6 py-2 space-x-3 h-12", {
                "transition duration-300 border-secondary": viewMode === "edit",
            })}
        >
            <div className="flex-1 flex items-center">{renderInput()}</div>
            <div className="flex items-center">
                <MeasureValues
                    viewMode={viewMode}
                    measure={editedMeasure}
                    setViewMode={handleSetViewMode}
                    onUpdateMeasure={handleUpdateMeasure}
                />
            </div>
            <div className="w-fit">
                <Button
                    hidden={locked || creatorMode}
                    size="sm"
                    tooltip="delete"
                    tooltipPosition="left"
                    shape="circle"
                    onClick={handleRemove}
                >
                    <DeleteIcon size={16} />
                </Button>
            </div>
        </div>
    );
});

export default MeasureCard;
