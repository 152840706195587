import { doc, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { editDoc, getCollectionRef } from "../../../firebase/firebaseActions";

function getRoleRef(workspaceId, roleId) {
    const rolesRef = getCollectionRef(workspaceId, "roles");
    return doc(rolesRef, roleId);
}

const writeRole = (workspaceId, roleId, update, batch = writeBatch(db)) => {
    const roleDocRef = getRoleRef(workspaceId, roleId);
    batch = editDoc(roleDocRef, { id: roleId, ...update }, batch);
    return batch;
};

const disableRole = (workspaceId, roleId, batch = writeBatch(db)) => {
    const roleDocRef = getRoleRef(workspaceId, roleId);
    batch.set(roleDocRef, { disabled: true }, { merge: true });
    return batch;
};

export { writeRole, disableRole };
