import React, { useRef, useEffect, useMemo } from "react";
import * as d3 from "d3";
import { isChartReady } from "../../../common/utils/chartUtils";
import { getUniqueId } from "../../../common/utils/getUniqueId";
import ReactTooltip from "react-tooltip";

const defaultColorFunction = () => "steelblue";

const LollipopChart = ({
    data,
    height,
    width,
    prevLabel = "Previous",
    newLabel = "Now",
    diffLabel = "Change",
    getColor = defaultColorFunction,
}) => {
    const svgRef = useRef();
    const tooltipId = useMemo(() => getUniqueId(), []);

    useEffect(() => {
        const chartIsReady = isChartReady(data, height, width);
        if (chartIsReady) {
            const margin = { top: 20, right: 30, bottom: 70, left: 40 }, // Adjusted bottom margin for rotated labels
                innerWidth = width - margin.left - margin.right,
                innerHeight = height - margin.top - margin.bottom;

            // Remove previous SVG elements
            d3.select(svgRef.current).selectAll("*").remove();

            // Create the SVG container
            const svg = d3
                .select(svgRef.current)
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            // Set the x and y scales
            const x = d3
                .scaleBand()
                .domain(data.map((d) => d.label))
                .range([0, innerWidth])
                .padding(1);

            // Determine the y-axis domain
            const minChange = d3.min(data, (d) => d.change);
            const maxChange = d3.max(data, (d) => d.change);
            const yDomain = [Math.min(0, minChange), Math.max(0, maxChange)];

            const y = d3.scaleLinear().domain(yDomain).range([innerHeight, 0]);

            // Add the x-axis
            svg.append("g")
                .attr("transform", `translate(0,${innerHeight})`)
                .call(d3.axisBottom(x))
                .selectAll("text")
                .attr("transform", "rotate(-45)") // Rotate labels to 45 degrees
                .style("text-anchor", "end");

            // Add the y-axis
            svg.append("g").call(d3.axisLeft(y));

            // Add a horizontal line at y=0
            svg.append("line")
                .attr("x1", 0)
                .attr("x2", innerWidth)
                .attr("y1", y(0))
                .attr("y2", y(0))
                .attr("stroke", "black")
                .attr("stroke-width", 1)
                .attr("stroke-dasharray", "4 4");

            // Add the lines for the lollipops
            svg.selectAll(".lollipop-line")
                .data(data)
                .enter()
                .append("line")
                .attr("class", "lollipop-line")
                .attr("x1", (d) => x(d.label) + x.bandwidth() / 2)
                .attr("x2", (d) => x(d.label) + x.bandwidth() / 2)
                .attr("y1", y(0))
                .attr("y2", (d) => y(d.change))
                .attr("stroke", "black")
                .attr("stroke-width", 2); // Set the stroke width for the lollipop bars

            // Add the circles for the lollipops
            svg.selectAll(".lollipop-circle")
                .data(data)
                .enter()
                .append("circle")
                .attr("class", "lollipop-circle")
                .attr("cx", (d) => x(d.label) + x.bandwidth() / 2)
                .attr("cy", (d) => y(d.change))
                .attr("r", 6)
                .attr("fill", (d) => getColor(d.newVal))
                .attr(
                    "data-tip",
                    (d) =>
                        `${d.label}<br/>
                 ${newLabel}: ${d.newVal}<br/>
                 ${prevLabel}: ${d.prevVal}<br/>
                 ${diffLabel}: ${d.change}`
                )
                .attr("data-for", tooltipId);

            ReactTooltip.rebuild();
        }
    }, [data, height, width, diffLabel,newLabel, prevLabel, tooltipId, getColor]);

    return (
        <>
            <svg ref={svgRef}></svg>
            <ReactTooltip id={tooltipId} multiline={true} />
        </>
    );
};

export default LollipopChart;
