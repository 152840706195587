import classNames from "classnames";

const Checkbox = ({ size = "sm", color = "neutral", label, checked, onChange }) => {
    const handleChange = () => {
        onChange(!checked);
    };
    const sizeClass = `checkbox-${size}`;
    const colorClass = `checkbox-${color}`;
    const textSizeClass = `text-${size}`;

    return (
        <div className="flex space-x-2 items-center">
            {label && <label className={classNames(textSizeClass, "tracking-tight font-medium capitalize")}>{label}</label>}
            <input
                className={classNames("checkbox", colorClass, sizeClass, "cursor-pointer")}
                checked={checked}
                type="checkbox"
                onChange={handleChange}
            />
        </div>
    );
};

export default Checkbox;
