

function listRoleParentRole(parentMap, roleId) {
    const parentRoleId = parentMap[roleId];
    return parentRoleId;
}

function listRoleParentRoleIds(parentMap, roleId) {
    let parentRoles = [];
    let parentRoleId = parentMap[roleId];
    while (parentRoleId) {
        parentRoles.push(parentRoleId);
        parentRoleId = parentMap[parentRoleId];
    }
    return parentRoles;
}

function listRoleOrgLevel(parentMap, roleId) {
    const allParents = listRoleParentRoleIds(parentMap, roleId);
    return allParents.length;
}

function listChildRoleIds(parentMap, roleId) {
    let childRoles = Object.entries(parentMap)
        .filter(([, parentRoleId]) => parentRoleId === roleId)
        .map(([childRoleId]) => childRoleId);
    return childRoles;
}

function isManagementRole(parentMap, roleId) {
    const childRoles = listChildRoleIds(parentMap, roleId);
    return childRoles.length > 0;
}

function listAllRoleIdsBelow(parentMap, roleId) {
    let allChildRoles = [];

    function helper(roleId) {
        const childRoles = listChildRoleIds(parentMap, roleId);
        allChildRoles.push(...childRoles);
        childRoles.forEach((childRoleId) => {
            helper(childRoleId);
        });
    }

    helper(roleId);
    return allChildRoles;
}

function listChildEmployeeIds(parentMap, roles, roleId) {
    const childRoleIds = listChildRoleIds(parentMap, roleId);
    const childEmployeeIds = childRoleIds.map((childRoleId) => roles[childRoleId]?.incumbentId);
    return childEmployeeIds.filter(Boolean);
}

function listAllEmployeeIdsBelow(parentMap, roles, roleId) {
    const allRoleIdsBelow = listAllRoleIdsBelow(parentMap, roleId);
    const allEmployeeIdsBelow = allRoleIdsBelow.map((roleId) => roles[roleId]?.incumbentId);
    return allEmployeeIdsBelow.filter(Boolean);
}

function doesRoleHaveChildren(parentMap, roleId) {
    const childRoles = listChildRoleIds(parentMap, roleId);
    return childRoles.length > 0;
}

function listDirectReports(parentMap, roles, roleId) {
    const subordinateEmployees = listChildEmployeeIds(parentMap, roles, roleId);
    return subordinateEmployees;
}

function listAccessibleParentRoles(roleId, topLevelRoleId) {
    const parentRoleIds = listRoleParentRoleIds(roleId);
    const topRoleIndex = parentRoleIds.indexOf(topLevelRoleId) + 1;
    return parentRoleIds.splice(0, topRoleIndex).reverse();
}

export {
    doesRoleHaveChildren, isManagementRole, listAccessibleParentRoles, listAllEmployeeIdsBelow, listAllRoleIdsBelow,
    listChildEmployeeIds, listChildRoleIds, listDirectReports, listRoleOrgLevel, listRoleParentRole,
    listRoleParentRoleIds
};
