import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import applogger from "../../../../../../common/utils/applogger";
import { useCustomFields } from "../../../../../../features/CustomFields/hooks/useCustomFields";
import { useEmployeeRelationships } from "../../../../../../features/Employees/hooks/useEmployeeRelationships";
import ObjectiveReviewEditorContainer from "../../../../../../features/Reviews/containers/modals/containers/ObjectiveReviewEditorContainer";
import TalentReviewContainer from "../../../../../../features/Reviews/containers/modals/containers/TalentReviewContainer";
import { useReviewListeners } from "../../../../../../features/Reviews/hooks/useReviewListeners";
import { useOrgNodeContext } from "../../OrgNodePage";
import { DUMMY_OBJECTIVES } from "../../../../../../features/Cycles/containers/modals/CycleEditor/defaultConfig";

function selectAssessment(isCurrentIteration, savedReview, activeAssessment) {
    // If the cycle isn't live, use the save flags and assessment data
    if (isCurrentIteration) {
        return activeAssessment;
    } else {
        const { flags = {}, ratings = {} } = savedReview || {};
        return { flags, ratings };
    }
}

function useRelationship(reviewerId, employeeId) {
    const { checkRelationship } = useEmployeeRelationships();
    const relationship = useMemo(() => {
        return checkRelationship(reviewerId, employeeId);
    }, [checkRelationship, reviewerId, employeeId]);
    return relationship;
}

const savedReview = {};

const PreviewPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const isSubmitting = false;
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const { employeeId, cycleId, iterationId, reviewerId } = params;
    const { isSelf, activeEmployee, activeAssessment } = useOrgNodeContext();
    const { assessmentFields } = useCustomFields();
    const relationship = useRelationship(reviewerId, employeeId);
    const { cycle } = useReviewListeners(employeeId, cycleId, null, reviewerId);
    const isCurrentIteration = true;
    const initialFlagsAndRatings = useMemo(() => {
        return selectAssessment(isCurrentIteration, savedReview, activeAssessment);
    }, [isCurrentIteration, activeAssessment]);
    const unsubmittedSaves = savedReview?.unsubmittedSaves || 0;

    const dummyObjectives = useMemo(() => {
        const ownerIdAdded = {};
        Object.entries(DUMMY_OBJECTIVES).forEach(([key, val]) => {
            ownerIdAdded[key] = { ...val, ownerEmployeeId: employeeId };
        });
        return ownerIdAdded;
    }, [employeeId]);

    // Navigate away if not an admin
    useEffect(() => {
        if (!isAdmin) {
            applogger.info("PreviewPage: Unauthorised");
        }
    }, [relationship, isAdmin]);

    const includeNotesPage = useMemo(() => {
        if (cycle?.cycleType === "objectives") return false;
        switch (relationship) {
            case "employee": {
                const hasNotes = cycle?.employeeNotes?.length > 0;
                const hasFlags = cycle?.employeeFlags?.length > 0;
                return hasNotes || hasFlags;
            }
            case "manager": {
                const hasNotes = cycle?.managerNotes?.length > 0;
                const hasFlags = cycle?.managerFlags?.length > 0;
                return hasNotes || hasFlags;
            }
            default:
                return false;
        }
    }, [relationship, cycle]);

    const includeFlagsPage = useMemo(() => {
        switch (relationship) {
            case "employee": {
                return cycle?.employeeFlags?.length > 0;
            }
            case "manager": {
                return cycle?.managerFlags?.length > 0;
            }
            default:
                return false;
        }
    }, [relationship, cycle]);

    const handleSaveDraft = () => {
        // No saving; preview only
        toast.info("This is a preview only. No changes have been saved.");
    };

    const handleToggle = () => {
        navigate(-1);
    };

    const handleApprove = async () => {
        // No approving; preview only
    };

    const handleSubmit = async () => {
        // No submitting; preview only
        toast.info("This is a preview only. No changes have been saved.");
    };

    const values = {
        isSelf,
        isSubmitting,
        locked: false,
        userEmployeeId: reviewerId, // preview as the reviewer
        cycle,
        employeeId: employeeId,
        cycleId: cycleId,
        includeNotesPage,
        includeFlagsPage,
        unsubmittedSaves,
        preview: true,
        iterationId: iterationId,
        savedReview: { ...savedReview, employeeId, cycleId, iterationId, reviewerId },
        liveObjectives: dummyObjectives,
        savedNotes: [],
        activeEmployee,
        initialFlagsAndRatings: initialFlagsAndRatings,
        relationship,
        assessmentFields,
    };

    const actions = {
        onSaveDraft: handleSaveDraft,
        onApprove: handleApprove,
        onSubmit: handleSubmit,
        onToggle: handleToggle,
    };

    switch (cycle?.cycleType) {
        case "objectives":
            return <ObjectiveReviewEditorContainer values={values} actions={actions} />;
        case "talent":
            return <TalentReviewContainer values={values} actions={actions} />;
        default:
            return null;
    }
};

export default PreviewPage;
