import classNames from "classnames";
import React, { useMemo } from "react";
import { Button, PageSection, RichText } from "../../../../../common/components";
import DateSelector from "../../../../../common/components/organisms/DateSelector/DateSelector";
import { formatDate } from "../../../../../helpers/helpers";
import ObjectiveNote from "../../../../Reviews/components/organisms/ObjectiveNote/ObjectiveNote";
import { useEditedObjective } from "../../../hooks/useEditedObjective";
import { ObjectiveResultSelector, ObjectiveStatusSelector } from "../../atoms/ObjectiveStatus";
import CheckInBlock from "../../molecules/CheckInBlock";
import MeasureGroup from "../MeasureGroup/MeasureGroup";

const ObjectiveReviewContent = ({
    userEmployeeId,
    objective,
    notes,
    showHeader,
    iterationId,
    cycleId,
    locked,
    showCheckIn,
    lockCheckIn,
    onSaveNote = () => {},
    onSaveObjective = () => {},
    onToggle = () => {},
}) => {
    const { editedObjective, updateObjective, hasChanges } = useEditedObjective(objective, locked);
    const { id: objectiveId, description, measures, status, result, dueOn } = editedObjective;
    const formattedDueDate = formatDate(dueOn);
    const isSelf = userEmployeeId === objective.ownerEmployeeId;

    const objectiveNotes = useMemo(() => {
        const objectiveNotes = notes.filter((n) => n.objectiveId === objectiveId);
        const ownNote = objectiveNotes.find((n) => n.authorId === userEmployeeId);
        const counterpartNote = objectiveNotes.find((n) => n.authorId !== userEmployeeId);
        return { ownNote, counterpartNote };
    }, [notes, objectiveId, userEmployeeId]);

    const handleUpdateObjective = (key, newVal) => {
        updateObjective(key, newVal);
    };

    const handleUpdateNote = (note) => {
        onSaveNote({
            ...note,
            authorId: userEmployeeId,
            objectiveId: objectiveId,
            iterationId: iterationId,
            cycleId: cycleId,
        });
    };

    const handleToggle = () => {
        if (hasChanges) {
            onSaveObjective(editedObjective);
        }
        onToggle();
    };

    const handleChangeDate = (date) => {
        updateObjective("dueOn", date);
    };

    return (
        <div className="h-full relative flex-1 flex flex-col">
            <div
                className={classNames("flex justify-between items-center border-b py-4 px-6", { hidden: !showHeader })}
            >
                <h4>{objective.objectiveName}</h4>
            </div>
            <div className="flex items-center space-x-2 justify-between border-b py-4 px-6">
                <div className="max-w-md flex-1 flex space-x-2 justify-start font-semibold">
                    <span>To Be Achieved By:</span>
                    <div className="flex items-center">
                        <DateSelector dateValue={formattedDueDate} onChange={handleChangeDate} />
                    </div>
                </div>
                <div className="flex items-center space-x-2 justify-between">
                    <ObjectiveStatusSelector
                        status={status}
                        onChange={handleUpdateObjective}
                    />
                    <ObjectiveResultSelector
                        status={status}
                        result={result}
                        onChange={handleUpdateObjective}
                    />
                </div>
            </div>
            <div className="flex-1 flex flex-col space-y-6 p-6 overflow-scroll">
                <div className="flex-1 flex-col justify-between space-y-4">
                    <div className="flex-1">
                        <h6 className="font-semibold mb-1">Description</h6>
                        <RichText content={description} maxHeight={220} readOnly removePadding />
                    </div>
                    <div className="flex space-x-2">
                        <div className="flex-1 flex flex-col min-h-40">
                            <h6 className="font-semibold mb-1">Your Comments</h6>
                            <ObjectiveNote
                                note={objectiveNotes.ownNote}
                                objectiveId={objectiveId}
                                readOnly={locked}
                                onUpdate={handleUpdateNote}
                                className="flex-1"
                                bgClass="bg-editing"
                            />
                        </div>
                        <div className="flex-1 flex flex-col">
                            <h6 className="font-semibold mb-1">{isSelf ? "Manager Comments" : "Employee Comments"}</h6>
                            <ObjectiveNote
                                note={objectiveNotes.counterpartNote}
                                readOnly={true}
                                className="flex-1 border rounded-lg"
                            />
                        </div>
                    </div>
                    <hr />
                    <MeasureGroup
                        allowAdd={false}
                        locked={true}
                        measures={measures}
                        updateObjective={handleUpdateObjective}
                    />
                </div>
                <PageSection hidden={!showCheckIn} title="Check In">
                    <CheckInBlock
                        objective={editedObjective}
                        updateField={handleUpdateObjective}
                        readOnly={lockCheckIn || locked}
                    />
                </PageSection>
            </div>
            <div className={classNames("flex justify-end items-center border-t py-3 px-6", { hidden: !showHeader })}>
                <Button size="sm" variant="outline" onClick={handleToggle}>
                    DONE
                </Button>
            </div>
        </div>
    );
};

export default ObjectiveReviewContent;
