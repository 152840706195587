import { deleteField, serverTimestamp, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { getWorkspaceId } from "../../Workspace/utils/workspaceSelectors";
import { deleteNotification, writeEmployee } from "./employeeDb";

const revokeAccessThunk = (employeeId) => async (dispatch, getState) => {
    const workspaceId = getWorkspaceId(getState());
    const update = {
        isUser: deleteField(),
        inviteAccepted: deleteField(),
        userRole: deleteField(),
        customClaims: deleteField(),
    };
    let batch = writeEmployee(workspaceId, employeeId, update);
    await batch.commit();
};

const changeWorkspaceClaimsThunk = (employeeId, newClaims, prevClaims) => async (dispatch, getState) => {
    const workspaceId = getState().workspace.workspaceId;
    const { isUser, userRole, inviteAccepted, ...customClaims } = newClaims;
    let employeeUpdate = {
        userRole,
        isUser,
        customClaims,
        workspaceId, // Ensures the workspaceId is on the document for invite querying
    };
    if (inviteAccepted !== undefined) {
        employeeUpdate.inviteAccepted = inviteAccepted;
    }
    // Create an invite for new users;
    const wasUser = Object.keys(prevClaims || {}).includes("userRole");
    const willBeUser = Object.keys(newClaims || {}).includes("userRole");
    const becomingUser = !wasUser && willBeUser;
    let batch = writeBatch(db);
    employeeUpdate.isUser = willBeUser;
    if (becomingUser) {
        employeeUpdate.inviteAccepted = false;
        employeeUpdate.inviteDate = serverTimestamp();
    }
    batch = writeEmployee(workspaceId, employeeId, employeeUpdate, batch);
    await batch.commit();
};

const deleteNotificationThunk = (employeeId, notificationId) => async (dispatch, getState) => {
    const workspaceId = getWorkspaceId(getState());
    const batch = deleteNotification(workspaceId, employeeId, notificationId);
    await batch.commit();
};

export { changeWorkspaceClaimsThunk, deleteNotificationThunk, revokeAccessThunk };
