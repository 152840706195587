import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ViewTalentAssessment from "../../../../../../features/EmployeeAssessments/Views/ViewTalentAssessment";
import { useOrgNodeContext } from "../../OrgNodePage";

const ManagerAssessmentPage = () => {
    const navigate = useNavigate();
    const { activeEmployee, snapshot, isSelf } = useOrgNodeContext();

    // Navigate to the dashboard if the employee is the user
    useEffect(() => {
        if (isSelf) {
            //navigate("development-plan");
        }
    }, [navigate, isSelf]);

    const { talentAssessment, selfAssessment } = activeEmployee;

    return (
        <div className="page page-padding">
            <ViewTalentAssessment
                activeEmployee={activeEmployee}
                thisAssessment={talentAssessment}
                compareAssessment={selfAssessment}
                snapshot={snapshot}
            />
        </div>
    );
};

export default ManagerAssessmentPage;
