import classNames from "classnames";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Button, TextInput } from "../../../../../common/components";
import DateSelector from "../../../../../common/components/organisms/DateSelector/DateSelector";
import { useValidation } from "../../../../../common/hooks/useValidation";
import { FIELD_SIZE_LIMITS } from "../../../../../constants";
import { useEditedObjective } from "../../../hooks/useEditedObjective";
import ObjectiveDescription from "../../atoms/ObjectiveDescription";
import CascadeSelector from "../../molecules/CascadeSelector";
import CheckInBlock from "../../molecules/CheckInBlock";
import MeasureGroup from "../MeasureGroup/MeasureGroup";

const ObjectiveSettingContent = ({
    objective,
    locked = false,
    showCheckIn = false,
    isInModal = false,
    hasChildren = false,
    onChange = () => {},
    onValidation = () => {},
    onClose = () => {},
    onSave = () => {},
}) => {
    const objectiveEditor = useEditedObjective(objective, locked);
    const { editedObjective, updateObjective, hasChanges, objectiveValidation } = objectiveEditor;
    const { objectiveName, description, measures, blockCascades, cascadeType, dueOn } = editedObjective || {};
    const [isValid, errors] = useValidation(editedObjective, objectiveValidation);

    useEffect(() => {
        onValidation(isValid);
    }, [onValidation, isValid]);

    useEffect(() => {
        onChange(editedObjective);
    }, [onChange, editedObjective]);

    const handleSetName = (_, val) => {
        updateObjective("objectiveName", val);
    };

    const handleSetDescription = (val) => {
        updateObjective("description", val);
    };

    const handleSetCascadeType = (val) => {
        updateObjective("cascadeType", val);
    };

    const handleSetDate = (val) => {
        updateObjective("dueOn", val);
    };

    const handleSetMeasures = (field, val) => {
        updateObjective(field, val);
    };

    const handleSave = () => {
        const disabled = !hasChanges || !isValid;
        if (disabled) {
            Object.values(errors || {}).forEach((error) => {
                toast.error(error);
            });
        } else {
            onSave(editedObjective);
        }
        if (isValid) onClose();
    };

    const handleDiscard = () => {
        onClose();
    };

    const placeholder = editedObjective.parentName
        ? `How will you contribute to "${editedObjective.parentName}"?`
        : "What are you going to achieve?";

    return (
        <div className="h-full relative flex-1 flex flex-col overflow-auto">
            <div className="sticky border-b">
                {!isInModal && (
                    <div className="bg-white flex justify-between items-center border-b py-4 px-6">
                        <h4>Create New Objective</h4>
                    </div>
                )}
                <div className="py-2 px-6 flex items-center space-x-6 justify-end border-base-500">
                    {hasChildren && (
                        <div className="max-w-md flex-1 flex items-center space-x-2 justify-end font-semibold">
                            <h6 className="text-sm text-base-800 font-medium tracking-tight">Cascading</h6>
                            <div className="flex items-center">
                                <CascadeSelector
                                    disabled={locked || blockCascades}
                                    value={cascadeType}
                                    onChange={handleSetCascadeType}
                                />
                            </div>
                        </div>
                    )}
                    <div className="max-w-md flex space-x-2 justify-start font-semibold">
                        <h6 className="text-sm text-base-800 font-medium tracking-tight">To Be Achieved By:</h6>
                        <div className="flex items-center">
                            <DateSelector disabled={locked} dateValue={dueOn} noDay onChange={handleSetDate} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1 px-6 space-y-6 py-4 overflow-scroll">
                <div className="flex-col justify-between space-y-4">
                    <div className="flex flex-col">
                        <h6 className="text-sm text-base-800 font-medium tracking-tight">Objective Name</h6>
                        <TextInput
                            onChange={handleSetName}
                            value={objectiveName}
                            placeholder={placeholder}
                            maxLength={FIELD_SIZE_LIMITS.GENERIC_NAME}
                        />
                    </div>
                    <div>
                        <h6 className="text-sm text-base-800 font-medium tracking-tight">Description</h6>
                        <div
                            className={classNames(
                                "flex items-stretch justify-stretch flex-1 border overflow-hidden rounded-lg"
                            )}
                        >
                            <ObjectiveDescription
                                description={description}
                                placeholder="Add some more details about this objective"
                                onChange={handleSetDescription}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <MeasureGroup
                        allowAdd
                        title="Measures"
                        locked={locked}
                        measures={measures}
                        updateObjective={handleSetMeasures}
                    />
                </div>
                <CheckInBlock
                    hidden={!showCheckIn}
                    objective={editedObjective}
                    updateField={updateObjective}
                    readOnly={locked}
                />
            </div>
            {!isInModal && (
                <div className="flex space-x-3 justify-end border-t py-3 px-6">
                    <Button size="sm" variant="outline" onClick={handleDiscard}>
                        Close without saving
                    </Button>
                    <Button size="sm" color="primary" variant="outline" onClick={handleSave}>
                        Save & Close
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ObjectiveSettingContent;
