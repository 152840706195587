import { NotUserIcon, PendingUserIcon, UserIcon } from "../../../../components/Icons/MyIcons";
import MyTooltip from "../../../../components/MyComponents/Tooltip";

export const UserStatusIcon = ({ employee, position = "right", size = 26 }) => {
    const { userRole, inviteAccepted } = employee
    const isUser = userRole && userRole !== "none"

    function renderIcon() {
        if (isUser && !inviteAccepted) {
            return <PendingUserIcon size={size} />;
        } else if (isUser && inviteAccepted) {
            return <UserIcon size={size} className="text-success" />;
        } else {
            return <NotUserIcon size={size} className="opacity-20" />;
        }
    }
    const tooltipText = !isUser ? "Not yet invited" : inviteAccepted ? "Yet to accept invite" : "Joined";
    return (
        <MyTooltip tooltip={tooltipText} position={position}>
            {renderIcon()}
        </MyTooltip>
    );
};