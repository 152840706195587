import { deleteField, doc, writeBatch } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { disableDoc, editDoc } from "../../../firebase/firebaseActions";

function getWorkspaceRef(workspaceId) {
    return doc(db, "workspaces", workspaceId);
}

const writeWorkspace = (workspaceId, update, batch = writeBatch(db)) => {
    const workspaceRef = getWorkspaceRef(workspaceId);
    batch.set(workspaceRef, update, { merge: true });
    return batch;
};

const writeTraitMap = (workspaceId, update, batch = writeBatch(db)) => {
    // convert any null values to deleteField()
    let cleaned = { ...update };
    Object.keys(cleaned).forEach((key) => {
        if (cleaned[key] === null) {
            cleaned[key] = deleteField();
        }
    });
    const traitMapRef = doc(getWorkspaceRef(workspaceId), "talentInfo", "traitMap");
    batch.set(traitMapRef, cleaned, { merge: true });
    return batch;
};

const writeTalentItem = (workspaceId, collectionKey, itemId, update, batch = writeBatch(db)) => {
    const workspaceRef = getWorkspaceRef(workspaceId);
    const thisDocRef = doc(workspaceRef, collectionKey, itemId);
    batch = editDoc(thisDocRef, update, batch);
    return batch;
};

const deleteTalentItem = (workspaceId, collectionKey, itemId, batch = writeBatch(db)) => {
    const workspaceRef = getWorkspaceRef(workspaceId);
    const thisDocRef = doc(workspaceRef, collectionKey, itemId);
    switch (collectionKey) {
        case "actions":
        case "traits":
        case "talentAreas":
            batch.delete(thisDocRef);
            break;
        default:
            batch = disableDoc(thisDocRef, batch);
            break;
    }

    return batch;
};

export { deleteTalentItem, writeTalentItem, writeTraitMap, writeWorkspace };
