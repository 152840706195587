import { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { usePrevious } from "./usePrevious";

export function useTrackChanges(initialData = {}, allowReinitialization = false) {
    const [changes, setChanges] = useState(false);
    const [after, setAfter] = useState(initialData);
    const prevInitialData = usePrevious(initialData);

    useEffect(() => {
        const initialDataChanges = isEqual(initialData, prevInitialData);
        if (allowReinitialization && !initialDataChanges) {
            setChanges(false);
            setAfter(initialData);
        }
    }, [prevInitialData, initialData, allowReinitialization]);

    const setChange = useCallback(
        (key, value) => {
            if (!key) {
                setChanges(false);
                setAfter(initialData);
            } else {
                setChanges((prevChanges) => {
                    let updatedChanges = { ...prevChanges };
                    const prevValue = initialData[key];
                    if (prevValue === value) {
                        delete updatedChanges[key];
                    } else {
                        updatedChanges[key] = value;
                    }
                    if (Object.keys(updatedChanges).length === 0) {
                        setAfter(initialData);
                        return false;
                    } else {
                        setAfter({ ...initialData, ...updatedChanges });
                        return updatedChanges;
                    }
                });
            }
        },
        [initialData]
    );

    const overwriteChanges = useCallback(
        (replacedChanges) => {
            setChanges(replacedChanges);
            setAfter({ ...initialData, ...replacedChanges });
        },
        [initialData]
    );

    return [changes, setChange, after, overwriteChanges];
}
