import React from "react";
import ReviewHistoryGroup from "../organisms/ReviewHistoryItem/ReviewHistoryGroup";

const ReviewHistoryTemplate = ({
    userEmployeeId,
    pastReviews,
    getEmployee,
    fetchStatus,
    hasMore,
    fetchMore,
    onReviewClick,
}) => {
    return (
        <div className="flex-1 flex flex-col space-y-2">
            <h5>Completed Reviews</h5>
            <div className="flex-1 flex flex-col">
                <ReviewHistoryGroup
                    userEmployeeId={userEmployeeId}
                    reviews={pastReviews}
                    fetchStatus={fetchStatus}
                    hasMore={hasMore}
                    fetchMore={fetchMore}
                    getEmployee={getEmployee}
                    onReviewClick={onReviewClick}
                />
            </div>
        </div>
    );
};

export default ReviewHistoryTemplate;
