import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useCustomFields } from "../../CustomFields/hooks/useCustomFields";
import { OBJECTIVE_FIELD_OPTIONS } from "../../../constants";

export function useFieldLabels() {
    const { getCustomField } = useCustomFields();
    const overallRatingLabel = useSelector((state) => state.config.everyoneConfig.overallRatingLabel);
    const traits = useSelector((state) => state.workspace.traits);
    const talentAreas = useSelector((state) => state.workspace.talentAreas);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const actions = useSelector((state) => state.workspace.actions);

    const getTalentFieldLabel = useCallback(
        (fieldId, fieldValue) => {
            switch (fieldId) {
                case "ratings":
                    const trait = traits[fieldValue];
                    return trait?.traitName;
                case "talentAreas":
                    const talentArea = talentAreas[fieldValue];
                    return talentArea?.talentAreaName;
                case "talentBoards":
                    const talentBoard = talentBoards[fieldValue];
                    return talentBoard?.boardName;
                case "actions":
                    const action = actions[fieldValue];
                    return action?.actionName;
                default:
                    return null;
            }
        },
        [traits, talentAreas, talentBoards, actions]
    );

    const getFieldLabel = useCallback(
        (field = {}) => {
            const { fieldId, fieldValue } = field;
            switch (fieldId) {
                case "ratings":
                case "talentAreas":
                case "talentBoards":
                    return getTalentFieldLabel(fieldId, fieldValue);
                case "overallRating":
                    return overallRatingLabel;
                case "gridPosition":
                    return "Grid Position";
                case "objectives":
                    return OBJECTIVE_FIELD_OPTIONS.find((f) => f.id === fieldValue)?.label || fieldValue;
                default:
                    const foundField = getCustomField(fieldValue);
                    if (foundField) return foundField.label;
                    return fieldId && fieldId.charAt(0).toUpperCase() + fieldId.slice(1);
            }
        },
        [overallRatingLabel, getCustomField, getTalentFieldLabel]
    );

    return { getFieldLabel };
}
