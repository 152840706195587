export const BASE_CYCLE = {
    filters: [],
    currentIterationId: null,
    employeeGuidance: {},
    managerGuidance: {},
    employeeNotes: [],
    managerNotes: [],
    employeeFlags: [],
    managerFlags: [],
    isLive: true,
    isRunning: false,
};

export const DUMMY_OBJECTIVES = {
    bK6dR4x: {
        id: "bK6dR4x",
        cascadeType: "individual",
        dueOn: "2025-05-20T14:30:00.000Z",
        confidence: 75,
        description: "Details for the objective will be added here",
        createdOn: "2024-05-20T14:30:00.000Z",
        createdInIterationId: "dummy",
        result: null,
        measures: [
            {
                measureType: "boolean",
                startingVal: false,
                targetVal: true,
                currentVal: false,
                measureName: "Measures will be added here",
                id: "ab7dHj2",
            },
        ],
        blockers: [],
        objectiveName: "Dummy Objective 1",
        isComplete: false,
    },
    cL7eS5y: {
        id: "cL7eS5y",
        cascadeType: "individual",
        dueOn: "2025-09-15T10:00:00.000Z",
        confidence: 50,
        createdInIterationId: "dummy",
        description: "Details for the objective will be added here",
        createdOn: "2024-09-15T10:00:00.000Z",
        result: null,
        measures: [
            {
                measureType: "boolean",
                startingVal: false,
                targetVal: true,
                currentVal: false,
                measureName: "Measures will be added here",
                id: "ab7dHj2",
            },
        ],
        blockers: [],
        objectiveName: "Dummy Objective 2",
        isComplete: false,
    },
};
