import React, { useMemo } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { groupBy } from "lodash";
import { camelCaseToTitleCase } from "../../../../utils/stringUtils";
import MyDropdown from "../../../../../components/MyComponents/Dropdown";
import { MyMenuGroup, MyMenuItem } from "../../../../../components/MyComponents/Menu";

const InfiniteTableContextMenu = ({ menuId, disabled, actions = [], highlightEnabled = false, hidden }) => {

    // group orgActions by groupId
    const groupedActions = useMemo(() => {
        return groupBy(actions, "groupId");
    }, [actions]);

    const noActions = actions.length === 0;
    if (hidden || noActions) return null;

    return (
        <MyDropdown
            disabled={disabled}
            toggleIcon={FaEllipsisV}
            toggleShape="circle"
            toggleVariant="outline"
            highlightEnabled={highlightEnabled}
            toggleSize="xs"
        >
            {Object.entries(groupedActions).map(([groupId, actions]) => (
                <MyMenuGroup key={`action-group-${groupId}`} vertical paddingClass="py-2 px-3">
                    <MyMenuItem header>{camelCaseToTitleCase(groupId)}</MyMenuItem>
                    {actions.map((item) => {
                        const key = `${menuId}-${item.id}-org-table`;
                        return (
                            <MyMenuItem key={key} onClick={item.onClick} disabled={item.disabled}>
                                {item.label}
                            </MyMenuItem>
                        );
                    })}
                </MyMenuGroup>
            ))}
        </MyDropdown>
    );
};

export default InfiniteTableContextMenu;
