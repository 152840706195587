import { addDoc } from "firebase/firestore";
import { getWorkspacesCollectionRef } from "../../../firebase/firebaseActions";
const rootRoleId = "eLKBas";
const ownerEmployeeId = "pUrmk";

const WORKSPACE_TEMPLATE = {
    ownerId: ownerEmployeeId,
    rootRoleId: rootRoleId,
    active: true,
    configured: false,
};

async function createWorkspace(workspace) {
    const newWorkspace = {
        ...WORKSPACE_TEMPLATE,
        ...workspace,
        configured: true,
    };
    const workspacesRef = getWorkspacesCollectionRef();
    try {
        await addDoc(workspacesRef, newWorkspace);
        return true;
    } catch (error) {
        throw error;
    }
}

export { createWorkspace };
