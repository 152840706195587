import React from "react";
import errorImg from "../../assets/img/emptystates/Error.png";
import { Button } from "../../common/components";

const SignInErrorPage = ({ onSignOut }) => {
    const handleSignOut = () => {
        onSignOut();
    };

    return (
        <div className="absolute inset-0 flex-1 flex flex-col justify-start py-6 space-y-6">
            <div className="space-y-6">
                <div className="space-y-2">
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex-1 text-primary">
                            <img src={errorImg} alt="illustration of a cloud with a grumpy face" width={420} />
                        </div>
                    </div>
                    <h1 className="text-center">No Invites Found</h1>
                    <p className="text-center mb-0">
                        We can't find any Workspace invites for the email address you provided.
                    </p>
                </div>
                <hr />
                <div className="mb-4 text-lg flex flex-col items-center tracking-tight space-y-4">
                    <p className="text-center mb-0">
                        If you believe this is a mistake, please confirm the following with your support team:
                    </p>
                    <div className="max-w-xl">
                        <ul className="bullets tracking-tight space-y-2">
                            <li>Confirm you have been invited to the workspace</li>
                            <li>
                                Confirm that the email address they have invited matches the one you are attempting to
                                login with
                            </li>
                            <li>
                                If you are still having trouble, ask your support team to uninvite and re-invite you
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex justify-center text-sm">
                <Button color="primary" onClick={handleSignOut}>
                    Sign Out
                </Button>
            </div>
        </div>
    );
};

export default SignInErrorPage;
