import { useCallback } from "react";
import { useListener } from "../../../firebase/hooks/useListener";
import { prepDocSnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { useCycleRefs } from "../refs/useCycleRefs";

function useCycleListener(cycleId, onDataReceived, enabled) {
    const { cycleRef } = useCycleRefs(cycleId);
    const handleDataReceived = useCallback(
        (doc) => {
            const data = prepDocSnapshot(doc);
            onDataReceived(data);
        },
        [onDataReceived]
    );
    const listenerStatus = useListener(cycleRef, handleDataReceived, enabled);
    return enabled ? listenerStatus : "success";
}

export default useCycleListener;
