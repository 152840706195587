import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const FormStep = (props) => {
    return (
        <div className="flex flex-col h-full flex-1" style={props.style}>
            <div className={classNames("fade", props.className)}>
                {props.children}
            </div>
        </div>
    );
};
FormStep.propTypes = {
    sidePanel: PropTypes.elementType,
};

export default FormStep;