import { configureStore } from "@reduxjs/toolkit";

import businessUnitReducer from "../features/BusinessUnits/utils/businessUnitSlice";
import configReducer from "../features/Config/utils/configSlice";
import snapshotReducer from "../features/Snapshots/utils/snapshotsSlice";
import userReducer from "../features/User/utils/userSlice";
import workspaceReducer from "../features/Workspace/utils/workspaceSlice";
import appReducer from "./appSlice";
import { saveLocalConfig } from "./localStorage";

// Check if Redux DevTools should be enabled
const isReduxDevToolsEnabled = process.env.REACT_APP_REDUX_DEVTOOLS === "true";
const isSerializableCheckEnabled = process.env.REACT_APP_ENABLE_SERIALIZABLE_CHECK === 'true';

/*
const loggerMiddleware = (storeAPI) => (next) => (action) => {
    //applogger.info("Dispatching action:", action);
    let result = next(action);
    //applogger.info("Next state:", storeAPI.getState());
    return result;
};

const errorCatchingMiddleware = (storeAPI) => (next) => (action) => {
    try {
        return next(action);
    } catch (err) {
        //applogger.error("Caught an exception!", err);
        throw err;
    }
};
*/

const store = configureStore({
    reducer: {
        app: appReducer,
        user: userReducer,
        config: configReducer,
        businessUnit: businessUnitReducer,
        workspace: workspaceReducer,
        snapshots: snapshotReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: isSerializableCheckEnabled,
        }),
    devTools: isReduxDevToolsEnabled,
});

// Subscribe to store updates to persist state changes
store.subscribe(() => {
    const activeWorkspaceId = store.getState().user.activeWorkspaceId;
    const uid = store.getState().user.id;
    const ownConfig = store.getState().config.ownConfig;
    const savedConfigChanged = store.getState().config.savedConfigChanged;
    if (activeWorkspaceId && savedConfigChanged) {
        saveLocalConfig("userId", uid);
        saveLocalConfig(activeWorkspaceId, ownConfig);
    }
});

export default store;
