import { useCallback } from "react";
import { useSelector } from "react-redux";
import { listChildEmployeeIds } from "../../Roles/utils/roleHelpers";
import { prepEmployeeSnapshot } from "../utils/snapshotHelpers";

export function useSnapshots() {
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const employeeSnapshots = useSelector((state) => state.snapshots.employeeSnapshots);
    const traits = useSelector((state) => state.workspace.traits);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);
    const talentAreas = useSelector((state) => state.workspace.talentAreas);
    const boardMap = useSelector((state) => state.businessUnit.boardMap);
    const roles = useSelector((state) => state.businessUnit.roles);
    const employees = useSelector((state) => state.businessUnit.employees);
    const parentMap = useSelector((state) => state.businessUnit.parentMap);

    const getFilteredSnapshots = useCallback(
        (roleId) => {
            const includedIds = listChildEmployeeIds(parentMap, roles, roleId);
            let includedSnapshots = {};
            includedIds.forEach((id) => {
                if (employeeSnapshots[id]) {
                    includedSnapshots[id] = employeeSnapshots[id];
                }
            });
            return includedSnapshots;
        },
        [employeeSnapshots, parentMap, roles]
    );

    // If an assessment has been passed in, use it to create a snapshot
    const getSnapshot = useCallback(
        (employeeId, fullAssessment, logToConsole = false) => {
            if (userEmployeeId === employeeId) {
                const employeeSnapshot = employeeSnapshots[employeeId];
                const { objectives } = employeeSnapshot || {};
                if (objectives) {
                    return { objectives };
                } else {
                    return {};
                }
            } else {
                if (fullAssessment) {
                    const employee = employees[employeeId];
                    const preppedSnapshot = prepEmployeeSnapshot({
                        employeeId,
                        thisEmployee: employee,
                        rawSnapshot: fullAssessment,
                        traits,
                        talentBoards,
                        talentAreas,
                        boardMap,
                        logToConsole,
                    });
                    return preppedSnapshot;
                } else {
                    const employeeSnapshot = employeeSnapshots[employeeId];
                    return employeeSnapshot || {};
                }
            }
        },
        [userEmployeeId, employees, employeeSnapshots, traits, talentBoards, talentAreas, boardMap]
    );

    return { getSnapshot, getFilteredSnapshots };
}
