import useEditValues from "../../../common/hooks/useEditValues";
import { CLAIM_OPTIONS } from "../../../constants";

function getDefaultClaims() {
    let claimsKeys = CLAIM_OPTIONS.map((claim) => claim.id);
    let defaultClaims = {};
    claimsKeys.forEach((key) => {
        defaultClaims[key] = false;
    });
    return defaultClaims;
}

export function useEditedEmployee(initialValues, locked = false) {
    const [editedEmployee, setValue, hasChanges, setAllValues] = useEditValues(initialValues);

    const updateEmployee = (updateType, val) => {
        if (locked) return;
        switch (updateType) {
            case "custom":
                const [customFieldId, value] = val;
                const newCustomFields = { ...editedEmployee.custom, [customFieldId]: value };
                setValue("custom", newCustomFields);
                break;
            case "userRole":
                const isUser = val !== "none";
                const defaultClaims = getDefaultClaims();
                if (!isUser) {
                    setValue("customClaims", defaultClaims);
                } else {
                    const currentClaims = initialValues.customClaims || {};
                    setValue("customClaims", currentClaims);
                }
                setValue("userRole", val);
                setValue("isUser", isUser);
                break;
            case "canCreateAdmins":
            case "canEditOrg":
            case "canEditTalent":
            case "canViewAssessments":
                const currentClaims = editedEmployee.customClaims || {};
                const newClaims = { ...currentClaims, [updateType]: val };
                setValue("customClaims", newClaims);
                break;
            default:
                // updateType is just a key in default case
                setValue(updateType, val);
                break;
        }
    };

    return [editedEmployee, updateEmployee, hasChanges, setAllValues];
}
