import classNames from "classnames";
import React, { useRef } from "react";
import { BiChevronDown as DefaultToggleIcon } from "react-icons/bi";
import { useClickOutside } from "../../../hooks/useClickOutside";
import ToggleComponent from "../../molecules/ToggleComponent/ToggleComponent";

const ExpandableCard = ({
    title,
    children,
    headerComponent,
    removeBorder = false,
    hideToggle,
    toggled,
    className,
    headerClassName,
    fadeMe,
    toggleOnClick = false,
    toggleSize = 24,
    toggleIcon = DefaultToggleIcon,
    onHeaderClick = () => {},
    onToggle = () => {},
}) => {
    const ToggleIcon = toggleIcon;
    const toggleRef = useRef(null);

    useClickOutside(toggleRef, handleClickOutside);

    function handleClickOutside() {
        if (toggled) {
            onToggle();
        }
    }

    const handleHeaderClick = (e) => {
        if (toggleOnClick) handleToggle(e);
        onHeaderClick(e);
    };

    const handleToggle = (e) => {
        e.stopPropagation();
        onToggle();
    }

    const renderHeader = () => {
        if (headerComponent) {
            return headerComponent;
        }
        return (
            <div className="p-4 flex justify-between items-center bg-gray-100 border-b">
                <h2 className="text-lg font-medium">{title}</h2>
                {!hideToggle && <ToggleComponent component={<ToggleIcon size={toggleSize} />} toggled={toggled} onToggle={handleToggle} />}
            </div>
        );
    };

    return (
        <div
            ref={toggleRef}
            className={classNames(
                {
                    "border rounded-lg shadow-md": !removeBorder,
                    "opacity-50 blur-sm": fadeMe,
                },
                className
            )}
        >
            <div
                className={classNames(headerClassName)}
                onClick={handleHeaderClick}
            >
                {renderHeader()}
            </div>
            {toggled && <div>{children}</div>}
        </div>
    );
};

export default ExpandableCard;
