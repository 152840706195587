import { groupBy } from "lodash";
import React, { useMemo } from "react";
import { Each } from "../../../../common/components";
import ReviewQueueGroup from "../organisms/ReviewQueueGroup/ReviewQueueGroup";

function isActionRequired(review, userComplete, approvalRequired) {
    if (approvalRequired) return true;
    if (userComplete) return false;
    const { managerId, revieweeId, completedIds = [], relationship, flow } = review;
    const managerComplete = completedIds.includes(managerId);
    const revieweeComplete = completedIds.includes(revieweeId);
    switch (flow) {
        case "employeeFirst":
            return revieweeComplete || relationship === "employee";
        case "managerFirst":
            return managerComplete || relationship === "manager";
        default:
            return true;
    }
}

function checkStatuses(review, userEmployeeId) {
    const { approvedIds = [], completedIds = [] } = review;
    const userCompleted = completedIds.includes(userEmployeeId);
    const userApproved = approvedIds.includes(userEmployeeId);
    const completedLength = completedIds.length;
    const approvalRequired = completedLength > 0 && !userApproved;
    const actionRequired = isActionRequired(review, userCompleted, approvalRequired);
    return {
        userCompleted,
        userApproved,
        approvalRequired,
        actionRequired,
    };
}

const ReviewQueueTemplate = ({ reviewQueue, userEmployeeId, getEmployee, onReviewClick }) => {
    const allItems = useMemo(() => {
        let items = [];
        for (let item of reviewQueue) {
            const statuses = checkStatuses(item, userEmployeeId);
            items.push({
                ...item,
                ...statuses,
            });
        }
        return items;
    }, [reviewQueue, userEmployeeId]);

    const groupedItems = useMemo(() => {
        const actionRequiredItems = allItems.filter((item) => item.actionRequired);
        const otherItems = allItems.filter((item) => !item.actionRequired);
        const actionGroup = groupBy(actionRequiredItems, "cycleId");
        const otherGroup = groupBy(otherItems, "cycleId");
        return { actionGroup, otherGroup };
    }, [allItems]);

    function getCycleName(cycleId) {
        const cycle = reviewQueue.find((item) => item.cycleId === cycleId);
        return cycle?.cycleName || "Unknown Cycle";
    }

    return (
        <div className="flex-1 flex flex-col space-y-8">
            <div className="space-y-1">
                <h5>Requires Your Attention</h5>
                <div className="space-y-4">
                    <Each
                        of={Object.entries(groupedItems?.actionGroup || {})}
                        emptyStateProps={{ type: "tasks", className: "h-40", message: "You're up to date" }}
                        render={([cycleId, reviewItems]) => (
                            <ReviewQueueGroup
                                defaultToggled={true}
                                info="The following reviews require your attention:"
                                cycleId={cycleId}
                                reviewItems={reviewItems}
                                userEmployeeId={userEmployeeId}
                                getEmployee={getEmployee}
                                getCycleName={getCycleName}
                                onReviewClick={onReviewClick}
                            />
                        )}
                    />
                </div>
            </div>
            <hr className="border-secondary" />
            <div className="space-y-1 flex-1 flex flex-col min-h-60">
                <h5>Waiting for Others</h5>
                <div className="flex-1 flex flex-col space-y-1">
                    <Each
                        of={Object.entries(groupedItems?.otherGroup || {})}
                        emptyStateProps={{ type: "done", className: "flex-1", message: "Nothing Waiting" }}
                        render={([cycleId, reviewItems]) => (
                            <ReviewQueueGroup
                                cycleId={cycleId}
                                info="The following reviews are waiting for others to complete:"
                                reviewItems={reviewItems}
                                userEmployeeId={userEmployeeId}
                                getEmployee={getEmployee}
                                getCycleName={getCycleName}
                                onReviewClick={onReviewClick}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReviewQueueTemplate;
