import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitButton } from "../../../../../common/components/atoms/Button/Button";
import applogger from "../../../../../common/utils/applogger";
import MyCheckbox from "../../../../../components/MyComponents/Checkbox";
import { MyModal, MyModalBody, MyModalFooter, MyModalHeader } from "../../../../../components/MyComponents/Modal";
import { CLAIM_OPTIONS, USER_ROLES } from "../../../../../constants";
import { useEditedEmployee } from "../../../../Employees/hooks/useEditedEmployee";
import { changeWorkspaceClaimsThunk, revokeAccessThunk } from "../../../../Employees/utils/employeeThunks";
import UserRolePicker from "../../../../User/components/atoms/UserRolePicker";

const ChangeUserRole = ({ activeEmployee, isOpen, onToggle }) => {
    const dispatch = useDispatch();
    const selectedEmployeeId = useSelector((state) => state.app.selectedEmployeeId);
    const featureFlips = useSelector((state) => state.config.everyoneConfig.featureFlips);
    const locked = !featureFlips.invites;
    const [editedEmployee, updateEmployee, hasChanges] = useEditedEmployee(activeEmployee, locked);
    const { customClaims: initialClaims = {} } = activeEmployee;
    const { displayName, email, userRole, customClaims: editedClaims = {} } = editedEmployee;
    const assigningAdmin = editedEmployee.userRole === "admin";
    const assigningEditor = editedEmployee.userRole === "editor";
    const fullRoleDetails = USER_ROLES[userRole];
    const roleInfo = fullRoleDetails && fullRoleDetails.tooltip;
    const claimList = Object.entries(editedClaims || {})
        .filter(([_, value]) => value)
        .map(([key, _]) => {
            return CLAIM_OPTIONS.find((claim) => claim.id === key);
        });

    const handleChangeRole = (userRole) => {
        updateEmployee("userRole", userRole);
    };

    const handleToggleClaim = (claimId) => {
        const currentVal = editedClaims[claimId] || false;
        updateEmployee(claimId, !currentVal);
    };

    const handleSubmit = async () => {
        const willBeUser = editedEmployee?.isUser;
        const wasUser = activeEmployee?.isUser;

        if (!hasChanges) {
            // WHEN THERE ARE NO CHANGES
            applogger.info("No changes made");
            onToggle();
        } else if (!willBeUser && wasUser) {
            // WHEN REVOKING ACCESS
            applogger.info("Revoking access");
            dispatch(revokeAccessThunk(selectedEmployeeId));
            onToggle();
        } else if (willBeUser) {
            applogger.info("Changing claims");
            const currentClaims = {
                isUser: activeEmployee.isUser,
                userRole: activeEmployee.userRole,
                ...initialClaims,
            };
            let newClaims = {
                isUser: editedEmployee.isUser,
                userRole: editedEmployee.userRole,
                ...editedClaims,
            };
            if (!wasUser) {
                newClaims.inviteAccepted = false;
            }
            dispatch(changeWorkspaceClaimsThunk(selectedEmployeeId, newClaims, currentClaims));
            onToggle();
        } else {
            applogger.error("Unhandled case");
            onToggle();
        }
    };

    const disableSubmit = !hasChanges || (assigningEditor && claimList.length === 0);

    return (
        <MyModal isOpen={isOpen} onToggle={onToggle} height="lg">
            <MyModalHeader onToggle={onToggle}>Change User Role</MyModalHeader>
            <MyModalBody>
                <div className="space-y-4 pt-3 px-4 pb-8">
                    <div className="space-y-1">
                        <div className="text-lg tracking-tight font-medium">{displayName}</div>
                        <div className="text-sm tracking-tight">{email}</div>
                    </div>
                    <div className="flex border-t space-x-2">
                        <div className="flex-1 space-y-4">
                            <div className="space-y-1 pt-2">
                                <UserRolePicker
                                    disabled={locked}
                                    selectedIds={userRole || "none"}
                                    canCreateAdmins={editedClaims.canCreateAdmins || false}
                                    onChange={handleChangeRole}
                                />
                            </div>
                            {assigningAdmin && (
                                <div className="space-y-2">
                                    <div className="max-w-xs">
                                        <MyCheckbox
                                            size="sm"
                                            label="Can Create Admins"
                                            checked={editedClaims.canCreateAdmins || false}
                                            onChange={() => handleToggleClaim("canCreateAdmins")}
                                        />
                                    </div>
                                </div>
                            )}
                            {assigningEditor && (
                                <div className="space-y-2">
                                    {CLAIM_OPTIONS.filter((claim) => !claim.adminOnly).map((claim) => (
                                        <MyCheckbox
                                            key={`claim-${claim.id}`}
                                            size="sm"
                                            label={claim.label}
                                            checked={editedClaims[claim.id] || false}
                                            onChange={() => handleToggleClaim(claim.id)}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="flex-2 p-2 space-y-1 text-sm">
                            {assigningAdmin && (
                                <h6 className="text-error">WARNING: Admins have full access to your workspace.</h6>
                            )}
                            {roleInfo && !assigningAdmin && <span>{roleInfo}</span>}
                            {locked && <span>User role changes are disabled</span>}
                            <ul className="space-y-2 bullets divide-y">
                                {claimList.map((claim) => (
                                    <li className="py-2" key={`info-${claim.id}`}>
                                        {claim?.tooltip}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </MyModalBody>
            <MyModalFooter>
                <SubmitButton disabled={disableSubmit} onClick={handleSubmit} color="primary">
                    Submit
                </SubmitButton>
            </MyModalFooter>
        </MyModal>
    );
};

export default ChangeUserRole;