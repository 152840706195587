import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCheckedIdsThunk } from "../redux/appSlice";

export function useCheckedIds(saveId, dontClearOnUnmount = false) {
    const dispatch = useDispatch();
    const allCheckedIds = useSelector((state) => state.app.allCheckedIds);
    const checkedIds = useMemo(() => allCheckedIds[saveId] || [], [allCheckedIds, saveId]);

    // clear checked on unmount
    useEffect(() => {
        if (!dontClearOnUnmount) {
            return () => {
                if (saveId) {
                    dispatch(setCheckedIdsThunk(saveId, []));
                }
            };
        }
    }, [dispatch, dontClearOnUnmount, saveId]);

    const setChecked = useCallback(
        (update) => {
            dispatch(setCheckedIdsThunk(saveId, update || []));
        },
        [dispatch, saveId]
    );

    return [checkedIds, setChecked];
}
