import classNames from "classnames";
import { readableColor } from "polished";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import { EmptyIcon } from "../../../../components/Icons/MyIcons";
import MyEmptyState from "../../../../components/MyComponents/EmptyState";
import { getRatingColor } from "../../../Snapshots/utils/snapshotSelectors";
import { getTalentArea } from "../../../Workspace/utils/workspaceSelectors";
import TraitWordCloud from "./TraitWordCloud";

const TalentAreaSection = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    const { talentAreaId, rating, selected, horizontal } = props;
    const talentArea = useSelector((state) => getTalentArea(state, talentAreaId));
    const ratingColor = useSelector((state) => getRatingColor(state, rating));
    const tabStyle = {
        backgroundColor: ratingColor,
        color: readableColor(ratingColor),
    };
    const chevronStyle = {
        color: ratingColor,
    };

    return (
        <div
            className={classNames("relative cursor-pointer flex-1 flex items-center border-b border-base-100 border", {
                hovered: isHovered,
                "py-3": !horizontal,
            })}
            style={tabStyle}
            onClick={props.onSelect}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="flex justify-center items-center flex-1">
                <h6 className="flex-1 text-sm font-bold text-center">{talentArea.talentAreaName}</h6>
            </div>
            <div
                className={classNames("absolute", {
                    hidden: !selected,
                    "-right-4": horizontal,
                    "left-1/2 -bottom-4": !horizontal,
                })}
                style={chevronStyle}
            >
                {horizontal ? <AiFillCaretRight size={24} /> : <AiFillCaretDown size={24} />}
            </div>
        </div>
    );
};

const TalentStrength = (props) => {
    const { talentAreaRatings, ratings } = props;
    const [filteredRatings, setFilteredRatings] = useState([]);
    const [selectedTalentAreaId, setSelectedTalentAreaId] = useState(false);
    const traits = useSelector((state) => state.workspace.traits);
    const talentBoards = useSelector((state) => state.workspace.talentBoards);

    useEffect(() => {
        let filteredRatings = [...ratings];

        // When selected, filter traits for specific talent areas
        if (selectedTalentAreaId) {
            filteredRatings = filteredRatings.filter((rating) => {
                const trait = traits[rating.id];
                return trait?.linkedTalentAreaId === selectedTalentAreaId;
            });
        }
        setFilteredRatings(filteredRatings);
    }, [ratings, talentBoards, traits, selectedTalentAreaId]);

    const handleSelectTalentArea = (talentAreaId) => {
        if (talentAreaId === selectedTalentAreaId) {
            setSelectedTalentAreaId(false);
        } else {
            setSelectedTalentAreaId(talentAreaId);
        }
    };

    const isEmpty = Object.keys(talentAreaRatings).length === 0;

    talentAreaRatings.sort((a, b) => b.rating - a.rating);

    if (isEmpty) {
        return (
            <MyEmptyState message="No Talent Data Yet" className="flex-1">
                <EmptyIcon size={64} />
            </MyEmptyState>
        );
    }

    return (
        <div className={classNames(props.className, "overflow-y-auto flex-1 flex flex-col justify-between border")}>
            <div className={classNames("flex flex-1 min-h-84", { "flex-col": !props.horizontal })}>
                <div
                    className={classNames("flex-1 flex", {
                        "flex-col max-w-sm": props.horizontal,
                    })}
                >
                    {talentAreaRatings.map((entry) => (
                        <TalentAreaSection
                            horizontal={props.horizontal}
                            key={entry.id}
                            rating={entry.rating}
                            talentAreaId={entry.id}
                            selected={selectedTalentAreaId === entry.id}
                            onSelect={() => handleSelectTalentArea(entry.id)}
                            rowView={!props.column}
                        />
                    ))}
                </div>
                <div className="min-h-20 flex-2 rounded-tl-lg rounded-tr-lg w-full flex items-center justify-center overflow-hidden">
                    <TraitWordCloud isPending={props.isPending} ratings={filteredRatings} maxSize={props.maxWordSize} />
                </div>
            </div>
        </div>
    );
};

TalentStrength.propTypes = {
    talentAreaRatings: PropTypes.array,
    ratings: PropTypes.array,
    horizontal: PropTypes.bool,
    maxWordSize: PropTypes.number,
    className: PropTypes.string,
    minHeight: PropTypes.number,
};

TalentStrength.defaultProps = {
    talentAreaRatings: {},
    minHeight: 320,
    ratings: {},
    maxWordSize: 32,
};

export default TalentStrength;
