import { nanoid } from "nanoid";
import { Timestamp as FirestoreTimestamp } from "firebase/firestore";
import { COLORS, TOOLTIP_ID } from "../constants";
import { isEqual, sortBy } from "lodash";
export const IS_DEV = process.env.NODE_ENV === "development";
export const IS_TEST = process.env.NODE_ENV === "test";

export const getUniqueId = (length = 7) => {
    return nanoid(length);
};

export const checkIfUndefined = (value) => {
    let isArray = Array.isArray(value);
    if (isArray) {
        return value.some((item) => item === undefined);
    } else {
        return value === undefined;
    }
};

export function camelCaseToTitleCase(text) {
    return text.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
    });
}

export function filterObjectKeys(obj = {}, includeKeys = []) {
    let filtered = {};
    Object.keys(obj).forEach((key) => {
        if (includeKeys.includes(key)) {
            filtered[key] = obj[key];
        }
    });
    return filtered;
}

export function convertArrayToObject(array) {
    // Key must be id
    let obj = {};
    array.forEach((entry) => {
        let { id, ...newEntry } = entry;
        obj[id] = { ...newEntry };
    });
    return obj;
}

export function sortArrayOfObjects(sortThisArray, byThisField, sortOrder, parentKey) {
    const sortDesc = sortOrder === "desc" || sortOrder === true;

    const sortedArray = sortThisArray.slice().sort((a, b) => {
        let aValue = a[byThisField];
        let bValue = b[byThisField];

        // Use the parentKey to find the value from a parent object if specified
        if (parentKey) {
            const aParent = a[parentKey];
            const bParent = b[parentKey];
            aValue = aParent && aParent[byThisField];
            bValue = bParent && bParent[byThisField];
        }

        // Coerce undefined to false for boolean sorting
        if (typeof aValue === "boolean" || typeof bValue === "boolean") {
            aValue = aValue === undefined ? false : aValue;
            bValue = bValue === undefined ? false : bValue;
        }

        // Sorting logic for strings
        if (typeof aValue === "string" && typeof bValue === "string") {
            return sortDesc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
        }
        // Sorting logic for numbers
        else if (typeof aValue === "number" && typeof bValue === "number") {
            return sortDesc ? bValue - aValue : aValue - bValue;
        }
        // Sorting logic for booleans
        else if (typeof aValue === "boolean" && typeof bValue === "boolean") {
            if (aValue === bValue) {
                return 0; // no change if both are true or both are false
            } else if (sortDesc) {
                return aValue ? -1 : 1; // true comes first if descending
            } else {
                return aValue ? 1 : -1; // false comes first if ascending
            }
        }
        // Fallback case if types are not consistent or not recognized
        else {
            // Data types don't match; treat the values as equal.
            return 0;
        }
    });
    return sortedArray;
}

export function removeUndefined(dataToClean) {
    if (!dataToClean) return null;
    if (Array.isArray(dataToClean)) {
        let cleaned = [];
        dataToClean.forEach((entry) => {
            if (entry !== undefined && entry !== "undefined") cleaned.push(entry);
        });
        return cleaned;
    } else {
        Object.keys(dataToClean).forEach((key) => dataToClean[key] === undefined && delete dataToClean[key]);
        return dataToClean;
    }
}

export function removeNulls(initialData) {
    const cleanedData = { ...initialData };
    Object.keys(cleanedData).forEach((key) => {
        if (cleanedData[key] === null) {
            delete cleanedData[key];
        } else if (typeof cleanedData[key] === "object" && !Array.isArray(cleanedData[key])) {
            removeNulls(cleanedData[key]);
        }
    });
    return cleanedData;
}

export function getAddedAndRemoved(newIds = [], prevIds = []) {
    const removed = prevIds.filter((id) => !newIds.includes(id));
    const added = newIds.filter((id) => !prevIds.includes(id));
    return { added: added, removed: removed };
}

export function dateIsXDaysAgo(checkDate, days) {
    const date = new Date(checkDate);
    const today = new Date();
    const daysAgo = new Date(today.setDate(today.getDate() - days));
    return date < daysAgo;
}

export function dateIsBefore(thisDate, isBeforeThisDate) {
    const parseDate = (date) => {
        if (typeof date === "string") {
            return new Date(date);
        } else if (date && "seconds" in date && "nanoseconds" in date) {
            // Firestore timestamp
            return new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
        } else {
            return date;
        }
    };

    const isThisDate = parseDate(thisDate);
    const beforeThisDate = parseDate(isBeforeThisDate);

    return isThisDate < beforeThisDate;
}

export function daysAgo(date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const timeDiff = currentDate - inputDate;
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return isNaN(daysAgo) ? 0 : daysAgo;
}

export function yearsAgo(date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const yearsDifference = currentDate.getFullYear() - inputDate.getFullYear();

    // Check if the input date has not yet passed in the current year
    if (
        currentDate.getMonth() < inputDate.getMonth() ||
        (currentDate.getMonth() === inputDate.getMonth() && currentDate.getDate() < inputDate.getDate())
    ) {
        return yearsDifference - 1;
    }

    return yearsDifference;
}

export function changeDate(inputDate, days) {
    let date;

    // Check if inputDate is an ISO string or a Date object
    if (typeof inputDate === "string") {
        date = new Date(inputDate);
    } else if (inputDate instanceof Date) {
        date = new Date(inputDate.getTime()); // Create a new instance to avoid mutating the original date
    } else {
        throw new Error("Invalid date format. Please provide a Date object or an ISO string.");
    }

    // Add/subtract the days
    date.setDate(date.getDate() + days);

    // Return the new date in ISO string format
    return date.toISOString();
}

export function calculateDaysPassed(checkDate) {
    const convertToDateObject = (date) => {
        if (date instanceof Date) return new Date(date.setHours(0, 0, 0, 0));
        if (date instanceof FirestoreTimestamp) return new Date(date.toDate().setHours(0, 0, 0, 0));
        if (typeof date === "number") return new Date(new Date(date).setHours(0, 0, 0, 0)); // Assuming it's a timestamp in milliseconds
        return new Date(new Date(date).setHours(0, 0, 0, 0)); // Parses date strings
    };

    const date = convertToDateObject(checkDate);
    date.setHours(0, 0, 0, 0); // Normalize to start of the date

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to start of today

    const timeDiff = today - date;
    const daysPassed = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    return daysPassed || 0;
}

export function displayDate(date) {
    return date ? new Date(date).toLocaleDateString("en-GB") : null;
}

// Formats a date or firestore timestamp
export function formatDate(rawDate, showDay = true, showMonth = true, showYear = true) {
    if (!rawDate) return null;
    let date;
    if (rawDate instanceof Date) {
        date = rawDate;
    } else if (typeof rawDate === "object" && "seconds" in rawDate) {
        date = new Date(rawDate.seconds * 1000);
    } else if (typeof rawDate === "string") {
        date = new Date(rawDate);
    } else {
        return rawDate;
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    if (isNaN(day) || isNaN(year)) return rawDate;

    let formattedDate = "";
    if (showDay) formattedDate += `${day} `;
    if (showMonth) formattedDate += `${month} `;
    if (showYear) formattedDate += year;

    return formattedDate;
}

export const toggleItemInArray = (arr = [], item) => {
    const set = new Set(arr);
    set.has(item) ? set.delete(item) : set.add(item);
    return Array.from(set);
};

export function areArrayItemsTheSame(arr1 = [], arr2 = [], sortKey) {
    if (arr1.length !== arr2.length) return false;
    if (!sortKey) {
        return arr1.every((item) => arr2.includes(item));
    } else {
        // Use lodash isEqual and sortBy to compare arrays of objects
        const sorted1 = sortBy(arr1, sortKey);
        const sorted2 = sortBy(arr2, sortKey);
        return isEqual(sorted1, sorted2);
    }
}

export function getTooltipProps(tooltip, tooltipPosition = "top") {
    if (!tooltip) return {};
    return {
        "data-tip": tooltip,
        "data-for": TOOLTIP_ID,
        "data-place": tooltipPosition,
    };
}

// Gets values to darken or lighten colors color class provided; used in buttons and badges
export function getColorChanges(color) {
    const baseColor = COLORS[color] || COLORS.primary;
    switch (color) {
        case "accent":
            return [0.3, 0.6, baseColor];
        case "primary":
            return [0.3, 0.7, baseColor];
        case "success":
            return [0.4, 0.6, baseColor];
        case "info":
            return [0.3, 0.7, baseColor];
        default:
            return [0.2, 0.6, baseColor];
    }
}

export function mergeClassNames(defaultClassName, extraClasses = "") {
    const classGroups = {
        padding: ["p-", "px-", "py-", "pt-", "pb-", "ps-", "pe-"],
        margin: ["m-", "mx-", "my-", "mt-", "mb-", "ms-", "me-"],
        border: ["border", "border-t", "border-b", "border-s", "border-e", "border-x", "border-y"],
        size: ["min-h-", "max-h-", "min-w-", "max-w-", "h-", "w-"],
    };
    const allClassPrefixes = Object.values(classGroups).flat();

    let finalClassName = defaultClassName;

    allClassPrefixes.forEach((classPrefix) => {
        if (extraClasses.includes(classPrefix)) {
            // Regular expression to match padding classes in the default className
            const regex = new RegExp(`\\b${classPrefix}\\d+\\b`, "g");
            // Remove the padding class from the default className
            finalClassName = finalClassName.replace(regex, "");
        }
    });

    // Add extra classes to the final className
    return `${finalClassName} ${extraClasses}`.trim();
}
