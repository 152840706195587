import PropTypes from "prop-types";
import React from "react";
import Each from "../../molecules/Each/Each";

const DEFAULT_OPTIONS = [
    { value: 1, label: "Never" },
    { value: 25, label: "Rarely" },
    { value: 50, label: "Sometimes" },
    { value: 75, label: "Often" },
    { value: 100, label: "Always" },
];

const Input = ({ option, selectedValue, onChange }) => {
    const handleChange = () => {
        onChange(option.value);
    };

    return (
        <div className="flex text-sm flex-1 space-x-1 tracking-tight cursor-pointer">
            <div className="middle">
                <label>{option.label}</label>
                <input
                    className="checkbox checkbox-xs"
                    checked={selectedValue === option.value}
                    type="checkbox"
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

const LikertScale = ({ options = DEFAULT_OPTIONS, value = 60, onChange }) => {
    const handleChange = (value) => {
        onChange(value);
    };

    return (
        <div className="likert-scale space-x-6 flex">
            <Each
                of={options}
                render={(option) => <Input option={option} selectedValue={value} onChange={handleChange} />}
            />
        </div>
    );
};

LikertScale.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.arrayOf(PropTypes.number),
    onValueChange: PropTypes.func,
};

export default LikertScale;
