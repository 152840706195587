import React from "react";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./inputs.scss";

const NumberInput = (props) => {
    const { hideLabel, number, min, max, scrollIncrement, label, className, onBlur, onChange } = props;
    const inputRef = useRef(null);
    const [unvalidated, setUnvalidated] = useState(number);

    useEffect(() => {
        setUnvalidated(number);
    }, [number]);

    useEffect(() => {
        const ignoreScroll = (e) => {
            e.preventDefault();
        };
        inputRef.current && inputRef.current.addEventListener("wheel", ignoreScroll);
        inputRef.current.focus();
    }, []);

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            inputRef.current.blur();
        }
    };

    const handleWheel = (e) => {
        const incremented = Math.sign(e.deltaY) * scrollIncrement;
        let newValue = Math.max(unvalidated + incremented, min);
        newValue = max ? Math.min(newValue, max) : newValue;
        setTimeout(() => {
            handleChange(newValue);
        }, 100);
    };

    const handleChange = (value) => {
        if (!value || value <= max) {
            setUnvalidated(value);
            onChange(value);
        } else {
            setUnvalidated(max);
            onChange(max);
        }
    };

    const handleBlur = () => {
        if (isNaN(unvalidated)) {
            setUnvalidated(min);
        } else {
            const validatedNumber = Math.min(Math.max(unvalidated, min), max);
            if (!isNaN(validatedNumber)) {
                onBlur(validatedNumber);
                setUnvalidated(validatedNumber);
            } else {
                onBlur(min);
            }
        }
    };

    return (
        <div className={classNames("flex flex-col mc-number-input", className)} style={{ overflow: "auto" }}>
            <input
                ref={inputRef}
                type="number"
                className={classNames("number-input")}
                value={unvalidated || ""}
                onChange={(e) => handleChange(parseInt(e.target.value, 10))}
                onBlur={handleBlur}
                onWheel={handleWheel}
                onKeyDown={handleKeyDown}
            />
            {label && !hideLabel && (
                <h6 className="font-bold" htmlFor="total-employees">
                    {label}
                </h6>
            )}
        </div>
    );
};

NumberInput.propTypes = {
    number: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    scrollIncrement: PropTypes.number,
    label: PropTypes.string,
    className: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

NumberInput.defaultProps = {
    number: 0,
    min: 0,
    max: 99999999,
    scrollIncrement: 1,
    label: "",
    onBlur: () => {},
    onChange: () => {},
};

export default NumberInput;
