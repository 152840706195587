import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useDrop } from "react-dnd";

const DragAndDropCell = (props) => {
    const { onDrop, rowId, position, className, style } = props;
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "CARD",
        drop: (item) => {
            onDrop(item, rowId, position);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} style={style} className={classNames(className, { "cell-over": isOver })}>
            {props.children}
        </div>
    );
};

DragAndDropCell.propTypes = {
    primaryColHeader: PropTypes.string,
};

DragAndDropCell.defaultProps = {
    primaryColHeader: "Role",
};

export default DragAndDropCell;
