import React from "react";
import { ResponsiveContainer } from "../../../../../components";
import RatingCard from "../../../../EmployeeAssessments/objectives/RatingCard";
import ScheduledActionItem from "../../../../ScheduledActions/ScheduledActionItem";
import { Each } from "../../../../../common/components";
import TraitPolarAreaChart from "../../../../Traits/containers/TraitPolarAreaChart";
import TraitScroll from "../../../../Traits/components/organisms/TraitScroll";

const TalentAssessmentStepTemplate = ({ values, actions }) => {
    const {
        locked,
        draft,
        includedTraits,
        selectedTraitId,
        selectedTrait,
        recommendedActions,
        currentScheduledActionIds,
        completedActionIds,
    } = values;
    const { ratings, scheduleActionIds = [] } = draft;
    const { fetchTrait, fetchAction, selectTrait, changeRating, toggleScheduledAction } = actions;

    const getActionStatus = (actionId) => {
        if (currentScheduledActionIds.includes(actionId)) {
            return "in_progress";
        }
        if (completedActionIds.includes(actionId)) {
            return "completed";
        }
        return "none";
    };

    const handleChangeRating = (rating) => {
        if (locked) return;
        changeRating(selectedTraitId, rating);
    };

    const handleToggleScheduledAction = (actionId) => {
        if (locked) return;
        toggleScheduledAction(actionId);
    };

    return (
        <div className="flex relative fade h-full w-full items-stretch border rounded">
            <div className="h-full z-50 w-fit max-w-60 min-w-60 bg-base-300 p-2 flex flex-col justify-center overflow-hidden">
                <div className="w-full p-4">
                    <ResponsiveContainer>
                        <TraitPolarAreaChart
                            backgroundColor="white"
                            selectedTraitId={selectedTraitId}
                            traitName={selectedTrait?.traitName}
                            includedTraits={includedTraits}
                            onItemClick={selectTrait}
                            onBackgroundClick={selectTrait}
                            ratings={ratings}
                        />
                    </ResponsiveContainer>
                </div>
                <TraitScroll
                    rounded
                    traits={includedTraits}
                    selectedTraitId={selectedTraitId}
                    onSelectTrait={selectTrait}
                    ratings={ratings}
                    showRatingColors
                />
            </div>
            <div className="h-full flex-3 flex flex-col items-center justify-stretch overflow-hidden border-r bg-base-100">
                <RatingCard
                    className="flex-1 overflow-scroll h-full hide-scroll-bars"
                    selectedTraitId={selectedTraitId}
                    selectedTrait={selectedTrait}
                    includedTraits={includedTraits}
                    ratings={ratings}
                    fetchTrait={fetchTrait}
                    onChangeRating={handleChangeRating}
                    selectTrait={selectTrait}
                />
            </div>
            <div className="flex-2 max-w-md bg-base-100 p-4 overflow-auto pt-8">
                <h6 className="text-lg font-bold mb-2">Development Options</h6>
                <hr />
                <div className="overflow-auto space-y-4 pt-4">
                    <Each
                        of={recommendedActions}
                        emptyStateProps={{ hidden: true }}
                        render={(action) => {
                            return (
                                <ScheduledActionItem
                                    key={action.id}
                                    fetchAction={fetchAction}
                                    actionId={action.id}
                                    actionName={action.actionName}
                                    disabled={
                                        currentScheduledActionIds?.includes(action.id) ||
                                        completedActionIds?.includes(action.id)
                                    }
                                    status={getActionStatus(action.id)}
                                    checked={scheduleActionIds.includes(action.id)}
                                    onToggleChecked={() => handleToggleScheduledAction(action.id)}
                                />
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TalentAssessmentStepTemplate;
