/*
import React, { useState } from "react";
import { FaBell } from "react-icons/fa";
import MyDropdown from "../../components/MyComponents/Dropdown";
import NotificationItems from "./NotificationItems";
import { useNotifications } from "./hooks/useNotifications";

const NotificationsDropdown = () => {
    const { notificationCount, groupedNotifications, markAllAsRead } = useNotifications();
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = (newIsOpen) => {
        setIsOpen(newIsOpen);
        if (newIsOpen === false && notificationCount > 0) {
            markAllAsRead();
        }
    };

    return (
        <MyDropdown
            toggleIcon={FaBell}
            disabled={notificationCount === 0}
            count={notificationCount}
            width={360}
            isOpen={isOpen}
            onToggle={handleToggle}
        >
            {Object.entries(groupedNotifications).map(([notificationType, notifications]) => (
                <NotificationItems
                    key={notificationType}
                    group={notifications}
                    groupType={notificationType}
                    onItemClick={() => handleToggle(false)}
                />
            ))}
        </MyDropdown>
    );
};
*/

//TODO: Notification dropdown needs rethinking; disabled for now
const NotificationsDropdown = () => {
    return null;
};

export default NotificationsDropdown;