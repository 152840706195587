import { writeBatch } from "firebase/firestore";
import _ from "lodash";
import { useSelector } from "react-redux";
import { db } from "../../../firebase/firebase";
import { getUniqueId } from "../../../helpers/helpers";
import { writeBoardMap } from "../utils/businessUnitDb";

export function useBusinessUnits() {
    const workspaceId = useSelector((state) => state.workspace.workspaceId);
    const businessUnitId = useSelector((state) => state.businessUnit.businessUnitId);
    const roles = useSelector((state) => state.businessUnit.roles);
    const employees = useSelector((state) => state.businessUnit.employees);
    const boardMap = useSelector((state) => state.businessUnit.boardMap);

    function buildBoardMapUpdate(roleId, talentBoardIds, boardMapUpdate = {}) {
        // Add a boardMap update for any connected boards
        if (talentBoardIds) {
            talentBoardIds.forEach((boardId) => {
                const currentMap = boardMap?.[boardId] || [];
                if (!currentMap.includes(roleId)) {
                    let newMap = [...currentMap];
                    newMap.push(roleId);
                    boardMapUpdate[boardId] = newMap;
                }
            });
        }
        return boardMapUpdate;
    }

    function updateBoardMap(boardMapUpdate, batch = writeBatch(db)) {
        batch = writeBoardMap(workspaceId, businessUnitId, boardMapUpdate, batch);
        return batch;
    }

    function getUnitDocId(group, docSizeLimit) {
        // Find a viable doc that has fewer entries than the size limit, if there is one
        const items = group === "roles" ? roles : employees;
        const entriesToGroup = Object.values(items);
        const docGroups = _.groupBy(entriesToGroup, "unitDocId");
        const viableIds = Object.keys(docGroups).filter((docId) => {
            return docGroups[docId].length <= docSizeLimit;
        });

        // Return the first docId with space, or a new id if they're all full
        const id = viableIds[0] || getUniqueId();
        return id;
    }

    return { getUnitDocId, buildBoardMapUpdate, updateBoardMap };
}
