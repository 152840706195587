import { useCallback } from "react";
import { selectEmployeeThunk, selectRoleThunk } from "../../../redux/appSlice";
import { useEmployeeRelationships } from "../../Employees/hooks/useEmployeeRelationships";
import { useDispatch } from "react-redux";

export function useSelectOrgNodes() {
    const dispatch = useDispatch();
    const { checkCanManageEmployee, checkCanManageRole, checkIsSelf } = useEmployeeRelationships();

    const selectEmployee = useCallback(
        (empId) => {
            if (empId === undefined) return;
            dispatch(selectEmployeeThunk(empId));
        },
        [dispatch]
    );

    const selectRole = useCallback(
        (roleId) => {
            dispatch(selectRoleThunk(roleId));
        },
        [dispatch]
    );

    const getEmployeePath = useCallback((employeeId) => {
        return `organisation/view/employees/${employeeId}`;
    }, []);

    const getRolePath = useCallback((roleId) => {
        return `organisation/view/roles/${roleId}`;
    }, []);

    const getOrgNodeLink = useCallback(
        (roleId, employeeId, tab) => {
            if (employeeId) {
                const isSelf = checkIsSelf(employeeId);
                const defaultTab = isSelf ? "things-to-do" : "talent-assessment";
                const employeeTab = tab || defaultTab;
                const canManageEmployee = checkCanManageEmployee(employeeId);
                const okToView = isSelf || canManageEmployee;
                if (!okToView) return null;
                const employeePath = getEmployeePath(employeeId);
                return `/${employeePath}/${employeeTab}`;
            } else if (roleId) {
                const roleTab = tab || "team";
                const canManageRole = checkCanManageRole(roleId);
                const rolePath = getRolePath(roleId);
                return canManageRole && `/${rolePath}/${roleTab}`;
            }
        },
        [checkCanManageEmployee, checkCanManageRole, getEmployeePath, getRolePath, checkIsSelf]
    );

    return {
        selectEmployee,
        selectRole,
        getOrgNodeLink,
        getEmployeePath,
        getRolePath,
    };
}
