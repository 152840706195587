import { onSnapshot } from "firebase/firestore";
import { useEffect, useMemo, useRef, useState } from "react";
import { dropListener, listenerTracker, readTracker } from "../../common/utils/activityTracker";
import applogger from "../../common/utils/applogger";
import { checkIfFirestoreRef } from "../utils/identifyFirestoreRef";

export function useListener(firestoreRef, onDataReceived, isActive = true) {
    const isListening = useRef(false);
    const hasAttempted = useRef(false);
    const hasSucceeded = useRef(false);
    const [status, setStatus] = useState("idle");

    // Determine if the listener should be active or not
    const shouldListen = useMemo(() => {
        const isValidRef = checkIfFirestoreRef(firestoreRef);
        return isValidRef && isActive;
    }, [firestoreRef, isActive]);

    useEffect(() => {
        let unsubscribe;
        if (shouldListen && !isListening.current) {
            try {
                isListening.current = true;
                hasAttempted.current = true;
                listenerTracker(firestoreRef);
                unsubscribe = onSnapshot(firestoreRef, (snapshot) => {
                    if (snapshot.docs) {
                        const readCount = snapshot.docChanges().length;
                        readTracker(firestoreRef, readCount);
                    } else {
                        readTracker(firestoreRef, 1);
                    }
                    onDataReceived(snapshot);
                    if (!hasSucceeded.current) {
                        hasSucceeded.current = true;
                        setStatus("success");
                    }
                });
                
            } catch (error) {
                applogger.error(firestoreRef, error);
                hasSucceeded.current = false;
                setStatus("failed");
            }
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
                dropListener(firestoreRef);
                isListening.current = false;
                hasSucceeded.current = false;
                setStatus("idle");
            }
        };
    }, [shouldListen, firestoreRef, onDataReceived]);

    // If the listener has not succeeded after 5 seconds, throw an error
    useEffect(() => {
        if (shouldListen) {
            const timeout = setTimeout(() => {
                if (!hasSucceeded.current) {
                    setStatus("timeout");
                    applogger.error(firestoreRef, new Error("Listener timeout after 5 seconds"));
                }
            }, 5000);
            return () => clearTimeout(timeout);
        }
    }, [shouldListen, firestoreRef]);

    return status;
}
