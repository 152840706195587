import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-daisyui";
import MyTooltip from "./Tooltip";

const MyButton = (props) => {
    if (props.hidden) return null;
    const {
        style,
        label,
        tooltip,
        tooltipPosition,
        tooltipColor,
        extraClasses,
        color,
        disabled,
        activeColor,
        dropdownItem,
        notificationCount,
        loadingLabel,
        disableOnAny = [],
        ...cleanProps
    } = props;
    const colorToUse = props.active ? activeColor : props.color;
    const accessibility = {
        "aria-label": label,
    };
    const disableFlags = [disabled, ...disableOnAny];
    const disableButton = disableFlags.some((flag) => flag);

    const renderButton = () => {
        return (
            <MyTooltip tooltip={tooltip} position={tooltipPosition} color={tooltipColor}>
                <Button
                    {...cleanProps}
                    {...accessibility}
                    disabled={disableButton}
                    style={style}
                    color={colorToUse}
                    className={classNames(`text-${color}-content, tracking-wide focus:outline-base-500`, extraClasses)}
                >
                    {!props.loading ? props.children : loadingLabel}
                </Button>
            </MyTooltip>
        );
    };

    if (notificationCount > 0) {
        return (
            <div className="indicator">
                <span className="indicator-item badge badge-secondary"></span>
                {renderButton()}
            </div>
        );
    } else {
        return renderButton();
    }
};

MyButton.propTypes = {
    glass: PropTypes.bool,
    animation: PropTypes.bool,
    shape: PropTypes.oneOf(["circle", "square"]),
    active: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    tooltip: PropTypes.string,
    tooltipPosition: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    color: PropTypes.oneOf([
        "primary",
        "ghost",
        "secondary",
        "accent",
        "neutral",
        "error",
        "info",
        "warning",
        "success",
        "base-100",
    ]),
    activeColor: PropTypes.string,
    borderColor: PropTypes.string,
    label: PropTypes.string.isRequired,
    loadingLabel: PropTypes.string,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(["link", "outline"]),
    style: PropTypes.object,
    children: PropTypes.node,
    hidden: PropTypes.bool,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

MyButton.defaultProps = {
    animation: true,
    glass: false,
    label: "",
    disabled: false,
    size: "md",
    onClick: () => {},
};

export default MyButton;
