// IMPORTANT
//////////////////////////////////////////////////////////////////
// Single axis Grid is a retired feature only enabled for legacy workspaces

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

import { listAxisValue } from "../../Snapshots/utils/snapshotHelpers";

export function prepSingleAxisGridData(snapshots, config) {
    if (config?.gridThresholds) {
        const { grid, gridThresholds } = config;
        const preppedGrid = grid.map((box) => {
            const { row, col } = box;
            const segmentThresholds = gridThresholds.find(
                (thresholds) => thresholds.row === row && thresholds.col === col
            );
            const { min, max } = segmentThresholds || {};
            return {
                ...box,
                min: min,
                max: max,
            };
        });
        return Object.entries(snapshots).map(([empId, snap]) => {
            return getCustomEmployeeGridPosition(empId, snap, preppedGrid, config);
        });
    } else {
        return dynamicSingleAxisGrid(snapshots, config);
    }
}

function dynamicSingleAxisGrid(snapshots, config) {
    const { z, grid } = config;

    // Get the statically placed positions (top talent flag)
    let placements = [];
    grid.filter((box) => !!box.requiredField).forEach((box) => {
        const { col, row, ...rest } = box;
        const requiredField = box.requiredField;
        Object.entries(snapshots)
            .filter(([_, snap]) => !!snap?.flags[requiredField])
            .forEach(([empId, snap]) => {
                const zVal = listAxisValue(snap, z);
                placements.push({ employeeId: empId, ...rest, x: col, y: row, z: zVal });
            });
    });

    // Get the dynamically placed positions
    const boxes = grid.filter((box) => !box.requiredField).length;
    const boxRange = Math.ceil(100 / boxes);
    Object.entries(snapshots).forEach(([empId, snap]) => {
        if (!placements.find((entry) => entry.employeeId === empId)) {
            const zVal = listAxisValue(snap, z);
            const boxIndex = Math.ceil(zVal / boxRange);
            let box = grid[boxIndex - 1];
            const { col, row, ...rest } = box || {};
            placements.push({ employeeId: empId, ...rest, x: col, y: row, z: zVal });
        }
    });
    return placements;
}

//// uses hard coded grid thresholds provided in config
export function getCustomEmployeeGridPosition(empId, snap, preppedGrid, config) {
    const { z } = config;
    const zVal = listAxisValue(snap, z);

    // Temp - TopTalent Flag
    const isTop = snap?.flags?.["f4"];
    let employeeBox = {};
    if (isTop) {
        employeeBox = preppedGrid.find((box) => box.requiredField === "f4");
    } else {
        employeeBox = preppedGrid.find((box) => {
            const isWithinRange = zVal >= box.min && zVal <= box.max;
            return isWithinRange;
        });
    }

    if (!employeeBox) return {};

    const { min, max,row, col, ...cleaned } = employeeBox;
    const prepped = {
        ...cleaned,
        x: col,
        y: row,
        employeeId: empId,
        z: zVal,
    };
    return prepped;
}
