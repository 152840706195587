export function checkIfEmptyContent(contentState) {
    // check type of content
    const contentType = typeof contentState;
    switch (contentType) {
        case "string":
            return contentState.trim().length === 0;
        case "object":
            try {
                const { blocks } = contentState;
                return !blocks || blocks.length === 0;
            } catch (e) {
                return true;
            }
        default:
            return true;
    }
}
