import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useListener } from "../../../firebase/hooks/useListener";
import { prepQuerySnapshot } from "../../../firebase/utils/prepFirebaseSnapshots";
import { setLiveCycles } from "../../Workspace/utils/workspaceSlice";
import { useCycleRefs } from "../refs/useCycleRefs";

function useCycleListeners() {
    const dispatch = useDispatch();
    const needsLiveCycles = useSelector((state) => state.app.needsLiveCycles);
    const { liveCyclesRef } = useCycleRefs();

    const handleLiveDataReceived = useCallback(
        (querySnapshot) => {
            const data = prepQuerySnapshot(querySnapshot);
            dispatch(setLiveCycles(data));
        },
        [dispatch]
    );

    useListener(liveCyclesRef, handleLiveDataReceived, needsLiveCycles);
}

export default useCycleListeners;
