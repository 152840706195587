import React, { useRef, useState } from "react";
import { useEmployeeFields } from "../../../../../../features/Employees/hooks/useEmployeeFields";
import { usePreppedEmployees } from "../../../../../../features/Employees/hooks/usePreppedEmployees";
import { useSubordinates } from "../../../../../../features/Employees/hooks/useSubordinates";
import TalentMapToolbar from "../../../../../../features/TalentMap/components/organisms/TalentMapToolbar/TalentMapToolbar";
import TalentMapTemplate from "../../../../../../features/TalentMap/components/templates/TalentMapTemplate/TalentMapTemplate";
import { useTalentMap } from "../../../../../../features/TalentMap/hooks/useTalentMap";
import { useTalentMapConfig } from "../../../../../../features/TalentMap/hooks/useTalentMapConfig";
import { useFieldLabels } from "../../../../../../features/Workspace/hooks/useFieldLabels";
import { useOrgContext } from "../../../../OrgPage";
import { useOrgNodeContext } from "../../OrgNodePage";

const INCLUDE_TOP_ROLE = false

const RoleTalentMapPage = () => {
    const { navigateToOrgNode } = useOrgContext();
    const { roleId } = useOrgNodeContext();
    const [editMode, setEditMode] = useState("none");
    const talentMapRef = useRef(null);
    const { talentMapConfig } = useTalentMapConfig();
    const { allFields } = useEmployeeFields();
    const subordinates = useSubordinates(roleId, INCLUDE_TOP_ROLE);
    const preppedEmployees = usePreppedEmployees(subordinates, allFields);
    const { grid, scatterData } = useTalentMap(preppedEmployees, talentMapConfig);
    const { getFieldLabel } = useFieldLabels();

    const handleItemClick = (employeeId) => {
        navigateToOrgNode(null, employeeId);
    };

    return (
        <div className="flex-1 flex flex-col">
            <div className="pt-4 px-4">
                <TalentMapToolbar talentMapRef={talentMapRef} hideControls />
            </div>
            <TalentMapTemplate
                editMode={editMode}
                talentMapRef={talentMapRef}
                talentMapConfig={talentMapConfig}
                grid={grid}
                scatterData={scatterData}
                setEditMode={setEditMode}
                getFieldLabel={getFieldLabel}
                onItemClick={handleItemClick}
            />
        </div>
    );
};

export default RoleTalentMapPage;
