import classNames from "classnames";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FaUserPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import applogger from "../../common/utils/applogger";
import MyButton from "../../components/MyComponents/Button";
import { useTopLevelRoleId } from "../../features/BusinessUnits/hooks/useTopLevelRoleId";
import { useConfig } from "../../features/Config/hooks/useConfig";
import { useEmployeeRelationships } from "../../features/Employees/hooks/useEmployeeRelationships";
import OrgToolbar from "../../features/Organisation/components/OrgToolbar";
import { useExpandedNodes } from "../../features/Organisation/hooks/useExpandedNodes";
import { useOrgChart } from "../../features/Organisation/hooks/useOrgChart";
import { useOrgData } from "../../features/Organisation/hooks/useOrgData";
import { useSelectOrgNodes } from "../../features/Organisation/hooks/useSelectOrgNodes";
import { usePathArray } from "../../hooks/usePathArray";
import { selectEmployeeThunk, selectRoleThunk, setActiveModal } from "../../redux/appSlice";
import { LoadingIndicator } from "../../components";

const EmptyOrg = ({ activeRoleId }) => {
    const dispatch = useDispatch();
    const topLevelRoleId = useTopLevelRoleId();
    return (
        <div className={classNames(activeRoleId && "hidden", "flex-1 flex py-5 justify-center")}>
            <div className="p-3 text-center rounded bg-base-100 w-1/2">
                <div className="rounded-full bg-light w-1/4 m-auto flex items-center justify-center mb-3">
                    <FaUserPlus size={64} />
                </div>
                <h3 className="mb-4 text-center">Your Organisation is Empty</h3>
                <p>Create your first role to get going.</p>
                <div className="mt-5 flex justify-center">
                    <MyButton
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(selectRoleThunk(topLevelRoleId));
                            dispatch(setActiveModal("createRole"));
                        }}
                        size="lg"
                    >
                        Add Role
                    </MyButton>
                </div>
            </div>
        </div>
    );
};

const OrgContext = createContext();

export function useOrgContext() {
    return useContext(OrgContext);
}

const OrgPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const pathArray = usePathArray();
    const { ownConfig, setConfigValue } = useConfig();
    const [toolbarComponents, setToolbarComponents] = useState(null);
    const activeSideArea = useSelector((state) => state.app.activeSideArea);
    const isAdmin = useSelector((state) => state.user.isAdmin);
    const userEmployeeId = useSelector((state) => state.user.userEmployeeId);
    const userRoleId = useSelector((state) => state.user.userRoleId);
    const rootRoleId = useSelector((state) => state.workspace.rootRoleId);
    const { filteredTopLevelRoleId } = ownConfig;
    const highestAllowedRoleId = useTopLevelRoleId();
    const roles = useSelector((state) => state.businessUnit.roles);
    const { getOrgNodeLink, selectEmployee, selectRole } = useSelectOrgNodes();

    const { topLevelRoleId, topLevelOrgChartRoleId } = useMemo(() => {
        const topLevelRoleId = filteredTopLevelRoleId || highestAllowedRoleId;
        const topLevelOrgChartRoleId = filteredTopLevelRoleId || rootRoleId;
        return { topLevelRoleId, topLevelOrgChartRoleId };
    }, [filteredTopLevelRoleId, highestAllowedRoleId, rootRoleId]);

    const [preppedEmployees, pageReady] = useOrgData(topLevelRoleId, highestAllowedRoleId);

    const showToolbar = !pathArray.includes("view");
    const { orgTree } = useOrgChart(topLevelOrgChartRoleId);

    const { expandedNodes, updateExpandedNodes } = useExpandedNodes();
    const { getDirectReportIds } = useEmployeeRelationships();
    const queryParams = new URLSearchParams(window.location.search);
    const goToEmployeeId = queryParams.get("employeeId");

    // deselect role on unmount
    useEffect(() => {
        return () => {
            dispatch(selectRoleThunk());
        };
    }, [dispatch]);

    useEffect(() => {
        if (goToEmployeeId) {
            dispatch(selectEmployeeThunk(goToEmployeeId));
        }
    }, [goToEmployeeId, dispatch]);

    const hasDirectReports = useMemo(() => {
        return getDirectReportIds(userEmployeeId).length > 0;
    }, [getDirectReportIds, userEmployeeId]);

    function switchTab(tab) {
        setConfigValue("orgView", tab);
        const pageName = pathArray[0];
        navigate(`/${pageName}/${tab}`);
    }

    const deselectNode = useCallback(() => {
        if (!activeSideArea) {
            const fullPath = pathArray.join("/");
            navigate(`/${fullPath}`);
            dispatch(selectRoleThunk());
        }
    }, [activeSideArea, pathArray, navigate, dispatch]);

    const setTopLevelRoleId = useCallback(
        (roleId) => {
            setConfigValue("filteredTopLevelRoleId", roleId);
            setConfigValue("expandedNodes", { [roleId]: true });
            dispatch(selectRoleThunk());
        },
        [setConfigValue, dispatch]
    );

    const navigateToOrgNode = useCallback(
        (roleId, employeeId) => {
            const orgNodeLink = getOrgNodeLink(roleId, employeeId);
            if (orgNodeLink) {
                navigate(orgNodeLink);
            }
        },
        [getOrgNodeLink, navigate]
    );

    const handleSaveNote = useCallback((note) => {
        applogger.info("save note", note);
    }, []);

    const handleSaveObjective = useCallback((objective) => {
        applogger.info("save objective", objective);
    }, []);

    const value = {
        isAdmin,
        showToolbar,
        activeTab: pathArray[1],
        expandedNodes,
        userRoleId,
        topLevelRoleId,
        topLevelOrgChartRoleId,
        highestAllowedRoleId,
        ownConfig,
        orgTree,
        preppedEmployees,
        hasDirectReports,
        rootRoleId,
        setToolbarComponents,
        updateExpandedNodes,
        saveNote: handleSaveNote,
        saveObjective: handleSaveObjective,
        setConfigValue,
        selectEmployee,
        selectRole,
        deselectNode,
        navigateToOrgNode,
        setTopLevelRoleId,
        switchTab,
    };

    if (Object.keys(roles || {}).length === 1) {
        return <EmptyOrg activeRoleId={activeSideArea} />;
    }

    return (
        <OrgContext.Provider value={value}>
            <OrgToolbar show={showToolbar}>{toolbarComponents}</OrgToolbar>
            {pageReady ? <Outlet /> : <LoadingIndicator fullscreen message="Building Org Chart" />}
        </OrgContext.Provider>
    );
};

export default OrgPage;
