// Formats a date or firestore timestamp
export function formatDate(rawDate, showDay = true, showMonth = true, showYear = true) {
    if (!rawDate) return null;
    let date;
    if (rawDate instanceof Date) {
        date = rawDate;
    } else if (typeof rawDate === "object" && "seconds" in rawDate) {
        date = new Date(rawDate.seconds * 1000);
    } else if (typeof rawDate === "string") {
        date = new Date(rawDate);
    } else {
        return rawDate;
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    if (isNaN(day) || isNaN(year)) return rawDate;

    let formattedDate = "";
    if (showDay) formattedDate += `${day} `;
    if (showMonth) formattedDate += `${month} `;
    if (showYear) formattedDate += year;

    return formattedDate;
}

export function daysAgo(date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const timeDiff = currentDate - inputDate;
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return isNaN(daysAgo) ? 0 : daysAgo;
}

export function convertFirestoreTimestamp(data) {
    if (!data) return null;
    if (data && typeof data === "object" && "seconds" in data) {
        // Convert Firestore timestamp to JavaScript Date
        return new Date(data.seconds * 1000);
    } else {
        try {
            const date = new Date(data);
            if (isNaN(date.getTime())) {
                return new Date(); // Return today's date if parsing fails
            }
            return date;
        } catch {
            return null;
        }
    }
}
