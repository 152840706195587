import React from "react";
import MyBadge from "../../../../components/MyComponents/Badge";

const ObjectiveScopeBadge = ({ objective, hidden }) => {
    if (hidden) return null;
    switch (objective.cascadeType) {
        case "team":
            return (
                <MyBadge color="info" className="text-sm">
                    Team
                </MyBadge>
            );
        case "branch":
            return (
                <MyBadge color="secondary" className="text-sm">
                    Branch
                </MyBadge>
            );
        case "individual":
        default:
            return (
                <MyBadge color="primary" className="text-sm">
                    Individual
                </MyBadge>
            );
    }
};

export default ObjectiveScopeBadge;
