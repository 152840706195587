import { useMemo } from "react";
import { useSelector } from "react-redux";

export function useOrgTableConfig(orgTableId) {
    const orgTables = useSelector((state) => state.config.orgTables);
    const savedActiveOrgTableId = useSelector((state) => state.config.ownConfig.activeOrgTableId);

    const activeOrgTableId = useMemo(() => {
        if (savedActiveOrgTableId) return savedActiveOrgTableId;
        if (Object.keys(orgTables).length === 0) return null;
        return Object.keys(orgTables)[0];
    }, [orgTables, savedActiveOrgTableId]);

    const activeOrgTable = useMemo(() => {
        if (!activeOrgTableId) return null;
        return orgTables[activeOrgTableId];
    }, [activeOrgTableId, orgTables]);

    const orgTableConfig = useMemo(() => {
        if (orgTableId) {
            return orgTables[orgTableId];
        } else {
            return activeOrgTable || {};
        }
    }, [activeOrgTable, orgTableId, orgTables]);

    

    return { activeOrgTable, orgTableConfig, activeOrgTableId };
}
